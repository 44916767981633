import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import Loader from "../../../../components/Loader"
import activeAndSortedList from "../../../../components/utils/activeAndSortedList"

import * as reducers from "../../reducers"

import CategorySelector from "./CategorySelector"
import { categoryIcon } from '../../../../components/Icons'

class DepartmentSelector extends Component {
  state = {
    selected_department: undefined
  }

  static getDerivedStateFromProps(props, state) {
    const { selected_department } = state

    // Set Initial Selection
    if (selected_department === undefined) {
      return { selected_department: activeAndSortedList(Object.values(props.departments))[0] }
    }
    return null
  }

  renderDepartmentLinks = () => {
    const { departments } = this.props
    const { selected_department } = this.state
    const departmentId = _.get(selected_department, "id")

    const orderedActiveDepartmentsArr = activeAndSortedList(Object.values(departments))

    if (departments == null) { return (<Loader />) }

    return (
      orderedActiveDepartmentsArr.map(department => (
        <Link
          key={`${department.attributes.slug}`}
          to={`/shop/${department.attributes.slug}`}
          onMouseEnter={() => this.setState({ selected_department: department })}>
          <li className={department.id === departmentId ? "active" : ""}>
            {department.links.icon.tiny.url && (
              <span className="dep-icon">
                <img className="mr-1" src={department.links.icon.tiny.url} />
              </span>
            )}
            {department.attributes.name}
          </li>
        </Link>
      ))
    )
  }

  renderCategories = () => {
    const { selected_department } = this.state
    const { isFetchingCategories } = this.props

    if (selected_department === undefined || isFetchingCategories) { return (null) }

    return <CategorySelector department={selected_department} />
  }

  renderNavbarInner = () => {
    const { departments } = this.props
    const { selected_department } = this.state

    return (
      <div className="row no-gutters">
        <div className="col-xl-4 border-right pr-0 department-selector">
          <ul className="list-unstyled department-list">
            { departments && this.renderDepartmentLinks() }
          </ul>
        </div>
        <div className="col-xl-8">
          { selected_department && this.renderCategories() }
        </div>
      </div>
    )
  }

  render() {
    const { departments } = this.props

    return (
      <li className="nav-item dropdown nav-department">
        <a
          aria-expanded="false"
          aria-haspopup="true"
          className="nav-link dropdown-toggle btn"
          data-toggle="dropdown"
          href="/"
          id="navbarDropdown"
          role="button">
            <img src={categoryIcon} className='mr-1' width='16px' height='16px' />
          Shop by department
        </a>
        <div aria-labelledby="navbarDropdown" className="dropdown-menu">
          {
            (_.isEmpty(departments))
            ? <div className="mt-4 mb-4"><Loader /></div>
            : this.renderNavbarInner()
          }
        </div>
      </li>
    )
  }
}

const mapStateToProps = state => ({
  departments: reducers.getAllDepartments(state)
})

export default connect(mapStateToProps, {})(DepartmentSelector)
