import { useEffect } from 'react'

const useElementOnDOMTree = ({ id, offset = 100 }) => {
  const callBack = (mutations, obs) => {
    if (id) {
      const dealCard = document.getElementById(id)
      if (dealCard) {
        const headerOffset = offset
        const elementPosition = dealCard.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset
        window.scrollTo({
          behavior: 'smooth',
          top: offsetPosition
        })
        obs.disconnect()
      }
    }
  }

  useEffect(() => {
    if (id) {
      const observer = new window.MutationObserver(callBack)
      observer.observe(document, {
        childList: true,
        subtree: true
      })
    }
  }, [id])
}

export default useElementOnDOMTree
