// ToDo: Remove Deal: TopElasticDeals, AllElasticDeals
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../../../../components/Loader'

import Breadcrumb from '../../../../../components/Breadcrumb'
import Meta from '../../../../../components/Meta'
import { loadStorePage, seoContentRequestStart } from '../../../actions'
import * as reducers from '../../../reducers'
import Collections from '../../Collections'
// import { getTopElasticDeals, getAllElasticDeals } from '../../../requesters'
import Header from './Header'
import StoreReviews from './StoreReviews'
import { checklist } from './validateCheckList'

import {
  // TopPriceDropsList,
  // AllProductsList,
  TopCouponsList,
  TopSalesEventsList,
  SeoInfo
} from '../../listings'


class StorePage extends Component {
  constructor () {
    super()
    // this.state = { topDealResponse: [] }
    this.storeReviewDetailsRef = React.createRef()
  }

  componentDidMount () {
    const {
      loadStorePage,
      seoContentRequestStart,
      match: {
        params: { slug }
      }
    } = this.props

    loadStorePage({ store: slug })
    seoContentRequestStart({ store: slug })
    // if (this.props.location.state?.storeName || this.props?.store?.attributes?.name) {
    //   const params = {
    //     per_page: 50,
    //     store_names: [
    //       this.props.location.state?.storeName || this.props?.store?.attributes?.name
    //     ],
    //     price_shift: [-1, 0]
    //   }
    //   getTopElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ topDealResponse: response?.data || [] })
    //   })
    //   getAllElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ allDealResponse: response?.data || [] })
    //   })
    // }
  }

  componentDidUpdate (prevProps) {
    const {
      loadStorePage,
      match: {
        params: { slug }
      }
    } = this.props

    const prevSlug = prevProps.match.params.slug
    // if (this.props.store !== null && this.props?.store?.attributes?.name !== prevProps?.store?.attributes?.name && !this.props.location.state?.storeName) {
    //   const params = {
    //     per_page: 50,
    //     store_names: [
    //       this.props.store.attributes.name
    //     ],
    //     price_shift: [-1, 0]
    //   }
    //   getTopElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ topDealResponse: response?.data || [] })
    //   })
    //   getAllElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ allDealResponse: response?.data || [] })
    //   })
    // }
    if (prevSlug !== slug) {
      loadStorePage({ store: slug })
    }
  }

  checkDataNull () {
    const {
      // topPriceDropIds,
      topSaleEventIds, topCouponIds, newsletterIds
    } = this.props

    const ids = _.flatten(_.concat(
      // topPriceDropIds,
      topSaleEventIds, topCouponIds, newsletterIds))
    if (_.isEmpty(ids) && this.checkCollectionDataNull()) {
      return true
    }
    return false
  }

  checkIfFetchingData () {
    const {
      // isFetchingTopPriceDrops,
      isFetchingTopSaleEvents, isFetchingTopCoupons, isFetchingNewsletters
    } = this.props

    if (
      // isFetchingTopPriceDrops ||
      isFetchingTopSaleEvents || isFetchingTopCoupons || isFetchingNewsletters || this.checkIfFetchingCollectionData()) {
      return true
    }
    return false
  }

  checkCollectionDataNull (data) {
    if (_.isEmpty(data)) return true
    return false
  }

  checkIfFetchingCollectionData (status) {
    if (_.includes([undefined, null, 'finished'], status)) return false
    return true
  }

  scrollToReviews () {
    window.scrollTo({ top: this.storeReviewDetailsRef?.current?.offsetTop - 25, behavior: 'smooth' })
  }

  renderMainContent () {
    const {
      store,
      store: {
        attributes: {
          name
        },
        links
      }
    } = this.props
    const storeDepartment = { attributes: { name } }

    return (
      <>
        {
          this.checkIfFetchingData()
            ? <Loader isLoading />
            : (
              <>
                {/* <TopPriceDropsList
                  viewAllUrl={`${links.storeUrl}/top-price-drops`}
                  department={storeDepartment}
                  wrapperClass='listing-carousel'
                  currentSection={name + ' PriceDrop'} sectionPosition='1'
                  store={this.props.store}
                  topDealList={this.state?.topDealResponse}
                /> */}
                {/* <AllProductsList
                  viewAllUrl={`${links.storeUrl}/all-products`}
                  department={storeDepartment}
                  wrapperClass='listing-carousel'
                  currentSection={'Discover More Products from' + name}
                  sectionPosition='2'
                  store={this.props.store}
                  topDealList={this.state?.allDealResponse}
                /> */}
                <TopSalesEventsList
                  viewAllUrl={`${links.storeUrl}/top-sales-events`}
                  title={`${name} Sales`}
                  wrapperClass='listing-carousel'
                  currentSection={name + ' SalesEvents'}
                  sectionPosition='3'
                />
                <Collections
                  placements='highlights'
                  store={store}
                  extraClass=''
                  checkIfNull={this.checkCollectionDataNull}
                  check
                  checkFetchStatus={this.checkIfFetchingCollectionData}
                  currentSection={name + ' Collections'}
                  sectionPosition='4'
                />
                <TopCouponsList
                  viewAllUrl={`${links.storeUrl}/top-promo-codes`}
                  title={`${name} Promo Codes`}
                  extraClass=''
                  currentSection={name + ' Promo Codes'}
                  sectionPosition='5'
                />
                {
                  parseFloat(store?.attributes?.storeRating || 0) > 0 &&
                    <section ref={this.storeReviewDetailsRef} className='section-group reviews-on-storepage'>
                      <div className='container'>
                        <StoreReviews store={store} />
                      </div>
                    </section>
                }
                <div className='container py-5 text-center'>
                  {this.checkDataNull() && !this.checkIfFetchingData() && `Looks like there's no great deals for ${name} right now. Hit Follow and we'll alert you as soon as there's some great deals to be had`}
                </div>
              </>
              )
        }
      </>
    )
  }

  render () {
    const {
      store,
      location,
      seo,
      isFetchingSeoContent
    } = this.props

    const metaProps = {
      name: 'Store Page',
      description: `See ${store?.attributes?.name}'s best Price Drops, Sale Events, Vouchers, Clearance and Discounts at Little Birdie`,
      title: `${store?.attributes?.name} | Best Deals & Vouchers | Little Birdie`
    }

    if (checklist.includes(store?.id)) {
      metaProps.name = 'robots'
      metaProps.description = 'noindex'
    }

    return (
      <>
        <div className='container main-wrapper'>
          {store && (
            <Meta {...metaProps} />
          )}
          <Breadcrumb breadcrumbs={location} />
          {store && <Header scrollToReviews={() => this.scrollToReviews()} store={store} />}

        </div>
        {store && this.renderMainContent()}
        {store && <SeoInfo seoData={seo.data} isLoading={isFetchingSeoContent} />}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  store: reducers.getStoreBySlug(state, props.match.params.slug),
  // topPriceDropIds: reducers.getTopDealIds(state),
  // isFetchingTopPriceDrops: reducers.isFetchingTopDeals(state),
  topSaleEventIds: reducers.getTopSaleEventIds(state),
  isFetchingTopSaleEvents: reducers.isFetchingTopSaleEvents(state),
  topCouponIds: reducers.getTopCouponIds(state),
  isFetchingTopCoupons: reducers.isFetchingTopCoupons(state),
  newsletterIds: reducers.getNewsletterIds(state),
  isFetchingNewsletters: reducers.isFetchingNewsletters(state),
  baseUrl: reducers.getBaseUrl(state),
  seo: reducers.getSeoContent(state),
  isFetchingSeoContent: reducers.isFetchingSeoContent(state)
})

export default connect(mapStateToProps, {
  loadStorePage,
  seoContentRequestStart
})(StorePage)
