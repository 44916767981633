import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import PageSection from '../components/PageSection'
import activeAndSortedList from '../components/utils/activeAndSortedList'
import { categoryPath, subcategoryPath } from '../helpers/urlHelpers'
import { useMediaQuery } from 'react-responsive'
import ChevronRight from '../apps/App/components/ChevronRight'

function filterPopularList (list) {
  const filteredList = activeAndSortedList(list)
  return _.filter(filteredList, ['attributes.popular', true])
}

export default function TrendingListings({ match, department, category }) {
  const [popularSubListing, setPopularSubListing] = useState([])
  const isLargeScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const ref = useRef(null)

  useEffect(() => {
    const {
      params: { department: departmentSlug, category: categorySlug }
    } = match
    if (departmentSlug) {
      if (categorySlug) {
        // category listing page
        const subcategories = _.get(category, 'relationships.subcategories')
        if (Array.isArray(subcategories)) {
          setPopularSubListing(
            _.map(filterPopularList(subcategories), (item) => ({
              name: _.get(item, 'attributes.name'),
              url: subcategoryPath({
                departmentSlug,
                categorySlug,
                subcategorySlug: _.get(item, 'attributes.slug')
              })
            }))
          )
        }
      } else {
        // department listing page
        const categories = _.get(department, 'relationships.categories')
        if (Array.isArray(categories)) {
          setPopularSubListing(
            _.map(filterPopularList(categories), (item) => ({
              name: _.get(item, 'attributes.name'),
              url: categoryPath({
                departmentSlug,
                categorySlug: _.get(item, 'attributes.slug')
              })
            }))
          )
        }
      }
    }
  }, [match, department, category])

  if (popularSubListing.length === 0) {
    return null
  }
  return (
    <PageSection>
      <div className='listing-carousel trending-carousel'>
        <div
          className={`wrap ${
            isLargeScreen && 'listing-group-carousel trending-group-carousel'
          }`}
          ref={ref}>
          {popularSubListing.map((item) => (
            <div className='box' key={item.name}>
              <Link className='trend-pill' to={item.url}>
                {item.name}
              </Link>
            </div>
          ))}
          <ChevronRight offset={300} ref={ref} className='trendlist-chevron' />
        </div>
      </div>
    </PageSection>
  )
}
