import PropTypes from 'prop-types'

function narrativeDataLayer (eventType, objectType = null, currentSection = null, sectionPosition = null) {
  // Track view event for Narratve Page

  if (eventType === 'narratView') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'view', // Type of Interaction i.e click or clear if users clears all from component
            object_type: null, // the filter section name
            object_position: null, // the position of filter section Clear All button is always null
            component_name: currentSection || null, // the name of the component will always be 'search-filters'
            component_position: Number(sectionPosition) || null// I believe this should be null as filter section will be sticky
          }
        }
      ])
  }

  // Track view event for Narratve Page
  if (eventType === 'narratClick') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or clear if users clears all from component
            object_type: objectType, // the filter section name
            object_position: null, // the position of filter section Clear All button is always null
            component_name: currentSection || null, // the name of the component will always be 'search-filters'
            component_position: Number(sectionPosition) || null// I believe this should be null as filter section will be sticky
          }
        }
      ])
  }
}

narrativeDataLayer.PropTypes = {
  eventType: PropTypes.string.isRequired,
  currentSection: PropTypes.string.isRequired,
  sectionPosition: PropTypes.number.isRequired,
}

export default narrativeDataLayer
