import * as types from './types'

export const experimentsRequestStart = data => {
  return ({
    type: types.EXPERIMENTS_REQUEST_START,
    data
  })
}

export const experimentsRequestSuccess = data => {
  const allExperiments = data || []
  const { changed, created, deleted } = Object.keys(allExperiments).length ? allExperiments : { changed: [], created: [], deleted: [] }
  return ({
    type: types.EXPERIEMNTS_REQUEST_SUCCESS,
    data: [...(changed || []), ...(created || []), ...(deleted || [])]
  })
}

export const experimentRequestFailure = error => ({
  type: types.EXPERIEMNTS_REQUEST_FAILURE,
  error
})
