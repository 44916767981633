import * as types from "./types"

export const loadListingPage = data => ({
  type: types.LOAD_LISTINGS_PAGE,
  data
})

export const loadDepartmentCategoryPage = data => ({
  type: types.LOAD_DEPARTMENT_CATEGORY_PAGE,
  data
})

export const loadNewListingPage = data => ({
  type: types.LOAD_NEW_LISTINGS_PAGE,
  data
})

export const loadRankingPage = data => ({
  type: types.LOAD_RANKINGS_PAGE,
  data
})

export const loadFollowingRankingPage = data => ({
  type: types.LOAD_FOLLOWING_RANKINGS_PAGE,
  data
})

export const refreshFollowingRankingList = data => ({
  type: types.REFRESH_FOLLOWING_RANKING_LIST,
  data
})

export const loadSubmitNewListingPage = data => ({
  type: types.LOAD_SUBMIT_NEW_LISTINGS_PAGE,
  data
})

export const refreshRankingList = data => ({
  type: types.REFRESH_RANKING_LIST,
  data
})

export const refreshTopDeals = data => ({
  type: types.TOP_DEALS_REFRESH,
  data
})

export const refreshTopCoupons = data => ({
  type: types.TOP_COUPONS_REFRESH,
  data
})

export const refreshTopStores = data => ({
  type: types.TOP_STORES_REFRESH,
  data
})

export const refreshTopBrands = data => ({
  type: types.TOP_BRANDS_REFRESH,
  data
})

export const refreshTopSaleEvents = data => ({
  type: types.TOP_SALE_EVENTS_REFRESH,
  data
})

export const loadProfileActivityPage = ({ identifier, data }) => ({
  type: types.LOAD_PROFILE_ACTIVITY_PAGE,
  identifier,
  data
})

export const loadProfileFollowingPage = data => ({
  type: types.LOAD_PROFILE_FOLLOWING_PAGE,
  data
})

export const loadPublicProfilePage = data => ({
  type: types.LOAD_PUBLIC_PROFILE_PAGE,
  data
})

export const loadProfileCalendarPage = data => ({
  type: types.LOAD_PROFILE_CALENDAR_PAGE,
  data
})

export const loadStorePage = data => ({
  type: types.LOAD_STORE_PAGE,
  data
})

export const loadBrandPage = data => ({
  type: types.LOAD_BRAND_PAGE,
  data
})

export const loadDealPage = ({ identifier }) => ({
  type: types.LOAD_DEAL_PAGE,
  identifier
})

export const loadCouponPage = ({ identifier }) => ({
  type: types.LOAD_COUPON_PAGE,
  identifier
})

export const loadSaleEventPage = ({ identifier }) => ({
  type: types.LOAD_SALE_EVENT_PAGE,
  identifier
})

export const loadDepartmentCategoryListPage = data => ({
  type: types.LOAD_DEPARTMENT_CATEGORY_LIST_PAGE,
  data
})
