import React, { Component } from "react"

class ListingUrlField extends Component {
  setTitle = listing_type => {
    switch (listing_type) {
      case "deal":
        return "Deal link / URL:"
      case "sale_event":
        return "Event link / URL:"
      case "coupon":
        return "Store link / URL:"
      default:
        return "Deal link / URL:"
    }
  }

  setName = listing_type => {
    switch (listing_type) {
      case "deal":
        return "product_url"
      case "sale_event":
      case "coupon":
        return "url"
      default:
        return "product_url"
    }
  }

  setValue = (listing_type, product_url, url) => {
    switch (listing_type) {
      case "deal":
        return product_url
      case "sale_event":
      case "coupon":
        return url
      default:
        return product_url
    }
  }

  render() {
    const {
      listing_type,
      handleAttributeChange,
      url,
      product_url,
      errorMessage
    } = this.props

    const title = this.setTitle(listing_type)
    const name  = this.setName(listing_type)
    const value = this.setValue(listing_type, product_url, url)

    return (
      <div className="form-group">
        <label>{title}</label>
        <input
          type="text"
          onChange={e => handleAttributeChange({ value: e.target.value, name: e.target.name })}
          value={value}
          name={name}
          placeholder="Link to the product page or the deal page"
          className="form-control" />
        {errorMessage}
      </div>
    )
  }
}

export default ListingUrlField
