import React, { Component } from "react"
import { connect } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import Loader from "../../../../components/Loader"
import Breadcrumb from "../../../../components/Breadcrumb"
import Meta from "../../../../components/Meta"
import RedirectWithAlert from "../../../../components/utils/RedirectWithAlert"

import ListingCard from "../ListingCard"

import {
  TopUpcomingSalesSidebarList,
  TopBrandsSidebarList,
  TopStoresSidebarList,
  TopDealHuntersSidebarList
} from "../listings"

import {
  loadFollowingRankingPage,
  refreshFollowingRankingList
} from "../../actions"
import * as reducers from "../../reducers"

class FollowingRankingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
  }

  componentDidMount() {
    this.loadPage(this.listRequestParams())
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prev_params } } = prevProps
    const { match: { params } } = this.props

    // If either department/category or subcategory changes we go refresh the data
    if (!_.isEqual(params, prev_params)) {
      this.loadPage(this.listRequestParams())
    }
  }

  listRequestParams = () => {
    const { page } = this.state
    const { currentUser, match: { params } } = this.props

    return {
      page,
      listType: params.listType,
      pageUserSlug: params.user,
      userId: currentUser.id
    }
  }

  // Update page state and refreshes list only
  //
  fetchNextPage = () => {
    const { page } = this.state
    this.setState({ page: page + 1 })
    this.refreshList()
  }

  // Load page does a full page refresh
  //
  loadPage = () => {
    const { loadFollowingRankingPage } = this.props
    loadFollowingRankingPage(this.listRequestParams())
  }

  // Load page does a full page refresh
  //
  refreshList = () => {
    const { refreshFollowingRankingList } = this.props
    refreshFollowingRankingList(this.listRequestParams())
  }

  renderListItems = () => {
    const {
      listItemIds,
      match: { params: { listType } }
    } = this.props

    const itemType = {
      deals: "deal",
      vouchers: "coupon",
      "sales-events": "saleEvent",
      "followed-best-sellers": "deal"
    }[listType]

    if (listItemIds === undefined) { return ([]) }

    return _.map(listItemIds, (itemId, index) => {
      function colClass() {
        if (itemType === "saleEvent") return "col-lg-6"
        return "col-6 col-lg-3"
      }
      return <ListingCard type={itemType} position={index + 1} id={itemId} wrapperClass={colClass()} key={`${itemType}-${itemId}`} currentSection='FollowingRankingPage' sectionPosition={1} />
    })
  }

  isHidden = () => {
    const {
      currentUser,
      pageUser,
      match: { params: { listType } }
    } = this.props

    const attribute = {
      deals: "showFollowedPriceDrops",
      vouchers: "showFollowedCoupons",
      "sales-events": "showFollowedUpcomingSales"
    }[listType]

    return _.get(pageUser, `attributes.publicProfileSettings.${attribute}`) === false && pageUser.id !== currentUser.id
  }

  render() {
    const {
      isFetchingList,
      location,
      currentUser,
      pageUser,
      match: { params: { listType, user } }
    } = this.props

    if (this.isHidden()) {
      return <RedirectWithAlert />
    }

    const items = this.renderListItems()
    const listTitle = _.startCase(listType)
    const title = `${listTitle} ${pageUser === currentUser ? "You're Following" : `${_.get(pageUser, "attributes.username")} Follows`}`

    if (pageUser) {
      return (
        <div className="listing-page">
          <Breadcrumb breadcrumbs={location} />
          <Meta
            title={`Following | ${user} | Little Birdie`}
            description={`The best ${listTitle} from millions of products from hundreds of stores in at Little Birdie`}
            og_description={`${user} is a spotter on Little Birdie. See all their deals, price drops, votes, vouchers, sale events and more. Save money with us`}
            name={listTitle} />

          <div className="main-wrapper">

            <div className="main-content">
              <section>
                <h3>{title}</h3>

                {
                  (!isFetchingList && items.length === 0)
                  ? <p>{currentUser.attributes.slug === user ? `Follow some ${_.startCase(listType)} to populate this list` : `${currentUser.attributes.name} does not follow any ${_.startCase(listType)}`}</p>
                  : null
                }

                <InfiniteScroll
                  className="row listing-grid"
                  dataLength={items.length} // This is important field to render the next data
                  next={this.fetchNextPage}
                  hasMore
                  loader={<div className="listing-card col-12"><Loader isLoading={isFetchingList} /></div>}
                  endMessage={(
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  )}>
                  {items}
                </InfiniteScroll>
              </section>
            </div>
            <div className="sidebar">
              <TopUpcomingSalesSidebarList />
              <TopBrandsSidebarList />
              <TopStoresSidebarList />
              <TopDealHuntersSidebarList />
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = (state, ownProps) => ({
  isFetchingList: reducers.isFetchingListFromType(state, ownProps.match.params.listType),
  listItemIds: reducers.getListIdsFromType(state, ownProps.match.params.listType),
  pageUser: reducers.getUserBySlug(state, ownProps.match.params.user),
  currentUser: reducers.getCurrentUser(state)
})

export default connect(mapStateToProps, {
  loadFollowingRankingPage,
  refreshFollowingRankingList
})(FollowingRankingPage)
