import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import swal from 'sweetalert'

import { crossIcon } from '../../../../../components/Icons'
import Loader from '../../../../../components/Loader'
import ItemIcon from './ItemIcon'
import { getBaseUrl } from '../../../reducers'
import { getTopStores, followingRequester } from '../../../requesters'
import onboardingFlowDataLayer from '../../../../../components/dataLayers/onboardingFlowDataLayer'

export default function StoreSelect ({
  hideModal,
  handleNext,
  handleBack,
  activeDepartments,
  selectStore,
  hidePopup,
  progressBar,
  followedStores,
  handleSkip
}) {
  const [stores, setStores] = useState([])
  const [nagCount, setNagCount] = useState(0)
  const baseUrl = useSelector(getBaseUrl)

  useEffect(() => {
    onboardingFlowDataLayer('onboarding-flow-view', null, 'stores', 5)
  }, [])

  useEffect(() => {
    async function fetchStores (department, perPage) {
      const params = {
        per_page: perPage,
        by_department: department
      }

      const { response } = await getTopStores(baseUrl, params)
      if (_.get(response, 'data.store')) {
        response.ids.forEach((index) =>
          setStores((oldStores) => [...oldStores, response.data.store[index]])
        )
      }
    }

    async function follow (id) {
      const params = {
        type: 'department',
        action: 'follow',
        id: id
      }
      await followingRequester(baseUrl, params)
    }

    if (activeDepartments.length === 0) {
      fetchStores(null, 50)
    } else {
      activeDepartments.forEach((department) => follow(department))
      activeDepartments.forEach((department) =>
        fetchStores(department, Math.ceil(100 / activeDepartments.length))
      )
    }
  }, [activeDepartments])

  function handleNagNext () {
    if (nagCount < 1 && followedStores.length === 0) {
      swal({
        title:
          'Selecting at least one allows us to bring you more of what you love',
        button: 'OK'
      })
      setNagCount(nagCount + 1)
    } else {
      handleNext()
    }
  }

  const activeStores = []

  return (
    <>
      <Modal.Header className='d-flex flex-column border-0 pb-0'>
        <div className='align-self-end'>
          <button type='button' className='close' onClick={hideModal}>
            <img src={crossIcon} />
          </button>
        </div>
        <div className='header d-flex justify-content-between w-100'>
          <h3>Stores</h3>
          <div className='label-skip'>
            <span onClick={handleSkip} >Skip</span>
          </div>
        </div>
        <div className='subtitle'>Follow your favourite stores and never miss their best offers.</div>
        <div className='w-100'>
          {progressBar}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='favourites-stores'>
          <div className='row'>
            {stores.length === 0
              ? (<Loader isLoading />)
              : (
                  stores.map((store) => {
                    if (!activeStores.includes(store.id)) {
                      activeStores.push(store.id)
                      return (
                        <ItemIcon key={`stricn-${store.id}`} item={store} onClick={(id) => selectStore(id)} />
                      )
                    }
                    return null
                  })
                )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='mx-auto'>
          <button
            type='button'
            className='btn btn-outline-dark rounded-pill mx-2'
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type='button'
            className='btn btn-main rounded-pill mx-2'
            onClick={() => handleNagNext()}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </>
  )
}
