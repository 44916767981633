/**
 * Sort array of objects based on another array
 * used when we want to retain objects that were mucked up by normalizer library
 */
export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key]
    const B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    }
      return -1
  })
  return array
}