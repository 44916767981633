import React, { useRef } from 'react'
import { Link, useParams } from 'react-router-dom'

import PageSection from '../../../../components/PageSection'
import { labelTag } from '../../../../components/Icons'
import CollectionCard from '../../../../components/ListingCards/CollectionCard'
import ChevronRight from '../ChevronRight'

export default function Lists ({
  title,
  subtitle,
  customLists,
  extraClass,
  store,
  brand,
  hideViewAll
}) {
  const params = useParams()
  const myRef = useRef(null)

  const collections = _.map(customLists, (item, index) => (
    <CollectionCard
      key={`collection-${index}`}
      item={item}
    />
  ))

  function viewAllLink () {
    const url = '/collections'
    if (!params) return url
    if (store) return `${url}?store=${params.slug}`
    if (brand) return `${url}?brand=${params.slug}`
    return `${url}?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`
  }

  function renderViewAllButton () {
    if (!hideViewAll) {
      return (
        <Link
          to={viewAllLink()}
          className='btn btn-outline-dark rounded-pill btn-view-all'
        >
          View all
        </Link>
      )
    }
    return null
  }

  return (
    <PageSection
      icon={labelTag}
      iconClass='label-tag'
      title={title}
      viewAll={renderViewAllButton()}
      description={subtitle}
      extraClass={extraClass}
    >
      <div className='listing-carousel'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          <ChevronRight offset={300} ref={myRef} direction='left' />
          {collections}
          <ChevronRight offset={300} ref={myRef} direction='right' />
        </div>
      </div>
    </PageSection>
  )
}
