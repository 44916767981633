import React, { useState, useEffect } from "react"
import { useRouteMatch } from "react-router"
import { useSelector } from "react-redux"

import { getBaseUrl } from "../../reducers"
import { getCustomLists } from "../../requesters"

import Lists from "./Lists"

export default function CustomLists({
  match,
  // placement,
  // page,
  store,
  brand,
  extraClass,
  hideViewAll,
  checkIfNull,
  check,
  checkFetchStatus
}) {
  const [customLists, setCustomLists] = useState([])

  const apiBaseUrl = useSelector(getBaseUrl)

  const routeMatch = useRouteMatch()

  const pageUrl = _.get(routeMatch, "url", "")
  const department = _.get(routeMatch, "params.department", null)
  const category = _.get(routeMatch, "params.category", null)
  const follower = match?.path.includes("spotter") ? match.params.slug : null
  // Fetch the data for the custom list
  useEffect(() => {
    async function fetchData() {
      const requestParams = {
        page: 1,
        per_page: 10,
        by_followed_user: follower,
        // by_page: page,
        // by_placement: placement,
        by_department: department,
        by_category: category,
        by_store: _.get(store, "attributes.slug", null),
        by_brand: _.get(brand, "attributes.slug", null)
      }

      const { response } = await getCustomLists(apiBaseUrl, requestParams)
      setCustomLists(_.uniq(response.ids.map(id => response.data.customList[id])))
      if (check) checkFetchStatus("finished")
    }
    fetchData()
  }, [pageUrl])

  if (_.isEmpty(customLists)) { return (null) }

  if (check) checkIfNull("notNull")

  const title = () => {
    const storeName = _.upperCase(_.get(store, "attributes.name", null))
    if (storeName.length > 0) {
      return `Little Birdie Collections from ${storeName}`
    }
    return `Little Birdie Collections`
  }

  return (
    <Lists
      title={title()}
      subtitle="Highlighted Price Drops from brands or retailers, gathered together."
      customLists={customLists}
      extraClass={extraClass}
      store={store}
      brand={brand}
      hideViewAll={hideViewAll} />
  )
}
