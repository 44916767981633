import React from "react"
import { Link } from "react-router-dom"

import RecipientLink from "./RecipientLinks"

function assignKeyLabel(key) {
  switch (key) {
    case "deal.approved":
    case "coupon.approved":
    case "sale_event.approved":
    case "unique_coupon.create":
      return "posted"
    default:
      return `unknow type [${key}]`
  }
}

export default function Posting({ item, owner, recipient }) {
  if (!(owner && recipient)) return null

  const {
    attributes: { key, createdAtLabel },
  } = item

  const keyLabel = assignKeyLabel(key)

  const {
    attributes: {
      username: ownerUsername,
    },
    links: {
      avatar: { thumb: { url: ownerAvatarUrl } },
      userUrl: ownerUserUrl
    }
  } = owner

  return (
    <div className="border-bottom activity-row p-4">
      <div className="media">
        <img alt="" className="mr-2 rounded-circle img-40" src={ownerAvatarUrl} />
        <div className="media-body">
          <Link to={ownerUserUrl}>{`@${ownerUsername}`}</Link>
          {` ${keyLabel} `}
          <RecipientLink recipient={recipient} />
          <div className="text-muted">{` - ${createdAtLabel.match(/just now/i) ? createdAtLabel : `${createdAtLabel} ago`}`}</div>
        </div>
      </div>
    </div>
  )
}
