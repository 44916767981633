import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useInView } from 'react-intersection-observer'
import { noImage } from '../Icons'
import positionDataLayer from '../../components/dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../constant'
import { renderStoreBrandStats, getOrderStats }  from '../utils/renderBrandStoreStats'

export default function BrandCard ({
  wrapperClass,
  item,
  position,
  currentSection,
  sectionPosition
}) {
  const [brandStats, setBrandStats] = useState([])

  const [refBrandCard, inView] = useInView(USE_IN_VIEW_OPTIONS)

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition)
    }
  }, [inView])

  useEffect(() => {
    const allowedKeys = ['deals_count', 'sale_events_count', 'coupons_count', 'products_count', 'followers_count']
    const objectOrder = {
      deals_count: 0,
      sale_events_count: 0,
      coupons_count: 0,
      products_count: 0,
      followers_count: 0
    }
    let modifiedBrandStats= getOrderStats(item,allowedKeys,objectOrder)
    setBrandStats(() => ([
      ...modifiedBrandStats
    ]));

  }, [item])

  return (
    <div className={`listing-card ${wrapperClass}`} ref={refBrandCard}>
      <div className='card card-store-brand-list'>
      { renderStoreBrandStats(brandStats,'list') }
        <Link
          className='link-wrapper'
          to={`/shop/brand/${item.slug}`}
          onClick={() => { positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition) }}
        >
          <div className='card-img-top card-img-top-list'>
            <LazyLoadImage
              effect='opacity'
              className='listing-img'
              alt={item.name}
              src={item.logo_url || noImage}
            />
          </div>
        </Link>
        <Link to={`/shop/brand/${item.slug}`}>
          <div className='d-flex justify-content-center align-items-center store-brand-footer store-brand-footer-list'>
            View all from&nbsp;<b className='mb-0'>{item.name}</b>
          </div>
        </Link>
      </div>
    </div>
  )
}
