import React, { useEffect, useRef, useState } from 'react'

const useElementOnScreen = (options) => {
  const elmRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callBack = (entries) => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const observer = new window.IntersectionObserver(callBack, options)
    if (elmRef.current) observer.observe(elmRef.current)

    return () => {
      if (elmRef.current) observer.unobserve(elmRef.current)
    }
  })

  return [elmRef, isVisible]
}

export default useElementOnScreen
