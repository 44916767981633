
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBaseUrl,
  getSearchKeyword,
  getIsSearchInputActive,
  getIsMobile,
  getDepartments,
  getCategories,
  getSubcategories,
  getBrandBySlug,
  getStoreBySlug,
  getIsOverlayOpen,
  getCurrentUser
} from '../../reducers'
import { getElasticGlobalSuggestions } from '../../requesters'
import { searchSetKeyword, setSearchActive, setOverlaySearch } from '../../actions'

import { noImage, searchIconGreen, chevronLeft, googleFull, chevronRightFilled, birdBf } from '../../../../components/Icons'
import { 
  gon,
  LB_NATIVE_APP_VERSION,
  // SHOW_BLACK_FRIDAY
} from '../../../../../constant'
import { isValidUrl } from '../pages/Common/helpers'

const trandingKeywords = [
  'Apple Iphone 14',
  'Dyson Stick Vacuum',
  'Women\'s Birkenstocks',
  'Nintendo Switch',
  'Apple Ipad',
  'Nike Air Max 270',
  'Philips Airfryer',
  'Haier Washing Machine',
  'Revlon Colorstay',
  'VTech Baby Monitor',
  'Natio Flawless Foundation SPF15',
  'La Roche BB Cream'
]

export default function SearchInput (props) {
  const { mobileSlider, modifySearchState, showSearchBar, isHomepageSearch, topOffset } = props
  const pathName = window?.location?.pathname || ''
  const history = useHistory()
  const [searchInput, setSearchInput] = useState('')
  const [suggestionList, setSuggestionList] = useState({})
  const [inputFocus, setInputFocus] = useState(false)
  const homePage = (pathName === '/' || pathName === '')
  const isMobile = useSelector(getIsMobile)

  const baseUrl = useSelector(state => getBaseUrl(state))
  const activeKeyword = useSelector(getSearchKeyword)
  const departments = useSelector(getDepartments)
  const categories = useSelector(getCategories)
  const subcategories = useSelector(getSubcategories)
  const isSearchInputActive = useSelector(getIsSearchInputActive)
  const isOpen = useSelector(getIsOverlayOpen)

  const brandSlug = pathName?.match(/^\/shop\/brand\/.+/i) ? pathName?.split('/')?.[3] : ''
  const brandDetails = useSelector(state => getBrandBySlug(state, brandSlug))
  const storeSlug = pathName?.match(/^\/shop\/store\/.+/i) ? pathName?.split('/')?.[3] : ''
  const storeDetails = useSelector(state => getStoreBySlug(state, storeSlug))

  const dispatch = useDispatch()
  const searchInputRef = useRef(null)
  const showGoogleSearchOption = !isValidUrl(searchInput) && searchInput?.trim()?.length > 0 && window.ReactNativeWebView && LB_NATIVE_APP_VERSION >= 2.8

  useEffect(() => {
    async function getSuggestions () {
      setSuggestionList({})
      const { pageContextKey, pageContextValue } = getPageContext()
      const { response } = await getElasticGlobalSuggestions(baseUrl, searchInput, { pageContextKey, pageContextValue })
      if (response.suggestions) { setSuggestionList(response.suggestions) }
    }

    if (searchInput?.length > 2) { getSuggestions() }
  }, [searchInput])

  useEffect(() => {
    if (isSearchInputActive || isOpen) {
      isSearchInputActive && searchInputRef?.current?.focus()
      document.body.classList.add('mobile-body-overflow')
      document.documentElement.classList.add('mobile-body-overflow')
    } else {
      document.body.classList.remove('mobile-body-overflow')
      document.documentElement.classList.remove('mobile-body-overflow')
    }
    setInputFocus(isSearchInputActive)
  }, [isSearchInputActive, isOpen])

  useEffect(() => {
    setSearchInput(activeKeyword.searchTerm)
  }, [activeKeyword])

  useEffect(() => {
    modifySearchState && modifySearchState(inputFocus)
  }, [inputFocus])

  useEffect(() => {
    const pathName = window?.location?.pathname || ''
    if (!pathName.match(/^\/search\//i)) {
      setSearchInput('')
    }
    if (pathName.match(/^\/search\/.+/i)) {
      const inputKeywords = _.split(pathName, 'search/')
      dispatch(searchSetKeyword({ searchTerm: decodeURIComponent(_.last(inputKeywords)), searchMeta: activeKeyword.searchMeta || null }))
    }
  }, [window.location.pathname])

  useEffect(() => {
    isMobile && showSearchBar && inputFocus && dispatch(setOverlaySearch(true))
  }, [isMobile, showSearchBar, inputFocus])

  const getPageContext = () => {
    let pageContextKey = ''
    let pageContextValue = ''

    const pathName = window?.location?.pathname || ''
    const pathNameArr = pathName?.split('/')

    if (pathName?.match(/^\/shop\/store\/.+/i)) {
      pageContextKey = 'store_name'
      pageContextValue = storeDetails?.attributes?.name
    } else if (pathName?.match(/^\/shop\/brand\/.+/i)) {
      pageContextKey = 'brand_name'
      pageContextValue = brandDetails?.attributes?.name
    } else if (pathName?.match(/^\/shop\/.+/i)) {
      if (pathNameArr[4]) {
        pageContextKey = 'subcategory_name'
        const subCatSlug = pathNameArr[4]
        pageContextValue = subcategories?.forEach(subcat => {
          if (subcat?.attributes?.slug === subCatSlug) return subcat?.attributes?.name || ''
        }) || ''
      } else if (pathNameArr[3]) {
        pageContextKey = 'category_name'
        const catSlug = pathNameArr[3]
        categories?.forEach(cat => {
          if (cat?.attributes?.slug === catSlug) pageContextValue = cat?.attributes?.name || ''
        })
      } else if (pathNameArr[2]) {
        pageContextKey = 'department_name'
        const deptSlug = pathNameArr[2]
        departments?.forEach(dept => {
          if (dept?.attributes?.slug === deptSlug) pageContextValue = dept?.attributes?.name || ''
        })
      }
    }

    return { pageContextKey, pageContextValue }
  }

  const handleCloseClick = () => {
    modifySearchState && modifySearchState(false)
    searchInputRef.current.blur()
    dispatch(setOverlaySearch(false))
    dispatch(setSearchActive(false))
    document.body.classList.remove('mobile-body-overflow')
    document.documentElement.classList.remove('mobile-body-overflow')
  }

  function handleSearchSubmit (event, suggestion) {
    event.preventDefault()

    const searchKeyword = event.currentTarget?.dataset?.searchKeyword
    const searchTerm = suggestion ? searchKeyword?.trim() : searchInputRef?.current?.value?.trim()

    if(!searchTerm?.length) {
      return false
    }

    localStorage.removeItem('search_product_filter')
    document.body.classList.remove('mobile-body-overflow')
    document.documentElement.classList.remove('mobile-body-overflow')
    searchInputRef.current.blur()
    dispatch(setSearchActive(false))
    dispatch(setOverlaySearch(false))
    
    const searchMeta = {
      filterKey: event.currentTarget?.dataset?.filterKey || '',
      filterValue: event.currentTarget?.dataset?.filterValue || ''
    }

    setInputFocus(false)
    dispatch(searchSetKeyword({ searchTerm: searchTerm, searchMeta: searchMeta }))
    history.push(`/search/${encodeURIComponent(encodeURIComponent(searchTerm))}`)
  }

  function handleInput (event) {
    event.preventDefault()
    setSearchInput(event.target.value)
  }

  function handleSetInputActive(value) {
    dispatch(setSearchActive(value))
    !isOpen && dispatch(setOverlaySearch(true))
  }

  function handleInputFocus (value) {
    setInputFocus(value)
    isMobile ? handleSetInputActive(value) : !value && dispatch(setSearchActive(false))
  }

  const getTrendingKeywords = () => {
    const tHeading = (
      <div
        onClick={(e) => e.stopPropagation()}
        className='trendingHeading'
      >
        Trending
      </div>
    )
    const trandingLi = trandingKeywords.map(
      tkey =>
        <li
          key={tkey + 'keyword'}
          className='list-group-item pl-3 text-wrap'
          onClick={e => handleSearchSubmit(e, true)}
          data-search-keyword={tkey}
          data-filter-key='keyword'
          data-filter-value={tkey}
        >
          <b>{tkey}</b>
        </li>
    )
    return [tHeading, ...trandingLi]
  }

  // ToDo: Temp Logic, Search bar to only display to admin
  const currentUser = useSelector(getCurrentUser)
  const role = gon?.current_user?.role || ''

  function handleInputFocus (value) {
    setInputFocus(value)
    isMobile ? dispatch(setSearchActive(value)) : !value && dispatch(setSearchActive(false))
  }


  const getSuggestionsUi = suggestionList => {
    const liItems = []
    let storeLiItems = []
    let brandLiItems = []
    let departmentLiItems = []
    let categoryLiItems = []
    let subcategoryLiItems = []
    let productLiItems = []

    if (searchInput?.length < 3) {
      return null
    }

    const keywordLiItem = (
      searchInput &&
        <li
          key={searchInput + 'keyword'}
          className='list-group-item pl-3 text-wrap'
          onClick={e => handleSearchSubmit(e, true)}
          data-search-keyword={searchInput}
          data-filter-key='keyword'
          data-filter-value={searchInput}
        >
          <b>{searchInput}</b>
        </li>
    )

    Object.keys(suggestionList).forEach(sl => {
      switch (sl) {
        case 'pagecontext_suggest':
        {
          const liItem = suggestionList.pagecontext_suggest.data?.map((pc, index) => {
            return (
              <li
                key={`${pc.key}-${index}-page`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={searchInput}
                data-filter-key='departmentNames'
                data-filter-value={pc.key}
              >
                <b>{searchInput}</b> - {pc.key}
              </li>
            )
          }) || []
          liItems.push(...liItem)
          break
        }
        case 'preemptive_category_suggestion':
        {
          const liItem = suggestionList.preemptive_category_suggestion.data?.map((pc, index) => {
            return (
              <li
                key={`${pc.key}-${index}-pre-cat`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={searchInput}
                data-filter-key='categoryNames'
                data-filter-value={pc.key}
              >
                <b>{searchInput}</b> - in {pc.key}
              </li>
            )
          }) || []
          liItems.push(..._.take(liItem, 1))
          break
        }
        case 'preemptive_subcategory_suggestion':
        {
          const liItem = suggestionList.preemptive_subcategory_suggestion.data?.map((pc, index) => {
            return (
              <li
                key={`${pc.key}-${index}-pre-subcat`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={searchInput}
                data-filter-key='subcategoryNames'
                data-filter-value={pc.key}
              >
                <b>{searchInput}</b> - in {pc.key}
              </li>
            )
          }) || []
          liItems.push(..._.take(liItem, 2))
          break
        }
        case 'store_main':
        {
          storeLiItems = suggestionList.store_main.data?.map((pc, index) => {
            return (
              <li
                key={`${pc._source?.store_name}-${index}-store`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc._source?.store_name}
                data-filter-key='storeNames'
                data-filter-value={pc._source?.store_name}
              >
                <img className='search-result-logo' src={pc._source?.store_logo || noImage} alt={pc._source?.store_name} />
                <b>{pc._source?.store_name}</b> - Store
              </li>
            )
          }) || []
          break
        }
        case 'brand_main':
        {
          brandLiItems = suggestionList.brand_main.data?.map((pc, index) => {
            return (
              <li
                key={`${pc._source?.brand_name}-${index}-brand`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc._source?.brand_name}
                data-filter-key='brandNames'
                data-filter-value={pc._source?.brand_name}
              >
                <img className='search-result-logo' src={pc._source?.brand_logo || noImage} alt={pc._source?.brand_name} />
                <b>{pc._source?.brand_name}</b> - Brand
              </li>
            )
          }) || []
          break
        }
        case 'department_main':
        {
          departmentLiItems = suggestionList.department_main.data?.map((pc, index) => {
            return (
              <li
                key={`${pc._source?.department_name}-${index}-dept`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc._source?.department_name}
                data-filter-key='departmentNames'
                data-filter-value={pc._source?.department_name}
              >
                <b>{pc._source?.department_name}</b> - Department
              </li>
            )
          }) || []
          break
        }
        case 'category_main':
        {
          categoryLiItems = suggestionList.category_main.data?.map((pc, index) => {
            return (
              <li
                key={`${pc._source?.category_name}-${index}-cat`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc._source?.category_name}
                data-filter-key='categoryNames'
                data-filter-value={pc._source?.category_name}
              >
                <b>{pc._source?.category_name}</b> - Category
              </li>
            )
          }) || []
          break
        }
        case 'subcategory_main':
        {
          subcategoryLiItems = suggestionList.subcategory_main.data?.map((pc, index) => {
            return (
              <li
                key={`${pc._source?.subcategory_name}-${index}-subcat`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc._source?.subcategory_name}
                data-filter-key='subcategoryNames'
                data-filter-value={pc._source?.subcategory_name}
              >
                <b>{pc._source?.subcategory_name}</b> - Subcategory
              </li>
            )
          }) || []
          break
        }
        case 'product_suggestion':
        {
          productLiItems = suggestionList.product_suggestion.data?.map((pc, index) => {
            return (
              <li
                key={`${pc.key}-${index}-product`}
                className='list-group-item pl-3 text-wrap'
                onClick={e => handleSearchSubmit(e, true)}
                data-search-keyword={pc.key}
                data-filter-key='productName'
                data-filter-value={pc.key}
              >
                <b>{`${pc.key}`}</b>
              </li>
            )
          }) || []
          break
        }
      }
    })
    // page_context, pre-emptive category & pre-emptive subcategory
    // already pushed as no data manupulation is required
    // rest other result required data manupulation
    const maxFiveDept = [...departmentLiItems, ...categoryLiItems, ...subcategoryLiItems]

    liItems.push(keywordLiItem)

    liItems.push(
      ..._.take([...brandLiItems, ...storeLiItems], 5),
      ..._.take(maxFiveDept, 5),
      ...productLiItems
    )
    return _.take(liItems, 15)
  }

  const googleSearchNative = () => {
    let parsedMessgae = {
      type: 'google-search-webview', 
      data: `https://www.google.com/search?q=${searchInput}`
    }
    
     window.ReactNativeWebView?.postMessage(JSON.stringify(parsedMessgae))

     handleCloseClick()
  }

  function SuggestionDropDown () {
    let searchSuggestion = getSuggestionsUi(suggestionList)

    if (inputFocus) {
      return (
        <ul className={`search-suggestions ${homePage && 'on-homepage'} shadow-sm list-group`}>
          {/* {
            !searchInput.length
              ? getTrendingKeywords()
              : null
          } */}
          {showGoogleSearchOption &&
            <li
              className='list-group-item pl-3 text-wrap google-search-link'
              data-search-keyword={searchInput}
              data-filter-key='keyword'
              data-filter-value={searchInput}
              onClick={googleSearchNative}
            >
              Search <strong>{searchInput}</strong> on <img className='google' src={googleFull} />
              <img className='float-right' src={chevronRightFilled} />
            </li>
          }
          {searchSuggestion}
        </ul>
      )
    }
    return null
  }

  if (mobileSlider) {
    return (
      <form className={`form-inline ${showSearchBar || isOpen ? 'visible' : 'invisible'} `} onSubmit={handleSearchSubmit}>
        <div className='mobile-search'>
          <div className='d-flex justify-content-around align-item-center'>
            <button onClick={() => handleCloseClick()} type='button' className={`btn btn-sm search-close ${!inputFocus && 'd-none'}`}>
              <img src={chevronLeft} />
            </button>
            <div className='input-group flex-fill align-items-center'>
              <input
                ref={searchInputRef}
                type='search'
                name='search'
                autoComplete='off'
                className='form-control'
                placeholder='I’m looking for...'
                aria-label='Search'
                autoFocus={isSearchInputActive}
                aria-describedby='search-input'
                value={searchInput}
                onChange={event => { handleInput(event) }}
                onFocus={() => { handleInputFocus(true) }}
                // onBlur={() => { setTimeout(() => { handleInputFocus(false) }, 200) }}
              />
              <button id='search-submit-btn' type='submit' className='btn btn-sm btn-light'>
                <img src={searchIconGreen} />
              </button>
            </div>
          </div>
          <SuggestionDropDown />
        </div>
      </form>
    )
  }

  return (
    <form className={`form-inline form-search ${showSearchBar || isHomepageSearch ? 'visible' : 'invisible'} `} onSubmit={handleSearchSubmit}>
      <div className='input-group search-input-group' style={{ top: `${topOffset}px` }}>
        {/* {SHOW_BLACK_FRIDAY && !showSearchBar && <img className={`bird-bf `} src={birdBf} /> } */}
        <input
          ref={searchInputRef}
          onChange={event => { handleInput(event) }}
          aria-label='Search'
          autoComplete='off'
          aria-describedby='search-submit-btn'
          className='form-control'
          placeholder='I’m looking for...'
          value={searchInput}
          type='search'
          autoFocus={isSearchInputActive}
          onFocus={() => { handleInputFocus(true) }}
          onBlur={() => { setTimeout(() => { handleInputFocus(false) }, 200) }}
        />
        <button
          id='search-submit-btn'
          type='submit'
          className='btn btn-md btn-light'
        >
          <img src={searchIconGreen} />
        </button>
        <SuggestionDropDown />
      </div>
    </form>
  )
}
