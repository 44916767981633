import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import Loader from "../../../../../../components/Loader"

import { couponOptions } from "../sortOptions"

import AdvertisementList from "../../../AdvertisementList"
import ListingCard from "../../../ListingCard"
import { getBaseUrl } from "../../../../reducers"
import { getTopCoupons } from "../../../../requesters"

export default function CustomCouponList({
  sortOptionCouponValue,
  customList,
  isReady,
  filterOptions: {
    storeIds,
    brandIds,
    departmentIds,
    categoryIds,
    paymentMethods,
    couponTypes
  },
  sortColumn, sortDirection
}) {
  const [couponIds, setCouponIds] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [column, setColumn] = useState("fixed_global_score")
  const [direction, setDirection] = useState("desc")

  const baseUrl = useSelector(getBaseUrl)
  const params = {
    page,
    per_page: 50,
    by_brands: brandIds,
    by_stores: storeIds,
    by_departments: departmentIds,
    by_categories: categoryIds,
    by_payment_methods: paymentMethods,
    by_coupon_types: couponTypes,
    "by_order[column]": column,
    "by_order[direction]": direction
  }

  useEffect(() => {
    setPage(0)
    setCouponIds([])
  }, [
    storeIds, brandIds,
    departmentIds, categoryIds,
    paymentMethods, couponTypes,
    column, direction,
    sortColumn, sortDirection
  ])

  useEffect(() => {
    async function fetchCustomCoupons() {
      setLoading(true)
      const { response } = await getTopCoupons(baseUrl, params)
      setCouponIds([...couponIds, ...response.ids])
      if (response.ids) setLoading(false)
    }

    if (customList && page > 0 && isReady) fetchCustomCoupons()
    if (page === 0) setPage(1)
  }, [page, isReady])

  useEffect(() => {
    const option = couponOptions.values.find(value => value.key === parseInt(sortOptionCouponValue, 10))
    if (option) {
      setColumn(option.value)
      setDirection(option.order)
    }
  }, [sortOptionCouponValue])

  return (
    <Fragment>
      <br />
      {
        // Show when there is no data to see
        (!loading && couponIds.length === 0)
        ? <p>No data to see here</p>
        : (
          <>
            {/* <h3>{customList.attributes.title}</h3>
            <p>{customList.attributes.subtitle}</p> */}
            <AdvertisementList location={`ranking-${customList.attributes.resourceType}-top`} position="middle" page="ranking" list={customList.attributes.resourceType} />
            <InfiniteScroll
              className="row listing-grid"
              dataLength={couponIds.length} // This is important field to render the next data
              next={() => setPage(page + 1)}
              hasMore
              loader={<div className="listing-card col-12"><Loader isLoading={loading} /></div>}
              endMessage={(
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
            )}>
              {_.map(couponIds, (couponId, index) => (
                <ListingCard type="coupon" id={couponId} wrapperClass="col-sm-6 col-lg-4" position={index + 1} key={`coupon-${couponId}`} currentSection='CustomCouponList' sectionPosition={1} />
            ))}
            </InfiniteScroll>
          </>
        )
      }
    </Fragment>
  )
}
