import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getIsSearchInputActive } from '../../reducers'
import SearchInput from '../MainNavbar/SearchInput'
import { getOffset } from './Common/helpers'

function SearchOverlayModal ({ isHomepageSearch }) {
  const [birdOverlayAnimationClass, setBirdOverlayAnimationClass] = useState('')
  const isSearchInputActive = useSelector(getIsSearchInputActive)

  useEffect(() => {
    !isSearchInputActive && setBirdOverlayAnimationClass('')
    const ovrlayFlyInTimer = setTimeout(() => {
      isSearchInputActive && setBirdOverlayAnimationClass('bird-fly-in')
    }, 10)
    const overlayTimer = setTimeout(() => {
      isSearchInputActive && setBirdOverlayAnimationClass('bird-main-move')
    }, 3000)

    return () => {
      clearTimeout(ovrlayFlyInTimer)
      clearTimeout(overlayTimer)
    }
  }, [isSearchInputActive])

  let topOffset = 0 
  let homepageSearchInput = document.querySelector('.homepage-search-input-group')
  topOffset = getOffset(homepageSearchInput)?.top || 0

  return (
    isSearchInputActive
      ? (
        <div className='overlay-searchbar'>
          <div className='overlay-bird-animation'>
            <div className={`bg-holder ${birdOverlayAnimationClass}`} style={{ marginTop: topOffset - 210 }} />
          </div>
          <SearchInput isHomepageSearch topOffset={topOffset} />
        </div>)
      : '')
}

export default SearchOverlayModal
