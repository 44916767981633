import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  FooterListingCounts
} from '../../components/Labels'
import { noImage, personalisedHearts } from '../../components/Icons'
import moment from 'moment'

const VoucherCard = ({ voucher, wrapperClass, displayOffer }) => {
  function handleImageError (event) {
    event.target.onError = null
    event.target.src = noImage
  }

  const isExclusive = voucher?.is_cba_exclusive?.toString() === 'true' || voucher?.type?.toLowerCase() == 'uniquecoupon'

  return (
    <div className={`listing-card listing-coupons ${wrapperClass}`}>
      <div className={`card card-coupon ${isExclusive ? 'exclusive': ''}`}>
        <Link
          className='link-wrapper'
          to={`/shop/voucher/${encodeURIComponent(voucher.slug)}`}
        >
          <div className='card-img-top coupon'>
            <LazyLoadImage
              className='img-fluid'
              alt={voucher.name}
              onError={e => handleImageError(e)}
              src={voucher.coupon_image || noImage}
            />
          </div>
          <div className='card-body'>
            <div className='coupon-title-info'>
              <div className='coupon-store'>{voucher.store_name}</div>
              <div className='card-text'>{voucher.name}</div>
            </div>
            <div className='coupon-offer-info'>
              <div className='coupon-type'>{displayOffer(voucher)}</div>
              <div className='d-flex align-items-center'>
                <div className='col-6 end-date-info'>{voucher.expire_at ? 'Ends ' + moment(voucher.expire_at).format('DD/MM/YYYY') : ''}</div>
                <div className='col-6 views-info p-0'>
                  <FooterListingCounts
                    impressionsCount={voucher.impressions_count}
                    wrapperClass='list-inline mb-0 d-flex justify-content-around'
                  />
                </div>
              </div>
            </div>
          </div>          
          <div className='card-footer'>
            <div className='card-meta-info'>            
              Get Promo Code
              {isExclusive && <span className='exclusive-badge'>
                  <img src={personalisedHearts} className='mr-2' width='16px' height='16px' />
                  Exclusive
                </span>
              }
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
export default VoucherCard
