import PropTypes from 'prop-types'
import moment from 'moment'
import { freshnessOptions } from '../../apps/App/components/Filter/checkboxOptions'

function filterDataLayer (itemData, eventType, position, currentSection = null, sectionPosition = null) {
  // Track event for filter action on other pages
  if (eventType === 'clear-all') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'clear', // Type of Interaction i.e click or clear if users clears all from component
            object_type: 'Clear All Sections', // the filter section name
            object_position: null, // the position of filter section Clear All button is always null
            component_name: 'search-filters', // the name of the component will always be 'search-filters'
            component_position: null // I believe this should be null as filter section will be sticky
          }
        }
      ])
  }

  // Track event for Filter Open Action
  if (eventType === 'open-filter') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or impression
            object_type: _.startCase(currentSection) || null, // the filter section name
            object_position: Number(position) || null, // the position of filter section
            component_name: 'search-filters', // the name of the component will always be 'search-filters'
            component_position: null // I believe this should be null as filter section will be sticky
          }
        }
      ])
  }

  // Track event for select/deselect filter action
  if (eventType === 'select-filter') {
    let priceFreshnessArr = []

    if (itemData.lastPriceRange?.length) {
      const priceFressnessDiff = moment(itemData.lastPriceRange[1]).diff(moment(itemData.lastPriceRange[0]), 'hours')
      const freshnessOptionsKey = priceFressnessDiff > 73 ? 3 : priceFressnessDiff > 49 ? 2 : 1
      const freshnessOptionsString = freshnessOptions.values?.filter(fresh => fresh.key === freshnessOptionsKey)
      priceFreshnessArr = (freshnessOptionsString[0]?.label)?.split(' - ')
    }

    const discount = itemData.discountRange?.map(value => parseInt(value * 100, 10)) || []

    snowplow('trackStructEvent', 'ux', 'filter-use', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/filters/jsonschema/1-0-0',
          data: {
            searchTerm: itemData.searchTerm || null,
            searchCount: itemData.searchCount || null,
            sort: _.startCase(itemData.sort) || null,
            brand: itemData?.brandNames?.length ? itemData.brandNames : null,
            store: itemData?.storeNames?.length ? itemData.storeNames : null,
            category: itemData?.departmentNames?.length ? itemData.departmentNames : null,
            subCategory: itemData?.categoryNames?.length ? itemData.categoryNames : null,
            type: itemData?.subcategoryNames?.length ? itemData.subcategoryNames : itemData?.couponTypes?.length ? itemData.couponTypes?.map(type => _.startCase(type)) : null,
            priceMin: itemData?.priceRange?.length ? itemData.priceRange[0] : null,
            priceMax: itemData?.priceRange?.length ? itemData.priceRange[1] : null,
            discountMin: discount[0] || null,
            discountMax: discount[1] || null,
            paymentMethod: itemData?.paymentMethods?.length ? itemData.paymentMethods?.map(method => _.startCase(method)) : null,
            priceFreshness: priceFreshnessArr.length ? priceFreshnessArr : null
          }
        }
      ])
  }
}

filterDataLayer.PropTypes = {
  item: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  parent: PropTypes.string.isRequired
}

export default filterDataLayer
