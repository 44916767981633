/* eslint-disable-next-line */
import React, { useState, useEffect, useRef } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { useRouteMatch } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useMediaQuery } from 'react-responsive'
import { getBaseUrl, isUserLoading } from '../../reducers'
import { getAdvertisements } from '../../requesters'
import { setCarouselLoading, setCarouselLoaded } from '../../actions/carousel'
import useScrollToSection from '../BiInfiniteScroll/useScrollToSection'

export default function AdvertisementList ({
  position, page, list, rankingList, sectionId
}) {
  const [advertisements, setAdvertisements] = useState([])
  const [loaded, setLoaded] = useState(false)
  const apiBaseUrl = useSelector(getBaseUrl)
  const userLoading = useSelector(isUserLoading)
  const routeMatch = useRouteMatch()
  const isMobile = useMediaQuery({ query: `(max-width: ${position === 'hero' ? 992 : 768}px)` })
  const sectionRef = useRef(null)
  const dispatch = useDispatch()

  useScrollToSection({ ref: sectionRef, eleId: 'hsref', offset: 15 })

  useEffect(() => {
    dispatch(setCarouselLoading({ carousel: sectionId }))
  }, [])

  useEffect(()=> {
    if((loaded && _.isEmpty(advertisements)) || sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [advertisements, sectionRef?.current, loaded])

  const pageUrl = _.get(routeMatch, 'url', '')
  const department = _.get(routeMatch, 'params.department', null)
  const category = _.get(routeMatch, 'params.category', null)

  // Fetch the data for the advertisement list
  useEffect(() => {
    let isMounted = true
    async function fetchData () {
      const requestParams = {
        by_page: page,
        by_position: position,
        by_list: list === 'top-vouchers' ? 'top-coupons' : list,
        by_department: department,
        by_category: category
      }
        const { response } = await getAdvertisements(apiBaseUrl, requestParams)
        if (isMounted) setAdvertisements(response.ids.map(id => response.data.advertisement[id]))
        setLoaded(true)
    }
    fetchData()
    return () => { isMounted = false }
  }, [pageUrl])

  if (_.isEmpty(advertisements)) { return (null) }

  const renderAdvertisements = _.map((advertisements), (advert, index) => {
    const {
      name,
      target,
      size
    } = advert.attributes
    const {
      affiliateUrl,
      image,
      mobileImage,
      url
    } = advert.links
    const advertClass = {
      full_width: 'col-12',
      half_width: 'col-lg-6',
      third_width: 'col-md-4'
    }[size]
    const imageToShow = (isMobile && mobileImage) ? mobileImage : image
    return (
      <div key={`advert-${advert.id}-${index}`} className={`advert ${position !== 'hero' && 'p-1'}`}>
        {
          _.isEmpty(url)
            ? <LazyLoadImage
                effect='opacity'
                alt={name}
                width='100%'
                className='img-fluid'
                src={imageToShow.url}
              />
            : <a target={target} href={`${url}`} onClick={() => sectionId && localStorage.setItem("hsref", sectionId)}>
              <LazyLoadImage
                effect='opacity'
                alt={name}
                width='100%'
                className='img-fluid'
                src={imageToShow.url}
              />
              </a>
        }
      </div>
    )
  })

  const advertisementContent = (
    isMobile
      ? (
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          preventMovementUntilSwipeScrollTolerance
          showIndicators={advertisements.length > 1}
        >
          {renderAdvertisements}
        </Carousel>
        )
      : renderAdvertisements
  )
  const sectionClass = `${position !== 'hero' ? 'container' : ''} advertisements-list advertisements-list-${position}  ${rankingList ? 'mb-0 listing-card' : ''}`
  return (
    <div id={sectionId} ref={sectionRef} key={`advertisement-list-${position}`} className={sectionClass}>
      <div className='d-md-flex justify-content-between'>
        {advertisementContent}
      </div>
    </div>
  )
}
