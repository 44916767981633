import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Experiment, experimentDebugger, Variant, emitter } from '@marvelapp/react-ab-test'
import { getExperimentDetailsById, getActiveExperiment, getAllExperiments } from '../../reducers'

experimentDebugger.enable()

const AppExperiment = ({ id, components, defaultVarinat }) => {
  const allExperiments = useSelector(getAllExperiments) || []
  const experiment = useSelector(state => getExperimentDetailsById(state.experiments, id))
  const [isActive, setIsActive] = useState(null)
  const activeExperiments = useSelector(getActiveExperiment)

  useEffect(() => {
    if (experiment.name) setIsActive(emitter.getActiveVariant(experiment.name.replaceAll(' ', '_')))
  }, [experiment])

  return (
    <>
      {isActive && allExperiments?.length && experiment?.name
        ? (
            experiment.name && experiment.active && experiment.variants.length && activeExperiments?.includes(experiment.name) && (
              <Experiment name={experiment.name.replaceAll(' ', '_')}>
                {experiment.variants.map((ele, index) => (
                  <Variant name={ele} key={index}>
                    {components[ele]}
                  </Variant>))}
              </Experiment>
            ))
        : components[defaultVarinat]}
    </>
  )
}

export default AppExperiment
