import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"

// import PageSection from "../../../../components/PageSection"
import SubmitListingForm from "../../../../components/SubmitListingForm"

import {
  loadSubmitNewListingPage
} from "../../actions"

import * as reducers from "../../reducers"

import {
  TopBrandsSidebarList,
  TopDealHuntersSidebarList,
  TopStoresSidebarList
} from "../listings"

class SubmitListing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmSubmit: false,
      submitType: "danger",
      disableSubmit: false,
      listingTypeState: "deal"
    }
  }

  componentDidMount() {
    const {
      loadSubmitNewListingPage
    } = this.props
    loadSubmitNewListingPage({})
  }

  confirmSubmit = () => {
    const {
      history
    } = this.props

    const {
      submitType
    } = this.state

    this.setState({ showConfirmSubmit: false })

    if (submitType === "success") {
      history.push("/")
    }
  }

  handleSubmit = formData => {
    this.setState({ disableSubmit: true })

    const path = `${gon.urls.api}/listings`

    return axios.post(path, formData)
      .then(response => {
        // Grape param validation error won't go to catch, so adding a validation in then block
        if (response.data?.errors) {
          this.setState({ showConfirmSubmit: true, submitType: "danger", disableSubmit: false })
        } else {
          this.setState({ showConfirmSubmit: true, submitType: "success", disableSubmit: false })
        }
      })
      .catch(() => {
        this.setState({ showConfirmSubmit: true, submitType: "danger", disableSubmit: false })
      })
  }

  listingTypeState = event => {
    this.setState({ listingTypeState: event.value })
  }

  render() {
    const {
      departments,
      categories,
      subcategories,
      tags
    } = this.props

    const {
      showConfirmSubmit,
      submitType,
      disableSubmit,
      listingTypeState
    } = this.state

    const sweetAlertTitle = submitType === "success" ? "Listing Submitted!" : "Failed to submit listing."

    return (
      <div className="listing-page">
        <SweetAlert
          show={showConfirmSubmit}
          type={submitType}
          title={sweetAlertTitle}
          closeOnClickOutside={false}
          allowEscape={false}
          onConfirm={this.confirmSubmit} />

        <div className="container">
          <h1 className="mb-4 mt-4">
            Submit a
            {" "}
            {listingTypeState === "coupon" ? _.startCase("voucher") : _.startCase(listingTypeState)}
          </h1>
          <div className="card shadow-sm">
            <div className="card-body">
              <SubmitListingForm
                submitForm={this.handleSubmit}
                departments={departments}
                categories={categories}
                subcategories={subcategories}
                tags={tags}
                disableSubmit={disableSubmit}
                listtingTypeChange={this.listingTypeState} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  departments: reducers.getDepartments(state),
  categories: reducers.getCategories(state),
  subcategories: reducers.getSubcategories(state),
  tags: reducers.getTags(state)
})

export default withRouter(connect(mapStateToProps, {
  loadSubmitNewListingPage
})(SubmitListing))
