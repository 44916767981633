import React from 'react'

const NoResultDivider = ({ countZero }) => {
  return (
    <div className='col-12 text-center'>
      {countZero ? <div>No matches found</div> : <div>No More Results</div>}
      <hr className='mt-0' />
    </div>
  )
}

export default NoResultDivider