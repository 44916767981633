import * as types from './types'

export const setUserEmailAddress = data => ({
  type: types.SET_USER_EMAIL,
  data
})

export const setModalClose = data => ({
  type: types.SET_PERSONALISE_MODAL,
  data
})

export const setIntialPersonalise = data => ({
  type: types.SET_INTIALLISE_PERSONALISE,
  data
})
