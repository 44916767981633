// // This is a version 2 of the ranking page where its used for custom lists
// // This component utilises react hooks`

import React, { useState, useEffect, Fragment } from "react"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

import { getCustomLists } from "../../../../App/requesters"
import {
  getBaseUrl,
  getDepartmentBySlug,
  getCategoryBySlug,
  getBrandBySlug,
  getStoreBySlug
} from "../../../../App/reducers"

import Loader from "../../../../../components/Loader"
import CollectionCard from "../../../../../components/ListingCards/CollectionCard"

import { collectionOptions } from "./sortOptions"

export default function CollectionsList({
  sortOptionValue,
  mobileColumn,
  mobileDirection,
  setFilters,
  filterOptions: {
    brandIds, storeIds,
    departmentIds, categoryIds
  }
}) {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const {
    department, category, brand, store
  } = Object.fromEntries(urlSearchParams.entries())

  const presetDepartment = useSelector(state => getDepartmentBySlug(state, department))
  const presetCategory = useSelector(state => getCategoryBySlug(state, category))
  const presetBrand = useSelector(state => getBrandBySlug(state, brand))
  const presetStore = useSelector(state => getStoreBySlug(state, store))

  const apiBaseUrl = useSelector(getBaseUrl)

  const [collections, setCollections] = useState([])
  const [loading, setIsLoading] = useState(true)
  const [column, setColumn] = useState("published_at")
  const [direction, setDirection] = useState("desc")
  const [page, setPage] = useState(1)
  const [isReady, setIsReady] = useState(false)

  // Set filters from url
  useEffect(() => {
    if (isReady) return
    if (department && !presetDepartment) return
    if (category && !presetCategory) return
    if (brand && !presetBrand) return
    if (store && !presetStore) return

    setFilters({
      departmentIds: _.get(presetDepartment, "id") ? [presetDepartment.id] : [],
      categoryIds: _.get(presetCategory, "id") ? [presetCategory.id] : [],
      brandIds: _.get(presetBrand, "id") ? [presetBrand.id] : [],
      storeIds: _.get(presetStore, "id") ? [presetStore.id] : []
    })
    setIsReady(true)
  }, [
    presetDepartment, presetCategory,
    presetBrand, presetStore
  ])

  useEffect(() => {
    setPage(1)
    setCollections([])
  }, [
    brandIds, storeIds,
    departmentIds, categoryIds,
    mobileColumn, mobileDirection
  ])

  // Fetch the data for the custom list
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const params = {
        page,
        "by_order[column]": column,
        "by_order[direction]": direction,
        by_brands: brandIds,
        by_stores: storeIds,
        by_departments: departmentIds,
        by_categories: categoryIds
      }
      const { response } = await getCustomLists(apiBaseUrl, params)
      setCollections(collections => [...collections, ...response.ids.map(id => response.data.customList[id])])
      setIsLoading(false)
    }
    if (isReady) fetchData()
  }, [page, column, direction, isReady])

  // Generates the list for displaying in the paginatied view
  //
  const items = _.map(collections, (item, index) => (
    <CollectionCard
      key={`collection-${index}`}
      wrapperClass="col-sm-6 col-lg-4"
      item={item} />
  ))

  useEffect(() => {
    const option = collectionOptions.values.find(value => value.key === parseInt(sortOptionValue, 10))
    if (option) {
      const { value, order } = option
      setColumn(value)
      setDirection(order)
      setPage(1)
      setCollections([])
    }
  }, [sortOptionValue])
  return (
    <Fragment>
      <br />
      {
        // Show when there is no data to see
        (!loading && items.length === 0)
        ? <p>No data to see here</p>
        : (
          <InfiniteScroll
            className="row listing-grid"
            dataLength={items.length} // This is important field to render the next data
            next={() => setPage(page + 1)}
            hasMore
            loader={<div className="listing-card col-12"><Loader isLoading={loading} /></div>}
            endMessage={(
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            )}>
            {items}
          </InfiniteScroll>
        )
      }
    </Fragment>
  )
}
