export default function popularAndActiveAndSortedList (listArr) {
  const activePopuList = _.filter(listArr, e => _.get(e, 'attributes.active') === true && _.get(e, 'attributes.popular') === true)
  const sortedPopuActiveList = _.orderBy(activePopuList, ['attributes.position'], ['asc'])
  const activeNormalList = _.filter(listArr, e => _.get(e, 'attributes.active') === true && _.get(e, 'attributes.popular') === false)
  const sortedNormalActiveList = _.orderBy(activeNormalList, ['attributes.position'], ['asc'])
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const result = affinityData && affinityData[0]?.affinity?.departments.reduce(function (r, a) {
    r[a.score] = r[a.score] || [];
    r[a.score].push(a);
    return r;
}, Object.create(null));

  if (affinityData && affinityData[0]?.affinity?.departments.length > 0) {
    const tmpDepartmentList = sortedPopuActiveList.concat(sortedNormalActiveList)
    const finalArray = []
    const cpyList = JSON.parse(JSON.stringify(tmpDepartmentList))
    cpyList.forEach((tmpDep, tmpIndex) => {
      Object.keys(result)?.sort()?.reverse().forEach(scoreVal => {
        result[scoreVal]?.forEach(depName => {
          tmpDep?.attributes?.name === depName?.name && finalArray.push({...tmpDep, score: scoreVal})
          tmpDep?.attributes?.name === depName?.name && _.remove(tmpDepartmentList, td => td.attributes?.name === depName?.name)
        })
      })
    })
    return _.take((_.orderBy(finalArray, ['score'], ['desc'])).concat(tmpDepartmentList), 7)
  } else {
    return sortedPopuActiveList
  }
}
