export function departmentPath({ departmentSlug }) {
  return `/shop/${departmentSlug}`
}

export function categoryPath({ departmentSlug, categorySlug }) {
  return `/shop/${departmentSlug}/${categorySlug}`
}

export function subcategoryPath({ departmentSlug, categorySlug, subcategorySlug }) {
  return `/shop/${departmentSlug}/${categorySlug}/${subcategorySlug}`
}
