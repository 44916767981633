import React from "react"
import { Link } from "react-router-dom"

import withBreadcrumbs from "react-router-breadcrumbs-hoc"

const excludedRoutes = [
  "/spotter",
  "/shop"
]

const rankingRoutes = [
  "Store",
  "Brand"
]

function rankingPage(url) {
  const returnUrl = {
    Store: "/trending-stores",
    Brand: "/trending-brands"
  }[url]

  return returnUrl
}

const Breadcrumbs = ({ breadcrumbs, positionWrappper }) => {
  if (breadcrumbs.length === 1 && breadcrumbs[0].key === "/") { return (null) }
  return (
    <>
      <div className="breadcrumbs d-close d-lg-inline-block">
        <div className="breadcrumb">
          {_.map(breadcrumbs, ({ match, breadcrumb }, index) => {
            if (_.includes(excludedRoutes, match.url)) return null

            if (breadcrumbs.length - 1 === index) {
              return (
                <span aria-current="page" key={match.url} className="breadcrumb-item active">{_.startCase(breadcrumb.props.children.replace("-", " ").replace(/\d+$/, ""))}</span>
              )
            }

            return (
              <span className="breadcrumb-item" key={match.url}>
                <Link to={rankingRoutes.includes(breadcrumb.props.children) ? rankingPage(breadcrumb.props.children) : match.url} className="text-capitalize">
                  {_.startCase(breadcrumb.props.children.replace("-", " "))}
                </Link>
              </span>
            )
          })}
        </div>
      </div>

      <div className="dropdown d-lg-none">
        <div className="dropdown-toggle breadcrumbs" id="breadcrumbMenuButton" aria-expanded="false" aria-haspopup="true" data-toggle="dropdown">
          <div className="breadcrumb">
            {_.map(breadcrumbs, ({ match, breadcrumb }, index) => {
              if (_.includes(excludedRoutes, match.url)) return null

              if (breadcrumbs.length - 1 === index) {
                return (
                  <span aria-current="page" key={`${match.url}-${index}`} className="breadcrumb-item active">{_.startCase(breadcrumb.props.children.replace("-", " ").replace(/\d+$/, ""))}</span>
                )
              }

              return (
                <span className="breadcrumb-item" key={`${match.url}-${index}`}>
                  <Link to={rankingRoutes.includes(breadcrumb.props.children) ? rankingPage(breadcrumb.props.children) : match.url} className="text-capitalize">
                    {_.startCase(breadcrumb.props.children.replace("-", " "))}
                  </Link>
                </span>
              )
            })}
          </div>
        </div>
        <div className="dropdown-menu breadcrumb-menu" aria-labelledby="breadcrumbMenuButton">
          {_.map(breadcrumbs, ({ match, breadcrumb }, index) => {
            if (_.includes(excludedRoutes, match.url)) return null

            return (
              <Link key={index} to={rankingRoutes.includes(breadcrumb.props.children) ? rankingPage(breadcrumb.props.children) : match.url} className="text-capitalize dropdown-item">
                {_.startCase(breadcrumb.props.children.replace("-", " "))}
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}

const routes = [
  { path: "/listings", breadcrumb: null }
]

export default withBreadcrumbs(routes)(Breadcrumbs)
