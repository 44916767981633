const couponOptions = {
  values: [
    {
      key: 1, value: "fixed_global_score", order: "desc", text: "Top Ranked"
    },
    {
      key: 2, value: "published_at", order: "desc", text: "Newest"
    }
  ]
}

const dealOptions = {
  values: [
    {
      key: 1, value: "fixed_global_score", order: "desc", text: "Best of the Best"
    },
    {
      key: 2, value: "price", order: "desc", text: "$ High to Low"
    },
    {
      key: 3, value: "price", order: "asc", text: "$ Low to High"
    },
    {
      key: 4, value: "slug.keyword", order: "asc", text: "A-Z"
    },
    {
      key: 5, value: "slug.keyword", order: "desc", text: "Z-A"
    },
    {
      key: 6, value: "price_shift", order: "asc", text: "Discount High to Low (%)"
    },
    {
      key: 7, value: "price_shift", order: "desc", text: "Discount Low to High (%)"
    },
    {
      key: 8, value: "last_price_date", order: "desc", text: "Newest"
    }
  ]
}

const bestSellersOptions = {
  values: [
    {
      key: 1, value: "rank", order: "asc", text: "Top Ranked"
    },
    {
      key: 2, value: "name", order: "asc", text: "Title A - Z"
    },
    {
      key: 3, value: "name", order: "desc", text: "Title Z - A"
    },
    {
      key: 4, value: "price", order: "desc", text: "$ High to Low"
    },
    {
      key: 5, value: "price", order: "asc", text: "$ Low to High"
    },
    {
      key: 6, value: "price_shift", order: "asc", text: "Discount High to Low(%)"
    },
    {
      key: 7, value: "price_shift", order: "desc", text: "Discount Low to High(%)"
    },
    {
      key: 8, value: "last_price_date", order: "desc", text: "Newest"
    }
  ]
}

const brandAndStoreOptions = {
  values: [
    {
      key: 7, value: "rank", order: "asc", text: "Top Ranked"
    },
    {
      key: 3, value: "name", order: "asc", text: "Title A - Z"
    },
    {
      key: 4, value: "name", order: "desc", text: "Title Z - A"
    },
    {
      key: 5, value: "deals_count", order: "desc", text: "Number of Price Drops"
    }
  ]
}

const saleEventOptions = {
  values: [
    {
      key: 1, value: "fixed_global_score", order: "desc", text: "Top Ranked"
    },
    {
      key: 3, value: "name", order: "asc", text: "Title A - Z"
    },
    {
      key: 4, value: "name", order: "desc", text: "Title Z - A"
    },
    {
      key: 6, value: "published_at", order: "desc", text: "Newest"
    }
  ]
}

const collectionOptions = {
  values: [
    {
      // key: 1, value: "position", order: "desc", text: "Top Ranked"
      key: 1, value: "published_at", order: "desc", text: "Newest"
    },
    {
      key: 2, value: "name", order: "asc", text: "Title A - Z"
    },
    {
      key: 3, value: "name", order: "desc", text: "Title Z - A"
    }
    // {
    //   key: 4, value: "updated_at", order: "desc", text: "Newest"
    // }
  ]
}

export {
  bestSellersOptions,
  couponOptions,
  dealOptions,
  brandAndStoreOptions,
  saleEventOptions,
  collectionOptions
}
