import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axios from 'axios'

import {
  aboutIcon,
  crossIcon,
  impressionIcon
} from '../../../../../components/Icons'

import { gon } from '../../../../../../constant'
import onboardingFlowDataLayer from '../../../../../components/dataLayers/onboardingFlowDataLayer'
import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'

export default function GetStarted({
  hideModal,
  handleBack,
  progressBar,
  userData
}) {
  const [username, setUserName] = useState(null)
  const [password, setPassowrd] = useState(null)
  const [type, setType] = useState('password')
  const [remember, setRemember] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    onboardingFlowDataLayer('onboarding-flow-view', null, 'lets-get-started', 2)
  }, [])

  const validateForm = () => {
    if (!username && !password) {
      setErrors({ errors: ['Username can’t be blank', 'Password can\'t be blank'] })
      return false
    }

    if (!username) {
      setErrors({ errors: ['Username can’t be blank']})
      return false
    }

    if (!password) {
      setErrors({ errors: ['Password can\'t be blank']})
      return false
    }

    return true
  }

  const handleAccountCreation = async () => {
    if (validateForm()) {
      onboardingFlowDataLayer('onboarding-flow-click', 'continue', 'lets-get-started', 2)
      const path = `${gon.urls.api}/auth/register`
      const data = {
        email: userData.email,
        password: password,
        password_confirmation: password,
        remember_me: remember,
        username: username
      }

      const wtidUtmCookies = userData.wtidUtmCookies || {}
      return axios
        .post(path, {
          user: data,
          wtid: userData.wtidCookie,
          ...wtidUtmCookies
        })
        .then((response) => {
          if (response.status !== 200) {
            setErrors({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )
          window.location.reload()
        })
        .catch((error) => setErrors({ errors: error?.data?.errors }))
    }
  }

  return (
    <>
      <Modal.Header className='d-flex flex-column border-0 pb-0'>
        <div className='align-self-end'>
          <button type='button' className='close' onClick={hideModal}>
            <img src={crossIcon} />
          </button>
        </div>
        <div className='header d-flex justify-content-between w-100 m-top'>
          <h3>Let’s get started</h3>
        </div>
        <div className='w-100'>{progressBar}</div>
      </Modal.Header>
      <Modal.Body>
        {errors?.errors?.length ? (
          <div className='alert alert-danger alert-block error-label'>
            <button className='close' data-dismiss='alert' type='button'>
              <img src={crossIcon} />
            </button>
            <div className='mt-4'>
              {errors.errors.map((error, key) => (
                <p key={key} className='text-dark'>
                  <img src={aboutIcon} className='mr-3' />
                  {error}
                </p>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='mb-40'>
          <input
            placeholder='Username'
            required
            name='username'
            value={username}
            className='form-control'
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
          <div className='form-field-password d-flex'>
            <input
              placeholder='Password'
              name='password'
              required
              value={password}
              type={type}
              className='form-control'
              onChange={(e) => setPassowrd(e.target.value)}
            />
            <img
              onClick={() => setType(type === 'text' ? 'password' : 'text')}
              src={impressionIcon}
            />
          </div>
        <div className='mt-40'>
          <div className='custom-control custom-checkbox'>
            <input
              className='custom-control-input'
              id='customCheck1'
              type='checkbox'
              onChange={() => setRemember(!remember)}
              defaultChecked={remember}
            />
            <label className='custom-control-label' htmlFor='customCheck1'>
              Remember me
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='mx-auto'>
          <button
            type='button'
            className='btn btn-outline-dark rounded-pill mx-2'
            onClick={handleBack}>
            {' '}
            Back
          </button>
          <button
            type='button'
            // disabled={!validForm}
            className='btn btn-main rounded-pill mx-2'
            onClick={() => handleAccountCreation()}>
            Continue
          </button>
        </div>
      </Modal.Footer>
    </>
  )
}
