import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ListingCard from './ListingCard'
import ChevronRight from './ChevronRight'
import LazyLoad from 'react-lazyload'
import { affiTopCategoryRequestStart } from '../actions'
import ForYou from '../../../components/Labels/ForYou'
import { getCategoryByAttributes } from '../reducers'
import Loader from '../../../components/Loader'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { randomizeList, sortItems } from '../utilis/topPickCommonActions'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'
// import { cyberMondayBadge } from '../../../../javascript/components/Icons'

export default function CategoryTopTen (props) {
  const { deptCatPage, isPersonalised, category } = props
  const getAllCategoryData = useSelector((state) => getCategoryByAttributes(state, props?.category?.name || props?.category?.attributes?.name))
  const getCategory = getAllCategoryData.length && getAllCategoryData[0]
  const department = getCategory?.relationships?.department?.data || {}
  const getDepartment = useSelector((state) => state.data.departments?.byId[department?.id])
  const sectionRef = useRef(null)
  const dispatch = useDispatch()
  const [showCarousel, setShowCarousel] = useState(true)

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    dispatch(setCarouselLoading({ carousel: props.sectionId }))
  }, [])

  useEffect(() => {
    if (sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: props.sectionId }))
    }
  }, [sectionRef?.current])

  const isLargeScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const sectionHeight = isLargeScreen ? 520 : 564

  return (
    showCarousel
      ? (
        <div id={props.sectionId} ref={sectionRef}>
          <LazyLoad height={sectionHeight}>
            <section className='section-group top-50'>
              <div className='container-fluid full-width-container'>
                <div className={`your-top-section-bg ${(category?.score || isPersonalised) ? 'dept-top-10' : 'dept-top-10-for-you'}`} style={{ backgroundColor: '#f3f4f3' }} />
              </div>
              <div className={`section-group-body ${(category?.score || isPersonalised) ? 'dept-group-body' : 'dept-group-body-for-you'}`}>
                <div className='container'>
                {/* <img className='bf-tag-small' src={cyberMondayBadge} /> */}
                  <div className='listing-group-header'>
                    <div className='d-flex mb-2'>
                      {(category?.score || isPersonalised) && <ForYou />}
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h2 className='carousel-title'>Top {_.get(getCategory, 'attributes.name')} Offers</h2>
                        {/* <div className='subtitle'>
                          The best offers in the past 24 hours
                        </div> */}
                      </div>
                      <Link
                        to={deptCatPage ? `/shop/${getDepartment?.attributes?.slug}/${getCategory?.attributes?.slug}/view-all` : `/shop/${getDepartment?.attributes?.slug}/${getCategory?.attributes?.slug}`}
                        className='btn btn-outline-dark rounded-pill px-5 btn-view-all d-none d-md-block'
                        onClick={() => props.sectionId && localStorage.setItem('hsref', props.sectionId)}
                      >
                        {deptCatPage ? 'View all' : 'View More'}
                      </Link>
                    </div>
                  </div>
                </div>
                <TopPicksAffinityBody
                  sectionId={props.sectionId}
                  department={getDepartment}
                  category={getCategory}
                  sectionPosition={props?.sectionPosition}
                  isPersonalised={props?.isPersonalised}
                  handleShowCarousel={(isShow) => setShowCarousel(isShow)}
                />
                <div className='d-xs-block d-md-none d-flex justify-content-center'>
                  <Link
                    to={deptCatPage ? `/shop/${getDepartment?.attributes?.slug}/${getCategory?.attributes?.slug}/view-all` : `/shop/${getDepartment?.attributes?.slug}/${getCategory?.attributes?.slug}`}
                    className='btn btn-outline-dark rounded-pill px-5 btn-view-all btn-view-all-mobile'
                    onClick={() => props.sectionId && localStorage.setItem('hsref', props.sectionId)}
                  >
                    {deptCatPage ? 'View all' : 'View More'}
                  </Link>
                </div>
              </div>
            </section>
          </LazyLoad>
        </div>
        )
      : null
  )
}

const TopPicksAffinityBody = ({ category, department, sectionPosition, sectionId, isPersonalised, handleShowCarousel }) => {
  // value of `isPersonalised` should be boolean,
  // so pass isPersonalised = { false} to CategoryTopTen component as props

  const [finalList, setFinalList] = useState([])
  const dispatch = useDispatch()
  const { topCategory } = useSelector(state => state.affinityTopCategory)

  useEffect(() => {
    const catDataToRandamize = topCategory?.data?.[`cat_${category?.attributes?.id}`] || []
    catDataToRandamize?.response && setFinalList(randomizeYourListNew(catDataToRandamize?.response))
    const isDealsAvailable = Object.keys(catDataToRandamize?.response || {})?.filter(key => {
      return catDataToRandamize?.response[key]?.length > 0
    }) || []
    catDataToRandamize?.response && !isDealsAvailable?.length && handleShowCarousel(false)
  }, [topCategory])

  useEffect(() => {
    category && dispatch(affiTopCategoryRequestStart({
      id: _.get(category, 'attributes.id'),
      slug: `${_.get(department, 'attributes.slug')}/${_.get(category, 'attributes.slug')}`,
      name: _.get(category, 'attributes.name'),
      isPersonalised: isPersonalised
    }))
  }, [category])

  const myRef = useRef(null)

  const randomizeYourListNew = (lists) => {
    const finalList = randomizeList(lists)

    const randFinalList = sortItems(finalList)
    return randFinalList
  }

  return (
    <div className='container' id={sectionId}>
      <div className='listing-carousel'>
        {topCategory?.isLoading && !finalList.length
          ? <Loader isLoading />
          : (
            <div className='d-flex listing-group-carousel' ref={myRef}>
              {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='left' /> : null}
              {finalList.map((item, index) => {
                return (
                  <TopPickItem
                    sectionId={sectionId}
                    key={`${item?.itemType}-${item?.id}`}
                    type={item?.itemType}
                    id={item?.id}
                    index={index}
                    listItem={item}
                    isElasticResult={item?.isElasticResult}
                    sectionPosition={sectionPosition}
                    category={category}
                  />
                )
              })}
              {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='right' /> : ''}
            </div>)}
      </div>
    </div>
  )
}

function TopPickItem ({ type, id, index, listItem, sectionPosition, category, sectionId }) {
  const wrapperClass = {
    deal: 'listing-price-drop',
    saleEvent: 'event',
    coupon: 'coupon-large-image',
    uniqueCoupon: 'coupon-large-image',
    customList: ''
  }[type]
  return (
    <ListingCard
      sectionId={sectionId}
      wrapperClass={wrapperClass}
      type={type}
      id={id}
      position={index + 1}
      showRank
      currentSection={`${_.get(category, 'attributes.name')} Top 10`}
      sectionPosition={sectionPosition}
      isYourTopList
      listItem={listItem}
      isPersonalised
    />
  )
}
