import React, { Component } from 'react'
import { connect } from 'react-redux'

import Meta from '../../../../../components/Meta'
import SortSelect from './SortSelect'
import SearchResults from './SearchResults'
import StoreBrandSearchResults from './StoreBrandSearchResults'
import {
  dealOptions as options,
  voucherOptions,
  saleEventOptions
} from './options'
import { DesktopFilter, MobileFilter } from '../../Filter'
import { getSearchKeyword } from '../../../reducers'
import filterDataLayer from '../../../../../components/dataLayers/filterDataLayer'
import { debounce } from '../Common/helpers'
import VouchersResults from './VouchersResults'
import SearchSaleEvent from './searchSaleEvent'
import { oneColumnIcon, twoColumnIcon,oneColumnIconInactive, twoColumnIconInactive, googleFull, chevronRightFilled } from '../../../../../components/Icons'
import { LB_NATIVE_APP_VERSION } from '../../../../../../constant'

const filters = {
  voucher: {
    showElasticBrand: true,
    showElasticStore: true,
    showElasticDepartments: true,
    options: voucherOptions
  },
  sale: {
    showElasticBrand: true,
    showElasticStore: true,
    showElasticDepartments: true,
    options: saleEventOptions
  },
  deal: {
    showAttributeFilters: false,
    showElasticBrand: true,
    showElasticStore: true,
    showElasticDepartments: true,
    showPriceRange: true,
    showDiscountRange: true,
    showPaymentMethods: true,
    showFreshness: true,
    options: options
  }
}
const tabTypes = [
  'voucher',
  'sale',
  'deal',
  'store',
  'brand',
]

const tabOptions = ['dealOptions', 'voucherOptions', 'saleOptions']

class SearchPage extends Component {
  constructor(props) {
    super(props)
    const activeTabStorage = window.localStorage.getItem('activeTab') || null
    const activeTabParam  = this.getActiveFromUrl()
    
    this.state = {
      key:1,
      prevScrollPos: 0,
      filterSticky: false,
      tabSticky: false,
      keyword: this.props.keyword?.searchTerm,
      searchMeta: this.props.keyword?.searchMeta,
      isLoading: false,
      isDealReady: false,
      isSaleReady: false,
      isVoucherReady: false,
      storeBrands: [],
      stores: [],
      brands: [],
      isDealEnabled: false,
      isSaleEnabled: false,
      isVoucherEnabled: false,
      isStoreEnabled: false,
      isBrandEnabled: false,
      isFilter: false,
      dealOrderBy: {
        value: null,
        order: null,
        key: null
      },
      saleOrderBy: {
        value: null,
        order: null,
        key: null
      },
      voucherOrderBy: {
        value: null,
        order: null,
        key: null
      },
      searchMinMaxPrice: [],
      dealOptions: this.getStoredFilterOptions(),
      voucherOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      saleOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      totalResultsCount: null,
      activeTabParam,
      activeTabStorage,
      selectedSearchTab: activeTabStorage || activeTabParam || 'deal',
      dealCount: 0,
      brandCount: 0,
      storeCount: 0,
      saleCount: 0,
      voucherCount: 0,
      isFinished: true,
      mbColumn: 'twoColumn',
      selTabValidated: false
    }
  }

  getActiveFromUrl() {
    const { location: { search } } = this.props
    const searchParam = new URLSearchParams(search)

    return tabTypes.indexOf(searchParam?.get('tab')) > -1 ? searchParam?.get('tab'): null
  }

  componentDidUpdate(prevProps, preState) {
    const { keyword: prev_keyword } = prevProps
    const { keyword } = this.props
    const { isDealReady, isSaleReady, isVoucherReady, selTabValidated } = this.state

    // If either department/category or subcategory changes we go refresh the data
    if (!_.isEqual(keyword, prev_keyword)) this.refreshPage({
      isDealReady: false,
      isSaleReady: false,
      isVoucherReady: false,
      selTabValidated: false
    })
    else if(isDealReady && isSaleReady && isVoucherReady && !selTabValidated) this.validateSelectedTab()
  }

  refreshPage = (overrideState) => {
    this.setState(this.getInitialState(overrideState))
  }

  getStoredFilterOptions = () => {

    const dealOptions = {
      brandNames: [],
      storeNames: [],
      departmentNames: [],
      categoryNames: [],
      subcategoryNames: [],
      priceRange: [],
      discountRange: [],
      paymentMethods: [],
      lastPriceRange: [],
      attributeFilters: {}
    }

    const { options, isAvailable } = this.getStoredFilters()

    if(!options.check){

      if(options.storeNames){
        dealOptions.storeNames = options.storeNames
      }

        if(options.brandNames){
          dealOptions.brandNames = options.brandNames
        }

        if (options.priceRange) {
          dealOptions.priceRange = options.priceRange || []
        }

        if (options.discountRange) {
          dealOptions.discountRange = options.discountRange || []
        }

        if (options.lastPriceRange) {
          dealOptions.lastPriceRange = options.lastPriceRange || [];
        }

        if (options.departmentNames) {
          dealOptions.departmentNames = options.departmentNames || [];
        }

        if (options.categoryNames) {
          dealOptions.categoryNames = options.categoryNames || [];
        }

        if (options.subcategoryNames) {
          dealOptions.subcategoryNames = options.subcategoryNames || []

        }
      }

      return dealOptions
  }

  getStoredFilters = () => {
    const getStoredOptions = JSON.parse(window.localStorage.getItem("search_product_filter") || '{}')
    const options = Object.keys(getStoredOptions)
    return {
      options: getStoredOptions,
      isAvailable: options.length
    }
  }

  removeStoreOptions = () => {
    const getPage = JSON.parse(window.localStorage.getItem('search_product_filter') || '{}')
    getPage.check && window.localStorage.removeItem('search_product_filter')
  }

  getInitialState = (overrideState = {}) => {

    const searchMeta = this.props.keyword.searchMeta || null
    const keyword = this.props.keyword.searchTerm

    const getDealOptions = this.getStoredFilterOptions()
    const activeTabStorage = window.localStorage.getItem('activeTab')
    const activeTabParam = this.getActiveFromUrl()

    const initialState = {
      keyword: keyword,
      searchMeta: searchMeta,
      searchMinMaxPrice: [],
      sLoading: false,
      dealOrderBy: {
        value: null,
        order: null,
        key: null
      },
      saleOrderBy: {
        value: null,
        order: null,
        key: null
      },
      voucherOrderBy: {
        value: null,
        order: null,
        key: null
      },
      dealOptions: getDealOptions,
      voucherOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      saleOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      totalResultsCount: null,
      activeTabStorage,
      activeTabParam,
      selectedSearchTab: activeTabStorage || activeTabParam || 'deal',
      selTabValidated: false,
    }

    if (searchMeta !== null) {
      tabOptions.forEach(opt => {
          initialState[opt][searchMeta.filterKey] = [...(initialState[opt][searchMeta.filterKey] || []), searchMeta.filterValue]
        })
    }

    return _.extend(initialState, overrideState)
  }

  handleSort = (event) => {
    const { selectedSearchTab } = this.state
    let sortOptions = filters[selectedSearchTab].options
    const sortByKey = `${selectedSearchTab}OrderBy`

    const selected = sortOptions.values.find(
      (value) => value.key === parseInt(event.currentTarget.value, 10)
    )

    const newState = {}
    newState[sortByKey] = {
      value: selected.value,
      order: selected.order,
      key: selected.key
    }

    this.setState(newState)
  }

  handleFilter = (e, sectionPosition) => {
    const { name, value, attributeFilter } = e
    const { selectedSearchTab } = this.state
    const key = `${selectedSearchTab}Options`
    const filterOption = this.state[key]
    const attributeFilters = { ...filterOption.attributeFilters }

    if(attributeFilter) {
      if(!attributeFilters[name]) {
        attributeFilters[name] = []
      }

      if (value.constructor === Array) {
        attributeFilters[name] = attributeFilters[name] === value ? [] : value
      } else {
        attributeFilters[name] = attributeFilters[name].includes(value)
          ? attributeFilters[name].filter((e) => e !== value)
          : (attributeFilters[name] = [...attributeFilters[name], value])
      }
      filterOption.attributeFilters = attributeFilters
    }
    else {
      if (value.constructor === Array) {
        filterOption[name] = filterOption[name] === value ? [] : value
      } else {
        filterOption[name] = filterOption[name].includes(value)
          ? filterOption[name].filter((e) => e !== value)
          : (filterOption[name] = [...filterOption[name], value])
      }
    }

    const updateObj = {}
    updateObj[key] = filterOption
    updateObj.isFilter = true
    this.setState(updateObj)
  }

  handleFilterClear = (event) => {
    const name = event?.target?.name
    const { selectedSearchTab } = this.state
    const key = `${selectedSearchTab}Options`
    let filterOptions = this.state[key]
    const attributeFilters = { ...filterOptions.attributeFilters }

    if(event.currentTarget?.dataset?.attributeFilter) {
      attributeFilters[name] = []
      filterOptions.attributeFilters = attributeFilters
    }
    else {
      filterOptions[name] = []
    }

    const updateObj = {}
    updateObj[key] = filterOptions
    this.setState(updateObj)
  }

  isClearAllBtnDisplay = () => {
    const {
      brandNames,
      storeNames,
      departmentNames,
      categoryNames,
      subcategoryNames,
      priceRange,
      discountRange,
      paymentMethods,
      lastPriceRange,
      brandIds,
      storeIds,
      categoryIds,
      subcategoryIds,
      departmentIds,
      startRange,
      couponTypes,
      attributeFilters
    } = this.state[`${this.state.selectedSearchTab}Options`]

    let appliedAttributeFilter = _.some(attributeFilters, (value) => value?.length) || false

    if (
      brandNames?.length ||
      storeNames?.length ||
      departmentNames?.length ||
      categoryNames?.length ||
      subcategoryNames?.length ||
      priceRange?.length ||
      discountRange?.length ||
      paymentMethods?.length ||
      lastPriceRange?.length ||
      brandIds?.length ||
      categoryIds?.length ||
      departmentIds?.length ||
      subcategoryIds?.length ||
      storeIds?.length ||
      startRange?.length ||
      couponTypes?.length ||
      appliedAttributeFilter
    )
      return true
    return false
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)

    window.localStorage.removeItem('activeTab')

    const { isAvailable, options } = this.getStoredFilters()
    isAvailable &&  window.localStorage.setItem("search_product_filter", JSON.stringify({...options,check: true}))

    const filterParam = this.props.keyword.searchMeta || null
    if (filterParam) {
      const stateToUpdate = _.cloneDeep(this.state)

      this.setState(prevState => {
        tabOptions.forEach(opt => {
          stateToUpdate[opt][filterParam.filterKey] = [...(prevState[opt][filterParam.filterKey] || []), filterParam.filterValue]
        })
        return stateToUpdate
      })
    }
  }

  componentWillUnmount () {
    this.handleTabStore(this.state.selectedSearchTab)
    this.removeStoreOptions()
  }

//For Filter and sort sticky in mobile view
  handleScroll = debounce ( () =>  {
    const { prevScrollPos } = this.state
    const currentScrollPos = window.pageYOffset
    this.setState({filterSticky: prevScrollPos > currentScrollPos, prevScrollPos:currentScrollPos, tabSticky: prevScrollPos > currentScrollPos})
    if (currentScrollPos < 500) this.setState({filterSticky: false, tabSticky: false})
  }, 10)

  getMaxResultsTab = () => {
    let maxCount = 0
    let maxResultsTab = 'sale'

    tabTypes.forEach((tabType) => {
      if(this.state[`is${_.capitalize(tabType)}Enabled`] && this.state[`${tabType}Count`] > maxCount) {
        maxResultsTab = tabType
        maxCount = this.state[`${tabType}Count`]
      }
    })

    return maxResultsTab
  }

  validateSelectedTab = () => {
    let preferedActiveTab = this.state.activeTabStorage || this.state.activeTabParam || null
    let finalActiveTab = this.getMaxResultsTab()

    if(preferedActiveTab && this.state[`is${_.capitalize(preferedActiveTab)}Enabled`] && this.state[`${preferedActiveTab}Count`] > 0) {
      finalActiveTab = preferedActiveTab
    }

    this.setState({ selectedSearchTab: finalActiveTab, selTabValidated: true })
  }

  handleFilterClearAll = (e) => {
    const { selectedSearchTab } = this.state
    const { order, value, key } = this.state[`${selectedSearchTab}OrderBy`]

    const itemData = {
      listType: 'Search Page',
      searchTerm: this.props.keyword.searchTerm || '',
      sort: this.state?.by_order?.column || value,
      searchCount: this.state.dealsCount
    }

    const newState = {}
    newState[`${selectedSearchTab}OrderBy`] = {
      value, order, key
    }

    newState[`${selectedSearchTab}Options`] = {
      brandNames: [],
      storeNames: [],
      departmentNames: [],
      categoryNames: [],
      subcategoryNames: [],
      priceRange: [],
      discountRange: [],
      paymentMethods: [],
      lastPriceRange: [],
      attributeFilters: {}
    }

    this.setState(newState)

    filterDataLayer(itemData, 'clear-all', 1, 'Search Page', null)
  }

  setAvailableFilters = () => {
    return filters[this.state.selectedSearchTab]
  }

  handleTabStore = (value) => {
    window.localStorage.setItem('activeTab', value)
  }

  handleSearchFilterDataLayer = (response, respType) => {
    const {
      match: { params: { listType } }
    } = this.props

    const {
      key,
      by_order,
      selectedSearchTab
    } = this.state

    const itemData = {
      listType: listType || 'Search Page',
      searchTerm: this.props?.keyword?.searchTerm,
      sort: by_order?.column
    }

    let isFilterApplied = false
    if (selectedSearchTab === 'deal' && respType === 'deal') {
      // add deal specific options
      Object.keys(this.state.dealOptions)?.forEach(key => itemData[key] = this.state.dealOptions[key])
      itemData.searchCount = Number(response?.counts?.deals || 0) + Number(response?.counts?.brands || 0) + Number(response?.counts?.stores || 0)
      const option = options?.values?.find(opt => opt.key === parseInt(key, 10))
      if (option) {
        itemData.sort = option.text
      }
      filterDataLayer(itemData, 'select-filter', null, null, null)
    } else if (selectedSearchTab === 'sale'  && respType === 'sale') {
      // add sale events specific options
      Object.keys(this.state.saleOptions)?.forEach(key => itemData[key] = this.state.saleOptions[key])
      itemData.searchCount = response?.counts?.sale_events
      const option = saleEventOptions?.values?.find(opt => opt.key === parseInt(key, 10))
      if (option) {
        itemData.sort = option.text
      }
      filterDataLayer(itemData, 'select-filter', null, null, null)
    } else if (selectedSearchTab === 'voucher'  && respType === 'voucher') {
      // add voucher specific options
      Object.keys(this.state.voucherOptions)?.forEach(key => itemData[key] = this.state.voucherOptions[key])
      itemData.searchCount = response?.counts?.vouchers
      const option = voucherOptions?.values?.find(opt => opt.key === parseInt(key, 10))
      if (option) {
        itemData.sort = option?.text
      }
      filterDataLayer(itemData, 'select-filter', null, null, null)
    }
  }

  render() {

    const {
      key,
      keyword,
      searchMeta,
      searchMinMaxPrice,
      storeBrands,
      filterSticky,
      tabSticky,
      stores,
      brands,
      selectedSearchTab,
      dealCount,
      saleCount,
      voucherCount,
      brandCount,
      storeCount,
      dealOptions,
      voucherOptions,
      saleOptions,
      isDealEnabled,
      isSaleEnabled,
      isVoucherEnabled,
      isStoreEnabled,
      isBrandEnabled,
      dealOrderBy,
      saleOrderBy,
      voucherOrderBy,
      isFilter,
      mbColumn
    } = this.state

    const sortByKey = `${selectedSearchTab}OrderBy`

    const tabBody = [
      {
        id: 'deal',
        options: dealOptions,
        title: 'Products',
        count: dealCount,
        Ele: SearchResults,
        isSelected: selectedSearchTab === 'deal',
        isEnabled: isDealEnabled,
        orderBy: dealOrderBy
      },
      {
        id: 'sale',
        options: saleOptions,
        title: 'Sale Events',
        count: saleCount,
        Ele: SearchSaleEvent,
        isSelected: selectedSearchTab === 'sale',
        isEnabled:isSaleEnabled,
        orderBy: saleOrderBy
      },
      {
        id: 'voucher',
        options: voucherOptions,
        title: 'Promo Codes',
        Ele: VouchersResults,
        count: voucherCount,
        isSelected: selectedSearchTab === 'voucher',
        isEnabled: isVoucherEnabled,
        orderBy:voucherOrderBy
      },
      {
        id: 'brand',
        title: 'Brands',
        count: brandCount,
        data: brands,
        isEnabled: isBrandEnabled,
        Ele: StoreBrandSearchResults
      },
      {
        id: 'store',
        title: 'Stores',
        count: storeCount,
        data: stores,
        isEnabled: isStoreEnabled,
        Ele: StoreBrandSearchResults
      }
    ]

    const enabledTab = tabBody.filter((tab)=> !!tab.isEnabled) || []

    const goToGoogleSearch = () => {
      let parsedMessgae = {
        type: 'google-search-webview', 
        data: `https://www.google.com/search?q=${keyword}`
      }
      
      window.ReactNativeWebView?.postMessage(JSON.stringify(parsedMessgae))
    }

    return (
      <div className='container search-page'>
        {(window.ReactNativeWebView && LB_NATIVE_APP_VERSION >= 2.8) &&
          <div
            onClick={goToGoogleSearch}
            className='d-flex justify-content-between google-search-banner'>
            <h1 className="m-0 p-0">
              Search <strong>{keyword}</strong> on <img className='google' src={googleFull} />
            </h1>
            <div className="d-block d-sm-none">
              <img  src={chevronRightFilled} />
            </div>
          </div>
        }
        {enabledTab.length > 0 && 
          <div
            className={`search-tab-cotainer ${
              tabSticky ? 'tab-sticky' : 'fixed-tab'
            }`}>
            {enabledTab.map(
              (item, index) =>
                <span
                  key={`${item.id}-${index}`}
                  onClick={() => this.setState({ selectedSearchTab: item.id })}
                  className={`tab-pill ${
                    selectedSearchTab === item.id && 'selected'
                  }`}>
                  {item.title} ({item.count})
                </span>
            )}
          </div>
        }
        <div className='ranking-page-header mb-3'>
          <div className='d-flex justify-content-between'>
            <h1 className={`results-title m-0 p-0 ${selectedSearchTab==='deal' && 'col-9'}`}>
            Results for <span>{keyword}</span>
            </h1>
            {/* Mobile two and one column wide - icons */}
            {
              selectedSearchTab === 'deal' &&
              <div className="d-block d-sm-none">
                <img src={mbColumn ==='oneColumn' ? twoColumnIconInactive: twoColumnIcon} className="mb-column-icon" onClick={()=>this.setState({ mbColumn : 'twoColumn' })} />
                <img src={mbColumn ==='twoColumn' ? oneColumnIconInactive: oneColumnIcon} onClick={()=>this.setState({ mbColumn : 'oneColumn' })} />
            </div>
            }
            {/* ends here */}
          </div>
        </div>
        <div className='row main-wrapper'>
          {storeBrands.length > 0 && (
            <h3 className='d-block d-xl-none ml-2'>Products</h3>
          )}
          <Meta
            name='Search Page'
            description={`Find the best price on ${_.startCase(
              keyword
            )} only at Little Birdie`}
            title={`Search Results for ${_.startCase(keyword)} | Little Birdie`}
            og_title={`Little Birdie | See all results for ${_.startCase(
              keyword
            )}`}
          />
          {this.state[`${selectedSearchTab}Options`] && (
            <div
              className={`col-xl-3 d-none sidebar ${
                selectedSearchTab !== 'store' && selectedSearchTab !== 'brand'
                  ? 'd-xl-block'
                  : 'd-xl-none'
              }`}>
              <DesktopFilter
                isClearAllDisplay={this.isClearAllBtnDisplay()}
                handleChange={(e, sectionPosition) =>
                  this.handleFilter(e, sectionPosition)
                }
                handleClear={(e) => this.handleFilterClear(e)}
                handleClearAll={this.handleFilterClearAll}
                //sortOptions={options}
                //defaultValue={key}
                selectedItems={this.state[`${selectedSearchTab}Options`]}
                searchMinMaxPrice={searchMinMaxPrice}
                availableFilters={this.setAvailableFilters()}
                keyword={keyword}
                searchMeta={searchMeta}
                //selectedOrder={{ column: value, direction: order }}
                isReady
                isSearchPage
                voucherParam={
                  selectedSearchTab === 'voucher'
                    ? 'voucher_search'
                    : selectedSearchTab === 'sale'
                    ? 'sale_event_search'
                    : ''
                }
              />
            </div>
          )}
          <div
            className={`col-xl-${
              selectedSearchTab !== 'store' && selectedSearchTab !== 'brand'
                ? '9'
                : '12'
            } order-1 order-xl-2 ml-auto main-content`}>
            <section className='pt-1'>
              {selectedSearchTab !== 'store' && selectedSearchTab !== 'brand' && (
                <div
                  className={`fixedFilter ${
                    filterSticky ? 'filterSticky search-tab' : ''
                  }`}
                  id='mobileFilter'>
                  <div className='d-xl-none mobile-filter'>
                    <MobileFilter
                      isClearAllDisplay={this.isClearAllBtnDisplay()}
                      handleSort={(e) => this.handleSort(e)}
                      handleChange={(e, sectionPosition) =>
                        this.handleFilter(e, sectionPosition)
                      }
                      handleClear={(e) =>
                        this.handleFilterClear(e)
                      }
                      handleClearAll={this.handleFilterClearAll}
                      sortOptions={options}
                      defaultValue={key}
                      selectedItems={this.state[`${selectedSearchTab}Options`]}
                      searchMinMaxPrice={searchMinMaxPrice}
                      availableFilters={this.setAvailableFilters()}
                      keyword={keyword}
                      searchMeta={searchMeta}
                      selectedOrder={{ column: this.state[sortByKey]?.value, direction: this.state[sortByKey]?.order }}
                      isReady
                      isSearchPage
                      voucherParam={
                        selectedSearchTab === 'voucher'
                          ? 'voucher_search'
                          : selectedSearchTab === 'sale'
                          ? 'sale_event_search'
                          : ''
                      }
                    />
                  </div>
                  <SortSelect
                    byOrder={{
                      column: 'fixed_global_score',
                      direction: 'desc'
                    }}
                    handleSort={(e) => this.handleSort(e)}
                    options={this.setAvailableFilters()?.options}
                    currentSelectedKey={this.state[sortByKey].key}
                  />
                </div>
              )}
              {tabBody.map((item, index) => {
                const TabComponent = item.Ele
                return (
                  <div
                    key={`${item.id}-${index}`}
                    className={`${
                      selectedSearchTab === item.id ? 'd-block' : 'd-none'
                    }`}>
                    {item.data ? (
                      <TabComponent id={item.id} items={item.data} isLoading={this.state.isLoading}/>
                    ) : (
                      <TabComponent
                        id={item.id}
                        isFilter={isFilter}
                        keyword={keyword}
                        searchMeta={searchMeta}
                        filterOptions={item.options}
                        order={item.orderBy?.order}
                        value={item.orderBy?.value}
                        isEnabled={item.isShown}
                        handleStateUpdate={(data) => this.setState({ ...data })}
                        handleSearchFilterDataLayer={(data, respType) => this.handleSearchFilterDataLayer(data, respType)}
                        mbColumn={mbColumn}
                      />
                    )}
                  </div>
                )
              })}
            </section>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  keyword: getSearchKeyword(state)
})

export default connect(mapStateToProps, {})(SearchPage)
