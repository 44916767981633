import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import StarRatings from 'react-star-ratings'
import { isNumber } from 'lodash'

import { getBaseUrl } from '../../../reducers'
import { trustPilotIcon, trustpilotLogo, aboutIcon, crossIcon } from '../../../../../components/Icons'
import Loader from '../../../../../components/Loader'
import { getStoreReviewsByIdentifier } from '../../../requesters'
import moment from 'moment'

const StoreReviews = (props) => {
  const { store } = props
  const apiBaseUrl = useSelector(getBaseUrl)
  const storeReviewRef = useRef(null)
  const filterSectionRef = useRef(null)

  const [isScroll, setIsScroll] = useState(false)
  const [storeDetail, setStoreDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRating, setSelectedRating] = useState('all')
  const [tooltip, setTooltip] = useState(false)

  const progressRatingMapper = {
    reviewcount_five: 5,
    reviewcount_four: 4,
    reviewcount_three: 3,
    reviewcount_two: 2,
    reviewcount_one: 1
  }

  useEffect(() => {
    const onReviewBodyScroll = (event) => {
      if (event?.target?.scrollTop > 50) {
        setIsScroll(true)
      } else {
        setIsScroll(false)
      }
    }

    storeReviewRef?.current?.addEventListener('scroll', onReviewBodyScroll)

    return () => storeReviewRef?.current?.removeEventListener('scroll', onReviewBodyScroll)
  }, [])

  useEffect(() => {
    async function fetchStoreDetail () {
      setIsLoading(true)

      const { response } = await getStoreReviewsByIdentifier(apiBaseUrl, store?.attributes?.store_slug || store?.attributes?.slug)
      setIsLoading(false)
      if (response?.rating) {
        setStoreDetail({
          ...response.rating,
          reviews: {
            all: response?.reviews || []
          }
        })
      }
    }

    setStoreDetail(null)
    setSelectedRating('all')

    if (store) {
      fetchStoreDetail()
    }
  }, [store?.id])

  const applyFilter = async (filterVal) => {
    setSelectedRating(filterVal)

    if (filterVal && isNumber(filterVal)) {
      const { response } = await getStoreReviewsByIdentifier(apiBaseUrl, store?.attributes?.store_slug || store?.attributes?.slug, { by_star_rating: filterVal })

      if (response.rating) {
        setStoreDetail({
          ...response.rating,
          reviews: {
            ...storeDetail?.reviews,
            [filterVal]: response?.reviews || []
          }
        })
      }
    }

    storeReviewRef?.current?.scrollTo({
      top: filterSectionRef?.current?.offsetTop - 55 || 0,
      behavior: 'smooth'
    })
  }
  return (
    <div className='store-reviews' ref={storeReviewRef}>
      {/* modal header for desktop  */}
      <div className='store-review-header d-none d-md-block'>
        <img className='tustpilot-icon float-right' src={trustPilotIcon} alt='trustpilot' />
        <img className={`profile-img-w ${isScroll ? 'scroll' : ''}`} alt={store?.attributes?.name} src={store?.attributes?.store_logo || store?.links?.logoUrl} />
      </div>
      <div className='store-review-body'>
        <Loader isLoading={isLoading} />
        {
          storeDetail && !isLoading &&
            <>
              <div className='row'>
                {/* star ratings section  */}
                <div className='col-12 col-lg-8 col-xl-8'>
                  <div className='d-flex  star-rating'>
                    <img className='profile-img-w d-md-none' alt={store?.attributes?.name} src={store?.attributes?.store_logo || store?.links?.logoUrl} />
                    <div className='pt-2 star-rating-info'>
                      <p className='reviews-text align-middle mb-0 text-center'> {storeDetail?.attributes?.number_of_reviews} Reviews</p>
                      <div className='rating-star'>
                        <StarRatings
                          rating={parseFloat(storeDetail?.attributes?.star_rating || 0)}
                          starRatedColor='#239F55'
                          starEmptyColor='#F0F3F0'
                          numberOfStars={5}
                          name='rating'
                          starDimension='37'
                          starSpacing='0'
                        />
                      </div>
                      <p className={`rating-count align-middle mb-0 text-center ${tooltip && 'addBeforeCls'} `}>
                        <p className='trustscore-info'>TrustScore</p>
                        <b>{parseFloat(storeDetail?.attributes?.star_rating || 0).toFixed(1)}</b> out of 5
                        <img className='ml-1' src={aboutIcon} alt='infoIcon' width='14px' height='14px' onClick={() => setTooltip(true)} />
                      </p>
                    </div>
                  </div>
                  {
                    tooltip &&
                      <div className='ts-info-desc'>
                        <img className='float-right' src={crossIcon} alt='close-icon' width='18px' height='18px' onClick={() => setTooltip(false)} />
                        <div className='ts-info'>
                          <h3 className='ts-title'>What’s a TrustScore?</h3>
                          <p className='ts-desc'>A TrustScore is the overall measurement of reviewer satisfaction, based on all the service and location reviews a business receives on Trustpilot. This is a weighted score based on the quantity and recency of the reviews.
                          </p>
                        </div>
                      </div>
                  }
                </div>

                {/* progress rating section */}
                <div className='col-12 col-lg-4 col-xl-4 mt-md-4'>
                  <div className='progress-reviews'>
                    {Object.keys(progressRatingMapper).map((ratingKey, index) =>
                      <div className='d-flex justify-content-between' key={`${ratingKey}-${index}`}>
                        <span>{progressRatingMapper[ratingKey]} star</span>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            role='progressbar'
                            style={{ width: `${(storeDetail?.attributes?.[ratingKey] || 0) * 100 / storeDetail?.attributes?.number_of_reviews}%` }}
                            aria-valuenow='0'
                            aria-valuemin='0'
                            aria-valuemax='100'
                          />
                        </div>
                        <span className='text-right'>{Math.round((parseInt(storeDetail?.attributes?.[ratingKey] || 0) * 100) / parseInt(storeDetail?.attributes?.number_of_reviews))}%</span>
                      </div>
                    )}
                  </div>
                  <hr className='divider d-none' />
                </div>

                {/* filter ratings */}
                <div className={`col-12 col-lg-6 col-xl-8 filter-ratings-section ${tooltip && 'mb-filter-section'}`} ref={filterSectionRef}>
                  <h4 className='filter-title'>Filter by ratings</h4>
                  <div className='filter-rating-chips'>
                    <span className={`btn-review rounded-pill ${selectedRating === 'all' && 'selected'}`} onClick={() => applyFilter('all')}>All reviews</span>
                    <span className={`btn-review rounded-pill ${selectedRating === 5 && 'selected'}`} onClick={() => applyFilter(5)}> 5 stars</span>
                    <span className={`btn-review rounded-pill ${selectedRating === 4 && 'selected'}`} onClick={() => applyFilter(4)}> 4 stars</span>
                    <span className={`btn-review rounded-pill ${selectedRating === 3 && 'selected'}`} onClick={() => applyFilter(3)}> 3 stars</span>
                    <span className={`btn-review rounded-pill ${selectedRating === 2 && 'selected'}`} onClick={() => applyFilter(2)}> 2 stars</span>
                    <span className={`btn-review rounded-pill ${selectedRating === 1 && 'selected'}`} onClick={() => applyFilter(1)}> 1 star</span>
                  </div>
                </div>
                <div className='col-12'>
                  <hr className='divider d-none d-md-block' />
                </div>
              </div>
              {/* reviews sections */}
              <div className='col-12 reviews-section'>
                <Loader isLoading={storeDetail?.reviews[selectedRating] === undefined} />
                {storeDetail?.reviews[selectedRating] && storeDetail.reviews[selectedRating]?.length > 0 && storeDetail.reviews[selectedRating].map((review, index) =>
                  <div className='row' key={index}>
                    <div className='col-12'>
                      <img className='tp-logo' src={trustpilotLogo} alt='trustpilot-logo' />
                      <div className='d-inline-block align-top'>
                        <h4 className='tp-title'>TrustPilot</h4>
                        <span className='tp-days'>{moment(review?.attributes?.review_date).fromNow()}</span>
                      </div>
                    </div>
                    <div className='col-12 review-desc'>
                      <div className='star-reviews'>
                        <StarRatings
                          rating={Math.round(parseFloat(review?.attributes?.star_rating || 0))}
                          starRatedColor='#239F55'
                          starEmptyColor='#F0F3F0'
                          numberOfStars={5}
                          name='rating'
                          starDimension='37'
                          starSpacing='0'
                        />
                      </div>
                      <div>
                        <h4 className='review-title'>{review?.attributes?.title}</h4>
                        <p className='review-para'>{review?.attributes?.description}</p>
                      </div>
                      <hr className='divider d-md-none' />
                    </div>
                  </div>
                )}
                {storeDetail?.reviews[selectedRating] && storeDetail.reviews[selectedRating]?.length === 0 && <span>Oops! No reviews found. Try using different filters.</span>}
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default StoreReviews
