import React, { useEffect, useState } from 'react'

import {
  StoreOptions,
  BrandOptions,
  DepartmentOptions,
  PriceRangeOptions,
  DiscountOptions,
  PaymentOptions,
  FreshnessOptions,
  CouponTypeOptions,
  StartDateOptions
} from './Tabs'

import {
  ElasticBrandOptions,
  ElasticStoreOptions,
  ElasticDepartmentOptions,
  ElasticAttributesOptions
} from './ElasticTabs'

export default function DesktopFilter ({
  handleChange, handleClear, isClearAllDisplay,
  selectedItems, searchMinMaxPrice, availableFilters,
  keyword, handleClearAll,
  setDepartment, setCategory, setSubcategory, setBrand, setStore,
  isReady, isSearchPage, selectedCustomItems, topPriceDropsFollowingPageFilter, voucherParam
}) {
  const [priceMin, setPriceMin] = useState('')
  const [priceMax, setPriceMax] = useState('')

  useEffect(() => {
    if (searchMinMaxPrice) {
      setPriceMin(searchMinMaxPrice[0])
      setPriceMax(searchMinMaxPrice[1])
    }
  }, [searchMinMaxPrice])

  const [discountMin, setDiscountMin] = useState('')
  const [discountMax, setDiscountMax] = useState('')

  function handlePriceChange (field, value) {
    if (field === 'min') setPriceMin(value)
    if (field === 'max') setPriceMax(value)
  }

  function handleDiscountChange (field, value) {
    if (field === 'min') setDiscountMin(value)
    if (field === 'max') setDiscountMax(value)
  }

  function handleSelect (e, sectionPosition) {
    handleChange({ name: e.currentTarget.name, value: e.arrValue || e.currentTarget.value, inputDataName: e.currentTarget?.dataset?.inputName, inputDataValue: e.currentTarget?.dataset?.inputValue, attributeFilter: e.currentTarget?.dataset?.attributeFilter === 'true'  }, sectionPosition)
  }

  function handleFilterPill (name, value, attributeFilter = false) {
    handleChange({ name: name, value: value, attributeFilter: attributeFilter })
  }

  function clearInputState () {
    setPriceMin('')
    setPriceMax('')
    setDiscountMin('')
    setDiscountMax('')
  }

  function handleClearClick (e, sectionPosition) {
    e.preventDefault()
    clearInputState()
    handleClear(e, sectionPosition)
  }

  function handleFilterClear () {
    clearInputState()
    handleClearAll()
  }

  function isCollectionRankingPage () {
    if (window.location.pathname.match(/^\/collections\/.+/i)) return true
    return false
  }

  if (!availableFilters) return null

  return (
    <section className='pt-3'>
      <div className='d-flex justify-content-between align-items-center'>
        <h4 className='mx-1'>Filter By</h4>
        {
          isClearAllDisplay &&
            <button
              type='button'
              onClick={handleFilterClear}
              disabled={isCollectionRankingPage()}
              className='btn btn-clear large ml-auto'
            >
              Clear all
            </button>
        }
      </div>
      <div className='list-group filter-group'>
        {availableFilters.showBrand && (
          <BrandOptions
            sectionPosition={1}
            handleSelect={event => handleSelect(event, 1)}
            handleClearClick={event => handleClearClick(event, 1)}
            handleFilterPill={handleFilterPill}
            selectedBrands={selectedItems.brandIds}
          />
        )}
        {availableFilters.showElasticBrand && (
          <ElasticBrandOptions
            sectionPosition={1}
            handleSelect={event => handleSelect(event, 1)}
            handleClearClick={event => handleClearClick(event, 1)}
            handleFilterPill={handleFilterPill}
            isAllClear={isClearAllDisplay}
            selectedBrands={selectedItems.brandNames}
            selectedCustomBrands={_.get(selectedCustomItems, 'brandNames')}
            selectedStores={selectedItems.storeNames}
            selectedDepartments={selectedItems.departmentNames}
            selectedCategories={selectedItems.categoryNames}
            selectedSubcategories={selectedItems.subcategoryNames}
            selectedPriceRange={selectedItems.priceRange}
            selectedDateRange={selectedItems.lastPriceRange}
            selectedDiscountRange={selectedItems.discountRange}
            setBrand={setBrand}
            keyword={keyword}
            isReady={isReady}
            isSearchPage={isSearchPage}
            topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
            voucherParam={voucherParam}
          />
        )}
        {availableFilters.showStore && (
          <StoreOptions
            sectionPosition={2}
            handleSelect={event => handleSelect(event, 2)}
            handleClearClick={event => handleClearClick(event, 2)}
            handleFilterPill={handleFilterPill}
            selectedStores={selectedItems.storeIds}
          />
        )}
        {availableFilters.showElasticStore && (
          <ElasticStoreOptions
            sectionPosition={2}
            handleSelect={event => handleSelect(event, 2)}
            handleClearClick={event => handleClearClick(event, 2)}
            handleFilterPill={handleFilterPill}
            isAllClear={isClearAllDisplay}
            selectedStores={selectedItems.storeNames}
            selectedCustomStores={_.get(selectedCustomItems, 'storeNames')}
            selectedBrands={selectedItems.brandNames}
            selectedDepartments={selectedItems.departmentNames}
            selectedCategories={selectedItems.categoryNames}
            selectedSubcategories={selectedItems.subcategoryNames}
            selectedPriceRange={selectedItems.priceRange}
            selectedDateRange={selectedItems.lastPriceRange}
            selectedDiscountRange={selectedItems.discountRange}
            setStore={setStore}
            keyword={keyword}
            isReady={isReady}
            isSearchPage={isSearchPage}
            topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
            voucherParam={voucherParam}
          />
        )}
        {availableFilters.showDepartment && (
          <DepartmentOptions
            sectionPosition={3}
            handleSelect={event => handleSelect(event, 3)}
            handleClearClick={event => handleClearClick(event, 3)}
            showDepartment={availableFilters.showDepartment}
            handleFilterPill={handleFilterPill}
            showCategory={availableFilters.showCategory}
            showSubcategory={availableFilters.showSubcategory}
            activeDepartments={selectedItems.departmentIds}
            activeCategories={selectedItems.categoryIds}
            activeSubcategories={selectedItems.subcategoryIds}
          />
        )}
        {availableFilters.showElasticDepartments && (
          <ElasticDepartmentOptions
            sectionPosition={3}
            handleSelect={event => handleSelect(event, 3)}
            handleClearClick={event => handleClearClick(event, 3)}
            handleFilterPill={handleFilterPill}
            selectedDepartments={selectedItems.departmentNames}
            selectedCustomDepartments={_.get(selectedCustomItems, 'departmentNames')}
            selectedCategories={selectedItems.categoryNames}
            selectedCustomCategories={_.get(selectedCustomItems, 'categoryNames')}
            selectedSubcategories={selectedItems.subcategoryNames}
            selectedStores={selectedItems.storeNames}
            selectedBrands={selectedItems.brandNames}
            selectedPriceRange={selectedItems.priceRange}
            selectedDateRange={selectedItems.lastPriceRange}
            selectedDiscountRange={selectedItems.discountRange}
            keyword={keyword}
            setDepartment={setDepartment}
            setCategory={setCategory}
            setSubcategory={setSubcategory}
            isReady={isReady}
            isSearchPage={isSearchPage}
            topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
            voucherParam={voucherParam}
          />
        )}

        {availableFilters.showAttributeFilters && (
          <ElasticAttributesOptions
            sectionPosition={4}
            handleSelect={event => handleSelect(event, 4)}
            handleClearClick={event => handleClearClick(event, 4)}
            handleFilterPill={ (name, value) => handleFilterPill(name, value, true)}
            isAllClear={isClearAllDisplay}
            selectedStores={selectedItems.storeNames}
            selectedCustomStores={_.get(selectedCustomItems, 'storeNames')}
            selectedBrands={selectedItems.brandNames}
            selectedDepartments={selectedItems.departmentNames}
            selectedCategories={selectedItems.categoryNames}
            selectedSubcategories={selectedItems.subcategoryNames}
            selectedPriceRange={selectedItems.priceRange}
            selectedDateRange={selectedItems.lastPriceRange}
            selectedDiscountRange={selectedItems.discountRange}
            setStore={setStore}
            keyword={keyword}
            isReady={isReady}
            isSearchPage={isSearchPage}
            topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
            voucherParam={voucherParam}
            selectedAttributeFilters={selectedItems.attributeFilters}
          />
        )}

        {availableFilters.showPriceRange && (
          <PriceRangeOptions
            sectionPosition={6}
            handleSelect={event => handleSelect(event, 6)}
            handleClearClick={event => handleClearClick(event, 6)}
            handleFilterPill={handleFilterPill}
            selectedPriceRange={selectedItems.priceRange}
            selectedCustomPriceRange={_.get(selectedCustomItems, 'priceRange')}
            priceMin={priceMin || searchMinMaxPrice[0]}
            priceMax={priceMax || searchMinMaxPrice[1]}
            handleChange={handlePriceChange}
          />
        )}
        {availableFilters.showDiscountRange && (
          <DiscountOptions
            sectionPosition={7}
            handleSelect={event => handleSelect(event, 7)}
            handleClearClick={event => handleClearClick(event, 7)}
            handleFilterPill={handleFilterPill}
            selectedDiscountRange={selectedItems.discountRange}
            selectedCustomDiscountRange={_.get(selectedCustomItems, 'discountRange')}
            discountMin={discountMin}
            discountMax={discountMax}
            handleChange={handleDiscountChange}
            keyword={keyword}
          />
        )}
        {availableFilters.showPaymentMethods && (
          <PaymentOptions
            sectionPosition={5}
            handleSelect={event => handleSelect(event, 5)}
            handleClearClick={event => handleClearClick(event, 5)}
            handleFilterPill={handleFilterPill}
            selectedPayments={selectedItems.paymentMethods}
          />
        )}
        {availableFilters.showFreshness && (
          <FreshnessOptions
            sectionPosition={8}
            handleSelect={event => handleSelect(event, 8)}
            handleClearClick={event => handleClearClick(event, 8)}
            handleFilterPill={handleFilterPill}
            selectedPriceRange={selectedItems.lastPriceRange}
            selectedCustomPriceFreshnessRange={_.get(selectedCustomItems, 'lastPriceRange')}
          />
        )}
        {availableFilters.showStartDate && (
          <StartDateOptions
            sectionPosition={6}
            handleSelect={event => handleSelect(event, 6)}
            handleClearClick={event => handleClearClick(event, 6)}
            handleFilterPill={handleFilterPill}
            selectedDate={selectedItems.startRange}
          />
        )}
        {availableFilters.showCouponTypes && (
          <CouponTypeOptions
            sectionPosition={6}
            handleSelect={event => handleSelect(event, 6)}
            handleClearClick={event => handleClearClick(event, 6)}
            handleFilterPill={handleFilterPill}
            selectedTypes={selectedItems.couponTypes}
          />
        )}
      </div>
    </section>
  )
}
