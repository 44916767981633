/* eslint-disable require-yield */
import {
  takeLatest, all, fork, select, call, put
} from 'redux-saga/effects'

import * as actionTypes from '../actions/types'
import { departmentRequestSuccess, departmentRequestFailure } from '../actions'
import { getBaseUrl } from '../reducers'
import * as requester from '../requesters'

function* fetchDepartments () {
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(requester.getDepartments, baseUrl, {})
  yield (response) ? put(departmentRequestSuccess(response)) : put(departmentRequestFailure(error))
}

function* initFetch () {
  yield all([
    fork(fetchDepartments)
  ])
}

export function* watchInitSaga () {
  yield takeLatest(actionTypes.INIT_APPLICATION, initFetch)
}
