export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  } else {
    return navigator.maxTouchPoints &&
              navigator.maxTouchPoints > 2 &&
              /MacIntel/.test(navigator.platform)
  }
}
export const isPad = () => {
  return /iPad/.test(navigator.platform) || navigator.platform === 'MacIntel';
}


export const isSafari = isIOS() && !!window.navigator?.userAgent?.match(/WebKit/i) && !window.navigator?.userAgent?.match(/CriOS/i)

export const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase());
