import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { filterIcon, crossIcon } from '../../../../components/Icons'

import {
  StoreOptions,
  BrandOptions,
  DepartmentOptions,
  PriceRangeOptions,
  DiscountOptions,
  PaymentOptions,
  FreshnessOptions,
  CouponTypeOptions,
  StartDateOptions
  // SortOptions
} from './Tabs'

import {
  ElasticBrandOptions,
  ElasticStoreOptions,
  ElasticDepartmentOptions,
  ElasticAttributesOptions
} from './ElasticTabs'

export default function MobileFilter ({
  handleChange, handleClear, handleClearAll, isClearAllDisplay,
  selectedItems, searchMinMaxPrice, availableFilters,
  // sortOptions, selectedOrder, handleSort,
  keyword, url, setDepartment, setCategory, setSubcategory, setBrand, setStore,
  isReady, isSearchPage, selectedCustomItems, topPriceDropsFollowingPageFilter, voucherParam
}) {
  const [showModal, setShowModal] = useState(false)

  const [priceMin, setPriceMin] = useState('')
  const [priceMax, setPriceMax] = useState('')

  useEffect(() => {
    if (searchMinMaxPrice) {
      setPriceMin(searchMinMaxPrice[0])
      setPriceMax(searchMinMaxPrice[1])
    }
  }, [searchMinMaxPrice])

  const [discountMin, setDiscountMin] = useState('')
  const [discountMax, setDiscountMax] = useState('')

  function handlePriceChange (field, value) {
    if (field === 'min') setPriceMin(value)
    if (field === 'max') setPriceMax(value)
  }

  function handleDiscountChange (field, value) {
    if (field === 'min') setDiscountMin(value)
    if (field === 'max') setDiscountMax(value)
  }

  function handleSelect (e, sectionPosition) {
    handleChange({ name: e.currentTarget.name, value: e.arrValue || e.currentTarget.value, inputDataName: e.currentTarget?.dataset?.inputName, inputDataValue: e.currentTarget?.dataset?.inputValue, attributeFilter: e.currentTarget?.dataset?.attributeFilter === 'true' }, sectionPosition)
  }

  function handleFilterPill (name, value, attributeFilter = false) {
    handleChange({ name: name, value: value, attributeFilter: attributeFilter })
  }

  function clearInputState () {
    setPriceMin('')
    setPriceMax('')
    setDiscountMin('')
    setDiscountMax('')
  }

  function handleClearClick (e, sectionPosition) {
    e.preventDefault()
    clearInputState()
    handleClear(e, sectionPosition)
  }

  function handleFilterClear () {
    setShowModal(false)

    clearInputState()
    handleClearAll()
  }

  let dialogClass = null
  let modalClass = null
  const filterName = 'Filter'

  if (url === '/trending-stores' || url === '/trending-stores') {
    dialogClass = 'halfModal'
    modalClass = 'noTransition'
    // filterName = 'Sort'
  }

  function isCollectionRankingPage () {
    if (window.location.pathname.match(/^\/collections\/.+/i)) return true
    return false
  }

  return (
    <>
      <button
        className='btn btn-outline-dark btn-floating-filter'
        type='button'
        onClick={() => setShowModal(true)}
      >
        {filterName}
        <img src={filterIcon} />
        {' '}
      </button>
      <Modal
        className={modalClass}
        dialogClassName={`modal-dialog-scrollable ${dialogClass}`}
        id='filterModal'
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        {/* TODO need to fix this position */}
        <Modal.Header className='py-4'>
          <div className='d-flex'>
            <h4 className=''>Filter By</h4>
            {
             isClearAllDisplay &&
               <button
                 type='button'
                 onClick={handleFilterClear}
                 disabled={isCollectionRankingPage()}
                 className='btn btn-clear large mobile-clear'
               >
                 Clear all
               </button>
            }
          </div>
          <button
            aria-label='Close'
            className='close'
            type='button'
            onClick={() => setShowModal(false)}
          >
            <img src={crossIcon} />
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* {sortOptions && <SortOptions options={sortOptions} selectedOrder={selectedOrder} handleSort={handleSort} />} */}
          <div className='list-group filter-group'>
            {availableFilters.showBrand && (
              <BrandOptions
                sectionPosition={1}
                handleSelect={event => handleSelect(event, 1)}
                handleClearClick={event => handleClearClick(event, 1)}
                selectedBrands={selectedItems.brandIds}
              />
            )}
            {availableFilters.showElasticBrand && (
              <ElasticBrandOptions
                sectionPosition={1}
                handleSelect={event => handleSelect(event, 1)}
                handleClearClick={event => handleClearClick(event, 1)}
                isAllClear={isClearAllDisplay}
                handleFilterPill={handleFilterPill}
                selectedBrands={selectedItems.brandNames}
                selectedCustomBrands={_.get(selectedCustomItems, 'brandNames')}
                selectedStores={selectedItems.storeNames}
                selectedDepartments={selectedItems.departmentNames}
                selectedCategories={selectedItems.categoryNames}
                selectedSubcategories={selectedItems.subcategoryNames}
                selectedPriceRange={selectedItems.priceRange}
                selectedDateRange={selectedItems.lastPriceRange}
                selectedDiscountRange={selectedItems.discountRange}
                keyword={keyword}
                setBrand={setBrand}
                isReady={isReady}
                isSearchPage={isSearchPage}
                topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
                voucherParam={voucherParam}
              />
            )}
            {availableFilters.showStore && (
              <StoreOptions
                sectionPosition={2}
                handleSelect={event => handleSelect(event, 2)}
                handleClearClick={event => handleClearClick(event, 2)}
                handleFilterPill={handleFilterPill}
                selectedStores={selectedItems.storeIds}
              />
            )}
            {availableFilters.showElasticStore && (
              <ElasticStoreOptions
                sectionPosition={2}
                handleSelect={event => handleSelect(event, 2)}
                handleClearClick={event => handleClearClick(event, 2)}
                handleFilterPill={handleFilterPill}
                isAllClear={isClearAllDisplay}
                selectedStores={selectedItems.storeNames}
                selectedCustomStores={_.get(selectedCustomItems, 'storeNames')}
                selectedBrands={selectedItems.brandNames}
                selectedDepartments={selectedItems.departmentNames}
                selectedCategories={selectedItems.categoryNames}
                selectedSubcategories={selectedItems.subcategoryNames}
                selectedPriceRange={selectedItems.priceRange}
                selectedDateRange={selectedItems.lastPriceRange}
                selectedDiscountRange={selectedItems.discountRange}
                keyword={keyword}
                setStore={setStore}
                isReady={isReady}
                isSearchPage={isSearchPage}
                topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
                voucherParam={voucherParam}
              />
            )}
            {availableFilters.showDepartment && (
              <DepartmentOptions
                handleFilterPill={handleFilterPill}
                sectionPosition={3}
                handleSelect={event => handleSelect(event, 3)}
                handleClearClick={event => handleClearClick(event, 3)}
                showDepartment={availableFilters.showDepartment}
                showCategory={availableFilters.showCategory}
                showSubcategory={availableFilters.showSubcategory}
                activeDepartments={selectedItems.departmentIds}
                activeCategories={selectedItems.categoryIds}
                activeSubcategories={selectedItems.subcategoryIds}
              />
            )}
            {availableFilters.showElasticDepartments && (
              <ElasticDepartmentOptions
                sectionPosition={3}
                handleSelect={event => handleSelect(event, 3)}
                handleClearClick={event => handleClearClick(event, 3)}
                handleFilterPill={handleFilterPill}
                selectedDepartments={selectedItems.departmentNames}
                selectedCustomDepartments={_.get(selectedCustomItems, 'departmentNames')}
                selectedCategories={selectedItems.categoryNames}
                selectedCustomCategories={_.get(selectedCustomItems, 'categoryNames')}
                selectedSubcategories={selectedItems.subcategoryNames}
                selectedStores={selectedItems.storeNames}
                selectedBrands={selectedItems.brandNames}
                selectedPriceRange={selectedItems.priceRange}
                selectedDateRange={selectedItems.lastPriceRange}
                selectedDiscountRange={selectedItems.discountRange}
                keyword={keyword}
                setDepartment={setDepartment}
                setCategory={setCategory}
                setSubcategory={setSubcategory}
                isReady={isReady}
                isSearchPage={isSearchPage}
                topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
                voucherParam={voucherParam}
              />
            )}

            {availableFilters.showAttributeFilters && (
              <ElasticAttributesOptions
                sectionPosition={4}
                handleSelect={event => handleSelect(event, 4)}
                handleClearClick={event => handleClearClick(event, 4)}
                handleFilterPill={ (name, value) => handleFilterPill(name, value, true)}
                selectedDepartments={selectedItems.departmentNames}
                selectedCustomDepartments={_.get(selectedCustomItems, 'departmentNames')}
                selectedCategories={selectedItems.categoryNames}
                selectedCustomCategories={_.get(selectedCustomItems, 'categoryNames')}
                selectedSubcategories={selectedItems.subcategoryNames}
                selectedStores={selectedItems.storeNames}
                selectedBrands={selectedItems.brandNames}
                selectedPriceRange={selectedItems.priceRange}
                selectedDateRange={selectedItems.lastPriceRange}
                selectedDiscountRange={selectedItems.discountRange}
                keyword={keyword}
                setDepartment={setDepartment}
                setCategory={setCategory}
                setSubcategory={setSubcategory}
                isReady={isReady}
                isSearchPage={isSearchPage}
                topPriceDropsFollowingPageFilter={topPriceDropsFollowingPageFilter}
                voucherParam={voucherParam}
                selectedAttributeFilters={selectedItems.attributeFilters}
              />
            )}

            {availableFilters.showPriceRange && (
              <PriceRangeOptions
                sectionPosition={6}
                handleSelect={event => handleSelect(event, 6)}
                handleClearClick={event => handleClearClick(event, 6)}
                selectedPriceRange={selectedItems.priceRange}
                handleFilterPill={handleFilterPill}
                selectedCustomPriceRange={_.get(selectedCustomItems, 'priceRange')}
                priceMin={priceMin}
                priceMax={priceMax}
                handleChange={handlePriceChange}
              />
            )}
            {availableFilters.showDiscountRange && (
              <DiscountOptions
                sectionPosition={7}
                handleSelect={event => handleSelect(event, 7)}
                handleClearClick={event => handleClearClick(event, 7)}
                selectedDiscountRange={selectedItems.discountRange}
                handleFilterPill={handleFilterPill}
                selectedCustomDiscountRange={_.get(selectedCustomItems, 'discountRange')}
                discountMin={discountMin}
                discountMax={discountMax}
                handleChange={handleDiscountChange}
              />
            )}
            {availableFilters.showPaymentMethods && (
              <PaymentOptions
                sectionPosition={5}
                handleSelect={event => handleSelect(event, 5)}
                handleClearClick={event => handleClearClick(event, 5)}
                selectedPayments={selectedItems.paymentMethods}
                handleFilterPill={handleFilterPill}
              />
            )}
            {availableFilters.showFreshness && (
              <FreshnessOptions
                sectionPosition={8}
                handleSelect={event => handleSelect(event, 8)}
                handleClearClick={event => handleClearClick(event, 8)}
                selectedPriceRange={selectedItems.lastPriceRange}
                handleFilterPill={handleFilterPill}
                selectedCustomPriceFreshnessRange={_.get(selectedCustomItems, 'lastPriceRange')}
              />
            )}
            {availableFilters.showStartDate && (
              <StartDateOptions
                sectionPosition={6}
                handleSelect={event => handleSelect(event, 6)}
                handleClearClick={event => handleClearClick(event, 6)}
                handleFilterPill={handleFilterPill}
                selectedDate={selectedItems.startRange}
              />
            )}
            {availableFilters.showCouponTypes && (
              <CouponTypeOptions
                sectionPosition={6}
                handleSelect={event => handleSelect(event, 6)}
                handleClearClick={event => handleClearClick(event, 6)}
                handleFilterPill={handleFilterPill}
                selectedTypes={selectedItems.couponTypes}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='text-center border-top-0'>
          <button
            className='btn btn-main rounded-pill btn-block'
            type='button'
            onClick={() => setShowModal(false)}
          >
            Select
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
