// ToDo: Remove Deal: TopElasticDeals, AllElasticDeals
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../../../../components/Loader'

import Breadcrumb from '../../../../../components/Breadcrumb'
import Meta from '../../../../../components/Meta'
import { loadBrandPage, seoContentRequestStart } from '../../../actions'
import * as reducers from '../../../reducers'
import Collections from '../../Collections'
// import { getTopElasticDeals, getAllElasticDeals } from '../../../requesters'

import Header from './Header'

import {
  // TopPriceDropsList,
  TopCouponsList,
  TopSalesEventsList,
  // AllProductsList,
  SeoInfo
} from '../../listings'

class BrandPage extends Component {
  // constructor () {
  //   super()
  //   this.state = { topDealResponse: [], allDealResponse: [] }
  // }

  componentDidMount () {
    const {
      loadBrandPage,
      seoContentRequestStart,
      match: {
        params: { slug }
      }
    } = this.props
    loadBrandPage({ brand: slug })
    seoContentRequestStart({ brand: slug })
    // if (this.props.location.state?.brandName || this.props?.brand?.attributes?.name) {
    //   const params = {
    //     per_page: 50,
    //     brand_names: [
    //       this.props.location.state?.brandName || this.props?.brand?.attributes?.name
    //     ],
    //     price_shift: [-1, 0]
    //   }
    //   getTopElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ topDealResponse: response?.data || [] })
    //   })
    //   getAllElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ allDealResponse: response?.data || [] })
    //   })
    // }
  }

  componentDidUpdate (prevProps) {
    const {
      loadBrandPage,
      match: {
        params: { slug }
      }
    } = this.props
    const prevSlug = prevProps.match.params.slug
    // if (this.props.brand !== null && this.props?.brand?.attributes?.name !== prevProps?.brand?.attributes?.name && !this.props.location.state?.brandName) {
    //   const params = {
    //     per_page: 50,
    //     brand_names: [
    //       this.props.brand.attributes.name
    //     ],
    //     price_shift: [-1, 0]
    //   }
    //   getTopElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ topDealResponse: response?.data || [] })
    //   })
    //   getAllElasticDeals(this.props.baseUrl, params).then(({ response }) => {
    //     this.setState({ allDealResponse: response?.data || [] })
    //   })
    // }
    if (prevSlug !== slug) {
      loadBrandPage({ brand: slug })
    }
  }

  checkDataNull () {
    const {
      // topPriceDropIds,
      topSaleEventIds,
      topCouponIds
    } = this.props

    // topPriceDropIds,
    const ids = _.flatten(_.concat(topSaleEventIds, topCouponIds))
    if (_.isEmpty(ids) && this.checkCollectionDataNull()) {
      return true
    }
    return false
  }

  checkIfFetchingData () {
    const {
      // isFetchingTopPriceDrops,
      isFetchingTopSaleEvents,
      isFetchingTopCoupons
    } = this.props

    if (
      // isFetchingTopPriceDrops ||
      isFetchingTopSaleEvents || isFetchingTopCoupons || this.checkIfFetchingCollectionData()) {
      return true
    }
    return false
  }

  checkCollectionDataNull (data) {
    if (_.isEmpty(data)) return true
    return false
  }

  checkIfFetchingCollectionData (status) {
    if (_.includes([undefined, null, 'finished'], status)) return false
    return true
  }

  renderMainContent () {
    const {
      brand,
      brand: {
        attributes: {
          name
        },
        links
      }
    } = this.props

    // const brandDepartment = { attributes: { name } }
    return (
      <>
        {
          this.checkIfFetchingData()
            ? <Loader isLoading />
            : (
              <>
                {/* <TopPriceDropsList
                  viewAllUrl={`${links.brandUrl}/top-price-drops`}
                  department={brandDepartment}
                  brand={this.props.brand}
                  wrapperClass='listing-carousel'
                  currentSection={name + ' PriceDrop'}
                  sectionPosition={1}
                  topDealList={this.state?.topDealResponse}
                /> */}
                {/* <AllProductsList
                  viewAllUrl={`${links.brandUrl}/all-products`}
                  department={brandDepartment}
                  brand={this.props.brand}
                  wrapperClass='listing-carousel'
                  currentSection={'Discover More Products from' + name}
                  sectionPosition={2}
                  topDealList={this.state?.allDealResponse}
                /> */}
                <TopSalesEventsList
                  viewAllUrl={`${links.brandUrl}/top-sales-events`}
                  title={`${name} Sales`}
                  wrapperClass='listing-carousel'
                  currentSection={name + ' SalesEvents'}
                  sectionPosition={3}
                />
                <Collections
                  placements='highlights'
                  brand={brand}
                  extraClass=''
                  checkIfNull={this.checkCollectionDataNull}
                  check
                  checkFetchStatus={this.checkIfFetchingCollectionData}
                  currentSection={name + ' Collection'}
                  sectionPosition={4}
                />
                <TopCouponsList
                  viewAllUrl={`${links.brandUrl}/top-promo-codes`}
                  title={`${name} Vouchers`}
                  extraClass=''
                  currentSection={name + ' Vouchers'}
                  sectionPosition={5}
                />
                <div className='container py-5 text-center'>
                  {this.checkDataNull() && !this.checkIfFetchingData() && `Looks like there's no great deals for ${name} right now. Hit Follow and we'll alert you as soon as there's some great deals to be had`}
                </div>
              </>
              )
        }
      </>
    )
  }

  render () {
    const {
      brand,
      location,
      seo,
      isFetchingSeoContent
    } = this.props

    return (
      <>
        <div className='container main-wrapper'>
          <Breadcrumb breadcrumbs={location} />
          {brand && (
            <Meta
              name='Brand Page'
              description={`See ${brand.attributes.name}'s best Price Drops, Sale Events, Vouchers, Clearance and Discounts at Little Birdie`}
              title={`${brand.attributes.name} | Best Deals & Vouchers | Little Birdie`}
            />
          )}
          {brand && <Header brand={brand} />}

        </div>
        {brand && this.renderMainContent()}
        {brand && <SeoInfo seoData={seo.data} isLoading={isFetchingSeoContent} />}
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  brand: reducers.getBrandBySlug(state, props.match.params.slug),
  // topPriceDropIds: reducers.getTopDealIds(state),
  // isFetchingTopPriceDrops: reducers.isFetchingTopDeals(state),
  topSaleEventIds: reducers.getTopSaleEventIds(state),
  isFetchingTopSaleEvents: reducers.isFetchingTopSaleEvents(state),
  topCouponIds: reducers.getTopCouponIds(state),
  isFetchingTopCoupons: reducers.isFetchingTopCoupons(state),
  baseUrl: reducers.getBaseUrl(state),
  seo: reducers.getSeoContent(state),
  isFetchingSeoContent: reducers.isFetchingSeoContent(state)
})

export default connect(mapStateToProps, {
  loadBrandPage,
  seoContentRequestStart
})(BrandPage)
