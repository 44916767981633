/* eslint-disable no-unused-expressions */
import axios from 'axios'
import { gon } from '../../../../constant'

export const getExperiments = () => {
  const path = `${gon.base_url}/api/experiments`

  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
