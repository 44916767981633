import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getBaseUrl } from '../../../reducers'
import { getAllFilterOptions } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'
import { FILTER_MIN_LIMIT, FILTER_MAX_LIMIT } from '../../../../../../constant'
import SearchFilter from '../SearchFilter'
import usePrevious from '../../../hooks/usePrevious'
import _ from 'lodash'

const targetAttributes = ['color', 'condition']

export default function ElasticAttributesOptions(props) {
  const {
    keyword,
    selectedBrands,
    selectedStores, selectedDepartments,
    selectedCategories, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange,
    isReady, isSearchPage, topPriceDropsFollowingPageFilter, voucherParam, selectedAttributeFilters
  } = props

  const [attributes, setAttributes] = useState({})
  const baseUrl = useSelector(getBaseUrl)
  const location = useLocation()
  const viewAllDeals = location.pathname.includes('all-products')
  const prevKeyword = usePrevious(keyword)
  const prevSelectedAttributeFilters = usePrevious(selectedAttributeFilters)

  useEffect(() => {
    let localAttributes = {}
    let promises = []
    const differenceInAppliedFilter = _.pickBy(selectedAttributeFilters, (v, k) => !_.isEqual(prevSelectedAttributeFilters[k], v))

    function defaultDiscountRange() {
      return isSearchPage ? [] : [-1, 0]
    }

    function params(isDefaultFilters, viewAllDeals, targetAttribute) {
      const param = {
        id: voucherParam || 'deal_search',
        query: keyword,
        per_page: 50,
        fields: ['attributes'],
        is_top_deal: keyword ? null : viewAllDeals ? false : true,
        store_name: selectedStores,
        brand_name: selectedBrands,
        department_name: selectedDepartments,
        category_name: selectedCategories,
        subcategory_name: selectedSubcategories,
        price: selectedPriceRange,
        last_price_date: selectedDateRange,
        price_shift: selectedDiscountRange?.length ? selectedDiscountRange.map(e => e * -1).reverse() : defaultDiscountRange(),
        applyFollowingPageFilter: topPriceDropsFollowingPageFilter,
        attribute_name: [targetAttribute],
        attribute_filters: _.pickBy(selectedAttributeFilters, (value, key)=> key!== targetAttribute)
      }

      if (isDefaultFilters) {
        return { ...param, store_name: [], brand_name: [], department_name: [], category_name: [], subcategory_name: [], price: [], last_price_date: [] }
      } else {
        return param
      }
    }

    async function getElasticAttributes(isDefaultFilters, targetAttribute) {
      const { response } = await getAllFilterOptions(baseUrl, params(isDefaultFilters, viewAllDeals, targetAttribute), viewAllDeals ? 'all-deal' : params(isDefaultFilters).is_top_deal ? 'top-list' : 'keyword-list')
      if(response?.attributes?.[0]) {
        localAttributes = { ...localAttributes, [targetAttribute]: response?.attributes[0] }
      }
    }

    if (isSearchPage && !keyword) return

    targetAttributes.forEach((targetAttribute)=> {
      if(!_.has(differenceInAppliedFilter, targetAttribute)) {
        if(isReady && !topPriceDropsFollowingPageFilter && prevKeyword === keyword) {
          promises.push(getElasticAttributes(false, targetAttribute))
        } else {
          promises.push(getElasticAttributes(true, targetAttribute))
        }
      }
    })

    Promise.all(promises).then(()=> {
      setAttributes({ ...attributes, ...localAttributes })
    })
  }, [
    keyword, selectedBrands, selectedStores,
    selectedDepartments, selectedCategories, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange,
    isReady, voucherParam, selectedAttributeFilters
  ])

  if (_.isEmpty(attributes)) { return (null) }

  return Object.keys(attributes)?.sort()?.map((attribute, index) => <AttributesOptionsAccordian key={`${attribute}-${index}`} attribute={attributes[attribute]} {...props} />) || ''
}

function AttributesOptionsAccordian({ attribute, selectedAttributeFilters,
  handleSelect, handleFilterPill,
  handleClearClick,
  sectionPosition, isAllClear
}) {
  const accordionHeader = useRef(null)
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('')
  const [searchedAttributes, setSearchedAttributes] = useState([])
  const [seeAll, setSeeAll] = useState(true)
  const selectedAttributes = selectedAttributeFilters[`${attribute.key}`] || []

  useAccordionOpenHeader(accordionHeader, sectionPosition, attribute.key)

  useEffect(() => {
    !isAllClear && setFilterSearchKeyword('')
  }, [isAllClear])

  useEffect(() => {
    setSearchedAttributes(getSearchedAttributes)
  }, [filterSearchKeyword, attribute.values])

  const getSearchedAttributes = () => attribute?.values?.filter(attributeValue => _.startsWith(attributeValue?.key?.toLowerCase(), filterSearchKeyword?.toLowerCase())) || []

  const handleClear = e => {
    setFilterSearchKeyword('')
    handleClearClick(e)
  }

  return (
    <div className='list-group-item p-0'>
      <div id={`heading${attribute.key}`}>
        <div ref={accordionHeader} aria-controls={`collapse${attribute.key}`} aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target={`#collapse${attribute.key}`} data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>{_.startCase(attribute.key)}  </div>
            {selectedAttributes?.length > 0 && <span className='selected-filter-preview text-capitalize'>{selectedAttributes.join(', ')}</span>}
            {
              selectedAttributes?.length > 0 &&
              <button
                type='button'
                name={attribute.key}
                className='btn btn-clear ml-auto mr-2'
                data-attribute-filter={true}
                onClick={handleClear}
              >
                Clear selection
              </button>
            }
          </div>
        </div>
      </div>
      <div aria-labelledby={`heading${attribute.key}`} className='collapse' id={`collapse${attribute.key}`}>

        {
          selectedAttributes?.length > 0 &&
          <div className='filter-pills-container'>
            {selectedAttributes.map((selectedAttribute, index) => (
              <div
                key={index}
                type='button'
                onClick={() => handleFilterPill(`${attribute.key}`, selectedAttribute)}
                className='filter-pill ml-auto mr-2 text-capitalize'
              >
                {selectedAttribute === '' ? 'Other' : selectedAttribute}
              </div>
            ))}
          </div>
        }

        <div className='p-3'>
          <SearchFilter
            filterSection={attribute.key}
            filterSearchKeyword={filterSearchKeyword}
            handleSearchFilter={keyword => setFilterSearchKeyword(keyword)}
          />
        </div>
        <div className='checklist-group'>
          {(_.take(_.filter(searchedAttributes, sAttribute => !selectedAttributes.includes(sAttribute.key)), (seeAll ? FILTER_MIN_LIMIT : FILTER_MAX_LIMIT))).map(attributeValue => (
            <div key={`${attribute.key}-${attributeValue.key}`} className='custom-control custom-checkbox'>
              <input
                className='custom-control-input'
                id={`${attribute.key}CustomCheck${attributeValue.key}`}
                type='checkbox'
                data-attribute-filter={true}
                checked={selectedAttributes ? selectedAttributes.includes(attributeValue.key) : null}
                name={`${attribute.key}`}
                value={attributeValue.key}
                onChange={e => handleSelect(e)}
              />
              <label className='custom-control-label text-capitalize' htmlFor={`${attribute.key}CustomCheck${attributeValue.key}`}>
                {attributeValue.key}
                <span>
                  &nbsp;(
                  {attributeValue.doc_count}
                  )
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}