import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getBaseUrl, getStoreBySlug } from '../../../reducers'
import { getAssociationByKeyword, getTopListFilters, getAllDealFilters } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'
import { FILTER_MIN_LIMIT, FILTER_MAX_LIMIT } from '../../../../../../constant'
import SearchFilter from '../SearchFilter'
import usePrevious from '../../../hooks/usePrevious'

export default function ElasticStoreOptions ({
  keyword, handleSelect, handleFilterPill,
  handleClearClick, selectedStores, selectedCustomStores,
  selectedBrands, selectedDepartments,
  selectedCategories, selectedSubcategories, setStore,
  selectedPriceRange, selectedDateRange, selectedDiscountRange,
  isReady, isSearchPage, topPriceDropsFollowingPageFilter,
  sectionPosition, isAllClear, voucherParam
}) {
  const [stores, setStores] = useState([])
  const [searchedStores, setSearchedStores] = useState([])
  const [lockedStores, setLockedStores] = useState([])
  const [seeAll, setSeeAll] = useState(true)
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('')
  const baseUrl = useSelector(getBaseUrl)
  const location = useLocation()
  const viewAllDeals = location.pathname.includes('all-products')

  const activeStore = useSelector(state => getStoreBySlug(state, setStore))
  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Store')

  useEffect(() => {
    setSearchedStores(getSearchedStores)
  }, [filterSearchKeyword, stores])

  useEffect(() => {
    !isAllClear && setFilterSearchKeyword('')
  }, [isAllClear])

  const getSearchedStores = () => stores?.filter(store => _.startsWith(store.key.toLowerCase(), filterSearchKeyword.toLowerCase())) || []

  const handleClear = e => {
    setFilterSearchKeyword('')
    handleClearClick(e)
  }

  const prevKeyword = usePrevious(keyword)

  useEffect(() => {
    function defaultDiscountRange () {
      return isSearchPage ? [] : [-1, 0]
    }
    function params (isDefaultFilters, viewAllDeals) {
      const param = {
        id: voucherParam || 'deal_search_v2',
        query: keyword,
        field: 'store_name',
        per_page: 50,
        is_top_deal: keyword ? null : viewAllDeals ? false : true,
        store_name: [],
        brand_name: selectedBrands,
        department_name: selectedDepartments,
        category_name: selectedCategories,
        subcategory_name: selectedSubcategories,
        price: selectedPriceRange,
        last_price_date: selectedDateRange,
        price_shift: selectedDiscountRange.length ? selectedDiscountRange.map(e => e * -1).reverse() : defaultDiscountRange(),
        applyFollowingPageFilter: topPriceDropsFollowingPageFilter
      }
      if (isDefaultFilters) {
        return { ...param, store_name: [], brand_name: [], department_name: [], category_name: [], subcategory_name: [], price: [], last_price_date: [] }
      } else {
        return param
      }
    }

    async function getElasticStores (isDefaultFilters) {
      if (viewAllDeals) {
        const { response } = await getAllDealFilters(baseUrl, params(isDefaultFilters, viewAllDeals))
        response?.options?.length ? setStores(_.concat(response?.options[0] || {}, _.orderBy(_.take(_.drop(response?.options, 1), FILTER_MAX_LIMIT - 1), [store => store?.key.toLowerCase()], ['asc']), _.takeRight(response?.options, (response?.options?.length > FILTER_MAX_LIMIT ? response?.options?.length - FILTER_MAX_LIMIT : 0)) || [])) : setStores([])
      } else {
        const { response } = params(isDefaultFilters).is_top_deal ? await getTopListFilters(baseUrl, params(isDefaultFilters)) : await getAssociationByKeyword(baseUrl, params(isDefaultFilters))
        response?.options?.length ? setStores(_.concat(response?.options[0] || {}, _.orderBy(_.take(_.drop(response?.options, 1), FILTER_MAX_LIMIT - 1), [store => store?.key.toLowerCase()], ['asc']), _.takeRight(response?.options, (response?.options?.length > FILTER_MAX_LIMIT ? response?.options?.length - FILTER_MAX_LIMIT : 0)) || [])) : setStores([])
      }
    }

    if (isSearchPage && !keyword) return
    isReady && !activeStore && _.isEmpty(selectedCustomStores) && !topPriceDropsFollowingPageFilter && prevKeyword === keyword ? getElasticStores(false) : getElasticStores(true)
    if (!_.isEmpty(selectedCustomStores)) setLockedStores(selectedCustomStores)
    if (activeStore) setLockedStores([_.get(activeStore, 'attributes.name')])
    if (topPriceDropsFollowingPageFilter) setLockedStores(selectedStores)
  }, [
    keyword, selectedBrands,
    selectedDepartments, selectedCategories, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange,
    isReady, voucherParam
  ])
  const handleSeeAllClick = () => {
    setSeeAll(!seeAll)
  }
  return (
    <div className='list-group-item p-0'>
      <div id='headingStore'>
        <div ref={accordionHeader} aria-controls='collapseStore' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseStore' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Store</div>
            {selectedStores?.length > 0 && <span className='selected-filter-preview'>{selectedStores.join(', ')}</span>}
            {
              selectedStores?.length > 0 &&
                <button
                  type='button'
                  name='storeNames'
                  data-input-name='storeIds'
                  disabled={!_.isEmpty(lockedStores)}
                  onClick={handleClear}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      <div aria-labelledby='headingStore' className='collapse' id='collapseStore' style={{}}>
        {
          selectedStores?.length > 0 &&
            <div className='filter-pills-container'>
              {selectedStores.map((store, index) => (
                <div
                  key={index}
                  type='button'
                  onClick={() => _.isEmpty(lockedStores) && handleFilterPill('storeNames', store)}
                  className='filter-pill ml-auto mr-2'
                >
                  {store === '' ? 'Other' : store}
                </div>
              ))}
            </div>
        }
        <div className='p-3'>
          {
            _.isEmpty(lockedStores) &&
              <SearchFilter
                filterSection='Store'
                filterSearchKeyword={filterSearchKeyword}
                handleSearchFilter={keyword => setFilterSearchKeyword(keyword)}
              />
          }
          <div className='checklist-group'>
            {_.isEmpty(lockedStores) &&
              (_.take(_.filter(searchedStores, sStore => !selectedStores.includes(sStore.key)), (seeAll ? FILTER_MIN_LIMIT : FILTER_MAX_LIMIT))).map(store => (
                <div key={`store-${store.key}`} className='custom-control custom-checkbox'>
                  <input
                    className='custom-control-input'
                    id={`storeCustomCheck${store.key}`}
                    type='checkbox'
                    checked={selectedStores ? selectedStores.includes(store.key) : null}
                    name='storeNames'
                    value={store.key}
                    onChange={e => handleSelect(e)}
                  />
                  <label className='custom-control-label' htmlFor={`storeCustomCheck${store.key}`}>
                    {store.key}
                    <span>
                      &nbsp;(
                      {store.doc_count}
                      )
                    </span>
                  </label>
                </div>
              ))}
            {searchedStores.length > FILTER_MIN_LIMIT
              ? (<span onClick={handleSeeAllClick} className='see-all-list'>{!seeAll ? 'See less' : 'See more'}</span>)
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
