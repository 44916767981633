import React from 'react'

export default function SortSelect ({ handleSort, options /* currentSelectedKey */ }) {
  return (
    <div className='sort-dropdown'>
      <select
        className='form-control'
        onChange={e => handleSort(e)}
        defaultValue=''
      >
        <option value='' disabled>Sort by</option>
        {options.values.map(e => (
          <option key={e.key} value={e.key}>{e.text}</option>
        ))}
      </select>
    </div>
  )
}
