import React, { Component, Fragment } from 'react'
import Modal from 'react-bootstrap/Modal'
import swal from 'sweetalert'
import onboardingFlowDataLayer from '../../../../../components/dataLayers/onboardingFlowDataLayer'

import {
  crossIcon
} from '../../../../../components/Icons'
import { gon } from '../../../../../../constant'

class DetailsForm extends Component {
  componentDidMount() {
    onboardingFlowDataLayer('onboarding-flow-view', null, 'youre-almost-there', 6)
  }

  handleComplete = () => {
    const {
      handleNext
    } = this.props

    handleNext()
    return null
  }

  render() {
    const {
      hideModal,
      handleBack,
      handleAttributeChange,
      progressBar,
      formData: {
        firstname,
        gender
      }
    } = this.props

    return (
      <Fragment>
       <Modal.Header className='d-flex flex-column border-0 pb-0'>
        <div className='align-self-end'>
          <button type='button' className='close' onClick={hideModal}>
            <img src={crossIcon} />
          </button>
        </div>
        <div className='header d-flex justify-content-between w-100 my-3'>
          <div className='mb-3'>
            <h3>You're almost there!</h3>
          </div>
        </div>
        <div className='mt-4 w-100'>
            {progressBar}
          </div>
      </Modal.Header>
        <Modal.Body >
          <div>
            <div className='mb-40'>
              <input
                defaultValue={firstname || ''}
                placeholder='First name (Optional)'
                name='firstname'
                className='form-control'
                onChange={e => handleAttributeChange({ name: e.currentTarget?.name, value: e.currentTarget?.value })}
              />
            </div>
            <div className='mb-40'>
              <select
                defaultValue={gender || ''}
                className='form-control'
                name='gender'
                onChange={e => handleAttributeChange({ name: e.currentTarget?.name, value: e.currentTarget?.value })}>
                <option disabled='disabled' value=''>Gender (Optional)</option>
                <option value='male'>Male</option>
                <option value='female'>Female</option>
                <option value='other'>Other</option>
                <option value={null}>Prefer Not To Say</option>
              </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='mx-auto'>
            <button
              type='button'
              className='btn btn-outline-dark rounded-pill mx-2'
              onClick={handleBack}>
              Back
            </button>
            <button
              type='button'
              className='btn btn-main rounded-pill mx-2'
              onClick={() => this.handleComplete()}>
              Get started
            </button>
          </div>
        </Modal.Footer>
      </Fragment>
    )
  }
}

export default DetailsForm
