import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchCategories(params = {}) {
  yield put(actions.categoryRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error, order } = yield call(requester.getCategories, baseUrl, params)
  yield (response) ? put(actions.categoryRequestSuccess(response, order, params.by_department)) : put(actions.categoryRequestFailure(error))
}

function* fetchCategoryData({ data }) {
  yield all(
    Object.keys(data?.department || {}).map(departmentId => (
      fetchCategories({ by_department: departmentId, by_active: true })
    ))
  )
}

function* fetchData(data) {
  yield all([
    fork(fetchCategories, data)
  ])
}

function* watchCategorySaga() {
  yield takeLatest(actionTypes.CATEGORIES_FETCH, fetchData)
  yield takeLatest(actionTypes.DEPARTMENTS_REQUEST_SUCCESS, fetchCategoryData)
}

export {
  watchCategorySaga
}
