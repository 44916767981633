import React from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const FilterRangeSlider = ({
  onSliderChange,
  selectedMin,
  selectedMax,
  minLable,
  maxLable,
  minVal,
  maxVal,
  stepVal
}) => {
  return (
    <div className='slider-container'>
      <Slider
        onChange={onSliderChange}
        min={minVal}
        max={maxVal}
        step={stepVal}
        range
        allowCross={false}
        value={[selectedMin, selectedMax]}
      />
      <div className='row'>
        <div className='col'>
          <p className='text-left'>{minLable}</p>
        </div>
        <div className='col'>
          <p className='text-right'>{maxLable}</p>
        </div>
      </div>
    </div>
  )
}

export default FilterRangeSlider
