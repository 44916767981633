import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import Loader from "../../../../components/Loader"
import { Link } from "react-router-dom"
import ListingCard from "../ListingCard"
import { getBaseUrl } from "../../reducers"
import { getCoupons } from "../../requesters"
import ChevronRight from "../ChevronRight"
import _ from "lodash"

export default function FollowedCouponsList({
  user, title, description, currentSection, sectionPosition
}) {
  const [followedCoupons, setFollowedCoupons] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = useSelector(getBaseUrl)
  const myRef = useRef(null)

  const {
    id
  } = user

  useEffect(() => {
    const params = {
      by_followed_user: id,
      page: 1,
      per_page: 8,
      "by_order[column]": "updated_at",
      "by_order[direction]": "desc"
    }

    async function fetchData() {
      const { response } = await getCoupons(baseUrl, params)
      if (response.coupon) {
        setFollowedCoupons(_.map(Object.values(response.coupon)))
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const couponIds = followedCoupons ? followedCoupons.map(coupon => coupon.id) : []
  const followedCopunsList = _.map(_.take(couponIds, 8), couponId => <ListingCard type="coupon" id={couponId} key={`folowed-coupons-{}-${couponId}`} currentSection={`${currentSection || 'FollowedCouponsList'}`} sectionPosition={sectionPosition} />)

  return (
    <div className="section-group">
      <div className="container">
        <div className="listing-group-header">
          <h2>{title || `Promo Codes ${user.attributes.username} following`}</h2>
          <p className="subtitle">{description || `Here's the list of Price Drops you've chosen to follow`}</p>
        </div>
      </div>
      <div className="listing-carousel">
        <div className="d-flex listing-group-carousel" ref={myRef}>
          {
           isLoading
            ? <Loader isLoading={isLoading} />
            : (
              <>
                <ChevronRight offset={300} ref={myRef} direction="left" />
                {followedCopunsList}
                <ChevronRight offset={300} ref={myRef} direction="right" />
                {
                  followedCopunsList.length < 8 && (
                    <Link to="/top-promo-codes" className="listing-card listing-find-more">
                      <div className="card-coupon d-flex align-items-center justify-content-center">
                        <div className="rounded-pill btn btn-outline-dark">
                          Find more +
                        </div>
                      </div>
                    </Link>
                  )
                }
                {followedCopunsList.length >= 8 && (
                  <div className="listing-card">
                    <Link to={`/spotter/${user.attributes.slug}/following/vouchers`} className="listing-find-more">
                      <div className="rounded-pill mx-auto">
                        View All
                      </div>
                    </Link>
                  </div>
                )}
              </>
          )
        }
        </div>
      </div>
    </div>
 )
}
