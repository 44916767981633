import PropTypes from 'prop-types'
function onboardingFlowDataLayer (eventType, linkType, currentSection = null, stepNumber) {
  // Modal stpe view event
  if (eventType === 'onboarding-flow-view') {
    snowplow('trackStructEvent', 'ux', 'interaction', 'onboarding', null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'view', // Type of Interaction i.e click or view/impression
            object_type: null, // no action, so excepting always button/link 'continue' || 'back' || 'skip' || 'get-started'
            object_position: null, // this is not applicable for this event type
            component_name: currentSection, // 'submit-email' || 'lets-get-started' || 'departments' || 'brands' || 'stores' || 'youre-almost-there' 
            component_position: Number(stepNumber) // The step position of the onboarding window where the interaction occured
          }
        }
      ])
  }

  // Modal button click event
  if (eventType === 'onboarding-flow-click') {
    snowplow('trackStructEvent', 'ux', 'interaction', 'onboarding', null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or view/impression
            object_type: linkType, // button/link 'continue' || 'back' || 'skip' || 'get-started'
            object_position: null, // this is not applicable for this event type
            component_name: currentSection, // 'submit-email' || 'lets-get-started' || 'departments' || 'brands' || 'stores' || 'youre-almost-there',
            component_position: Number(stepNumber) // The step position of the onboarding window where the interaction occured
          }
        }
      ])
  }
}

onboardingFlowDataLayer.PropTypes = {
  item: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  parent: PropTypes.string.isRequired
}

export default onboardingFlowDataLayer
