import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import Modal from "react-bootstrap/Modal"
import { withRouter } from "react-router"

import DepartmentSelect from "./DepartmentSelect"
import StoreSelect from "./StoreSelect"
import BrandSelect from "./BrandSelect"
import DetailsForm from "./DetailsForm"
import GetStarted from "./GetStarted"

import { getBaseUrl, getUserEmail } from "../../../reducers"
import { updateUser, updateNotificationSettings } from "../../../requesters"
import ProgressBar from "./ProgressBar"
import { triggerFollowing, followingNotifyEnd, userAffinityRequestStart, yourTopPickOrderStoreReset } from '../../../actions'
import { isCurrentUserLoading, currentUserUpdate } from '../../../actions/users'
import { setModalClose, setIntialPersonalise } from '../../../actions/personalise'
import axios from "axios"
import { gon } from "../../../../../../constant"
import onboardingFlowDataLayer from "../../../../../components/dataLayers/onboardingFlowDataLayer"
import { withCookies } from 'react-cookie'

class FavouritesModal extends Component {
  constructor(props) {
    super(props)
    const {
      currentUser
    } = this.props
    const excludePathArray = [
      '/extension-active',
      '/uninstall-feedback', 
      '/mobile/ext/permissions-details', 
      '/mobile/ext/permissions',
      '/mobile/ext/enable',
      '/safari-extension-active', 
      '/extension', 
      '/little-birdie-extension' 
    ]
    const excludePathName = excludePathArray.indexOf(window.location.pathname) > -1
    this.state = {
      activeModal: currentUser?.id ? 'departmentSelect' :'getStarted',
      show: localStorage.getItem('auth_token') && sessionStorage.getItem('fromExtension') || excludePathName ? false : true,
      activeDepartments: [],
      followedStores: [],
      followedBrands: [],
      formData: {
        gender: localStorage.getItem('gender') || gon?.current_user?.gender || '',
        firstname: localStorage.getItem('firstname') !== null ? localStorage.getItem('firstname') : gon?.current_user?.firstname || ''
      }
    }
  }

  fetchUserDepartments = () => {
    const { currentUser } = this.props
    axios.get(`${gon.urls.api}/departments?followed_by=${currentUser?.id}`)
    .then( response => response?.data?.data?.forEach( department => this.selectDepartment(department.id) ) )
  }

  fetchUserBrands = () => {
    const { currentUser } = this.props
    axios.get(`${gon.urls.api}/brands?followed_by=${currentUser?.id}`)
    .then( response => response?.data?.data?.forEach( brand => this.selectBrand(brand.id) ) )
  }

  fetchUserStores = () => {
    const { currentUser } = this.props
    axios.get(`${gon.urls.api}/stores?followed_by=${currentUser?.id}`)
    .then( response => response?.data?.data?.forEach( store => this.selectStore(store.id) ) )
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps?.userId?.show && !this.state?.show && !this.props.userId?.show) this.setState({activeModal: 'departmentSelect', show: true})
  }

  componentDidMount () {
    if(this.props.currentUser?.id){
      this.fetchUserDepartments()
      this.fetchUserBrands()
      this.fetchUserStores()
    }
    localStorage.removeItem('preferences')
  }

  handleTriggerFollow = ({ type, id, followed, follow_type }) => {
    this.props.setTriggerFollowing({ type, id, followed, follow_type })

      setTimeout(() => {
        this.props.setFollowingNotifyEnd({type, id, followed, follow_type })
      }, 3000)
  }

  triggerOnboardingDataLayer = (activeModal, action) => {
    const dataLayerObjType = {
      departmentSelect: ["departments", 3],
      brandSelect: ["brands", 4],
      storeSelect: ["stores", 5],
      detailsForm: ["youre-almost-there", 6]
    }[activeModal]

    onboardingFlowDataLayer('onboarding-flow-click', action, dataLayerObjType[0], dataLayerObjType[1])
  }

  handleClose = async () => {
    const { activeModal } = this.state
    const { baseUrl, currentUser } = this.props
    const { attributes: { popup_count } } = currentUser

    this.setState({ show: false })

    this.triggerOnboardingDataLayer(activeModal, 'close')

    const respUserData = await this.updateNotificationSetting({ popup_count: popup_count + 1, favourites_state: activeModal })
    this.props.setModalClose(false)

    this.handleSubmit()
  }

  updateNotificationSetting = async (dataToUpdate) => {
    const { baseUrl, currentUser } = this.props
    const respUserData = await updateNotificationSettings(baseUrl, dataToUpdate)
    respUserData?.response?.data && !_.isEqual(respUserData?.response?.data, currentUser) && this.props.setCurrentUser(respUserData?.response?.data)
    return respUserData
  }

  updateHidePopup = async () => {
    const { activeModal } = this.state

    const respUserData = await this.updateNotificationSetting({ hide_popup: true, favourites_state: activeModal })
    this.setState({ show: false })
  }

  handleSubmit = async () => {

    const {
      formData: {
        gender,
        firstname,
      }
    } = this.state
    const { baseUrl } = this.props

    async function submitForm() {
      if (gender || firstname) {
        const userUpdate = await updateUser(baseUrl, {
          gender,
          firstname
        })
        return userUpdate
      } else {
        return null
      }
    }

    return await submitForm()
  }

  handleNext = () => {
    const { activeModal } = this.state

    const nextModal = {
      getStarted: 'departmentSelect',
      departmentSelect: "brandSelect",
      brandSelect: "storeSelect",
      storeSelect: "detailsForm",
    }[activeModal]

    this.triggerOnboardingDataLayer(activeModal, 'continue')
    this.setState({ activeModal: nextModal })
  }

  handleBack = () => {
    const { activeModal } = this.state

    const prevModal = {
      brandSelect: "departmentSelect",
      storeSelect: "brandSelect",
      detailsForm: "storeSelect"
    }[activeModal]

    this.triggerOnboardingDataLayer(activeModal, 'back')
    this.setState({ activeModal: prevModal })
  }

  handleNavigateBack = () => {
    onboardingFlowDataLayer('onboarding-flow-click', 'back', 'lets-get-started', 2)
    this.props.setModalClose(false)
    this.props.history.goBack()
  }

  selectDepartment = id => {
    const { activeDepartments } = this.state

    if (activeDepartments.includes(id)) {
      const departments = _.without(activeDepartments, id)
      this.setState({ activeDepartments: departments })
    } else {
      activeDepartments.push(id)
      this.setState({ activeDepartments })
    }
  }

  selectStore = id => {
    const { followedStores } = this.state

    if (followedStores.includes(id)) {
      const stores = _.without(followedStores, id)
      this.setState({ followedStores: stores })
    } else {
      followedStores.push(id)
      this.setState({ followedStores })
    }
  }

  selectBrand = id => {
    const { followedBrands } = this.state

    if (followedBrands.includes(id)) {
      const brands = _.without(followedBrands, id)
      this.setState({ followedBrands: brands })
    } else {
      followedBrands.push(id)
      this.setState(followedBrands)
    }
  }

  handleAttributeChange = event => {
    const {
      name,
      value
    } = event
    const { formData } = this.state

    formData[name] = value
    localStorage.setItem(name, value)

    this.setState({ formData })
  }

  handlePersonalise = async () => {
    const { currentUser, cookies, userAffinityRequestStart, resetYourTopStore } = this.props
    onboardingFlowDataLayer('onboarding-flow-click', 'get-started', 'youre-almost-there', 6)
    this.closeModal()
    await this.handleSubmit()
    await this.updateHidePopup()
    this.props.setModalClose(false)
    let prevUrl = this.props?.location?.state?.[0]?.prevUrl
    const prevPage = prevUrl?.includes('/shop')
    if(prevPage) {
      this.props.history.push(`${prevUrl}`)
    }
    else {
      this.props.setIntialPersonalise(true)
    }
    
    resetYourTopStore()

    userAffinityRequestStart({
      user_id: currentUser?.id || '',
      network_userid: cookies.get('sp') || ''
    })

  
  }

  handleSkip = () => {
    const { activeModal } = this.state

    const nextModal = {
      getStarted: 'departmentSelect',
      departmentSelect: "brandSelect",
      brandSelect: "storeSelect",
      storeSelect: "detailsForm",
    }[activeModal]

    if (nextModal) {
      this.triggerOnboardingDataLayer(activeModal, 'skip')
      this.setState({ activeModal: nextModal })
    }
    else {
      this.closeModal()
      this.props.setModalClose(false)
    }
  }

  closeModal() {
    this.setState({show: false})
    this.props.setModalClose(false)
  }

  setProgressBar = () => {
    const { activeModal } = this.state
    const value = {
      getStarted: 1,
      departmentSelect: 2,
      storeSelect: 4,
      brandSelect: 3,
      detailsForm: 5
    }[activeModal]

    const steps = ['getStarted', 'departmentSelect', 'brandSelect', 'storeSelect', 'detailsForm']

    return (
     <ProgressBar steps={steps} currentStep={value} />
    )
  }

  modalContent = () => {
    const {
      activeModal,
      activeDepartments,
      followedStores,
      followedBrands,
      formData
    } = this.state

    const ModalContent = {
      departmentSelect: <DepartmentSelect
        hideModal={() => this.handleClose()}
        handleNext={() => this.handleNext()}
        selectDepartment={id => {
          this.selectDepartment(id)
          this.handleTriggerFollow({type: 'department', id: id})
        }}
        progressBar={this.setProgressBar()}
        handleSkip={() => this.handleSkip()}
        hidePopup={() => this.updateHidePopup()}
        activeDepartments={activeDepartments} />,
      storeSelect: <StoreSelect
        hideModal={() => this.handleClose()}
        handleNext={() => this.handleNext()}
        handleBack={() => this.handleBack()}
        selectStore={id => {
          this.selectStore(id)
          this.handleTriggerFollow({type: 'store', id: id})
        }}
        progressBar={this.setProgressBar()}
        handleSkip={() => this.handleSkip()}
        hidePopup={() => this.updateHidePopup()}
        activeDepartments={activeDepartments}
        followedStores={followedStores} />,
      brandSelect: <BrandSelect
        hideModal={() => this.handleClose()}
        handleNext={() => this.handleNext()}
        handleBack={() => this.handleBack()}
        selectBrand={id => {
          this.selectBrand(id)
          this.handleTriggerFollow({type: 'brand', id: id})
        }}
        hidePopup={() => this.updateHidePopup()}
        handleSkip={() => this.handleSkip()}
        progressBar={this.setProgressBar()}
        activeDepartments={activeDepartments}
        followedBrands={followedBrands} />,
      detailsForm: <DetailsForm
        hideModal={() => this.handleClose()}
        handleNext={() => this.handlePersonalise()}
        handleBack={() => this.handleBack()}
        hidePopup={() => this.updateHidePopup()}
        handleSkip={() => this.handleSkip()}
        handleAttributeChange={e => this.handleAttributeChange(e)}
        progressBar={this.setProgressBar()}
        formData={formData} />,
      getStarted: <GetStarted
        hideModal={() => this.closeModal()}
        handleNext={() => this.handleNext()}
        handleBack={() => this.handleNavigateBack()}
        handleAttributeChange={e => this.handleAttributeChange(e)}
        progressBar={this.setProgressBar()}
        userData={this.props.userId}
         />
    }[activeModal]

    return ModalContent
  }

  render() {
    const {
      show
    } = this.state
    const {
      currentUser,
      userId
    } = this.props

    if ((gon?.current_user?.wtid || currentUser?.attributes?.hide_popup) && !userId.show) { return null }

    return (
      <Modal show={show} backdrop={'static'} onHide={() => this.handleClose()} className="modal-favourites" style={{ display: "block", paddingLeft: 15 }} dialogAs={Fragment}>
        <Modal.Dialog className="modal-lg modal-dialog-centered">
          { this.modalContent() }
        </Modal.Dialog>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state),
  userId: getUserEmail(state)
})

const mapDispatchToEvents = (dispatch) => {
  return {
    setCurrentUser: data => dispatch(currentUserUpdate(data)),
    setModalClose : data => dispatch(setModalClose(data)),
    setIntialPersonalise: data => dispatch(setIntialPersonalise(data)),
    setTriggerFollowing: data => dispatch(triggerFollowing(data)),
    setFollowingNotifyEnd: data => dispatch(followingNotifyEnd(data)),
    setCurrentUserLoading: data => dispatch(isCurrentUserLoading(data)),
    setCurrentUserUpdate: data => dispatch(currentUserUpdate(data)),
    userAffinityRequestStart: data => dispatch(userAffinityRequestStart(data)),
    resetYourTopStore: () => dispatch(yourTopPickOrderStoreReset())
  };
};

export default connect(mapStateToProps, mapDispatchToEvents)(withRouter(withCookies(FavouritesModal)))
