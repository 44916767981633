import { useState } from 'react'
import { PER_PAGE_LIMIT } from '../../../../../constant'

export const useInfiniteScroll = ({ baseUrl, method, params, storedPage, initialData = [], pagePagination = false, initPageNo = 0 }) => {
  const [items, setItems] = useState(initialData)
  const [preItems, setPreItems] = useState([])
  const [isNextLoading, setIsNextLoading] = useState(false)
  const [isPreLoading, setIsPreLoading] = useState(false)
  const [itemUsers, setItemUsers] = useState([])
  const [preItemUsers, setPreItemUsers] = useState([])
  const [initPage, setInitPage] = useState(storedPage)
  const [pageNo, setPageNo] = useState(storedPage)
  const [prePageNo, setPrePageNo] = useState((storedPage && (storedPage - 1)) || 0)
  const [isDataFinished, setIsDataFinished] = useState(false)
  const [resp, setResp] = useState({})
  const [respHeader, setRespHeader] = useState({})
  const perPage = PER_PAGE_LIMIT

  async function loadNew () {
    setIsNextLoading(true)
    setItems([])
    setItemUsers([])
    setIsDataFinished(false)
    let reqParams = { ...params }
    if(pagePagination) {
      setPageNo(initPageNo)
      reqParams['page'] = initPageNo
    } else {
      reqParams['from'] = perPage * 0
    }
    const { response, headers } = await method(baseUrl, reqParams)
    if (response?.data?.length === 0 || response?.data?.length < PER_PAGE_LIMIT) setIsDataFinished(true)
    setItems([...(response?.data || [])])
    setItemUsers([...(response?.included?.data || [])])
    setPageNo(pagePagination ? initPageNo + 1 : 1)
    setPrePageNo(0)
    setPreItems([])
    setPreItemUsers([])
    setInitPage(0)
    setIsNextLoading(false)
    setResp(response)
    setRespHeader(headers)

    return response
  }

  async function loadNext () {
    setIsNextLoading(true)
    let reqParams = { ...params }
    if(pagePagination) {
      reqParams['page'] = pageNo
    } else {
      reqParams['from'] = perPage * pageNo
    }
    const { response, headers } = await method(baseUrl, reqParams)
    if (response?.data?.length === 0 || response?.data?.length < PER_PAGE_LIMIT) setIsDataFinished(true)
    setItems(items => [...items, ...(response?.data || [])])
    setItemUsers(users => [...users, ...(response?.included?.data || [])])
    setPageNo(pageNo + 1)
    setIsNextLoading(false)
    setResp(response)
    setRespHeader(headers)

    return response
  }

  async function loadPre () {
    if (prePageNo < initPage && prePageNo >= 0 && items.length) {
      const docHeight = document.body.clientHeight
      setIsPreLoading(true)
      let reqParams = { ...params }
      if(pagePagination) {
        reqParams['page'] = prePageNo
      } else {
        reqParams['from'] = perPage * prePageNo
      }
      const { response, headers } = await method(baseUrl, reqParams)
      setPreItems(items => [...(response?.data || []), ...items])
      setPreItemUsers(users => [...(response?.included?.data || []), ...users])
      setPrePageNo(prePageNo - 1)
      window.scrollTo({
        top: (document.body.clientHeight - docHeight)
      })
      setIsPreLoading(false)
      setResp(response)
      setRespHeader(headers)

      return response
    }
  }

  return [items, isNextLoading, isPreLoading, itemUsers, preItems, preItemUsers, pageNo, prePageNo, isDataFinished, initPage, loadNext, loadPre, loadNew, resp, respHeader]
}
