import React, { Component } from 'react'
import * as sentry from '@sentry/browser'

import ErrorReferenceMesage from './ErrorReferenceMesage'
import FallbackErrorComponent from './FallbackErrorComponent'
import InvalidRoute from '../../../components/utils/InvalidRoute'

const DefaultFallbackErrorComponent = ({error, className}) => {
    return <div className='container-fluid full-width-container section-group top-50'>
         <div className={className} style={{ backgroundColor: 'rgb(243, 244, 243)', borderRadius: '2em', height: '200px' }}>
        <p>Something went wrong 😭</p>

        {error?.message && <span>Here's the error: {error?.message}</span>}
        </div>
    </div>
}

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false,
      message: ''
    }
  }

  static getDerivedStateFromError (errors) {
    sentry.captureException(errors)
    return {
        hasError: true,
        error: {
          name : errors.name,
          message: errors.message
        }
    }
  }

  handleOnErrorCatch = (val) => this.props.onErrorCaught(val)

  triggerError = ({ error, errorInfo }) => this.setState({ hasError: true, error, errorInfo })

  componentDidCatch (error, errorInfo) {
    this.setState({ error: { name: error.name, message: error.message }, errorInfo, hasError: true })
    this.props.onErrorCaught && this.handleOnErrorCatch({ error: { name: error.name, message: error.message }, errorInfo, hasError: true })
  }

  resetError = () => this.setState({ hasError: false })

  render () {
    const { error, hasError } = this.state

    const { CustomFallbackErrorComponent, hide, messages = {}, statusCode } = this.props
    if (hasError) {
      if (CustomFallbackErrorComponent) {
        return <CustomFallbackErrorComponent {...(messages[error?.name] || ErrorReferenceMesage[error?.name])} />
      }
      return hide ? '' :<FallbackErrorComponent title='Something went wrong 😭' message={messages} />
    }
    if(statusCode === 404) {
      return <InvalidRoute />
    }


    return this.props.children
  }
}

export default ErrorBoundary
