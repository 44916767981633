import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  getBaseUrl,
  getDepartmentBySlug,
  getCategoryBySlug,
  getSubcategoryBySlug,
  getRouter
} from '../../../reducers'
import { getAssociationByKeyword, getTopListFilters, getAllDealFilters } from '../../../requesters'
import usePrevious from '../../../hooks/usePrevious'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function ElasticDepartmentOptions ({
  keyword, handleSelect, handleClearClick, handleFilterPill,
  selectedStores, selectedBrands, selectedCustomDepartments, selectedCustomCategories,
  selectedDepartments, selectedCategories, selectedSubcategories,
  setDepartment, setCategory, setSubcategory, selectedPriceRange, selectedDateRange,
  selectedDiscountRange, isReady, isSearchPage, topPriceDropsFollowingPageFilter, sectionPosition, voucherParam
}) {
  const [departments, setDepartments] = useState([])
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  const [lockedDepartments, setLockedDepartments] = useState([])
  const [lockedCategories, setLockedCategories] = useState([])
  const [lockedSubcategories, setLockedSubcategories] = useState([])

  const baseUrl = useSelector(getBaseUrl)
  const location = useLocation()
  const viewAllDeals = location.pathname.includes('all-products')

  const accordionDepartmentHeader = useRef(null)
  const accordionCategoryHeader = useRef(null)
  const accordionSubcategoryHeader = useRef(null)

  useAccordionOpenHeader(accordionDepartmentHeader, sectionPosition, 'Department')
  useAccordionOpenHeader(accordionCategoryHeader, sectionPosition + 1, 'Category')
  useAccordionOpenHeader(accordionSubcategoryHeader, sectionPosition + 2, 'Sub Category')

  const activeDepartment = useSelector(state => getDepartmentBySlug(state, setDepartment))
  const activeCategory = useSelector(state => getCategoryBySlug(state, setCategory))
  const activeSubcategory = useSelector(state => getSubcategoryBySlug(state, setSubcategory))

  const isLocationSearch = (_.get(useSelector(getRouter), 'location.pathname')).split('/')[1] === 'search'

  function defaultDiscountRange () {
    return isSearchPage ? [] : [-1, 0]
  }

  function params (field, isDefaultFilters, viewAllDeals) {
    const param = {
      id: voucherParam ? voucherParam : 'deal_search_v2',
      query: keyword,
      field,
      per_page: 50,
      is_top_deal: keyword ? null : viewAllDeals ? false : true,
      store_name: selectedStores,
      brand_name: selectedBrands,
      department_name: selectedDepartments,
      category_name: selectedCategories,
      subcategory_name: selectedSubcategories,
      price: selectedPriceRange,
      last_price_date: selectedDateRange,
      price_shift: selectedDiscountRange.length ? selectedDiscountRange.map(e => e * -1).reverse() : defaultDiscountRange(),
      applyFollowingPageFilter: topPriceDropsFollowingPageFilter
    }
    if (isDefaultFilters) {
      return { ...param, store_name: [], brand_name: [], department_name: [], category_name: [], subcategory_name: [], price: [], last_price_date: [] }
    } else {
      if (typeof field === 'string') {
        param[field] = []
      }
      return param
    }
  }

  const prevKeyword = usePrevious(keyword)

  useEffect(() => {
    async function fetchElasticDepartments (isDefaultFilters) {
      if (viewAllDeals) {
        const { response } = await getAllDealFilters(baseUrl, params('department_name', isDefaultFilters, viewAllDeals))
        setDepartments(response?.options || [])
      } else {
        const { response } = params(isDefaultFilters).is_top_deal ? await getTopListFilters(baseUrl, params('department_name', isDefaultFilters, viewAllDeals)) : await getAssociationByKeyword(baseUrl, params('department_name', isDefaultFilters, viewAllDeals))
        setDepartments(response?.options || [])
      }
    }
    if (isSearchPage && !keyword) return
    isReady && !activeDepartment && _.isEmpty(selectedCustomDepartments) && prevKeyword === keyword ? fetchElasticDepartments(false) : fetchElasticDepartments(true)
    if (!_.isEmpty(selectedCustomDepartments)) setLockedDepartments(selectedCustomDepartments)
    if (activeDepartment) setLockedDepartments([_.get(activeDepartment, 'attributes.name')])
  }, [
    keyword, isReady,
    selectedBrands, selectedStores,
    selectedCategories, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange, voucherParam
  ])

  useEffect(() => {
    async function fetchElasticCategories (isDefaultFilters) {
      if (viewAllDeals) {
        const { response } = await getAllDealFilters(baseUrl, params('category_name', isDefaultFilters, viewAllDeals))
        setCategories(response?.options || [])
      } else {
        const { response } = params(isDefaultFilters).is_top_deal ? await getTopListFilters(baseUrl, params('category_name', isDefaultFilters, viewAllDeals)) : await getAssociationByKeyword(baseUrl, params('category_name', isDefaultFilters, viewAllDeals))
        setCategories(response.options)
      }
    }
    if (isSearchPage && !keyword) return
    isReady && prevKeyword === keyword ? fetchElasticCategories(false) : fetchElasticCategories(true)
    if (!_.isEmpty(selectedCustomCategories)) setLockedCategories(selectedCustomCategories)
    if (activeCategory) setLockedCategories([_.get(activeCategory, 'attributes.name')])
  }, [
    keyword, isReady,
    selectedBrands, selectedStores,
    selectedDepartments, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange, voucherParam
  ])

  useEffect(() => {
    async function fetchElasticSubcategories (isDefaultFilters) {
      if (viewAllDeals) {
        const { response } = await getAllDealFilters(baseUrl, params('subcategory_name', isDefaultFilters, viewAllDeals))
        setSubcategories(response?.options || [])
      } else {
        const { response } = params(isDefaultFilters).is_top_deal ? await getTopListFilters(baseUrl, params('subcategory_name', isDefaultFilters, viewAllDeals)) : await getAssociationByKeyword(baseUrl, params('subcategory_name', isDefaultFilters, viewAllDeals))
        setSubcategories(response.options)
      }
    }
    if (isSearchPage && !keyword) return
    isReady && prevKeyword === keyword ? fetchElasticSubcategories(false) : fetchElasticSubcategories(true)
    if (activeSubcategory) setLockedSubcategories([_.get(activeSubcategory, 'attributes.name')])
  }, [
    keyword, isReady,
    selectedBrands, selectedStores,
    selectedDepartments, selectedCategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange, voucherParam
  ])
  return (
    <>
      <div className='list-group-item p-0'>
        <div id='headingDepartment'>
          <div ref={accordionDepartmentHeader} aria-controls='collapseDepartment' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseDepartment' data-toggle='collapse'>
            <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
              <div className='header-label'>Department</div>
              {(selectedDepartments?.length > 0 || lockedDepartments?.length > 0) && <span className='selected-filter-preview'>{lockedDepartments?.length > 0 ? lockedDepartments.join(', ') : selectedDepartments.join(', ')}</span>}
              {(selectedDepartments?.length > 0 || lockedDepartments?.length > 0) &&
                <button
                  type='button'
                  name='departmentNames'
                  disabled={!_.isEmpty(lockedDepartments)}
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>}
            </div>
          </div>
        </div>
        <div aria-labelledby='headingDepartment' className='collapse' id='collapseDepartment'>
          {(selectedDepartments?.length > 0 || lockedDepartments?.length > 0) &&
            <div className='filter-pills-container'>
              {!_.isEmpty(lockedDepartments)
                ? lockedDepartments.map((department, index) => (
                  <div
                    key={index}
                    type='button'
                    className='filter-pill ml-auto mr-2'
                  >
                    {department}
                  </div>
                  ))
                : selectedDepartments.map((department, index) => (
                  <div
                    key={index}
                    type='button'
                    onClick={() => !_.isEmpty(lockedDepartments) ? {} : handleFilterPill('departmentNames', department)}
                    className='filter-pill ml-auto mr-2'
                  >
                    {department === '' ? 'Other' : department}
                  </div>
                ))}
            </div>}
          <div className='p-3'>
            <div className='checklist-group'>
              {_.isEmpty(lockedDepartments) &&
                (
                  (isLocationSearch ? _.filter(departments, sDepartment => !selectedDepartments.includes(sDepartment.key)) : _.orderBy(_.filter(departments, sDepartment => !selectedDepartments.includes(sDepartment.key)), [dep => dep.key.toLowerCase()], ['asc'])).map(department => (
                    <div key={`department-${department.key}`} className='custom-control custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id={`departmentCustomCheck${department.key}`}
                        type='checkbox'
                        checked={selectedDepartments.includes(department.key)}
                        name='departmentNames'
                        value={department.key}
                        onChange={e => handleSelect(e)}
                      />
                      <label className='custom-control-label' htmlFor={`departmentCustomCheck${department.key}`}>
                        {department.key}
                        <span>
                          &nbsp;(
                          {department.doc_count}
                          )
                        </span>
                      </label>
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
      <div className='list-group-item p-0'>
        <div id='headingCategory'>
          <div ref={accordionCategoryHeader} aria-controls='collapseCategory' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseCategory' data-toggle='collapse'>
            <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
              <div className='header-label'>Category</div>
              {(selectedCategories?.length > 0 || lockedCategories?.length > 0) && <span className='selected-filter-preview'>{lockedCategories?.length > 0 ? lockedCategories.join(', ') : selectedCategories.join(', ')}</span>}
              {selectedCategories?.length > 0 &&
                <button
                  type='button'
                  name='categoryNames'
                  disabled={!_.isEmpty(lockedCategories)}
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>}
            </div>
          </div>
        </div>
        <div aria-labelledby='headingCategory' className='collapse' id='collapseCategory'>
          {(selectedCategories?.length > 0 || lockedCategories?.length > 0) &&
            <div className='filter-pills-container'>
              {!_.isEmpty(lockedCategories)
                ? lockedCategories.map((cat, index) => (
                  <div
                    key={index}
                    type='button'
                    className='filter-pill ml-auto mr-2'
                  >
                    {cat}
                  </div>
                  ))
                : selectedCategories.map((cat, index) => (
                  <div
                    key={index}
                    type='button'
                    onClick={() => handleFilterPill('categoryNames', cat)}
                    className='filter-pill ml-auto mr-2'
                  >
                    {cat === '' ? 'Other' : cat}
                  </div>
                ))}
            </div>}
          <div className='p-3'>
            <div className='checklist-group'>
              {_.isEmpty(lockedCategories) &&
                (
                  (isLocationSearch ? _.filter(categories, sCategory => !selectedCategories.includes(sCategory.key)) : _.orderBy(_.filter(categories, sCategory => !selectedCategories.includes(sCategory.key)), [cat => cat.key.toLowerCase()], ['asc'])).map(category => (
                    <div key={`category-${category.key}`} className='custom-control custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id={`categoryCustomCheck${category.key}`}
                        type='checkbox'
                        checked={selectedCategories.includes(category.key)}
                        name='categoryNames'
                        value={category.key}
                        onChange={e => handleSelect(e)}
                      />
                      <label className='custom-control-label' htmlFor={`categoryCustomCheck${category.key}`}>
                        {category.key}
                        <span>
                          &nbsp;(
                          {category.doc_count}
                          )
                        </span>
                      </label>
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
      <div className='list-group-item p-0'>
        <div id='headingSubcategory'>
          <div ref={accordionSubcategoryHeader} aria-controls='collapseSubcategory' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseSubcategory' data-toggle='collapse'>
            <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
              <div className='header-label'>Sub Category</div>
              {selectedSubcategories?.length > 0 && <span className='selected-filter-preview'>{selectedSubcategories.join(', ')}</span>}
              {selectedSubcategories?.length > 0 &&
                <button
                  type='button'
                  name='subcategoryNames'
                  disabled={!_.isEmpty(lockedSubcategories)}
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>}
            </div>
          </div>
        </div>
        <div aria-labelledby='headingSubcategory' className='collapse' id='collapseSubcategory'>
          {(selectedSubcategories?.length > 0 || lockedSubcategories?.length > 0) &&
            <div className='filter-pills-container'>
              {!_.isEmpty(lockedSubcategories)
                ? lockedSubcategories.map((subCat, index) => (
                  <div
                    key={index}
                    type='button'
                    className='filter-pill ml-auto mr-2'
                  >
                    {subCat}
                  </div>
                  ))
                : selectedSubcategories.map((subCat, index) => (
                  <div
                    key={index}
                    type='button'
                    onClick={() => handleFilterPill('subcategoryNames', subCat)}
                    className='filter-pill ml-auto mr-2'
                  >
                    {subCat === '' ? 'Other' : subCat}
                  </div>
                ))}
            </div>}
          <div className='p-3'>
            <div className='checklist-group'>
              {_.isEmpty(lockedSubcategories) &&
                (
                  (isLocationSearch ? _.filter(subcategories, sSubcategorie => !selectedSubcategories.includes(sSubcategorie.key)) : _.orderBy(_.filter(subcategories, sSubcategorie => !selectedSubcategories.includes(sSubcategorie.key)), [sub => sub.key.toLowerCase()], ['asc'])).map(subcategory => (
                    <div key={`subcategory-${subcategory.key}`} className='custom-control custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id={`subcategoryCustomCheck${subcategory.key}`}
                        type='checkbox'
                        checked={selectedSubcategories.includes(subcategory.key)}
                        name='subcategoryNames'
                        value={subcategory.key}
                        onChange={e => handleSelect(e)}
                      />
                      <label className='custom-control-label' htmlFor={`subcategoryCustomCheck${subcategory.key}`}>
                        {subcategory.key}
                        <span>
                          &nbsp;(
                          {subcategory.doc_count}
                          )
                        </span>
                      </label>
                    </div>
                  ))
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
