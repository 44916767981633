import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchSubcategories() {
  yield put(actions.subcategoryRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getSubcategories, baseUrl)
  yield (response) ? put(actions.subcategoryRequestSuccess(response)) : put(actions.subcategoryRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchSubcategories, data)
  ])
}

function* watchSubcategorySaga() {
  yield takeLatest(actionTypes.SUBCATEGORIES_FETCH, fetchData)
}

export {
  watchSubcategorySaga
}