/* eslint-disable require-yield */
import {
  takeLatest, call, put
} from 'redux-saga/effects'

import * as actionTypes from '../actions/types'

import { experimentsRequestSuccess, experimentRequestFailure } from '../actions'
import * as requester from '../requesters'

function* changeLocation (data) {
  const { payload } = data

  const newPath = payload.location.pathname

  return newPath
}

function* fetchAllExperiments () {
  const { response, error } = yield call(requester.getExperiments, {})
  yield (response.data) ? put(experimentsRequestSuccess(response.data)) : put(experimentRequestFailure(error))
}

export function* watchRouterChangeLocationSaga () {
  yield takeLatest(actionTypes.LOCATION_CHANGED, changeLocation)
  yield takeLatest(actionTypes.LOCATION_CHANGED, fetchAllExperiments)
}
