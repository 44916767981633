import React, { Component } from 'react'

import { Link, withRouter } from 'react-router-dom'
import { gon } from '../../../../../constant'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'
import { userIcon } from '../../../../components/Icons'
class SignedInList extends Component {
  handleNativeLogin () {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"login", "path":"${window.location.href}"}`)
    } else {
      this.props.history.push({
        pathname: '/login',
        state: { from: window.location.pathname }
      })
    }
  }

  render () {
    return (
      <ul className='navbar-nav ml-auto'>
          <li className='nav-item'>
          <Link
            className='nav-link'
            to={{
              pathname: '/login',
              state: { from: window.location.pathname }
            }}
          >
            {!gon.auth_token &&
            <div className="nav-item-image" onClick={() => this.handleNativeLogin()}>
            <img src={userIcon} className='rounded-circle' width='16px' height='16px' />
          </div>}
          </Link>
        </li>
      </ul>
    )
  }
}

export default withRouter(SignedInList)
