import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { getBaseUrl } from '../../../reducers'
import { getTopBrands } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'
import { FILTER_MIN_LIMIT, FILTER_MAX_LIMIT } from '../../../../../../constant'
import _ from 'lodash'

export default function BrandOptions ({ handleSelect, handleClearClick, selectedBrands, handleFilterPill, sectionPosition }) {
  const [brands, setBrands] = useState([])
  const baseUrl = useSelector(getBaseUrl)
  const [seeAll, setSeeAll] = useState(true)

  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Brand')

  useEffect(() => {
    const params = {
      per_page: 50
    }

    async function fetchTopBrands () {
      const { response } = await getTopBrands(baseUrl, params)
      if (_.get(response, 'data.brand')) {
        setBrands(_.orderBy(response?.data?.brand, [brand => brand?.attributes?.name?.toLowerCase()], ['asc']))
      }
    }

    fetchTopBrands()
  }, [])

  const handleSeeAllClick = () => {
    setSeeAll(!seeAll)
  }

  function getLabel (value) {
    return _.find(brands, (brand) => brand.id === value)?.attributes?.name
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingBrand'>
        <div ref={accordionHeader} aria-controls='collapseBrand' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseBrand' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Brand</div>
            {selectedBrands?.length > 0 && <span className='selected-filter-preview'>{_.filter(brands, brand => selectedBrands.includes(brand.id)).map(sBrand => sBrand.attributes.name).join(', ')}</span>}
            {selectedBrands?.length > 0 &&
              <button
                type='button'
                name='brandIds'
                onClick={e => handleClearClick(e)}
                className='btn btn-clear ml-auto mr-2'
              >
                Clear selection
              </button>}
          </div>
        </div>
      </div>
      <div aria-labelledby='headingBrand' className='collapse' id='collapseBrand' style={{}}>
        {selectedBrands?.length > 0 &&
          <div className='filter-pills-container'>
            {selectedBrands.map((brand, index) => (
              <div
                key={index}
                type='button'
                onClick={() => handleFilterPill('brandIds', brand)}
                className='filter-pill ml-auto mr-2'
              >
                {getLabel(brand)}
              </div>
            ))}
          </div>}
        <div className='p-3'>
          <div className='checklist-group'>
            {(_.take(_.filter(brands, sBrand => !selectedBrands.includes(sBrand.id)), (seeAll ? FILTER_MIN_LIMIT : FILTER_MAX_LIMIT))).map(brand => (
              <div key={`brand-${brand.id}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`brandCustomCheck${brand.id}`}
                  type='checkbox'
                  checked={selectedBrands ? selectedBrands.includes(brand.id) : null}
                  name='brandIds'
                  value={brand.id}
                  data-input-name='brandNames'
                  data-input-value={brand?.attributes?.name}
                  onChange={e => handleSelect(e)}
                />
                <label className='custom-control-label' htmlFor={`brandCustomCheck${brand.id}`}>
                  {brand.attributes.name}
                  <span> </span>
                </label>
              </div>
            ))}
            {brands.length > FILTER_MIN_LIMIT
              ? (<span onClick={handleSeeAllClick} className='see-all-list'>{!seeAll ? 'See less' : 'See more'}</span>)
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
