import React, { useEffect, useRef, useState } from 'react'

import FilterRangeSlider from '../../RangeSlider/FilterRangeSlider'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

// For const min max value refernce check `priceOptions` values at `Filter/checkboxOptions.js`
const DEFAULT_MIN_PRICE = 0
const DEFAULT_MAX_PRICE = 4000

export default function PriceRangeOptions ({
  selectedPriceRange, handleSelect, handleClearClick,
  priceMin, priceMax, selectedCustomPriceRange,
  handleFilterPill, sectionPosition
}) {
  const [isError, setIsError] = useState(false)

  const [selectedPrice, setselectedPrice] = useState({ priceMin: priceMin || DEFAULT_MIN_PRICE, priceMax: priceMax || DEFAULT_MAX_PRICE })
  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Price Range')

  useEffect(() => {
    if (selectedPriceRange.length) {
      setselectedPrice({ priceMin: selectedPriceRange[0], priceMax: selectedPriceRange[1] })
    } else {
      setselectedPrice({ priceMin: priceMin || DEFAULT_MIN_PRICE, priceMax: priceMax || DEFAULT_MAX_PRICE })
    }
  }, [selectedPriceRange, priceMin, priceMax])

  const onSliderChange = value => {
    setIsError(false)
    setselectedPrice({ ...selectedPrice, priceMin: value[0], priceMax: value[1] })
  }

  const handlePriceRangeInput = e => {
    setselectedPrice({ ...selectedPrice, [e.currentTarget.name]: e.currentTarget.value })
  }

  const handleClear = e => {
    setselectedPrice({ priceMin: priceMin, priceMax: priceMax })
    handleClearClick(e)
  }

  const handleOnBlur = event => {
    setselectedPrice({ ...selectedPrice, [event.target?.name]: selectedPrice[event.target?.name] })
    selectedPrice.priceMin === '' && setselectedPrice({ ...selectedPrice, priceMin: priceMin })
    selectedPrice.priceMax === '' && setselectedPrice({ ...selectedPrice, priceMax: priceMax })

    if (
      parseInt(selectedPrice.priceMax) < parseInt(selectedPrice.priceMin) ||
      (selectedPrice.priceMin && (selectedPrice.priceMin < priceMin)) ||
      (selectedPrice.priceMax && (selectedPrice.priceMax > priceMax))
    ) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }
  const handleOnFocus = event => {
    event.target.select()
  }

  function handlePriceRangeDisplay () {
    return (
      <div aria-labelledby='headingPrice' className='collapse' id='collapsePrice'>
        {
          selectedPriceRange?.length > 0 &&
            <div className='filter-pills-container'>
              <button
                type='button'
                name='priceRange'
                onClick={e => handleClear(e)}
                className='btn filter-pill ml-auto mr-2'
              >
                {`$${selectedPriceRange[0]} - $${selectedPriceRange[1]}`}
              </button>
            </div>
        }
        <div className='p-3'>
          <FilterRangeSlider
            onSliderChange={onSliderChange}
            selectedMin={selectedPrice.priceMin}
            selectedMax={selectedPrice.priceMax}
            minLable={`$ ${priceMin || DEFAULT_MIN_PRICE}`}
            maxLable={`$ ${priceMax || DEFAULT_MAX_PRICE}`}
            minVal={priceMin || DEFAULT_MIN_PRICE}
            maxVal={priceMax || DEFAULT_MAX_PRICE}
            stepVal={5}
          />
          <div className='d-flex align-items-center mt-2 price-range'>
            $
            <input
              placeholder='Min'
              className={`form-control form-control-sm mx-2 ${isError && 'error'}`}
              type='number'
              name='priceMin'
              value={selectedPrice.priceMin}
              onChange={handlePriceRangeInput}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
            to
            <input
              placeholder='Max'
              className={`form-control form-control-sm mx-2 ${isError && 'error'}`}
              type='number'
              name='priceMax'
              value={selectedPrice.priceMax}
              onChange={handlePriceRangeInput}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
            <button
              className='btn btn-link btn-sm'
              type='submit'
              name='priceRange'
              disabled={isError}
              onClick={e => handleSelect({ ...e, arrValue: [parseInt(selectedPrice.priceMin || DEFAULT_MIN_PRICE, 10), parseInt(selectedPrice.priceMax || DEFAULT_MAX_PRICE, 10)] })}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingPrice'>
        <div ref={accordionHeader} aria-controls='collapsePrice' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapsePrice' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Price</div>
            {selectedPriceRange?.length > 0 && <span className='selected-filter-preview'>{`$${selectedPriceRange[0]} - $${selectedPriceRange[1]}`}</span>}
            {
              selectedPriceRange?.length > 0 &&
                <button
                  type='button'
                  name='priceRange'
                  onClick={e => handleClear(e)}
                  disabled={!_.isEmpty(selectedCustomPriceRange)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      {handlePriceRangeDisplay()}
    </div>
  )
}
