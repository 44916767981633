import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import Loader from "../../../../components/Loader"

import { getCategoriesByDepartmentId, isFetchingCategoriesByDepartment, getResource } from "../../reducers"

export default function CategorySelector({ department }) {
  const [groupedIds, setGroupedIds] = useState([[]])
  const [complete, setComplete] = useState(false)
  const categoryIds = useSelector(state => getCategoriesByDepartmentId(state, department.id))
  const isFetchingCategoryIds = useSelector(state => isFetchingCategoriesByDepartment(state, department.id))

  useEffect(() => {
    function groupIds() {
      let count = 0
      categoryIds.forEach(id => {
        if (id === null) {
          count += 1
          groupedIds[count] = []
        } else {
          groupedIds[count].push(id)
        }
        setGroupedIds(groupedIds)
      })
      setComplete(true)
    }

    if (categoryIds?.length && !isFetchingCategoryIds && !complete) groupIds()
  }, [categoryIds, isFetchingCategoryIds])

  useEffect(() => {
    setGroupedIds([[]])
    setComplete(false)
  }, [department])

  if (categoryIds === null) return <Loader isLoading />

  return (
    <div className="nav-category-list">
      <div className="row no-gutters">
        <div className="col-xl-6">
          <Link
            to={`/shop/${department.attributes.slug}`} className="nav-category-see-all">
            <div>{`See all ${department.attributes.name} offers`}</div>
          </Link>
        </div>
      </div>
      <div className="row no-gutters">
        {groupedIds.map(ids => (
          <div className="col-xl-6 mb-4" key={`category-group-${ids[0]}`}>
            <ul className="list-unstyled category-list">
              {ids.map(id => (
                <Categories categoryId={id} department={department} key={`category-${id}`} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

function Categories({ categoryId, department }) {
  const category = useSelector(state => getResource(state, { id: categoryId, type: "category" }))

  if (!category) return null

  return (
    <Link
      to={`/shop/${department.attributes.slug}/${category.attributes.slug}`}
      key={`${category.attributes.slug}`}>
      <li>
        {category.attributes.name}
      </li>
    </Link>
  )
}
