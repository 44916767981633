import React, { useRef, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setModalClose } from '../../../apps/App/actions'
import ForYou from '../../../components/Labels/ForYou'
import PromptProgressBar from '../components/Modals/FavouritesModal/PromptProgressBar'
import { crossIconWhite } from '../../../components/Icons'
import { getBaseUrl } from '../reducers'
import { handleNativeRedirectWebView } from '../utilis/NativeDataShare'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'

function PersonalisationPrompt (props) {
  const sectionRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, setShowPersonalisationModal } = props
  const [isclosed, setIsClosed] = useState(false)
  const [completedSteps, setCompletedSteps] = useState([])
  const activeModal = 'getStarted'

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    dispatch(setCarouselLoading({ carousel: props.sectionId }))
  }, [])

  useEffect(() => {
    if(isclosed) {
      dispatch(setCarouselLoaded({ carousel: props.sectionId }))
    } else if(sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: props.sectionId }))
    }
  }, [isclosed, sectionRef?.current])

  const handlePromptClose = () => {    
    setIsClosed(true)
  }

  const startPersonalizeFlow = () => {
    setTimeout(() => {
      if (user !== null) setShowPersonalisationModal(true)
      else {
        if (window.ReactNativeWebView) {
          handleNativeRedirectWebView(`{"type":"register", "path":"${window.location.href}"}`)
        } else {
          history.push('/register')
        }
      }
    }, 600)
  }

  const value = {
    departmentSelect: 1,
    brandSelect: 2,
    storeSelect: 3,
    detailsForm: 4
  }[activeModal]

  const steps = ['departmentSelect', 'brandSelect', 'storeSelect', 'detailsForm']

  return (
    !isclosed
      ? (
        <div className='d-flex section-group personalisation-prompt black-friday' ref={sectionRef}>
          <div className='cloud'></div>
          <div className='container'>
            <div className='row prompt-content'>
              <img className='close-icon' src={crossIconWhite} onClick={handlePromptClose} />
              <div className='col-12 col-lg-6 px-0'>
                <ForYou />
                <div className={`d-flex flex-row-reverse align-items-start message-row`}>
                  <div className='col-12 px-0 mt-2'>
                    <div><span>Personalise</span></div>
                    <div className='feed-text'>your <span className='p-green-text'>Black Friday</span> </div>
                  </div>
                  <div className='d-none bird-fly-off-animation' />
                </div>
                <div className='second-row'>Little Birdie hand picks the best Black Friday offers for your shopping feed</div>
              </div>
              <div className='d-flex col-12 col-lg-6 flow-progress justify-content-center align-items-center'>
                <div className='pill-content'>
                  <div className='d-flex row badges-row'>
                    <div className='d-flex col-12 mb-2 mb-lg-3 px-0'>
                      <PromptProgressBar steps={steps} currentStep={value} completedSteps={completedSteps} />
                    </div>
                    <div className='d-flex col-12 px-0 align-items-center justify-content-between'>
                      <span className='tab-pill'>Departments</span>
                      <span className='tab-pill'>Brands</span>
                      <span className='tab-pill'>Stores</span>
                      <span className='tab-pill'>About you</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      type='button'
                      className='btn personalize-button'
                      onClick={startPersonalizeFlow}
                    >
                      Personalise now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      : null
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})
const mapDispatchToEvents = (dispatch) => {
  return {
    setShowPersonalisationModal: data => dispatch(setModalClose(data))
  }
}

export default connect(mapStateToProps, mapDispatchToEvents)(PersonalisationPrompt)
