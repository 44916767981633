const shuffle = (array) => {
  let currentIndex = array.length
  let randomIndex
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }
  return array
}

const randomizeList = (lists) => {
  const typeDList = lists?.deals?.map((deal, index) => ({ ...deal, itemType: 'deal', itemOrder: index + 1, isElasticResult: true })) || []
  const typeSList = lists?.sale_events?.map((sale, index) => ({ ...sale, itemType: 'saleEvent', itemOrder: index + 1, isElasticResult: true })) || []
  const typeCList = lists?.vouchers?.map((coupon, index) => ({ ...coupon, itemType: 'coupon', itemOrder: index + 1, isElasticResult: true })) || []
  const maxSList = _.take(typeSList, 4)
  const maxCList = _.take(typeCList, 2)
  const finallist = [...typeDList, ...maxSList, ...maxCList]
  return finallist
}

const sortItems = (finallist, sortType = 'asc', size = 10) => {
  const sortedData = _.take(_.orderBy(shuffle(finallist), ['itemOrder'], [sortType]), size)
  return sortedData
}

export {
  randomizeList,
  shuffle,
  sortItems
}
