import React from 'react'
import { BrandCard, StoreCard } from '../../../../../components/ElasticListingCards'
import Loader from '../../../../../components/Loader'

export default function StoreBrandSearchResults ({ items, isLoading }) {
  return (
    <div>
      {!isLoading
        ? (
          <div className='store-brand-ranking'>
            <div className='row listing-grid  listing-group-brands'>
              {
                items?.length === 0
                  ? <span>Oops! No results found. Try using different filters.</span>
                  : items.map((item, index) => (
                    item.type === 'store' ? <StoreCard wrapperClass='col-12 col-sm-6 col-lg-4 col-xl-3' item={item} key={`store-${item.id}`} position={index + 1} currentSection='StoreBrandList' sectionPosition={1} /> : <BrandCard wrapperClass='col-12 col-sm-6 col-lg-4 col-xl-3' item={item} key={`brand-${item.id}`} position={index + 1} currentSection='StoreBrandList' sectionPosition={1} />
                  ))
              }
            </div>
          </div>
          )
        : (
          <div className='col-12 m-3 d-flex justify-content-center'>
            <Loader isLoading />
          </div>
          )}
    </div>
  )
}
