import React from 'react'
import CategoryTopTen from '../apps/App/components/CategoryTopTen'
import DeparmentTopTen from '../apps/App/components/DeparmentTopTen'
import TrendingListings from './TrendingListings'
export default function DepartmentHeader ({ department, match, departmentNames, category, currentSection, sectionPosition, deptCounts }) {
  const { intro } = _.get(department, 'attributes')
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const deptCatName = department?.attributes?.name
  const affinityDeptData = affinityData?.[0]?.affinity?.departments?.filter(dept => dept.name === deptCatName)
  const affinityCatData = affinityData?.[0]?.affinity?.categories?.filter(cat => cat.name === deptCatName)
  return (
    <>
      <section className='section-group department-header'>
        <div className='container'>
          <div className='card'>
            <h1>{_.get(department, 'attributes.name')}</h1>
            {intro && <p>{intro}</p>}
          </div>
        </div>
        <TrendingListings
          match={match}
          department={departmentNames}
          category={category}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
        />
      </section>
      {
        category
          ? <CategoryTopTen category={category} sectionId='top10Categorywidget' sectionPosition={3} deptCatPage='true' isPersonalised={affinityCatData?.[0]?.absolute >= 20 ? true : false} />
          : <DeparmentTopTen deptCounts={deptCounts} department={departmentNames} sectionPosition={3} deptCatPage='true' isPage='department' isPersonalised={affinityDeptData?.[0]?.absolute >= 20 ? true : false} />
      }
    </>
  )
}

