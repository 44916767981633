/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getCategories = (baseUrl, data = {}) => {
  const path = `${baseUrl}/categories`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), order: data.meta.order, status }))
    .catch(error => { error })
}

export const getCategory = (baseUrl, categoryId, data = {}) => {
  const path = `${baseUrl}/categories/${categoryId}`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}
