import React, { Component } from "react"
import { connect } from "react-redux"

import Breadcrumb from "../../../../components/Breadcrumb"
import Meta from "../../../../components/Meta"
import { loadNewListingPage } from "../../actions"

import {
  NewDealsList,
  NewCouponsList,
  NewSalesEventsList,
  TopBrandsSidebarList,
  TopDealHuntersSidebarList,
  TopStoresSidebarList,
  TopUpcomingSalesSidebarList
} from "../listings"

class NewListingPage extends Component {
  componentDidMount() {
    this.initiatePageLoad()
  }

  componentDidUpdate() {
    this.initiatePageLoad()
  }

  initiatePageLoad = () => {
    const {
      loadNewListingPage
    } = this.props
    loadNewListingPage({})
  }

  // Generates the urls for the ranking pages including filtering
  //
  urlForRankingPage = rankingPage => `/${_.join(_.compact([rankingPage]), "/")}`

  render() {
    const {
      location
    } = this.props

    return (
      <div className="listing-page">
        <Meta
          name="New Listings"
          title="LittleBirdie.com.au - The Best Price Drops, Vouchers, Sales and Discounts"
          description="Find and Save with the best price drops, vouchers and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more." />
        <div className="container">
          <Breadcrumb breadcrumbs={location} />
        </div>

        <div className="">

          <NewDealsList
            viewAllUrl={this.urlForRankingPage("new-price-drops")}
            wrapperClass="d-flex listing-group-carousel"
            currentSection="Newly Added Price Drops"
            sectionPosition={1}
          />

          <NewSalesEventsList
            viewAllUrl={this.urlForRankingPage("new-sales-events")}
            wrapperClass="d-flex listing-group-carousel"
            currentSection="Newly Added Sales"
            sectionPosition={2}
          />

          <NewCouponsList
            viewAllUrl={this.urlForRankingPage("new-promo-codes")}
            wrapperClass="d-flex listing-group-carousel"
            currentSection="Newly Added Vouchers"
            sectionPosition={3}
          />
          {/* <TopBrandsSidebarList />
          <TopStoresSidebarList />
          <TopDealHuntersSidebarList />
          <TopUpcomingSalesSidebarList /> */}
        </div>
      </div>
    )
  }
}

const mapStateToProps = () => ({
  // isMobile: getIsMobile(state)
})

export default connect(mapStateToProps, {
  loadNewListingPage,
})(NewListingPage)
