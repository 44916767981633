import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { arrowRight } from '../../../components/Icons'
import { searchSetKeyword } from '../../../apps/App/actions'
import positionDataLayer from '../../../components/dataLayers/positionDataLayer'

function DepartmentViewAll({ department }) {
  const dispatch = useDispatch()

  const setActiveView = (event, activeView, position) => {
    if (activeView) {
      window.localStorage.setItem('activeView', activeView || '')
    } else {
      window.localStorage.setItem('activeTab', 'deal')
      const searchMeta = {
        filterKey: 'departmentNames',
        filterValue: _.get(department, 'attributes.name')
      }
      dispatch(searchSetKeyword({ searchTerm: _.get(department, 'attributes.slug'), searchMeta }))
    }

    positionDataLayer(null, 'department-view-all', event, position, 'DepartmentViewAll')
  }

  return (
    <div className="section-group container">
      <div className="row department-view-all-section m-0 text-center">
        <div className="col-12 col-xl-4 m-auto view-all-label">
          <span className="lb-secondary-text"> View all in {_.get(department, 'attributes.name')} </span>
          <img className='d-none d-xl-inline-block' src={arrowRight} />
        </div>
        <div className="col-12 col-xl-8 px-xl-8 view-btn-group first">
          <Link className='btn btn-main rounded-pill' onClick={() => setActiveView('view-all-vouchers', 'voucher', 3)} to={`/shop/${_.get(department, 'attributes.slug')}/view-all?activeTab=voucher`}>
            View all promo codes
          </Link>
          <Link className='btn btn-main rounded-pill' onClick={() => setActiveView('view-all-sale-events', 'sale', 4)} to={`/shop/${_.get(department, 'attributes.slug')}/view-all`}>
            View all sale events
          </Link>
          {/*
          ToDo: Remove Deal: Remove Products and Price Drop Links
          <Link className='btn btn-main rounded-pill' onClick={() => setActiveView('view-all-products', null, 1)} to={`/search/${_.get(department, 'attributes.slug')}`}>
            View all products
          </Link>
          <Link className='btn btn-main rounded-pill' onClick={() => setActiveView('view-all-price-drops', 'deal', 2)} to={`/shop/${_.get(department, 'attributes.slug')}/view-all?activeTab=deal`}>
            View all Price drops
          </Link> */}
        </div>
        {/* <div className="col-12 col-xl-4 view-btn-group second">
        </div> */}
      </div>
    </div>
  )
}

export default DepartmentViewAll
