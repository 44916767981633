import React from 'react'

export default function ForYou () {
  return (
    <div className='d-flex align-items-center justify-content-start for-you'>
      <div className='d-flex heart-icons' />
      <div className='ml-1 text-uppercase'>For You</div>
    </div>
  )
}
