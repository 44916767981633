import Cookies from 'universal-cookie'
import SetCbaCookie from './SetCbaCookie'
import { gon } from '../../constant'

function CheckCbaExclusiveItem (item, paramWtid, currentUser, setCookie, wtidValid) {
  const currentCookies = new Cookies()
  const wtid = currentCookies.get('wtid') || ''

  // Will enter condition only if is_cba_exclusive is true for object(sale_event/voucher)
  if (item.attributes.is_cba_exclusive) {

    //Case 5 (added 8/8/2022): wtid is not valid (not 40 characters)
    if(!wtidValid) window.location = '/'

    // Case 1: wtid, cookie and logged -in customer's wtid not present
    if (!wtid && !paramWtid && !gon?.current_user?.wtid) window.location = '/'

    // Case 2: wtid is present but is invalid and cookie not present
    if (!paramWtid && !wtid) window.location = '/'

    // Case 3: create Cookie when Wtid is present and valid, but cookie is not present
    if (wtidValid) {
      SetCbaCookie(paramWtid, setCookie)
    }
    // Case 4: wtid is not present but cookie is present, remove cookie and go to voucher page
    currentCookies.remove('wtid', {})
  }
  return null
}

export default CheckCbaExclusiveItem
