import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import Loader from "./../Loader"
import { userActivityRequestNextPage } from "../../apps/App/actions"
import { Link } from "react-router-dom"
import { getUserActivityByIdentifier } from "../../apps/App/requesters"
import { getBaseUrl } from "../../apps/App/reducers"
import ActivityRow from "./ActivityRow"
import positionDataLayer from '../../components/dataLayers/positionDataLayer'

const LoadMoreSpinner = (
  <div className="col-12 m-3 d-flex justify-content-center">
    <Loader isLoading />
  </div>
)

function NoMoreDivider({ dataCount }) {
  return (
    <div className="col-12 text-center p-4">
      <div>
        {dataCount === 0 ? "User hasn't done anything yet" : "No More Activity"}
      </div>
    </div>
  )
}

export default function UserActivities({ slug, numberOfActivities, title, currentSection, sectionPosition }) {
  const dispatch = useDispatch()
  const userActivity = useSelector(state => _.get(state, `data.userActivities.bySlug.${slug}`, {}))
  const data = _.get(userActivity, "data", [])
  const totalCount = _.get(userActivity, "totalCount", 0)
  const hasMore = !_.get(userActivity, "lastPage", false)

  const [activity, setActivity] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = useSelector(getBaseUrl)

  useEffect(() => {
    async function fetchData() {
      const { response } = await getUserActivityByIdentifier({
        baseUrl, identifier: slug, page: 1, per_page: 5
      })
      if (response.userActivity) {
        setActivity(_.map(Object.values(response.userActivity)).reverse())
      }
      setIsLoading(false)
    }
    if (numberOfActivities) fetchData()
  }, [])

  return (
    <div className="section-group">
      <div className="container">
        <div className="listing-group-header">
          <div className="d-flex align-items-bottom justify-content-between">
            {
              title
                ? <h2>{title}</h2>
                : (
                  <h2>
                    {slug}
                    &apos;s Activity
                  </h2>
                  )
            }
            <Link
              to={`/spotter/${slug}/my-activity`}
              onClick={() => positionDataLayer(null, 'view-all', 'view-all-link', null, currentSection, sectionPosition)}
              className='btn btn-outline-dark rounded-pill'
            >
              View all
            </Link>
          </div>
        </div>
        {
          numberOfActivities
          ? (
            <>
              <div className="card card-activity mt-3">
                <div className="card-body p-0">
                  {isLoading && <Loader isLoading={isLoading} /> }
                  {
                    !_.isEmpty(activity) && (
                      activity.slice(0, numberOfActivities).map(item => {
                        if (item && _.get(item, "relationships.owner.data") && _.get(item, "relationships.recipient.data")) {
                          return <ActivityRow key={`${item.type}-${item.id}`} item={item} />
                        }
                        return null
                      })
                    )
                  }
                </div>
              </div>
            </>
            )
          : (
            <div className="card card-activity mt-3">
              <div className="card-body p-0">

                <InfiniteScroll
                  className="card-body p-0"
                  dataLength={data.length}
                  next={() => { dispatch(userActivityRequestNextPage({ identifier: slug })) }}
                  hasMore={hasMore}
                  loader={LoadMoreSpinner}
                  endMessage={<NoMoreDivider dataCount={totalCount} />}>
                  {data.map(item => {
                    if (item && _.get(item, "relationships.owner.data") && _.get(item, "relationships.recipient.data")) {
                      return <ActivityRow key={`${item.type}-${item.id}`} item={item} />
                    }
                    return null
                  })}
                </InfiniteScroll>

              </div>
            </div>
            )
        }
      </div>
    </div>
  )
}
