import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import swal from 'sweetalert'

import {
  crossIcon
} from '../../../../../components/Icons'

import Loader from '../../../../../components/Loader'

import ItemIcon from './ItemIcon'

import { getBaseUrl } from '../../../reducers'
import { getTopBrands } from '../../../requesters'
import onboardingFlowDataLayer from '../../../../../components/dataLayers/onboardingFlowDataLayer'

export default function BrandSelect ({
  hideModal, handleNext, handleBack,
  activeDepartments, followedBrands,
  selectBrand, progressBar, hidePopup, handleSkip
}) {
  const [brands, setBrands] = useState([])
  const [nagCount, setNagCount] = useState(0)
  const baseUrl = useSelector(getBaseUrl)

  useEffect(() => {
    onboardingFlowDataLayer('onboarding-flow-view', null, 'brands', 4)
  }, [])

  useEffect(() => {
    async function fetchBrands (department, perPage) {
      const params = {
        per_page: perPage,
        by_department: department
      }

      const { response } = await getTopBrands(baseUrl, params)
      if (_.get(response, 'data.brand')) {
        response.ids.forEach(index => setBrands(oldBrands => [...oldBrands, response.data.brand[index]]))
      }
    }

    if (activeDepartments.length === 0) {
      fetchBrands(null, 50)
    } else {
      activeDepartments.forEach(department => fetchBrands(department, Math.ceil(100 / activeDepartments.length)))
    }
  }, [activeDepartments])

  function handleNagNext () {
    if (nagCount < 1 && followedBrands.length === 0) {
      swal({
        title: 'Selecting at least one allows us to bring you more of what you love',
        button: 'OK'
      })
      setNagCount(nagCount + 1)
    } else {
      handleNext()
    }
  }

  const activeBrands = []

  return (
    <>
      <Modal.Header className='d-flex flex-column border-0 pb-0'>
        <div className='align-self-end'>
          <button type='button' className='close' onClick={hideModal}>
            <img src={crossIcon} />
          </button>
        </div>
        <div className='header d-flex justify-content-between w-100'>
          <h3>Brands</h3>
          <div className='label-skip'>
          <span onClick={handleSkip} >Skip</span>
          </div>
        </div>
        <div className='subtitle'>Follow to see the hottest price drops, sale events and voucher codes.</div>
        <div className='w-100'>
          {progressBar}
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='favourites-stores'>
          <div className='row'>
            {brands.length === 0
              ? <Loader isLoading />
              : brands.map(brand => {
                if (!activeBrands.includes(brand.id)) {
                  activeBrands.push(brand.id)
                  return <ItemIcon key={`brndicn-${brand.id}`} item={brand} onClick={id => selectBrand(id)} />
                }
                return null
              })}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='mx-auto'>
          <button
            type='button'
            className='btn btn-outline-dark rounded-pill mx-2'
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type='button'
            className='btn btn-main rounded-pill mx-2'
            onClick={() => handleNagNext()}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </>
  )
}
