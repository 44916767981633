import _ from 'lodash'
import React, { useRef } from 'react'

import { paymentOptions } from '../checkboxOptions'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function PaymentOptions ({ handleSelect, handleClearClick, selectedPayments, handleFilterPill, sectionPosition }) {
  const accordionHeader = useRef(null)

  const paymentSectionPostion = window.location?.pathname?.includes('/search/') ? 8 : sectionPosition
  useAccordionOpenHeader(accordionHeader, paymentSectionPostion, 'Payment Method')
  const getPaymentLabel = (key) => {
    return (_.find(paymentOptions.values, (payment) => payment.value === key))?.label
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingPayment'>
        <div ref={accordionHeader} aria-controls='collapsePayment' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapsePayment' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Payment Method</div>
            {selectedPayments?.length > 0 && <span className='selected-filter-preview'>{selectedPayments?.map((payment) => getPaymentLabel(payment))?.join(', ')}</span>}
            {selectedPayments?.length > 0 &&
              <button
                type='button'
                name='paymentMethods'
                onClick={e => handleClearClick(e)}
                className='btn btn-clear ml-auto mr-2'
              >
                Clear selection
              </button>}
          </div>
        </div>
      </div>
      <div aria-labelledby='headingPayment' className='collapse' id='collapsePayment'>
        {
          selectedPayments?.length > 0 &&
            <div className='filter-pills-container'>
              {selectedPayments.map((payment, index) => (
                <div
                  key={index}
                  type='button'
                  onClick={() => handleFilterPill('paymentMethods', payment)}
                  className='filter-pill ml-auto mr-2'
                >
                  {getPaymentLabel(payment)}
                </div>
              ))}
            </div>
        }
        <div className='p-3'>
          <div className='checklist-group'>
            {_.filter(paymentOptions.values, sPaymentOption => !selectedPayments.includes(sPaymentOption.value))?.map(payment => (
              <div key={`payment-${payment.key}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`paymentCheck${payment.key}`}
                  type='checkbox'
                  checked={selectedPayments.includes(payment.value)}
                  name='paymentMethods'
                  value={payment.value}
                  onChange={e => handleSelect(e)}
                />
                <label className='custom-control-label' htmlFor={`paymentCheck${payment.key}`}>
                  {payment.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
