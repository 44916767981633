export default {
  ReferenceError: {
    title: 'Seems like something broke out code',
    message: 'We are working on it. And the feature will be back soon'
  },
  EvalError: {
    title: '',
    message: ''
  },
  RangeError: {
    title: '',
    message: ''
  },
  SyntaxError: {
    title: '',
    message: ''
  },
  TypeError: {
    title: '',
    message: ''
  },
  URIError: {
    title: '',
    message: ''
  }
}
