{/* ToDo: Remove Deal: Remove Deal Tab from View all Page */}

import React, { Component } from 'react'
import { connect } from 'react-redux'

import Meta from '../../../../../components/Meta'
// import DealsTab from './DealsTab'
import VouchersTab from './VouchersTab'
import SaleEventsTab from './SaleEventsTab'
import SortSelect from '../RankingPage/SortSelect'
import {
  // dealOptions as options,
  couponOptions as voucherOptions,
  saleEventOptions
} from '../RankingPage/sortOptions'
import { DesktopFilter, MobileFilter } from '../../Filter'
import { getDepartmentBySlug, getCategoryBySlug, getSubcategoryBySlug } from '../../../reducers'
import filterDataLayer from '../../../../../components/dataLayers/filterDataLayer'
import { debounce } from '../Common/helpers'
import { loadDepartmentCategoryListPage } from '../../../actions'
import ErrorBoundries from '../../../../../components/ErrorBoundry'
import { oneColumnIcon, twoColumnIcon,oneColumnIconInactive, twoColumnIconInactive } from '../../../../../components/Icons'

const filters = {
  voucher: {
    showBrand: true,
    showStore: true,
    showDepartment: true,
    showCategory: true,
    showPaymentMethods: true,
    showCouponTypes: true,
    options: voucherOptions
  },
  sale: {
    showBrand: true,
    showStore: true,
    showDepartment: true,
    showCategory: true,
    showStartDate: true,
    showPaymentMethods: true,
    options: saleEventOptions
  },
  // deal: {
  //   showElasticBrand: true,
  //   showElasticStore: true,
  //   showElasticDepartments: true,
  //   showPriceRange: true,
  //   showDiscountRange: true,
  //   showPaymentMethods: true,
  //   showFreshness: true,
  //   options: options
  // }
}

class DepartmentCategoryPage extends Component {
  constructor(props) {
    super(props)
    const { location: { search } } = props
    const searchParam = new URLSearchParams(search)
    const activeTabParam = filters.hasOwnProperty(searchParam.get('activeTab')) ? searchParam?.get('activeTab'): ''
    let storageActiveTab = window.localStorage.getItem('activeView')

    if(!filters.hasOwnProperty(storageActiveTab)) {
      window.localStorage.removeItem('activeView')
      storageActiveTab = null
    }

    this.state = {
      key: 1,
      prevScrollPos: 0,
      filterSticky: false,
      tabSticky: false,
      isLoading: false,
      // isDealEnabled: false,
      isSaleEnabled: false,
      isVoucherEnabled: false,
      isFilter: false,
      // dealOrderBy: {
      //   value: 'fixed_department_score',
      //   order: 'desc',
      //   key: null
      // },
      saleOrderBy: {
        value: null,
        order: null,
        key: null
      },
      voucherOrderBy: {
        value: null,
        order: null,
        key: null
      },
      searchMinMaxPrice: [],
      // dealOptions: this.getStoredFilterOptions(),
      voucherOptions: {
        storeIds: [],
        brandIds: [],
        departmentIds: [],
        categoryIds: [],
        subcategoryIds: [],
        paymentMethods: [],
        couponTypes: []
      },
      saleOptions: {
        storeIds: [],
        brandIds: [],
        departmentIds: [],
        categoryIds: [],
        subcategoryIds: [],
        paymentMethods: [],
        startRange: []
      },
      totalResultsCount: null,
      selectedSearchTab: storageActiveTab || activeTabParam || 'sale',
      // dealCount: 0,
      saleCount: 0,
      voucherCount: 0,
      isFinished: true,
      isReady: false,
      mbColumn: 'twoColumn'
    }
  }

  componentDidUpdate(prevProps, preState) {
    const { match: { params: prev_params } } = prevProps
    const { match: { params } } = this.props

    // If either department/category or subcategory changes we go refresh the data
    if (!_.isEqual(params, prev_params)) {
      this.refreshPage()
    }

    if (params.department && !this.props.department) return
    if (params.category && !this.props.category) return
    if (params.subcategory && !this.props.subcategory) return

    if (!this.state.isReady) {
      const commonOption = {}

      if (this.props.department) {
        commonOption['departmentNames'] = [_.get(this.props.department, 'attributes.name')]
        commonOption['departmentIds'] = [_.get(this.props.department, 'id')]
      }

      if (this.props.category) {
        commonOption['categoryNames'] = [_.get(this.props.category, 'attributes.name')]
        commonOption['categoryIds'] = [_.get(this.props.category, 'id')]
      }

      if (this.props.subcategory) {
        commonOption['subcategoryNames'] = [_.get(this.props.subcategory, 'attributes.name')]
        commonOption['subcategoryIds'] = [_.get(this.props.subcategory, 'id')]
      }

      const updateObj = {
        isReady: true,
        // dealOptions: {
        //   ...this.state.dealOptions,
        //   ...commonOption
        // },
        voucherOptions: {
          ...this.state.voucherOptions,
          ...commonOption
        },
        saleOptions: {
          ...this.state.saleOptions,
          ...commonOption
        }
      }

      this.setState(updateObj)
    }
  }

  refreshPage = (overrideState) => {
    this.setState(this.getInitialState(overrideState))
  }

  // getStoredFilterOptions = () => {
  //   const dealOptions = {
  //     brandNames: [],
  //     storeNames: [],
  //     departmentNames: [],
  //     categoryNames: [],
  //     subcategoryNames: [],
  //     priceRange: [],
  //     discountRange: [],
  //     paymentMethods: [],
  //     lastPriceRange: []
  //   }

  //   const { options, isAvailable } = this.getStoredFilters()

  //   if (!options.check) {

  //     if (options.storeNames) {
  //       dealOptions.storeNames = options.storeNames
  //     }

  //     if (options.brandNames) {
  //       dealOptions.brandNames = options.brandNames
  //     }

  //     if (options.priceRange) {
  //       dealOptions.priceRange = options.priceRange || []
  //     }

  //     if (options.discountRange) {
  //       dealOptions.discountRange = options.discountRange || []
  //     }

  //     if (options.lastPriceRange) {
  //       dealOptions.lastPriceRange = options.lastPriceRange || [];
  //     }

  //     if (options.departmentNames) {
  //       dealOptions.departmentNames = options.departmentNames || [];
  //     }

  //     if (options.categoryNames) {
  //       dealOptions.categoryNames = options.categoryNames || [];
  //     }

  //     if (options.subcategoryNames) {
  //       dealOptions.subcategoryNames = options.subcategoryNames || []
  //     }
  //   }

  //   return dealOptions
  // }

  getStoredFilters = () => {
    const getStoredOptions = JSON.parse(window.localStorage.getItem("department_page_product_filter") || '{}')
    const options = Object.keys(getStoredOptions)
    return {
      options: getStoredOptions,
      isAvailable: options.length
    }
  }

  removeStoreOptions = () => {
    const getPage = JSON.parse(window.localStorage.getItem('department_page_product_filter') || '{}')
    getPage.check && window.localStorage.removeItem('department_page_product_filter')
  }

  getInitialState = (overrideState = {}) => {
    // const getDealOptions = this.getStoredFilterOptions()

    const initialState = {
      searchMinMaxPrice: [],
      sLoading: false,
      // dealOrderBy: {
      //   value: null,
      //   order: null,
      //   key: null
      // },
      saleOrderBy: {
        value: null,
        order: null,
        key: null
      },
      voucherOrderBy: {
        value: null,
        order: null,
        key: null
      },
      // dealOptions: getDealOptions,
      voucherOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      saleOptions: {
        brandNames: [],
        storeNames: [],
        departmentNames: [],
        categoryNames: [],
        subcategoryNames: [],
        priceRange: [],
        discountRange: [],
        paymentMethods: [],
        lastPriceRange: []
      },
      totalResultsCount: null
    }

    return _.extend(initialState, overrideState)
  }

  handleSort = (event) => {
    const { selectedSearchTab } = this.state
    let sortOptions = filters[selectedSearchTab].options
    const sortByKey = `${selectedSearchTab}OrderBy`

    const selected = sortOptions.values.find(
      (value) => value.key === parseInt(event.currentTarget.value, 10)
    )

    const newState = {}
    newState[sortByKey] = {
      value: selected.value,
      order: selected.order,
      key: selected.key
    }

    this.setState(newState)
  }

  handleFilter = (e, sectionPosition) => {
    const { name, value } = e
    const { selectedSearchTab } = this.state
    const key = `${selectedSearchTab}Options`
    const filterOption = this.state[key]
    if (value.constructor === Array) {
      filterOption[name] = filterOption[name] === value ? [] : value
    } else {
      filterOption[name] = filterOption[name].includes(value)
        ? filterOption[name].filter((e) => e !== value)
        : (filterOption[name] = [...filterOption[name], value])
    }
    const updateObj = {}
    updateObj[key] = filterOption
    updateObj.isFilter = true
    this.setState(updateObj)
  }

  handleFilterClear = (name) => {
    const { selectedSearchTab } = this.state
    const key = `${selectedSearchTab}Options`
    let filterOptions = this.state[key]
    filterOptions[name] = []
    const updateObj = {}
    updateObj[key] = filterOptions
    this.setState(updateObj)
  }

  isClearAllBtnDisplay = () => {
    const {
      brandNames,
      storeNames,
      departmentNames,
      categoryNames,
      subcategoryNames,
      priceRange,
      discountRange,
      paymentMethods,
      lastPriceRange,
      brandIds,
      storeIds,
      categoryIds,
      subcategoryIds,
      departmentIds,
      startRange,
      couponTypes
    } = this.state[`${this.state.selectedSearchTab}Options`]
    if (
      brandNames?.length ||
      storeNames?.length ||
      departmentNames?.length ||
      categoryNames?.length ||
      subcategoryNames?.length ||
      priceRange?.length ||
      discountRange?.length ||
      paymentMethods?.length ||
      lastPriceRange?.length ||
      brandIds?.length ||
      categoryIds?.length ||
      departmentIds?.length ||
      subcategoryIds?.length ||
      storeIds?.length ||
      startRange?.length ||
      couponTypes?.length
    )
      return true
    return false
  }

  componentDidMount() {
    const {
      match: {
        params: {
          subcategory
        }
      }
    } = this.props

    if (subcategory) {
      this.props.loadDepartmentCategoryListPage({
        subcategory
      })
    }

    window.addEventListener('scroll', this.handleScroll)

    window.localStorage.removeItem('activeView')

    const { isAvailable, options } = this.getStoredFilters()
    isAvailable && window.localStorage.setItem("department_page_product_filter", JSON.stringify({ ...options, check: true }))
  }

  componentWillUnmount() {
    this.handleTabStore(this.state.selectedSearchTab)
    this.removeStoreOptions()
  }

  //For Filter and sort sticky in mobile view
  handleScroll = debounce(() => {
    const { prevScrollPos } = this.state
    const currentScrollPos = window.pageYOffset
    this.setState({ filterSticky: prevScrollPos > currentScrollPos, prevScrollPos: currentScrollPos, tabSticky: prevScrollPos > currentScrollPos })
    if (currentScrollPos < 500) this.setState({ filterSticky: false, tabSticky: false })
  }, 10)

  handleFilterClearAll = (e) => {
    const { selectedSearchTab } = this.state
    const { order, value, key } = this.state[`${selectedSearchTab}OrderBy`]

    const {
      match: {
        params: {
          department, category, subcategory
        }
      }
    } = this.props

    const newState = {}
    newState[`${selectedSearchTab}OrderBy`] = {
      value, order, key
    }

    newState[`${selectedSearchTab}Options`] = {
      brandNames: [],
      storeNames: [],
      departmentNames: [],
      categoryNames: [],
      subcategoryNames: [],
      priceRange: [],
      discountRange: [],
      paymentMethods: [],
      lastPriceRange: [],
      storeIds: [],
      brandIds: [],
      departmentIds: [],
      categoryIds: [],
      subcategoryIds: [],
      paymentMethods: [],
      startRange: [],
      couponTypes: []
    }

    this.setState(newState)

    const itemData = {
      listType: 'Category or Department View All Page',
      department,
      category,
      subcategory,
      sort: this.state?.by_order?.column || value
    }

    filterDataLayer(itemData, 'clear-all', 1, 'Category or Department View All Page', null)
  }

  setAvailableFilters = () => {
    return filters[this.state.selectedSearchTab]
  }

  handleTabStore = (value) => {
    window.localStorage.setItem('activeView', value)
  }

  handleSearchFilterDataLayer = (itemsCount, respType) => {
    const {
      key,
      by_order,
      selectedSearchTab
    } = this.state

    const {
      match: {
        params: {
          department, category, subcategory
        }
      }
    } = this.props

    const itemData = {
      listType: 'Category or Department View All Page',
      department,
      category,
      subcategory,
      sort: by_order?.column
    }

    // if (selectedSearchTab === 'deal' && respType === 'deal') {
    //   // add deal specific options
    //   Object.keys(this.state.dealOptions)?.forEach(key => itemData[key] = this.state.dealOptions[key])
    //   itemData.searchCount = Number(itemsCount || 0)
    //   const option = options?.values?.find(opt => opt.key === parseInt(key, 10))
    //   if (option) {
    //     itemData.sort = option.text
    //   }
    //   filterDataLayer(itemData, 'select-filter', null, null, null)
    // } else 
    if (selectedSearchTab === 'sale' && respType === 'sale') {
      // add sale events specific options
      Object.keys(this.state.saleOptions)?.forEach(key => itemData[key] = this.state.saleOptions[key])
      itemData.searchCount = itemsCount
      const option = saleEventOptions?.values?.find(opt => opt.key === parseInt(key, 10))
      if (option) {
        itemData.sort = option.text
      }
      filterDataLayer(itemData, 'select-filter', null, null, null)
    } else if (selectedSearchTab === 'voucher' && respType === 'voucher') {
      // add voucher specific options
      Object.keys(this.state.voucherOptions)?.forEach(key => itemData[key] = this.state.voucherOptions[key])
      itemData.searchCount = itemsCount
      const option = voucherOptions?.values?.find(opt => opt.key === parseInt(key, 10))
      if (option) {
        itemData.sort = option?.text
      }
      filterDataLayer(itemData, 'select-filter', null, null, null)
    }
  }

  render() {
    const {
      key,
      searchMinMaxPrice,
      filterSticky,
      tabSticky,
      selectedSearchTab,
      // dealCount,
      saleCount,
      voucherCount,
      // dealOptions,
      voucherOptions,
      saleOptions,
      // dealOrderBy,
      saleOrderBy,
      voucherOrderBy,
      isFilter,
      isReady,
      // isDealEnabled,
      isSaleEnabled,
      isVoucherEnabled,
      mbColumn
    } = this.state

    const {
      department,
      category,
      match: {
        params: {
          department: departmentParam, category: categoryParam, subcategory: subcategoryParam,
        }
      }
    } = this.props

    const sortByKey = `${selectedSearchTab}OrderBy`

    const tabBody = [
      {
        id: 'sale',
        options: saleOptions,
        title: 'Sale Events',
        count: saleCount,
        Ele: SaleEventsTab,
        isSelected: selectedSearchTab === 'sale',
        orderBy: saleOrderBy,
        isEnabled: isSaleEnabled
      },
      // {
      //   id: 'deal',
      //   options: dealOptions,
      //   title: 'Products',
      //   count: dealCount,
      //   Ele: DealsTab,
      //   isSelected: selectedSearchTab === 'deal',
      //   orderBy: dealOrderBy,
      //   isEnabled: isDealEnabled
      // },
      {
        id: 'voucher',
        options: voucherOptions,
        title: 'Promo Codes',
        Ele: VouchersTab,
        count: voucherCount,
        isSelected: selectedSearchTab === 'voucher',
        orderBy: voucherOrderBy,
        isEnabled: isVoucherEnabled
      }
    ]

    return (
      <div className='container search-page'>
        <ErrorBoundries>
          <div
            className={`search-tab-cotainer ${tabSticky ? 'tab-sticky' : 'fixed-tab'}`}>
            {tabBody.map(
              (item, index) =>
                (item.isEnabled && (
                  <span
                    key={`${item.id}-${index}`}
                    onClick={() => this.setState({ selectedSearchTab: item.id })}
                    className={`tab-pill ${selectedSearchTab === item.id && 'selected'
                      }`}>
                    {item.title} ({item.count})
                  </span>
                )) || ''
            )}
          </div>
        </ErrorBoundries>

        <div className='d-flex justify-content-between ranking-page-header mb-3'>
          {/* selectedSearchTab==='deal' && 'col-9' Below Line*/}
          <h1 className={`results-title m-0 p-0`}>
            {_.get(category ? category : department, "attributes.name")}
          </h1>
           {/* Mobile two and one column wide - icons */}
           {/* {
              selectedSearchTab === 'deal' &&
              <div className="d-block d-sm-none">
                <img src={mbColumn ==='oneColumn' ? twoColumnIconInactive: twoColumnIcon} className="mb-column-icon" onClick={()=>this.setState({ mbColumn : 'twoColumn' })} />
                <img src={mbColumn ==='twoColumn' ? oneColumnIconInactive: oneColumnIcon} onClick={()=>this.setState({ mbColumn : 'oneColumn' })} />
            </div>
            } */}
            {/* ends here */}
        </div>

        <div className='row main-wrapper'>
          <Meta
            name='Category or Department View All Page'
            description={`Find the best price on ${subcategoryParam || categoryParam ? `${subcategoryParam || ''} ${category || ''} ` : `${departmentParam || ''} `} only at Little Birdie`}
            title={`Search Results for ${subcategoryParam || categoryParam ? `${subcategoryParam || ''} ${categoryParam || ''} ` : `${departmentParam || ''} `} | Little Birdie`}
            og_title={`Little Birdie | See all results for ${subcategoryParam || categoryParam ? `${subcategoryParam || ''} ${categoryParam || ''} ` : `${departmentParam || ''} `}`}
          />
          {this.state[`${selectedSearchTab}Options`] && (
            <ErrorBoundries>
              <div
                className={`col-xl-3 d-none sidebar d-xl-block`}>
                <DesktopFilter
                  isClearAllDisplay={this.isClearAllBtnDisplay()}
                  handleClear={(e) => this.handleFilterClear(e.target?.name)}
                  handleChange={(e, sectionPosition) =>
                    this.handleFilter(e, sectionPosition)
                  }
                  handleClearAll={this.handleFilterClearAll}
                  selectedItems={this.state[`${selectedSearchTab}Options`]}
                  searchMinMaxPrice={searchMinMaxPrice}
                  availableFilters={this.setAvailableFilters()}
                  setDepartment={departmentParam}
                  setCategory={categoryParam}
                  setSubcategory={subcategoryParam}
                  isReady={isReady}
                  voucherParam={
                    selectedSearchTab === 'voucher'
                      ? 'voucher_search'
                      : selectedSearchTab === 'sale'
                        ? 'sale_event_search'
                        : ''
                  }

                />
              </div>
            </ErrorBoundries>
          )}
          <div className={`col-xl-9 order-1 order-xl-2 ml-auto main-content`}>
            <section className='pt-1'>
              <div
                className={`fixedFilter mb-4 ${filterSticky ? 'filterSticky search-tab' : ''
                  }`}
                id='mobileFilter'>
                <div className='d-xl-none mobile-filter'>
                  <ErrorBoundries>
                    <MobileFilter
                      isClearAllDisplay={this.isClearAllBtnDisplay()}
                      handleClear={(e) =>
                        this.handleFilterClear(e.target?.name)
                      }
                      handleChange={(e, sectionPosition) =>
                        this.handleFilter(e, sectionPosition)
                      }
                      handleClearAll={this.handleFilterClearAll}
                      handleSort={(e) => this.handleSort(e)}
                      defaultValue={key}
                      selectedItems={this.state[`${selectedSearchTab}Options`]}
                      searchMinMaxPrice={searchMinMaxPrice}
                      availableFilters={this.setAvailableFilters()}
                      setDepartment={departmentParam}
                      setCategory={categoryParam}
                      setSubcategory={subcategoryParam}
                      selectedOrder={{ column: this.state[sortByKey]?.value, direction: this.state[sortByKey]?.order }}
                      isReady={isReady}
                      voucherParam={
                        selectedSearchTab === 'voucher'
                          ? 'voucher_search'
                          : selectedSearchTab === 'sale'
                            ? 'sale_event_search'
                            : ''
                      }
                    />
                  </ErrorBoundries>
                </div>
                <ErrorBoundries>
                  <SortSelect
                    byOrder={{
                      column: 'fixed_global_score',
                      direction: 'desc'
                    }}
                    handleSort={(e) => this.handleSort(e)}
                    options={this.setAvailableFilters()?.options}
                    currentSelectedKey={this.state[sortByKey].key}
                  />
                </ErrorBoundries>
              </div>
              {isReady && tabBody.map((item, index) => {
                const TabComponent = item.Ele
                return (
                    <div
                      key={`${item.id}-${index}`}
                      className={`${selectedSearchTab === item.id ? 'd-block' : 'd-none'
                        }`}>
                      {item.data ? (
                        <TabComponent id={item.id} items={item.data} isLoading={this.state.isLoading} />
                      ) : (
                        <TabComponent
                          id={item.id}
                          isFilter={isFilter}
                          filterOptions={item.options}
                          order={item.orderBy?.order}
                          value={item.orderBy?.value}
                          isEnabled={item.isShown}
                          handleStateUpdate={(data) => this.setState({ ...data })}
                          handleSearchFilterDataLayer={(data, respType) => this.handleSearchFilterDataLayer(data, respType)}
                          mbColumn={mbColumn}
                        />
                      )}
                    </div>
                )
              })}
            </section>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state, ownProps) => ({
  department: getDepartmentBySlug(state, ownProps.match.params.department),
  category: getCategoryBySlug(state, ownProps.match.params.category),
  subcategory: getSubcategoryBySlug(state, ownProps.match.params.subcategory),
})

export default connect(mapStateToProps, { loadDepartmentCategoryListPage })(DepartmentCategoryPage)
