import React, { Component } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import FollowButton from '../../../../App/components/buttons/FollowButton'

import {
  socialFacebook,
  storeBloggerIcon,
  socialInstagram,
  storeTiktokIcon,
  socialTwitter,
  socialYoutube
} from '../../../../../components/Icons'

class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      imageErrored: false
    }

    this.handleImageError = this.handleImageError.bind(this)
  }

  handleImageError () {
    this.setState({ imageErrored: true })
  }

  renderCounts (count) {
    let modifiedCountValue = count
    if (/^[0-9]*$/.test(count)) modifiedCountValue = count > 0 ? count : ''
    return modifiedCountValue
  }

  render () {
    const {
      brand,
      brand: {
        attributes,
        attributes: {
          socialFacebookUrl,
          socialInstagramUrl,
          socialTwitterUrl,
          socialTiktokUrl,
          socialYoutubeUrl,
          socialBlogUrl
        },
        links
      }
    } = this.props

    const {
      imageErrored
    } = this.state

    // const checkSocialIconUrl = attributes => (attributes.socialFacebookUrl || attributes.socialInstagramUrl || attributes.socialTwitterUrl || attributes.socialTiktokUrl || attributes.socialYoutubeUrl || attributes.socialBlogUrl)

    return (
      <section className='section-group'>
        <div className='store-brand-summary d-flex'>
          <div className='listing-brand-store'>
            <div className='wrapper'>
              <LazyLoadImage
                effect='opacity'
                className='profile-img-lg rounded-circle profile-img'
                alt={attributes.name}
                src={links.logoUrl}
              />
              <FollowButton item={brand} buttonClasses='btn btn-follow' />
            </div>
          </div>
          <div className='store-summary'>
            <h1>{attributes.name}</h1>
            <div className='store-stats d-flex'>
              {
                this.renderCounts(attributes.followersCount) &&
                  <>
                    <span>
                      {attributes.followersCount}&nbsp;Followers
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              }
              {/* ToDo: Remove Deal: Remove Products Counts */}
              {/* {
                this.renderCounts(attributes.productsCount) &&
                  <>
                    <span>
                      {attributes.productsCount}&nbsp;Products
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              } */}
              {/* ToDo: Remove Deal: Remove Price Drops Counts */}
              {/* {
                this.renderCounts(attributes.dealsCount) &&
                  <>
                    <span>
                      {attributes.dealsCount}&nbsp;Price Drops
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              } */}
              {
                this.renderCounts(attributes.couponsCount) &&
                  <>
                    <span>
                      {attributes.couponsCount}&nbsp;Vouchers
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              }
              {
                this.renderCounts(attributes.saleEventsCount) &&
                  <span>
                    {attributes.saleEventsCount}&nbsp;Sales
                  </span>
              }
            </div>
            {/* <p className='store-description'>
              <CustomDescription customDescription={attributes.customDescription} />
            </p> */}

            <div className='d-md-flex'>
              <div className='d-flex mb-4 mb-lg-3 mb-xl-0'>
                {(socialFacebookUrl || socialInstagramUrl || socialTwitterUrl || socialTiktokUrl || socialYoutubeUrl || socialBlogUrl) && (
                  <>
                    <h5 className='mr-3'>Social</h5>
                    <ul className='list-inline social-icons mb-0'>
                      {socialFacebookUrl && <li className='list-inline-item'><span><a href={socialFacebookUrl} target='_blank' rel='noreferrer'><img src={socialFacebook} /></a></span></li>}
                      {socialInstagramUrl && <li className='list-inline-item'><span><a href={socialInstagramUrl} target='_blank' rel='noreferrer'><img src={socialInstagram} /></a></span></li>}
                      {socialTwitterUrl && <li className='list-inline-item'><span><a href={socialTwitterUrl} target='_blank' rel='noreferrer'><img src={socialTwitter} /></a></span></li>}
                      {socialTiktokUrl && <li className='list-inline-item'><span><a href={socialTiktokUrl} target='_blank' rel='noreferrer'><img src={storeTiktokIcon} /></a></span></li>}
                      {socialYoutubeUrl && <li className='list-inline-item'><span><a href={socialYoutubeUrl} target='_blank' rel='noreferrer'><img src={socialYoutube} /></a></span></li>}
                      {socialBlogUrl && <li className='list-inline-item'><span><a href={socialBlogUrl} target='_blank' rel='noreferrer'><img src={storeBloggerIcon} /></a></span></li>}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {(attributes.headerImage && !imageErrored) ? <hr /> : ''}

        {(attributes.headerImage && !imageErrored) ? <div><img src={attributes.headerImage} onError={this.handleImageError} alt={attributes.name} width='100%' height='auto' /></div> : ''}
      </section>
    )
  }
}

export default Header
