/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
import React, { Component } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import { amexPaymentIcon, aliPaymentIcon, afterpayPaymentIcon, applePaymentIcon, gPaymentIcon, hummPaymentIcon, mastercardPaymentIcon, paypalPaymentIcon, payrightPaymentIcon, visaPaymentIcon, zipPaymentIcon, socialFacebook, storeBloggerIcon, socialInstagram, storeTiktokIcon, socialTwitter, socialYoutube, klarnaPaymentIcon, latitudePaymentIcon, openpayPaymentIcon, trustPilotIconPng } from '../../../../../components/Icons'

import FollowButton from '../../../../App/components/buttons/FollowButton'
import StarRatings from 'react-star-ratings';

class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      imageErrored: false
    }

    this.handleImageError = this.handleImageError.bind(this)
  }

  handleImageError () {
    this.setState({ imageErrored: true })
  }

  renderSwitchPayment (value) {
    switch (value) {
      case 'alipay':
        return <img src={aliPaymentIcon} />
      case 'american_express':
      case 'american express':
        return <img src={amexPaymentIcon} />
      case 'latitude_pay':
      case 'latitude pay':
        return <img src={latitudePaymentIcon} />
      case 'master_card':
      case 'master card':
        return <img src={mastercardPaymentIcon} />
      case 'open_pay':
      case 'open pay':
        return <img src={openpayPaymentIcon} />
      case 'visa':
        return <img src={visaPaymentIcon} />
      case 'paypal':
        return <img src={paypalPaymentIcon} />
      case 'afterpay':
        return <img src={afterpayPaymentIcon} />
      case 'zip pay':
      case 'zip_pay':
        return <img src={zipPaymentIcon} />
      case 'payright':
        return <img src={payrightPaymentIcon} />
      case 'klarna':
        return <img src={klarnaPaymentIcon} />
      case 'google_pay':
      case 'google pay':
        return <img src={gPaymentIcon} />
      case 'apple pay':
      case 'apple_pay':
        return <img src={applePaymentIcon} />
      case 'humm':
        return <img src={hummPaymentIcon} />
      default:
        return ''
    }
  }

  renderCounts (count) {
    let modifiedCountValue = count
    if (/^[0-9]*$/.test(count)) modifiedCountValue = count > 0 ? count : ''
    return modifiedCountValue
  }

  render () {
    const {
      store,
      store: {
        attributes,
        links
      }
    } = this.props

    const {
      imageErrored
    } = this.state

    const storeRating = parseFloat(attributes?.storeRating) || 0
    const storeReviews = parseFloat(attributes?.storeReviews) || 0

    return (
      <section className='section-group'>
        <div className='store-brand-summary d-flex'>
          <div className='listing-brand-store'>
            <div className='wrapper'>
              <LazyLoadImage
                effect='opacity'
                className='profile-img-lg rounded-circle profile-img'
                alt={attributes.name}
                src={links.logoUrl}
              />
              <FollowButton item={store} buttonClasses='btn btn-follow' />
            </div>
          </div>
          <div className='store-summary'>
            <h1>{attributes.name}</h1>
            <div className='store-stats d-flex'>
              {
                this.renderCounts(attributes.followersCount) &&
                  <>
                    <span>
                      {attributes.followersCount}&nbsp;Followers
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              }
              {/* ToDo: Remove Deal: Remove Products Counts */}
              {/* {
                this.renderCounts(attributes.productsCount) &&
                  <>
                    <span>
                      {attributes.productsCount}&nbsp;Products
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              } */}
              {/* ToDo: Remove Deal: Remove Price Drops Counts */}
              {/* {
                this.renderCounts(attributes.dealsCount) &&
                  <>
                    <span>
                      {attributes.dealsCount}&nbsp;Price Drops
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              } */}
              {
                this.renderCounts(attributes.couponsCount) &&
                  <>
                    <span>
                      {attributes.couponsCount}&nbsp;Vouchers
                    </span>
                    <span className='dash mx-2'>-</span>
                  </>
              }
              {
                this.renderCounts(attributes.saleEventsCount) &&
                  <span>
                    {attributes.saleEventsCount}&nbsp;Sales
                  </span>
              }
            </div>
            {/* {attributes.customDescription && (
            <p className='store-description'>
              <CustomDescription customDescription={attributes.customDescription} />
            </p>
                      )} */}
            <div className='d-xl-flex store-social-payment'>
              {(attributes?.socialFacebookUrl || attributes?.socialInstagramUrl || attributes?.socialTwitterUrl || attributes?.socialTiktokUrl || attributes?.socialYoutubeUrl || attributes?.socialBlogUrl) && (
                <>
                  <div className='d-flex mb-4 mb-lg-3 mb-xl-0 store-ql'>
                    <h5 className='mr-3'>Social</h5>
                    <ul className='list-inline social-icons mb-0'>
                      {attributes?.socialFacebookUrl && <li className='list-inline-item'><span><a href={attributes.socialFacebookUrl} target='_blank' rel='noreferrer'><img src={socialFacebook} /></a></span></li>}
                      {attributes?.socialInstagramUrl && <li className='list-inline-item'><span><a href={attributes.socialInstagramUrl} target='_blank' rel='noreferrer'><img src={socialInstagram} /></a></span></li>}
                      {attributes?.socialTwitterUrl && <li className='list-inline-item'><span><a href={attributes.socialTwitterUrl} target='_blank' rel='noreferrer'><img src={socialTwitter} /></a></span></li>}
                      {attributes?.socialTiktokUrl && <li className='list-inline-item'><span><a href={attributes.socialTiktokUrl} target='_blank' rel='noreferrer'><img src={storeTiktokIcon} /></a></span></li>}
                      {attributes?.socialYoutubeUrl && <li className='list-inline-item'><span><a href={attributes.socialYoutubeUrl} target='_blank' rel='noreferrer'><img src={socialYoutube} /></a></span></li>}
                      {attributes?.socialBlogUrl && <li className='list-inline-item'><span><a href={attributes.socialBlogUrl} target='_blank' rel='noreferrer'><img src={storeBloggerIcon} /></a></span></li>}
                    </ul>
                  </div>
                </>
              )}
              {attributes.acceptedPayments.length > 0 && (
                <div className='d-flex store-ql'>
                  <h5 className='mr-3'>Accepts:</h5>
                  <ul className='list-inline payment-icons mb-0'>
                    {attributes.acceptedPayments.map((value, index) => (
                      <li key={index} className='list-inline-item'>
                        {this.renderSwitchPayment(value)}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className='d-xl-flex mt-2 store-custom-description'>
              {attributes?.customDescription && 
                <span>{attributes?.customDescription || ''}</span>
              }
            </div>
            {
              storeRating > 0 &&
                <div className='store-review'>
                  <StarRatings
                    rating={storeRating}
                    starRatedColor='#239F55'
                    starEmptyColor='#F0F3F0'
                    numberOfStars={5}
                    name='rating'
                    starDimension='37'
                    starSpacing='0'
                  />
                  <div className='store-review-view'>
                    <span><strong> {storeRating.toFixed(1)}</strong> {storeReviews > 0 && 'from'}</span>
                    {
                      storeReviews > 0 &&
                        <>
                          <span>&nbsp;{storeReviews} reviews&nbsp;</span>
                          <a onClick={() => this.props.scrollToReviews()} className='store-review-view-btn'>View reviews</a>
                        </>
                    }
                  </div>
                  <div className='tustpilot-icon'> <img src={trustPilotIconPng} alt='trustpilot' /></div>
                </div>
            }
          </div>
        </div>
        {(attributes.headerImage && !imageErrored) ? <hr /> : ''}

        {(attributes.headerImage && !imageErrored) ? <div><img src={attributes.headerImage} onError={this.handleImageError} alt={attributes.name} width='100%' height='auto' /></div> : ''}
      </section>
    )
  }
}

export default Header
