import React, { useRef, useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setModalClose } from '../../../apps/App/actions'
import ForYou from '../../../components/Labels/ForYou'
import PromptProgressBar from '../components/Modals/FavouritesModal/PromptProgressBar'
import { crossIcon } from '../../../components/Icons'
import { updateNotificationSettings } from '../requesters'
import { getBaseUrl } from '../reducers'
import useWindowSize from '../hooks/useWindowSize'
import { handleNativeRedirectWebView } from '../utilis/NativeDataShare'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'

function PersonalisationPrompt (props) {
  const sectionRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, setShowPersonalisationModal, baseUrl } = props
  const [isclosed, setIsClosed] = useState(false)
  const [birdFlyOff, setBirdFlyOff] = useState(false)
  const [completedSteps, setCompletedSteps] = useState([])

  const prsnPromptCounter = localStorage.getItem('prsnPromptCounter') || 0
  const prsnPromptSessionCounter = sessionStorage.getItem('prsnPromptSessionCounter')

  const isLoggedIn = user !== null
  const favouritesState = user?.attributes?.notification_setting?.favourites_state
  const activeModal = (user !== null) ? favouritesState || 'departmentSelect' : 'getStarted'

  const { width } = useWindowSize()

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    const completedStepArr = [
      user?.attributes?.department_followed,
      user?.attributes?.brand_followed,
      user?.attributes?.store_followed,
      user?.attributes?.about_complete
    ]
    const isFlowComplete = completedStepArr.filter(step => !step)?.length === 0

    setCompletedSteps(completedStepArr)

    if (prsnPromptSessionCounter === null) sessionStorage.setItem('prsnPromptSessionCounter', false)
    if (prsnPromptCounter === 0) {
      localStorage.setItem('prsnPromptCounter', 1)

      async function updateViewCount (count) {
        await updateNotificationSettings(baseUrl, { popup_count: count + 1, favourites_state: activeModal })
      }
      user && updateViewCount(user?.attributes?.popup_count)
    } else if (prsnPromptSessionCounter === null && prsnPromptCounter <= 10) {
      localStorage.setItem('prsnPromptCounter', +prsnPromptCounter + 1)
    }
    if (
      prsnPromptCounter > 10 ||
      isFlowComplete ||
      (user && user?.attributes?.popup_count > 10)) {
      setIsClosed(true)
    }
  }, [user])

  useEffect(() => {
    dispatch(setCarouselLoading({ carousel: props.sectionId }))
  }, [])

  useEffect(() => {
    if(isclosed) {
      dispatch(setCarouselLoaded({ carousel: props.sectionId }))
    } else if(sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: props.sectionId }))
    }
  }, [isclosed, sectionRef?.current])

  const handlePromptClose = () => {
    sessionStorage.setItem('prsnPromptSessionCounter', true)
    setIsClosed(true)
  }

  useEffect(() => {
    const birdTimer = setTimeout(() => birdFlyOff && setBirdFlyOff(false), 1000)
    return () => {
      clearTimeout(birdTimer)
    }
  }, [birdFlyOff])

  const startPersonalizeFlow = () => {
    width < 768 ? setBirdFlyOff(true) : setBirdFlyOff(false)
    setTimeout(() => {
      if (user !== null) setShowPersonalisationModal(true)
      else {
        if (window.ReactNativeWebView) {
          handleNativeRedirectWebView(`{"type":"register", "path":"${window.location.href}"}`)
        } else {
          history.push('/register')
        }
      }
    }, 600)
  }

  const value = {
    departmentSelect: 1,
    brandSelect: 2,
    storeSelect: 3,
    detailsForm: 4
  }[activeModal]

  const steps = ['departmentSelect', 'brandSelect', 'storeSelect', 'detailsForm']

  return (
    !isclosed
      ? (
        <div className='d-flex section-group personalisation-prompt' ref={sectionRef}>
          <div className='container'>
            <div className='row prompt-content'>
              <img className='close-icon' src={crossIcon} onClick={handlePromptClose} />
              <div className='col-12 col-lg-6 px-0'>
                <ForYou />
                <div className={`d-flex flex-row-reverse align-items-start message-row ${isLoggedIn && 'logged-in'} ${birdFlyOff && 'bg-img-none'}`}>
                  <div className='col-12 px-0 mt-2'>
                    <div className={`${isLoggedIn && 'break-text'}`}>{isLoggedIn ? <>Finish <span className='p-green-text'>personalising</span></> : <span className='p-green-text'>Personalise</span>}</div>
                    <div className='feed-text'>your shopping feed</div>
                  </div>
                  {birdFlyOff && <div className='bird-fly-off-animation' />}
                  <div className='d-none bird-fly-off-animation' />
                </div>
                <div className='second-row'>Little Birdie hand picks offers from your favourite brands and stores</div>
              </div>
              <div className='d-flex col-12 col-lg-6 flow-progress justify-content-center align-items-center'>
                <div className='pill-content'>
                  <div className='d-flex row badges-row'>
                    <div className='d-flex col-12 mb-2 mb-lg-3 px-0'>
                      <PromptProgressBar steps={steps} currentStep={value} completedSteps={completedSteps} />
                    </div>
                    <div className='d-flex col-12 px-0 align-items-center justify-content-between'>
                      <span className='tab-pill'>Departments</span>
                      <span className='tab-pill'>Brands</span>
                      <span className='tab-pill'>Stores</span>
                      <span className='tab-pill'>About you</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <button
                      type='button'
                      className='btn personalize-button'
                      onClick={startPersonalizeFlow}
                    >
                      {isLoggedIn ? 'Complete personalisation' : 'Personalise now'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      : null
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})
const mapDispatchToEvents = (dispatch) => {
  return {
    setShowPersonalisationModal: data => dispatch(setModalClose(data))
  }
}

export default connect(mapStateToProps, mapDispatchToEvents)(PersonalisationPrompt)
