import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  isFetchingListFromType,
  getListIdsFromType
} from "../../../../App/reducers"

import Loader from "../../../../../components/Loader"
import InfiniteScroll from "react-infinite-scroll-component"
import ListingCard from "../../ListingCard"
import AdvertisementList from "../../AdvertisementList"
import SortSelect from "./SortSelect"


export default function FixedList({
  fetchNextPage,
  handleSort,
  byOrder
}) {
  const { listType } = useParams()
  const isFetchingData = useSelector(state => isFetchingListFromType(state, listType))
  const listItemIds = useSelector(state => getListIdsFromType(state, listType))

  const [isFirstLoad, setIsFirstLoad] = useState()

  // prevent next to run when first load the component
  useEffect(() => {
    setIsFirstLoad(true)
    if (!_.isEmpty(listItemIds)) {
      setIsFirstLoad(false)
    }
  }, [listItemIds])

  // Returns the resource type for the fixed list
  //
  const resourceType = {
    "top-deals": "deal",
    "top-price-drops": "deal",
    "top-promo-codes": "coupon",
    "top-coupons": "coupon",
    "top-sales-events": "saleEvent",
    "trending-stores": "store",
    "top-spotters": "user",
    "trending-brands": "brand",
    "top-upcoming-sales": "saleEvent",
    "top-current-sales": "saleEvent",
    "new-deals": "deal",
    "new-price-drops": "deal",
    "new-sales-events": "saleEvent",
    "new-promo-codes": "coupon",
    "best-sellers": "deal"
  }[listType]


  // Generates the list for displaying in the paginatied view
  //
  const items = _.map(listItemIds, (itemId, index) => {
    function colClass() {
      if (resourceType === "saleEvent") return "col-sm-6 col-lg-4"
      if (resourceType === "store") return "col-12 col-sm-6 col-lg-4 col-xl-3 listing-card"
      if (resourceType === "brand") return "col-12 col-sm-6 col-lg-4 col-xl-3 listing-card"
      if (resourceType === "coupon") return "col-sm-6 col-lg-4"
      return "col-6 col-lg-3"
    }

    // We determine if we want to show the rank or not, by passing a number larger than 50, it will hide the rank for listing cards
    // We do this when we are filtering and the filter is not TOP
    const position = (_.includes(["rank", "fixed_global_score"], _.get(byOrder, "column")) ? index + 1 : 999)
    if (index === 12) {
      return (
        <Fragment key={`fragment-${index}`}>
          <AdvertisementList position="middle" page="ranking" rankingList list={listType} />
          <ListingCard type={resourceType} position={position} id={itemId} wrapperClass={colClass()} key={`${resourceType}-${itemId}`} isRankingCard cardStyle="center" currentSection={'Trending' + _.upperFirst(resourceType)} sectionPosition={1} />
        </Fragment>
      )
    }
    return <ListingCard type={resourceType} position={position} id={itemId} wrapperClass={colClass()} key={`${resourceType}-${itemId}`} isRankingCard cardStyle="center" currentSection={'Trending' + _.upperFirst(resourceType)} sectionPosition={1} />
  })

  // function bgClass() {
  //   if (resourceType === "saleEvent") return "bg-white"
  //   if (resourceType === "coupon") return "bg-white"
  //   return ""
  // }

  return (
    <section>
      <br />
      {
        // Show when there is no data to see
        !isFetchingData && _.isEmpty(items)
          ? <span>Oops! No results found. Try using different filters.</span>
          : (
            <InfiniteScroll
              className={`row listing-grid ${resourceType === "store" || resourceType === "brand" || resourceType === "user" ? " listing-group-brands" : ""}`}
              dataLength={items.length} // This is important field to render the next data
              next={isFirstLoad ? null : fetchNextPage}
              hasMore
              style={{}}
              loader={<div className="listing-card col-12"><Loader isLoading={isFetchingData} /></div>}
              endMessage={(
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
            )}>
              {items}
            </InfiniteScroll>
            )
      }
    </section>
  )
}
