
import React from 'react'

export default function SearchFilter (props) {
  const { filterSection, handleSearchFilter, filterSearchKeyword } = props

  return (
    <div className='search-filter'>
      <input
        onChange={event => { handleSearchFilter(event.target.value) }}
        aria-label='Search'
        className='form-control'
        placeholder={`Search ${filterSection || ''}`}
        value={filterSearchKeyword}
        type='search'
      />
    </div>
  )
}
