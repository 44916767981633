import React, { Component } from 'react'
import { connect } from 'react-redux'

import Breadcrumb from '../../../../../components/Breadcrumb'
import Meta from '../../../../../components/Meta'

import {
  getBaseUrl,
  getListIdsFromType
} from '../../../../App/reducers'
import { RankingPageLabel } from '../../../../../components/Labels'
// import { labelTag } from 'components/Icons'
import AdvertisementList from '../../AdvertisementList'

import CustomList from './CustomList'
import CollectionsList from './CollectionsList'
import ElasticList from './ElasticList'
import FixedList from './FixedList'
import filterDataLayer from '../../../../../components/dataLayers/filterDataLayer'

import {
  loadRankingPage,
  refreshRankingList
} from '../../../actions'

import {
  DesktopFilter,
  MobileFilter
} from '../../Filter'

import {
  bestSellersOptions,
  couponOptions,
  dealOptions,
  brandAndStoreOptions,
  saleEventOptions,
  collectionOptions
} from './sortOptions'
import SortSelect from './SortSelect'
import * as sortOpts from './sortOptions'
import { gon } from '../../../../../../constant'
import {
  getCustomListById
} from '../../../../App/requesters'
import { debounce } from '../Common/helpers';
import { oneColumnIcon, twoColumnIcon,oneColumnIconInactive, twoColumnIconInactive } from '../../../../../components/Icons'

class RankingPage extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  componentDidMount() {
    this.loadPage(this.listRequestParams())
    window.addEventListener('scroll', this.handleScroll)
    if( !this.isElasticList() && !this.isCollectionList() && !this.isFixedList() )
    this.getCustomListById()

    window.addEventListener('beforeunload', () => {
      this.removeStoreOptions()
    })

  }

  getCustomListById = async () => {
    const { listType } = this.props.match.params
     if(listType){
      const { response, status } = await getCustomListById(gon.urls.api, listType)
      this.setState({customListing:_.first(Object.values(response.customList)),status:status})
    }
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prev_params } } = prevProps
    const { match: { params } } = this.props

    // If either department/category or subcategory changes we go refresh the data
    if (!_.isEqual(params, prev_params)) this.refreshPage()
    const {options, isAvailable} = this.getStoredFilters()
    if(isAvailable && !options.check){
       window.localStorage.setItem("price_drop_filter", JSON.stringify({...options,check: true}))
    }
  }

  getStoredFilters = () => {
    const getStoredOptions = JSON.parse(window.localStorage.getItem("price_drop_filter") || '{}')
    const options = Object.keys(getStoredOptions)
    return {
      options: getStoredOptions,
      isAvailable: options.length
    }
  }

  removeStoreOptions = () => {
    const getPage = JSON.parse(window.localStorage.getItem('price_drop_filter') || '{}')
    getPage.check && window.localStorage.removeItem('price_drop_filter')
  }

  refreshPage = (overrideState = {}) => {
    const { match: { params: { listType } } } = this.props

    filterDataLayer({ listType: this.resourceTypeFromListType(listType), sort: this.state?.by_order?.column }, 'clear-all', 1, listType, null)
    this.setState(this.getInitialState(overrideState), () => this.loadPage(this.listRequestParams()))
  }

  resourceTypeFromListType = listType => {
      return {
        'top-deals': 'deal',
        'top-price-drops': 'deal',
        'top-promo-codes': 'coupon',
        'top-coupons': 'coupon',
        'top-sales-events': 'saleEvent',
        'trending-stores': 'store',
        'top-spotters': 'user',
        'trending-brands': 'brand',
        'top-upcoming-sales': 'saleEvent',
        'top-current-sales': 'saleEvent',
        'new-deals': 'deal',
        'new-price-drops': 'deal',
        'new-sales-events': 'saleEvent',
        'new-promo-codes': 'coupon',
        'best-sellers': 'deal',
        'all-products': 'deal'
      } [listType]
    }

  getInitialState = (overrideState = {}) => {
    const {
      match: { params: { listType, department } }
    } = this.props
    const searchParams = new URLSearchParams(window.location.search)
    let setStoreNames = searchParams.getAll('storeNames[]')
    let setBrandNames = searchParams.getAll('brandNames[]')
    let setStoreIds = searchParams.getAll('storeIds[]')
    let setBrandIds = searchParams.getAll('brandIds[]')
    let setStartRange = []
    let setPaymentMethods = []
    let setPriceRange = []
    let setDiscountRange = []
    let setLastPriceRange = []
    let setCouponTypes = []
    let setDepartmentName = []
    let setCategoryName = []
    let setSubcategoryNames = []
    let setAttributeFilters = {}

    const { options, isAvailable } = this.getRequestFilterOption()

    if (!isAvailable) {
      const getStoredOptions = JSON.parse(window.localStorage.getItem("price_drop_filter") || '{}')

      if(!getStoredOptions.check){

        if(getStoredOptions.storeNames && !options.store){
          setStoreNames = getStoredOptions.storeNames
        }

        if(getStoredOptions.brandNames && !options.brand){
          setBrandNames = getStoredOptions.brandNames
        }

        if (getStoredOptions.priceRange) {
          setPriceRange = getStoredOptions.priceRange || []
        }

        if (getStoredOptions.discountRange) {
          setDiscountRange = getStoredOptions.discountRange || []
        }

        if (getStoredOptions.lastPriceRange) {
          setLastPriceRange = getStoredOptions.lastPriceRange || [];
        }

        if (getStoredOptions.departmentNames && !options.department) {
          setDepartmentName = getStoredOptions.departmentNames || [];
        }

        if (getStoredOptions.categoryNames && !options.category) {
          setCategoryName = getStoredOptions.categoryNames || [];
        }

        if (getStoredOptions.subcategoryNames && !options.subcategory) {
          setSubcategoryNames = getStoredOptions.subcategoryNames || []
        }

        if (getStoredOptions.attributeFilters) {
          setAttributeFilters = getStoredOptions.attributeFilters || {}
        }
      }

    } else {
      window.localStorage.removeItem('price_drop_filter')
    }

    let column = 'fixed_global_score'
    let direction = 'desc'
    if (listType === 'best-sellers' || listType === 'trending-stores' || listType === 'trending-brands') {
      column = 'rank'
      direction = 'asc'
    }
    if ((listType === 'best-sellers' || listType === 'trending-stores' || listType === 'trending-brands') && department) {
      column = ''
      direction = 'asc'
    }
    if (this.isElasticList()) {
      column = 'fixed_global_score'
      if (department) column = 'fixed_department_score'
    }
    if (this.isCollectionList()) column = 'published_at'
    if (listType === 'new-sales-events' || listType === 'new-coupons') column = 'published_at'
    if (listType === 'new-price-drops' || listType === 'new-deals') column = 'last_price_date'

    const resourceType = this.resourceTypeFromListType(listType)

    const initialState = {
      mbColumn: 'twoColumn',
      totalResultsCount: null,
      prevScrollPos: 0,
      filterSticky: false,
      sortOptionValue: '',
      customListing: {},
      isRestored: true,
      status: '',
      page: 1,
      sortBy: 'top',
      isReady: this.isElasticList() ? false : !(!this.isFixedList() && !this.isCollectionList()),
      collectionList: null,
      searchMinMaxPrice: [],
      filterOptions: {
        storeIds: setStoreIds || [],
        brandIds: setBrandIds || [],
        departmentIds: [],
        categoryIds: [],
        subcategoryIds: [],
        startRange: setStartRange,
        paymentMethods: setPaymentMethods,
        priceRange: setPriceRange,
        discountRange: setDiscountRange,
        lastPriceRange: setLastPriceRange,
        couponTypes: setCouponTypes,
        // Including name filters for elastic search filtering
        storeNames: setStoreNames || [],
        brandNames: setBrandNames || [],
        departmentNames: setDepartmentName,
        categoryNames: setCategoryName,
        subcategoryNames: setSubcategoryNames,
        attributeFilters: setAttributeFilters || {}
      },
      customListFilterOptions: null,
      by_order: {
        column,
        direction
      },
      customListTitle: '',
      customListSubTitle: '',
      fixedListOptions: {
        deal: sortOpts.dealOptions,
        coupon: sortOpts.couponOptions,
        saleEvent: sortOpts.saleEventOptions,
        brand: sortOpts.brandAndStoreOptions,
        store: sortOpts.brandAndStoreOptions
      }[resourceType]
    }
    return _.extend(initialState, overrideState)
  }

  listRequestParams = (overrideParams = {}) => {
    const {
      page,
      by_order,
      filterOptions: {
        brandIds, storeIds,
        departmentIds, categoryIds,
        subcategoryIds, startRange,
        paymentMethods, priceRange,
        discountRange, lastPriceRange,
        couponTypes, attributeFilters
      }
    } = this.state
    const { match: { params } } = this.props

    const requestParams = {
      page,
      listType: params.listType,
      store: params.store,
      brand: params.brand,
      department: params.department,
      category: params.category,
      subcategory: params.subcategory,
      by_brands: brandIds,
      by_stores: storeIds,
      by_departments: departmentIds,
      by_categories: categoryIds,
      by_subcategories: subcategoryIds,
      by_date_range: startRange,
      by_payment_methods: paymentMethods,
      by_price_range: priceRange,
      by_discount_range: discountRange,
      by_last_price_range: lastPriceRange,
      by_coupon_types: couponTypes,
      by_order,
      attribute_filters: attributeFilters
    }

    // Apply any overrides here
    return _.extend(requestParams, overrideParams)
  }

  getRequestFilterOption = () => {
    const { match: { path, params } } = this.props
    const options = {
      store: path == '/shop/store/:store/:listType' ? undefined : params.store,
      brand: path == '/shop/brand/:brand/:listType' ? undefined : params.brand
    }

    const validOptions = Object.keys(options).filter( li => options[li] !== undefined )

    return {options, isAvailable : validOptions.length}
  }

  // Update page state and refreshes list only
  //
  fetchNextPage = () => {
    const { page } = this.state
    this.setState({ page: page + 1 })
    this.refreshList()
  }

  // Load page does a full page refresh
  //
  loadPage = () => {
    const { loadRankingPage } = this.props
    loadRankingPage(this.listRequestParams())
  }

  // Load page does a full page refresh
  //
  refreshList = (overrideParams = {}) => {
    const { refreshRankingList, match: { params: { listType } } } = this.props

    refreshRankingList(this.listRequestParams(overrideParams))

    if (
      listType === 'top-sales-events' || listType === 'new-sales-events' ||
      listType === 'top-coupons' || listType === 'top-promo-codes' || listType === 'new- vouchers'
    ) {
      const { filterOptions, sortOptionValue, by_order } = this.state
      const option = this.state.fixedListOptions.values.find(opt => opt.key === parseInt(sortOptionValue, 10))
      const itemData = {
        ...filterOptions,
        listType: listType,
        sort: by_order?.column
      }

      if (option) {
        itemData.sort = option.text
      }

      filterDataLayer(itemData, 'select-filter', null, null, null)
    }
  }

  renderSidebarList = () => {
    const {
      match: {
        params: {
          department, category, subcategory, brand, store
        }
      }
    } = this.props
    const {
      filterOptions, searchMinMaxPrice, by_order, collectionList, isReady, customListFilterOptions
    } = this.state

    return (
      <div className='col-xl-3 d-none d-xl-block sidebar'>
        {(this.setAvailableFilters()) && (
          <DesktopFilter
            isClearAllDisplay= {this.isClearAllBtnDisplay()}
            handleClear={(e, sectionPosition) => this.handleFilterClear(e, sectionPosition)}
            handleChange={(e, sectionPosition) => this.handleFilterChange(e, sectionPosition)}
            handleClearAll={() => this.refreshPage({ by_order, collectionList })}
            selectedItems={filterOptions}
            searchMinMaxPrice={searchMinMaxPrice}
            selectedCustomItems={customListFilterOptions}
            availableFilters={this.setAvailableFilters()}
            setDepartment={department}
            setCategory={category}
            setSubcategory={subcategory}
            setBrand={brand}
            setStore={store}
            isReady={isReady}
            topPriceDropsFollowingPageFilter={this.isPriceDropsPageWithFollowedBrandsOrStores()}
          />
        )}
      </div>
    )
  }

  setAvailableFilters = () => {
    const {
      match: {
        path,
        params: {
          listType, store, brand
        }, url
      },
      location
    } = this.props
    const viewAllDeals = location?.pathname?.includes('all-products')
    const { collectionList } = this.state
    if (this.isElasticList()) {
      return {
        showElasticBrand: true,
        showElasticStore: true,
        showElasticDepartments: true,
        showPriceRange: true,
        showDiscountRange: true,
        // showPaymentMethods: true,
        showFreshness: true,
        showAttributeFilters: !viewAllDeals && false
      }
    }
    if ((!this.isCollectionList() && !this.isFixedList() && !collectionList) || (url === '/trending-stores' || url === '/trending-brands' || url === '/top-spotters')) return false
    if (collectionList) {
      return {
        deal: {
          showElasticBrand: true, showElasticStore: true, showElasticDepartments: true, showPriceRange: true, showDiscountRange: true, showPaymentMethods: true, showFreshness: true, showAttributeFilters: !viewAllDeals && false
        },
        sale_event: {
          showStore: true, showBrand: true, showDepartment: true, showCategory: true, showStartDate: true, showPaymentMethods: true
        },
        coupon: {
          showStore: true, showBrand: true, showDepartment: true, showCategory: true, showPaymentMethods: true, showCouponTypes: true
        }
      }[collectionList]
    }

    const availableFilters = {
      'best-sellers': {
        showDepartment: true, showCategory: true, showSubcategory: true, showPriceRange: true, showDiscountRange: true, showPaymentMethods: true, showFreshness: true
      },
      'top-promo-codes': {
        // showDepartment: true, showCategory: true, showSubcategory: true, showPriceRange: true, showDiscountRange: true, showPaymentMethods: true, showFreshness: true, showCouponTypes: true
        showDepartment: true, showCategory: true, showPaymentMethods: true, showCouponTypes: true
      },
      'top-coupons': {
        // showDepartment: true, showCategory: true, showSubcategory: true, showPriceRange: true, showDiscountRange: true, showPaymentMethods: true, showFreshness: true, showCouponTypes: true
        showDepartment: true, showCategory: true, showPaymentMethods: true, showCouponTypes: true
      },
      'new-promo-codes': {
        // showDepartment: true, showCategory: true, showSubcategory: true, showPriceRange: true, showDiscountRange: true, showPaymentMethods: true, showFreshness: true, showCouponTypes: true
        showDepartment: true, showCategory: true, showPaymentMethods: true, showCouponTypes: true
      },
      'top-sales-events': {
        showDepartment: true, showCategory: true, showStartDate: true, showPaymentMethods: true
      },
      'top-upcoming-sales': {
        showDepartment: true, showCategory: true, showStartDate: true, showPaymentMethods: true
      },
      'top-current-sales': {
        showDepartment: true, showCategory: true, showStartDate: true, showPaymentMethods: true
      },
      'new-sales-events': {
        showDepartment: true, showCategory: true, showStartDate: true, showPaymentMethods: true
      },
      'trending-stores': {},
      'trending-brands': {},
      collections: { showDepartment: true, showCategory: true }
    }[path === '/collections' ? 'collections' : listType]
    if (!store) availableFilters.showStore = true
    if (!brand) availableFilters.showBrand = true

    return availableFilters
  }

  // Checks if this list is one of our fixed lists
  //
  isFixedList = () => {
    const { match: { params: { listType } } } = this.props
    return _.includes([
      'top-promo-codes',
      'top-coupons',
      'top-sales-events',
      'trending-stores',
      'top-spotters',
      'trending-brands',
      'top-upcoming-sales',
      'top-current-sales',
      'new-sales-events',
      'new-promo-codes',
      'best-sellers'
    ], listType)
  }

  isElasticList = () => {
    const { match: { params: { listType } } } = this.props
    return _.includes([
      'top-deals',
      'top-price-drops',
      'new-deals',
      'new-price-drops',
      'all-products',
    ], listType)
  }

  isPriceDropsPageWithFollowedBrandsOrStores = () => {
  // check if top price drops list is scoped to followed brands or stores
  const { location: { search } } = this.props
  return /\bstoreNames\[]|brandNames\[]/g.test(search)
  }

  isTrendingBrandsOrStoresPage = () => {
    const { match: { params: { listType } } } = this.props
    return _.includes([
      'trending-stores',
      'trending-brands'
    ], listType)
  }

  sortOptions = () => {
    const { match: { params: { listType } } } = this.props
    const { collectionList } = this.state
    if (this.isCollectionList()) return collectionOptions
    if (collectionList) {
      return {
        deal: dealOptions,
        coupon: couponOptions,
        sale_event: saleEventOptions
      }[collectionList]
    }
    return {
      'best-sellers': bestSellersOptions,
      'top-deals': dealOptions,
      'top-price-drops': dealOptions,
      'top-promo-codes': couponOptions,
      'top-coupons': couponOptions,
      'top-sales-events': saleEventOptions,
      'trending-stores': brandAndStoreOptions,
      // 'top-spotters',
      'trending-brands': brandAndStoreOptions,
      // 'top-upcoming-sales',
      // 'top-current-sales',
      'new-deals': dealOptions,
      'new-price-drops': dealOptions,
      'new-sales-events': saleEventOptions,
      'new-promo-codes': couponOptions,
      'all-products': dealOptions,
    }[listType]
  }

  // Checks if this is the collections list
  //
  isCollectionList = () => {
    const { match: { path } } = this.props
    return path === '/collections'
  }

  handleFilterClear = event => {
    const name = event?.currentTarget?.name
    const inputDataName = event?.currentTarget?.dataset?.inputName
    const {
      match: { params: { listType } }
    } = this.props
    const { filterOptions } = this.state
    const attributeFilters = { ...filterOptions.attributeFilters }

    if(event.currentTarget?.dataset?.attributeFilter) {
      attributeFilters[name] = []
      inputDataName && (attributeFilters[inputDataName] = [])

      filterOptions.attributeFilters = attributeFilters
    } else {
      filterOptions[name] = []
      inputDataName && (filterOptions[inputDataName] = [])
    }

    this.setState({ filterOptions, page: 1 }, this.refreshList)
  }

  handleFilterChange = event => {

    const { name, value, inputDataName, inputDataValue, attributeFilter } = event
    const { filterOptions } = this.state
    const attributeFilters = { ...filterOptions.attributeFilters }
    const {
      match: { params: { listType } }
    } = this.props

    if(attributeFilter) {
      if(!attributeFilters[name]) {
        attributeFilters[name] = []
      }

      if (value.constructor === Array) {
        attributeFilters[name] = attributeFilters[name] === value ? [] : value
        inputDataName && (attributeFilters[inputDataName] = attributeFilters[inputDataName] === inputDataValue ? [] : inputDataValue)
      } else {
        attributeFilters[name] = attributeFilters[name].includes(value) ? attributeFilters[name].filter(e => e !== value) : [...attributeFilters[name], value]
        inputDataName && (attributeFilters[inputDataName] = attributeFilters[inputDataName].includes(inputDataValue) ? attributeFilters[inputDataName].filter(e => e !== inputDataValue) : [...attributeFilters[inputDataName], inputDataValue])
      }

      filterOptions.attributeFilters = attributeFilters
    } else {
      if (value.constructor === Array) {
        filterOptions[name] = filterOptions[name] === value ? [] : value
        inputDataName && (filterOptions[inputDataName] = filterOptions[inputDataName] === inputDataValue ? [] : inputDataValue)
      } else {
        filterOptions[name] = filterOptions[name].includes(value) ? filterOptions[name].filter(e => e !== value) : [...filterOptions[name], value]
        inputDataName && (filterOptions[inputDataName] = filterOptions[inputDataName].includes(inputDataValue) ? filterOptions[inputDataName].filter(e => e !== inputDataValue) : [...filterOptions[inputDataName], inputDataValue])
      }
    }

    this.setState({ filterOptions, page: 1 }, this.refreshList)
  }

  handleSortChange(event) {
    const selected = this.sortOptions().values.find(value => value.key === parseInt(event.currentTarget.value, 10))
    const { value, order } = selected

    this.setState({ by_order: { column: value, direction: order }, page: 1 }, this.refreshList)
  }

  handleTotalResultsCount(counts) {
    this.setState({ totalResultsCount: counts })

    const {
      match: { params: { listType } }
    } = this.props
    const { filterOptions, sortOptionValue, by_order } = this.state

    const itemData = {
      ...filterOptions,
      listType: listType,
      sort: by_order?.column,
      searchCount: counts?.deals
    }

    const option = dealOptions.values.find(opt => opt.key === parseInt(sortOptionValue, 10))
    if (option) {
      itemData.sort = option.text
    }

    filterDataLayer(itemData, 'select-filter', null, null, null)
  }

  handlePriceRange(priceRange) {
    this.setState({searchMinMaxPrice: priceRange})
  }

  renderMetaDescription() {
    const fallback = 'Find and Save with the best price drops, vouchers and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more.'
    const {
      match: {
        path,
        params: {
          listType,
          store,
          brand,
          subcategory,
          category,
          department
        }
      }
    } = this.props

    let description = {
      '/shop/store/:store/:listType': `See and save money with ${store}'s ${_.startCase(listType)} at Little Birdie.`,
      '/shop/brand/:brand/:listType': `See and save money with ${brand}'s ${_.startCase(listType)} at Little Birdie.`,
      '/collections': fallback,
      '/:listType(top-features|top-deals|top-price-drops|top-sales-events|top-promo-codes|top-coupons|trending-brands|trending-stores|best-sellers|all-products)/:department?/:category?': `Save money with the ${_.startCase(listType)} in ${subcategory || category || department || 'Australia'} from millions of products from hundreds of stores in Australia at Little Birdie.`,
      '/:listType(new-deals|new-price-drops|new-sales-events|new-promo-codes|trending-stores|top-spotters|trending-brands|top-upcoming-sales|top-current-sales|all-products)': 'Find and Save with the best price drops, vouchers and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more.',
      '/collections/:listType/:department?/:category?': fallback,
      '/collections/:listType': fallback
    }[path]

    if (listType === 'best-sellers') { description = fallback }

    return description === undefined ? fallback : description
  }

  renderMetaTitle() {
    const fallback = 'The Latest Sale Events, Price Drops and Vouchers | Little Birdie'
    const {
      match: {
        path,
        params: {
          listType,
          store,
          brand,
          subcategory,
          category,
          department
        }
      }
    } = this.props

    let title = {
      '/shop/store/:store/:listType': `${_.startCase(store && store.name)} Sale Events, Price Drops and Vouchers in Australia | Little Birdie`,
      '/shop/brand/:brand/:listType': `${_.startCase(brand && brand.name)} Sale Events, Price Drops and Vouchers in Australia | Little Birdie`,
      '/collections': 'View All Collections | Little Birdie',
      '/:listType(top-features|top-deals|top-price-drops|top-sales-events|top-promo-codes|top-coupons|trending-brands|trending-stores|best-sellers|all-products)/:department?/:category?': `${_.startCase(listType)} ${subcategory || category ? `${subcategory || ''} ${category || ''} ` : `${department || ''} ` } in Australia | LittleBirdie`,
      '/:listType(new-deals|new-price-drops|new-sales-events|new-promo-codes|trending-stores|top-spotters|trending-brands|top-upcoming-sales|top-current-sales|all-products)': `${_.startCase(listType)} in Australia | LittleBirdie`,
      '/collections/:listType/:department?/:category?': fallback,
      '/collections/:listType': fallback
    }[path]

    if (listType === 'best-sellers') { title = fallback }

    return title
  }

  renderOgDescription() {
    const fallback = 'Little Birdie is a browser extension that checks the internet for the lowest price—and a shopping feed with top Sale Events and Voucher Codes.'
    const {
      match: {
        path,
        params: {
          listType,
          store,
          brand,
          subcategory,
          category,
          department
        }
      }
    } = this.props

    const og_description = {
      '/shop/store/:store/:listType': listType ? `See and save money with ${_.startCase(store)}'s ${_.startCase(listType)} at Little Birdie.` : `See ${_.startCase(store)}'s best price drops, vouchers, sale events, clearance and discounts at Little Birdie.`,
      '/shop/brand/:brand/:listType': listType ? `See and save money with ${_.startCase(brand)}'s ${_.startCase(listType)} at Little Birdie.` : `See ${_.startCase(brand)}'s best price drops, vouchers, sale events, clearance and discounts at Little Birdie.`,
      '/collections': 'View All Collections | Little Birdie',
      '/:listType(top-features|top-deals|top-price-drops|top-sales-events|top-promo-codes|top-coupons|trending-brands|trending-stores|best-sellers|all-products)/:department?/:category?': `Save money with the ${_.startCase(listType)} in ${subcategory || category || department} from millions of products from hundreds of stores in Australia at Little Birdie.`,
      '/:listType(new-deals|new-price-drops|new-sales-events|new-promo-codes|trending-stores|top-spotters|trending-brands|top-upcoming-sales|top-current-sales|all-products)': 'Find and Save with the best price drops, vouchers and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more.',
      '/collections/:listType/:department?/:category?': fallback,
      '/collections/:listType': fallback
    }[path]

    return og_description === undefined ? fallback : og_description
  }

  renderListType = () => {
    const {
      sortBy,
      filterOptions,
      isReady,
      by_order: { column, direction },
      mbColumn
    } = this.state
    const {
      match: { params: { listType } }
    } = this.props

    // Collections root list
    if (this.isCollectionList()) {
      return (
        <CollectionsList
          sortOptionValue={this.state.sortOptionValue}
          mobileColumn={column}
          mobileDirection={direction}
          filterOptions={filterOptions}
          setFilters={filters => this.setState({ filterOptions: { ...filterOptions, ...filters } })}
        />
      )
    }

    // Fixed Lists e.g top-coupons, top-sales-events
    if (this.isFixedList()) {
      const { by_order } = this.state
      return (
        <FixedList
          byOrder={by_order}
          sortBy={sortBy}
          handleSort={e => this.handleSortChange(e)}
          isRankingPage
          fetchNextPage={this.fetchNextPage}
        />
      )
    }

    // Ranking pages which use elasticsearch e.g new-deals, top-price-drops
    if (this.isElasticList()) {
      return (
        <ElasticList
          handlePriceRangeChange={e => this.handlePriceRange(e)}
          filterOptions={filterOptions}
          listType={listType}
          makeReady={() => this.setState({ isReady: true })}
          setDefaultDepartment={dep => this.setState({ filterOptions: { ...filterOptions, departmentNames: [dep] } })}
          setDefaultCategory={cat => this.setState({ filterOptions: { ...filterOptions, categoryNames: [cat] } })}
          setDefaultSubcategory={subcat => this.setState({ filterOptions: { ...filterOptions, subcategoryNames: [subcat] } })}
          setDefaultStore={store => this.setState({ filterOptions: { ...filterOptions, storeNames: [store] } })}
          setDefaultBrand={brand => this.setState({ filterOptions: { ...filterOptions, brandNames: [brand] } })}
          sortColumn={column}
          sortDirection={direction}
          sortOptionValue={this.state.sortOptionValue}
          topPriceDropsFollowingPageFilter={this.isPriceDropsPageWithFollowedBrandsOrStores()}
          handleTotalResultsCount={counts => this.handleTotalResultsCount(counts)}
          mbColumn={mbColumn}
        />
      )
    }

    // Return custom lists
    return (
      <CustomList
        handlePriceRangeChange={e => this.handlePriceRange(e)}
        sortOptionValue={this.state.sortOptionValue}
        getCollectionFilters={e => this.setState({ collectionList: e })}
        filterOptions={filterOptions}
        isReady={isReady}
        sortColumn={column}
        sortDirection={direction}
        setFilters={customOptions => this.setState({ isReady: true, filterOptions: _.extend(filterOptions, customOptions) })}
        setCustomListFilters={options => this.setState({ customListFilterOptions: options })}
        setTitle={title => this.setState({ customListTitle: title })}
        setSubTitle={subTitle => this.setState({ customListSubTitle: subTitle })}
        handleTotalResultsCount={counts => this.handleTotalResultsCount(counts)}
      />
    )
  }

  renderDescription() {
    const {
      match: { params: { listType } }
    } = this.props
    const { mbColumn } = this.state
    const resourceType = this.resourceTypeFromListType(listType)

    function description() {
      if (resourceType === 'saleEvent') {
        return 'Be quick, dive into the hottest sales.'}
      if (resourceType === 'store') return 'Follow the hottest trending stores in Australia by the Little Birdie community'
      if (resourceType === 'brand') return 'Follow the hottest trending brands in Australia by the Little Birdie community'
      if (resourceType === 'coupon') return 'Copy the code, apply at the checkout, and save.'
      return 'Take advantage of current Price Drops.'
    }

    if (this.isCollectionList()) {
      return (
        <p className='mt-3'>
          Highlighted Price Drops from brands or retailers, gathered together.
        </p>
      )
    }
    return (
      <div className='d-flex justify-content-between mt-3'>
        <p className='col-9 mb-0 pl-0'>
        {description()}
        </p>
        {/* Mobile two and one column wide - icons */}
        {
          resourceType === 'deal' && <div className="d-block d-sm-none">
          <img src={mbColumn ==='oneColumn' ? twoColumnIconInactive: twoColumnIcon} className="mb-column-icon" onClick={()=>this.setState({ mbColumn : 'twoColumn' })} />
          <img src={mbColumn ==='twoColumn' ? oneColumnIconInactive: oneColumnIcon} onClick={()=>this.setState({ mbColumn : 'oneColumn' })} />
        </div>
        }
        {/* ends here */}
      </div>
      
    )
  }

  renderRankingPageLabel() {
    const {
      match,
      match: {
        params: {
        listType, department, category, subcategory, brand, store
        }
      }
    } = this.props

    const {
      customListTitle, customListSubTitle
    } = this.state

    if (this.isCollectionList()) {
      return (
        <h1>
          Little Birdie Collections
        </h1>
      )
    }

    if (!this.isCollectionList() && !this.isElasticList() && !this.isFixedList()) {
      return (
        <>
          <h1>
            {customListTitle}
          </h1>
          <div className='subtitle'>
            {customListSubTitle}
          </div>
        </>
      )
    }

    return (
      <h1>
        <RankingPageLabel
          list={listType}
          props={{
                  match, department, category, subcategory, brand, store
                }} />
      </h1>
    )
  }
  defaultSelectedFilters = () => {
    const { departmentNames, categoryNames, subcategoryNames, brandNames, storeNames } = this.state.filterOptions
    const {
      match: {
        params: {
         department, category, subcategory, brand, store
        }
      }
    } = this.props
  if (department || category || subcategory || brand || store) {
    if (department){
      if (departmentNames?.length > 1) return true
    } else if (departmentNames?.length) return true
    if (category){
      if (categoryNames?.length > 1) return true
    } else if (categoryNames?.length) return true
    if (subcategory){
      if (subcategoryNames?.length > 1) return true
    } else if (subcategoryNames?.length) return true
    // For hard coded brand
    if (brand) {
      if (brandNames?.length > 1) return true
    } else if (brandNames?.length) return true
     // For hard coded store
    if(store) {
      if (storeNames?.length > 1) return true
    } else if (storeNames?.length) return true
  }
  else if (categoryNames?.length || departmentNames?.length || subcategoryNames?.length || brandNames?.length || storeNames?.length ) {
    return true
  }
  return false
 }
// To display clear all button conditionally
isClearAllBtnDisplay = () => {
  const {
    brandNames, storeNames, priceRange, discountRange, paymentMethods, lastPriceRange,
    brandIds, storeIds, categoryIds, subcategoryIds, departmentIds, startRange, couponTypes, attributeFilters
  } = this.state.filterOptions

  let appliedAttributeFilter = _.some(attributeFilters, (value) => value?.length) || false

  if (this.defaultSelectedFilters() ||
     priceRange?.length || discountRange?.length || paymentMethods?.length || lastPriceRange?.length ||
     brandIds?.length || categoryIds?.length || departmentIds?.length ||
    subcategoryIds?.length || storeIds?.length || startRange?.length ||
    couponTypes?.length || appliedAttributeFilter) {
      return true
  }
  return false
}
//For Filter and sort sticky in mobile view
  handleScroll = debounce ( () => {
    const { prevScrollPos } = this.state
    const currentScrollPos = window.pageYOffset
    this.setState({filterSticky: prevScrollPos > currentScrollPos, prevScrollPos:currentScrollPos})
    if (currentScrollPos < 250) this.setState({filterSticky: false})
  }, 10)
  //sending sort value to listing pages
  handleSortValue = event => {
    this.setState({sortOptionValue: event.currentTarget.value})
  }
  //manipulating options for custom listing component
  customListOptions = () => {
    let options = {};
    const { customListing, status } = this.state
    if(status === 200){
      switch (_.get(customListing, 'attributes.resourceType')) {
        case 'deal':
          return options = dealOptions
        case 'coupon':
          return options = couponOptions
        case 'sale_event':
          return options = saleEventOptions
        default: return options = dealOptions
      }
    }
    return options
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
    window.removeEventListener('beforeunload',  () => null, false);
    this.removeStoreOptions()
  }
  render() {

    const {
      // isFetchingList,
      location,
      // match,
      match: {
        params: {
        listType, department, category, subcategory, brand, store
        }, url
      }
    } = this.props
    const {
      filterOptions, searchMinMaxPrice, by_order, isReady, customListFilterOptions, fixedListOptions, filterSticky
    } = this.state

    // const items = this.renderListItems()
    const listTitle = _.startCase(listType)

    return (
      <div className='listing-page container'>
        <Breadcrumb breadcrumbs={location} />
        <div className='ranking-page-header'>
          {this.renderRankingPageLabel()}
          {(this.isCollectionList() || this.isElasticList() || this.isFixedList()) && this.renderDescription()}
        </div>
        <Meta
          description={this.renderMetaDescription()}
          og_description={this.renderOgDescription()}
          title={this.renderMetaTitle()}
          name={listTitle}
        />
        <div className={this.isTrendingBrandsOrStoresPage() ? 'store-brand-ranking' : 'row main-wrapper'}>
          { !this.isTrendingBrandsOrStoresPage() && this.renderSidebarList() }

          <div className={!this.isTrendingBrandsOrStoresPage() ? 'col-xl-9 main-content' : ''}>
            { <AdvertisementList location={`ranking-${listType}-top`} position='top' page='ranking' list={listType} /> }
              <div id='mobileFilter' className={`fixedFilter ${ filterSticky ? 'filterSticky':''}`}>
                {this.setAvailableFilters() && (
                  <div className='d-xl-none mobile-filter'>
                    <MobileFilter
                      isClearAllDisplay= {this.isClearAllBtnDisplay()}
                      handleClear={(e, sectionPosition) => this.handleFilterClear(e, sectionPosition)}
                      handleClearAll={() => this.refreshPage({ by_order })}
                      handleChange={(e, sectionPosition) => this.handleFilterChange(e, sectionPosition)}
                      selectedItems={filterOptions}
                      searchMinMaxPrice={searchMinMaxPrice}
                      selectedCustomItems={customListFilterOptions}
                      availableFilters={this.setAvailableFilters()}
                      sortOptions={this.sortOptions()}
                      selectedOrder={by_order}
                      url={url}
                      handleSort={e => this.handleSortChange(e)}
                      setDepartment={department}
                      setCategory={category}
                      setSubcategory={subcategory}
                      setBrand={brand}
                      setStore={store}
                      isReady={isReady}
                      topPriceDropsFollowingPageFilter={this.isPriceDropsPageWithFollowedBrandsOrStores()}
                    />
                  </div>
                )}
                <SortSelect  handleSort={event => this.isFixedList() ? this.handleSortChange(event) : this.handleSortValue(event) }
                  options={
                   this.isElasticList() && dealOptions ? dealOptions
                 :(this.isCollectionList() && collectionOptions ? collectionOptions :
                 (this.isFixedList() && fixedListOptions ? fixedListOptions :
                 this.customListOptions()  /* For custom list page options */
                 ))
                }/>
              </div>
            { this.renderListType() }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  listIdItems: getListIdsFromType(state, 'top-deals'),
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, {
  loadRankingPage,
  refreshRankingList
})(RankingPage)
