import React from "react"

export default function SortSelect({ handleSort, options, /* byOrder */ }) {

  return (
    <div className="sort-dropdown">
      <select
        className="form-control"
        onChange={e => handleSort(e)}
        defaultValue=""
        >
        <option value="" disabled>Sort by</option>
        {options.values && options.values.length && options.values.map(e => (
          <option
            // selected={byOrder.column === e.value && byOrder.direction === e.order}
            key={e.key}
            value={e.key}
            order={e.order}>
            {e.text}
          </option>
        ))}
      </select>
    </div>
  )
}
