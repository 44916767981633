import PropTypes from 'prop-types'

function multivariateDataLayer (variantGroup, experimentIdName, versionId, selectedVariantId) {
/****
    * snowplow('trackStructEvent', 'category','action','label','property','value');
      'category'  = 'experiment',
      'action'    = variantGroup      => 'Selected Variant Group',
      'label'     = experimentIdName  => {EXPERIMENT_ID} ' - ' {EXPERIMENT-NAME}, // Concatenated experiment id and name
      'property'  = versionId         => {VERSION_ID}, // version id of the experiment
      'value'     = selectedVariantId => {VARIANT_SELECTED_ID} // variant user is bucketed in A or B
    *
****/
  snowplow('trackStructEvent', 'experiment', variantGroup, experimentIdName, versionId, selectedVariantId)
}

multivariateDataLayer.PropTypes = {
  variantGroup: PropTypes.string.isRequired,
  experimentIdName: PropTypes.string.isRequired,
  versionId: PropTypes.string,
  selectedVariantId: PropTypes.number.isRequired
}

export default multivariateDataLayer
