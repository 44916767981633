import React, { Component } from "react"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import SelectBox from "../../components/InputComponents/SelectBox"
import SelectBoxLookup from "../../components/InputComponents/SelectBoxLookup"
import SubcategorySelectBoxLookup from "../../components/InputComponents/SubcategorySelectBoxLookup"
// import ListingPreview from "../../../../components/ListingPreview"
import { Spinner } from "react-bootstrap"
import CouponFields from "./CouponFields"
import ListingUrlField from "./ListingUrlField"

import {
  AVAILABILITY_OPTIONS_LIST,
  LISTING_TYPE_OPTIONS
} from "./formOptions"

import { FIELD_VALIDATIONS } from "./formErrors"

class SubmitListingForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      valid_fields: {
        name: undefined,
        item_url: undefined,
        listing_type: undefined,
        start_at: undefined,
        description: undefined,
        brand_id: undefined,
        price: undefined,
        department_id: undefined,
        category_id: undefined,
        subcategory_id: undefined,
      },
      form_data: {
        listing_type: "deal",
        listing_time: "future",
        name: undefined,
        department_id: undefined,
        category_id: undefined,
        subcategory_id: undefined,
        description: undefined,
        brand_id: undefined,
        tag_ids: undefined,
        start_at: undefined,
        expire_at: undefined,
        coupon_type: undefined,
        coupon_code: undefined,
        availability: undefined,
        price: undefined,
        price_prev: undefined,
        creator_type: undefined,
        product_url: undefined,
        url: undefined,
        discount_amount: undefined
      },
      activeCategories: []
    }
  }

  handleSubmit = () => {
    const { form_data } = this.state
    const { submitForm } = this.props

    submitForm(form_data)
  }

  validateForm = () => {
    const {
      valid_fields: {
        name,
        item_url,
        listing_type,
        start_at,
        description,
        brand_id,
        price,
        department_id,
        category_id,
        subcategory_id,
        coupon_type
      },
      valid_fields,
      form_data
    } = this.state

    let validFields = false

    switch (form_data.listing_type) {
      case "deal":
        validFields = (name
          && item_url
          && listing_type
          && start_at
          && description
          && brand_id
          && price
          && department_id
          && category_id
          && subcategory_id)
        break
      case "coupon":
        validFields = (name
          && item_url
          && coupon_type
          && listing_type
          && start_at
          && description
          && department_id
          && category_id)
        break
      case "sale_event":
        validFields = (name
          && item_url
          && listing_type
          && start_at
          && description
          // && brand_id
          && department_id
          && category_id)
        break
      default:
        validFields = false
    }

    if (validFields) {
      this.handleSubmit()
    } else {
      _.map(valid_fields, (value, key) => {
        if (value === undefined) {
          valid_fields[key] = false
        }
      })

      this.setState({ valid_fields })
    }
  }

  handleAttributeChange = event => {
    const {
      form_data,
      valid_fields
    } = this.state

    if (Array.isArray(event)) {
      // For multi select boxes
      const objects = []

      event.map(e => objects.push(e.value))

      form_data[event[0].name] = objects

      const valid = !_.isEmpty(event[0].value)

      if (["product_url", "url"].includes(event[0].name)) {
        valid_fields.item_url = valid
      } else {
        valid_fields[event[0].name] = valid
      }
    } else {
      // normal input fields
      const {
        name,
        value
      } = event

      form_data[name] = value

      if (["listing_time"].includes(name)) {
        if (value === "started") {
          valid_fields.start_at = true
          form_data.start_at = undefined
        } else {
          valid_fields.start_at = value === "started"
        }
      } else if (["start_at"].includes(name)) {
        valid_fields[name] = !_.isEmpty(Date.parse(value).toString())
      } else if (["product_url", "url"].includes(name)) {
        valid_fields.item_url = !_.isEmpty(value)
      } else {
        valid_fields[name] = !_.isEmpty(value)
      }
    }

    this.setState({ form_data })
  }

  handleCategoryChange = event => {
    // set value of Category in form_data with handleAttributeChange
    this.handleAttributeChange(event)
  }

  handleSubcategoryChange = event => {
    // set value of subcategory in form_data with handleAttributeChange
    this.handleAttributeChange(event)
  }

  handleListingTimeChange = event => {
    this.handleAttributeChange(event)
  }

  handleListingTypeUpdate = event => {
    const {
      listtingTypeChange
    } = this.props

    listtingTypeChange(event)

    this.handleAttributeChange(event)
  }

  onDepartmentSelect = event => {
    const {
      valid_fields,
      form_data
    } = this.state

    let valid = false

    if (event.value !== undefined) {
      valid = !_.isEmpty(event.value.toString())
    }

    valid_fields.department_id = valid

    this.setState({
      form_data: {
        ...form_data,
        ...{
          department_id: event.value,
          category_id: undefined,
          subcategory_id: undefined
        }
      }
    }, () => this.setState({
      activeCategories: this.categoriesSelectOptions()
    }))
  }

  onCategorySelect = event => {
    const {
      valid_fields,
      form_data
    } = this.state

    let valid = false

    if (event.value !== undefined) {
      valid = !_.isEmpty(event.value.toString())
    }

    valid_fields.category_id = valid

    this.setState({
      form_data: {
        ...form_data,
        ...{
          category_id: event.value,
          subcategory_id: undefined
        }
      }
    })
  }

  onSubcategorySelect = event => {
    const {
      valid_fields,
      form_data
    } = this.state

    let valid = false

    if (event.value !== undefined) {
      valid = !_.isEmpty(event.value.toString())
    }

    valid_fields.subcategory_id = valid

    this.setState({ form_data: { ...form_data, ...{ subcategory_id: event.value } } })
  }

  errorMessage = (attribute, value) => {
    const message = _.find(FIELD_VALIDATIONS, ["field", attribute])

    if (!value && value !== undefined) {
      return (
        <div className="err-msg">
          { message.message }
        </div>
      )
    }

    return null
  }

  // Options Selections
  departmentsSelectOptions = () => {
    const { departments } = this.props
    return departments
  }

  categoriesSelectOptions = () => {
    const { form_data: { department_id } } = this.state
    const { categories } = this.props
    if (department_id === undefined) { return ([]) }
    if (department_id === "all") { return categories }
    return _.filter(categories, ["relationships.department.id", `${department_id}`])
  }

  subcategoriesSelectOptions = () => {
    const { form_data: { category_id } } = this.state
    const { subcategories } = this.props
    if ([undefined, "all"].includes(category_id)) { return _.filter(subcategories, ["attributes.slug", "all"]) }
    return _.filter(subcategories, ["relationships.category.id", `${category_id}`])
  }

  render() {
    const {
      valid_fields,
      form_data: {
        name,
        description,
        listing_type,
        url,
        coupon_type,
        coupon_code,
        start_at,
        expire_at,
        tag_ids,
        listing_time,
        availability,
        price,
        price_prev,
        creator_type,
        product_url,
        discount_amount,
        department_id,
        category_id
      },
      activeCategories
    } = this.state

    const {
      tags,
      disableSubmit
    } = this.props

    return (
      <div className="submit-listing-form">
        <div className="row">
          <div className="col-md-6">
            <SelectBox
              title="Listing Type"
              name="listing_type"
              objects={LISTING_TYPE_OPTIONS}
              value={listing_type}
              errorMessage={this.errorMessage("listing_type", valid_fields.listing_type)}
              selectChange={this.handleListingTypeUpdate} />
          </div>
          <div className="col-md-6">
            <SelectBox
              title="Department"
              name="department_id"
              value={department_id}
              objects={this.departmentsSelectOptions()}
              errorMessage={this.errorMessage("department_id", valid_fields.department_id)}
              selectChange={this.onDepartmentSelect} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SelectBox
              title="Category"
              name="category_id"
              value={category_id}
              objects={activeCategories}
              errorMessage={this.errorMessage("category_id", valid_fields.category_id)}
              includeBlank
              selectChange={this.onCategorySelect} />
          </div>
          <div className="col-md-6">
            { listing_type === "deal"
              && (
                <SubcategorySelectBoxLookup
                  title="Subcategory"
                  name="subcategory_id"
                  isMultiSelect={false}
                  selectedDepartment={department_id}
                  selectedCategory={category_id}
                  errorMessage={this.errorMessage("subcategory_id", valid_fields.subcategory_id)}
                  selectChange={this.onSubcategorySelect} />
              )
            }
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Title</label>
              <input type="text" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={name} name="name" placeholder="Name of the deal" className="form-control" />
              { this.errorMessage("name", valid_fields.name) }
            </div>
          </div>
          <div className="col-md-6">
            <ListingUrlField
              listing_type={listing_type}
              product_url={product_url}
              url={url}
              handleAttributeChange={this.handleAttributeChange}
              errorMessage={this.errorMessage(listing_type === "deal" ? "product_url" : "url", valid_fields.item_url)} />
          </div>
        </div>
        <div className="row">
          { listing_type === "coupon"
            && (
              <div className="col-md-6">
                <CouponFields
                  coupon_type={coupon_type}
                  discount_amount={discount_amount}
                  errorMessage={this.errorMessage("coupon_type", valid_fields.coupon_type)}
                  handleAttributeUpdate={this.handleAttributeChange} />
              </div>
            )
          }

          { listing_type !== "sale_event"
            && (
              <div className="col-md-6">
                <div className="form-group">
                  <label>Voucher code (optional)</label>
                  <input type="text" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={coupon_code} name="coupon_code" placeholder="Optional" className="form-control" />
                </div>
              </div>
            )
          }
          <div className="col-md-6">

            { listing_type !== "coupon"
              && (
                <SelectBoxLookup
                  title="Brand"
                  name="brand_id"
                  isMultiSelect={false}
                  errorMessage={this.errorMessage("brand_id", valid_fields.brand_id)}
                  selectChange={this.handleAttributeChange} />
              )
            }
          </div>
        </div>

        <div className="form-group mt-3" onChange={e => this.handleListingTimeChange({ value: e.target.value, name: e.target.name })}>
          <div className="strong mb-3">Start</div>
          <div className="custom-control custom-radio">
            <label>
              <input className="custom-control-input" type="radio" name="listing_time" value="started" defaultChecked={listing_time === "started"} />
              {" "}
              <span className="custom-control-label">
                The deal has already started
              </span>
            </label>
          </div>
          <div className="custom-control custom-radio">
            <label>
              <input className="custom-control-input" type="radio" name="listing_time" value="future" defaultChecked={listing_time === "future"} />
              {" "}
              <span className="custom-control-label">
                The deal is in the future
              </span>
            </label>
          </div>
        </div>

        <div className="row">
          { listing_time === "future"
            && (
              <div className="form-group col-md-6">
                <label>Start Date:</label>
                <br />
                <DatePicker
                  name="start_at"
                  onChange={e => this.handleAttributeChange({ value: e, name: "start_at" })}
                  timeInputLabel="Time:"
                  showTimeInput
                  selected={start_at}
                  isClearable
                  shouldCloseOnSelect={false}
                  closeOnScroll={e => e.target === document}
                  className="form-control"
                  placeholderText="DD-MM-YYYY"
                  dateFormat="dd/MM/yyyy h:mm aa" />
                { this.errorMessage("start_at", valid_fields.start_at) }
              </div>
            )
          }

          <div className="form-group col-md-6">
            <label>
              End Date
              {" "}
              <i>(optional):</i>
            </label>
            <br />
            <DatePicker
              name="expire_at"
              onChange={e => this.handleAttributeChange({ value: e, name: "expire_at" })}
              timeInputLabel="Time:"
              showTimeInput
              selected={expire_at}
              isClearable
              shouldCloseOnSelect={false}
              closeOnScroll={e => e.target === document}
              className="form-control"
              placeholderText="DD-MM-YYYY"
              dateFormat="dd/MM/yyyy h:mm aa" />
          </div>
        </div>

        <div className="form-group">
          <label>Description</label>
          <textarea type="text" name="description" placeholder="Details about the deal" className="form-control" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={description} style={{ height: "150px" }} />
          { this.errorMessage("description", valid_fields.description) }
        </div>

        <div className="row">
          <div className="col-md-6">
            <SelectBox
              title="Tags"
              name="tag_ids"
              isMultiSelect
              objects={tags}
              value={tag_ids}
              selectChange={this.handleAttributeChange} />
          </div>
          <div className="col-md-6">
            <SelectBox
              title="Availability"
              name="availability"
              isMultiSelect
              objects={AVAILABILITY_OPTIONS_LIST}
              value={availability}
              selectChange={this.handleAttributeChange} />
          </div>
        </div>

         /* <SelectBox
            title="Tags"
            name="tag_ids"
            isMultiSelect
            objects={tags}
            value={tag_ids}
            selectChange={this.handleAttributeChange} /> */

        { listing_type === "deal"
          && (
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="price">Deal Price:</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input type="number" name="price" placeholder="How much is it?" className="form-control" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={price} />
                  </div>
                  { this.errorMessage("price", valid_fields.price) }
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="price_prev">Was price / RRP:</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input type="number" name="price_prev" placeholder="How much was it?" className="form-control" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={price_prev} />
                  </div>
                </div>
              </div>
            </div>
          )
        }

        <div className="association mt-3">
          <div className="strong mb-3">Association</div>
          <div className="form-group" onChange={e => this.handleAttributeChange({ value: e.target.value, name: "creator_type" })}>
            <div className="custom-control custom-radio">
              <label>
                <input className="custom-control-input" type="radio" name="creator_type" value="affiliated" defaultChecked={creator_type === "affiliated"} />
                {" "}
                <span className="custom-control-label">
                  I have an affiliation with this brand / product
                </span>
              </label>
            </div>
            <div className="custom-control custom-radio">
              <label>
                <input className="custom-control-input" type="radio" name="creator_type" value="fan" defaultChecked={creator_type === "fan"} />
                {" "}
                <span className="custom-control-label">
                  I&apos;m just a fan sharing a listing I found
                </span>
              </label>
            </div>
          </div>
        </div>

        <button type="submit" onClick={this.validateForm} disabled={disableSubmit} className=" btn btn-main rounded-pill mb-5">
          { disableSubmit
            ? <Spinner animation="border" role="status" size="sm" />
            : `Submit a ${listing_type === "coupon" ? _.startCase("voucher") : _.startCase(listing_type)}`
          }
        </button>
      </div>
    )
  }
}

export default SubmitListingForm
