import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'

import {
  calendarIcon,
  emailIcon,
  navHeartIcon,
  deleteIcon
} from '../../../../../components/Icons'
import * as reducers from '../../../reducers'

export default function Sidebar () {
  const currentUser = useSelector(reducers.getCurrentUser)
  const userSlug = _.get(currentUser, 'attributes.slug')
  const userAvatar = _.get(currentUser, 'links.avatar.mini.url')
  const { pathname } = useLocation()
  const profileUrl = `/spotter/${userSlug}/account`
  const profileClass = pathname === profileUrl ? 'active' : ''
  const emailUrl = `/spotter/${userSlug}/account/email`
  const emailClass = pathname === emailUrl ? 'active' : ''
  const calendarUrl = `/spotter/${userSlug}/my-calendar`
  const calendarClass = pathname === calendarUrl ? 'active' : ''
  const publicSettingsUrl = `/spotter/${userSlug}/account/public-profile-settings`
  const publicSettingsClass = pathname === publicSettingsUrl ? 'active' : ''
  const deleteAccountUrl = `/spotter/${userSlug}/account/delete-account`
  const deleteAccountClass = pathname === deleteAccountUrl ? 'active' : ''
  return (
    <section className='account-menu'>
      <h3 className='d-none d-lg-block'>My Account Details</h3>
      <ul className='list-group mt-lg-3'>
        <Link
          className={`link-wrapper list-group-item ${profileClass}`}
          to={profileUrl}
        >
          <img src={userAvatar} className='mr-2 profile-img profile-img-xs' />
          My Account Details
        </Link>
        <Link
          className={`list-group-item ${calendarClass}`}
          to={calendarUrl}
        >
          <img src={calendarIcon} className='mr-2' />
          My Calendar
        </Link>
        <Link
          className={`list-group-item ${emailClass}`}
          to={emailUrl}
        >
          <img src={emailIcon} className='mr-2' />
          Email Notifications
        </Link>
        <Link
          className={`list-group-item ${publicSettingsClass}`}
          to={publicSettingsUrl}
        >
          <img src={navHeartIcon} className='mr-2' />
          Public Profile Settings
        </Link>
        <Link
          className={`list-group-item ${deleteAccountClass}`}
          to={deleteAccountUrl}
        >
          <img src={deleteIcon} className='mr-2' width='24px' height='24px' />
          Delete Account
        </Link>
      </ul>
    </section>
  )
}
