import React, { useState } from 'react'
import axios from 'axios'

import {
  aboutIcon,
  crossIcon,
  impressionIcon
} from '../../../../../components/Icons'

import { gon } from '../../../../../../constant'
import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'

export default function RegistrationForm ({
  handleBack,
  userData,
  wtidCookie,
  wtidUtmCookies,
  prevUrl
}) {
  const [username, setUserName] = useState(null)
  const [password, setPassowrd] = useState(null)
  const [type, setType] = useState('password')
  const [remember, setRemember] = useState(false)
  const [errors, setErrors] = useState([])

  const validateForm = () => {
    if (!username && !password) {
      setErrors({ errors: ['Username can’t be blank', 'Password can\'t be blank'] })
      return false
    }

    if (!username) {
      setErrors({ errors: ['Username can’t be blank'] })
      return false
    }

    if (!password) {
      setErrors({ errors: ['Password can\'t be blank'] })
      return false
    }

    return true
  }

  const handleAccountCreation = async () => {
    if (validateForm()) {
      const path = `${gon.urls.api}/auth/register`
      const data = {
        email: userData.email,
        password: password,
        password_confirmation: password,
        remember_me: remember,
        username: username
      }

      const wtidUtmCookiesData = wtidUtmCookies || {}
      return axios
        .post(path, {
          user: data,
          wtid: wtidCookie,
          ...wtidUtmCookiesData
        })
        .then((response) => {
          if (response.status !== 200) {
            handleBack({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )
          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => handleBack({ errors: error?.data?.errors }))
    }
  }

  return (
    <div className='cba-simple-register'>
      {
        errors?.errors?.length
          ? (
            <div className='alert alert-danger alert-block error-label'>
              <button className='close' data-dismiss='alert' type='button'>
                <img src={crossIcon} />
              </button>
              <div className='mt-4'>
                {errors.errors.map((error, key) => (
                  <p key={key} className='text-dark'>
                    <img src={aboutIcon} className='mr-3' />
                    {error}
                  </p>
                ))}
              </div>
            </div>
            )
          : null
      }
      <div className='mb-40'>
        <input
          placeholder='Username'
          required
          name='username'
          value={username}
          className='form-control'
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      <div className='form-field-password d-flex'>
        <input
          placeholder='Password'
          name='password'
          required
          value={password}
          type={type}
          className='form-control'
          onChange={(e) => setPassowrd(e.target.value)}
        />
        <img
          onClick={() => setType(type === 'text' ? 'password' : 'text')}
          src={impressionIcon}
        />
      </div>
      <div className='remember-chk'>
        <div className='custom-control custom-checkbox' style={{ paddingLeft: '2rem' }}>
          <input
            className='custom-control-input'
            id='customCheck1'
            type='checkbox'
            onChange={() => setRemember(!remember)}
            defaultChecked={remember}
          />
          <label className='custom-control-label' htmlFor='customCheck1'>
            Remember me
          </label>
        </div>
      </div>
      <hr />
      <div className='d-flex'>
        <div className='d-flex w-100 justify-content-center'>
          <button
            type='button'
            className='btn btn-outline-dark rounded-pill mx-2 bck-btn'
            onClick={handleBack}
          >
            Back
          </button>
          <button
            type='button'
            className='btn btn-main rounded-pill mx-2 offer-btn'
            onClick={() => handleAccountCreation()}
          >
            Get Offer
          </button>
        </div>
      </div>
    </div>
  )
}
