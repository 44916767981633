import React, { useRef } from 'react'
import moment from 'moment'

import { freshnessOptions } from '../checkboxOptions'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function FreshnessOptions ({
  selectedPriceRange, handleSelect, handleClearClick,
  selectedCustomPriceFreshnessRange, handleFilterPill,
  sectionPosition
}) {
  const accordionHeader = useRef(null)

  const priceSectionPostion = window.location?.pathname?.includes('/search/') ? 9 : sectionPosition
  useAccordionOpenHeader(accordionHeader, priceSectionPostion, 'Price Freshness')

  function calculateTimePeriod() {
    return moment(selectedCustomPriceFreshnessRange[1]).diff(moment(selectedCustomPriceFreshnessRange[0]), 'days')
  }
  function getSelectedLabel (value) {
    return _.find(freshnessOptions?.values, (option) => option.value === selectedPriceRange)?.label
  }
  function handleFreshnessOptionsDisplay () {
    if (!_.isEmpty(selectedCustomPriceFreshnessRange)) {
      return (
        <div aria-labelledby='headingFreshness' className='collapse' id='collapseFreshness'>
          <div className='p-3'>
            <div className='checklist-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  type='checkbox'
                  name='lastPriceRange'
                  disabled
                  defaultChecked
                />
                <label className='custom-control-label'>
                  {`0 - ${calculateTimePeriod()} ${calculateTimePeriod() === 1 ? 'Day' : 'Days'}`}
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div aria-labelledby='headingFreshness' className='collapse' id='collapseFreshness'>
        {selectedPriceRange?.length > 0 &&
          <div className='filter-pills-container'>
            <div
              type='button'
              onClick={() => handleFilterPill('lastPriceRange', selectedPriceRange)}
              className='filter-pill ml-auto mr-2'
            >
              {getSelectedLabel(selectedPriceRange)}
            </div>
          </div>}
        <div className='p-3'>
          <div className='checklist-group'>
            {_.filter(freshnessOptions.values, sFreshnessOption => selectedPriceRange !== sFreshnessOption.value)?.map(fresh => (
              <div key={`freshness-${fresh.key}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`freshnessCheck${fresh.key}`}
                  type='checkbox'
                  name='lastPriceRange'
                  checked={fresh.value === selectedPriceRange}
                  value={fresh.value}
                  onChange={e => handleSelect({ ...e, arrValue: fresh.value })}
                />
                <label className='custom-control-label' htmlFor={`freshnessCheck${fresh.key}`}>
                  {fresh.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='list-group-item p-0'>
      <div id='headingFreshness'>
        <div ref={accordionHeader} aria-controls='collapseFreshness' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseFreshness' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Price Freshness</div>
            {selectedPriceRange?.length > 0 && <span className='selected-filter-preview'>{getSelectedLabel(selectedPriceRange)}</span>}
            {selectedPriceRange?.length > 0 &&
              <button
                type='button'
                name='lastPriceRange'
                onClick={e => handleClearClick(e)}
                disabled={!_.isEmpty(selectedCustomPriceFreshnessRange)}
                className='btn btn-clear ml-auto mr-2'
              >
                Clear selection
              </button>}
          </div>
        </div>
      </div>
      {handleFreshnessOptionsDisplay()}
    </div>
  )
}
