import React from 'react'
import { iconCheckFilled } from '../../../../../components/Icons'

function PromptProgressBar ({ steps, completedSteps = [] }) {
  return (
    <div className='d-flex w-100 stepper-list align-items-center justify-content-between px-lg-3'>
      {steps.map((li, index) => {
        const isCompleted = completedSteps[index] ? 'completed' : ''
        return (
          <div key={`ppb-${index}`} className='step-item'>
            <div className={`current-progress-point ${isCompleted}`}>
              {isCompleted ? <img src={iconCheckFilled} /> : '?'}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PromptProgressBar
