import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Loader from '../../../../../components/Loader'
import { SaleEventCard } from '../../../../../components/ListingCards'

import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { getBaseUrl } from '../../../reducers'
import { getElasticSaleEventResults } from '../../../requesters/'
import { PER_PAGE_LIMIT } from '../../../../../../constant'

function NoMoreDivider ({ countZero }) {
  return (
    <div className='col-12 text-center'>
      {
        countZero
          ? <div>No matches found</div>
          : (
            <div>
              No More Results
              {/* {reachedLimit && ', Please refine your search'} */}
            </div>
            )
      }
      <hr className='mt-0' />
    </div>
  )
}

function SearchSaleEvent ({ isFilter, searchMeta, value, order, keyword, filterOptions, handleStateUpdate, handleSearchFilterDataLayer }) {
  const baseUrl = useSelector(getBaseUrl)

  const {
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames
  } = filterOptions

  const params = {
    query: keyword,
    is_top_deal: false,
    per_page: PER_PAGE_LIMIT,
    from: PER_PAGE_LIMIT * 0,
    order: order,
    value: value,
    store_names: storeNames,
    brand_names: brandNames,
    department_names: departmentNames,
    category_names: categoryNames,
    subcategory_names: subcategoryNames
  }

  const [
    items,
    isNextLoading,
    isPreLoading,
    itemUsers,
    preItems,
    preItemUsers,
    pageNo,
    prePageNo,
    isDataFinished,
    initPage,
    loadNext,
    loadPre,
    loadNew,
    resp
  ] = useInfiniteScroll({
    baseUrl,
    method: getElasticSaleEventResults,
    params,
    storedPage: 0
  })

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  const nextData = _.map(items, (itemId, index) => {
    const item = {
      ...itemId,
      type: 'saleEvent',
      attributes: {
        name: itemId.name,
        commentsCount: itemId.comments_count,
        impressionsCount: itemId.impressions_count,
        durationPeriod: itemId.duration_period,
        upvotesCount: itemId.upvotes_count,
        storeName: itemId.store_name,
        offerText: itemId.offer_text,
        expireAt: itemId.expire_at
      },
      links: {
        saleEventUrl: `/shop/sale-event/${itemId.slug}`,
        imageUrl: itemId.sale_event_image
      }
    }

    // We determine if we want to show the rank or not, by passing a number larger than 50, it will hide the rank for listing cards
    // We do this when we are filtering and the filter is not TOP
    const position = _.includes(
      ['rank', 'fixed_global_score'],
      _.get(order, 'column')
    )
      ? index + 1
      : 999
    return (
      <SaleEventCard
        item={item}
        type={item.type}
        position={position}
        id={itemId.id}
        wrapperClass='col-sm-6 col-lg-4'
        key={`${item.type}-${itemId.id}`}
        isRankingCard
        cardStyle='center'
        currentSection={'Trending' + _.upperFirst(item.type)}
        sectionPosition={1}
      />
    )
  })

  const preData = _.map(preItems, (itemId, index) => {
    const item = {
      ...itemId,
      type: 'saleEvent',
      attributes: {
        name: itemId.name,
        commentsCount: itemId.comments_count,
        impressionsCount: itemId.impressions_count,
        durationPeriod: itemId.duration_period,
        upvotesCount: itemId.upvotes_count
      },
      links: {
        saleEventUrl: `/shop/sale-event/${itemId.slug}`,
        imageUrl: itemId.store_logo
      }
    }

    // We determine if we want to show the rank or not, by passing a number larger than 50, it will hide the rank for listing cards
    // We do this when we are filtering and the filter is not TOP
    const position = _.includes(
      ['rank', 'fixed_global_score'],
      _.get(order, 'column')
    )
      ? index + 1
      : 999
    return (
      <SaleEventCard
        item={item}
        type={item.type}
        position={position}
        id={itemId.id}
        wrapperClass='col-sm-6 col-lg-4'
        key={`${item.type}-${itemId.id}`}
        isRankingCard
        cardStyle='center'
        currentSection={'Trending' + _.upperFirst(item.type)}
        sectionPosition={1}
      />
    )
  })

  useEffect(() => {
    if (pageNo > 0 && !isNextLoading && isNextVisible) loadNext()
  }, [isNextVisible, pageNo])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) loadPre()
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    if (resp && resp.counts != null) {
      const { counts } = resp

      const newState = {}
      if (!isFilter && !order && !value) {
        if (counts.sale_events) {
          newState.isSaleEnabled = true
        } else {
          newState.isSaleEnabled = false
        }
      }

      newState.saleCount = counts.sale_events || 0
      newState.isFilter = false
      newState.isSaleReady = true
      handleStateUpdate(newState)
    }
  }, [resp])

  useEffect(() => {
    async function loadData () {
      if (keyword) {
        const resp = await loadNew()
        handleSearchFilterDataLayer(resp, 'sale')
      }
    }
    loadData()
  }, [
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    keyword,
    searchMeta,
    order,
    value
  ])

  return isDataFinished && !(isNextLoading || isPreLoading) && _.isEmpty(nextData)
    ? <span>Oops! No results found. Try using different filters.</span>
    : (
      <div className='row listing-grid'>
        <div className='listing-card col-12'>
          <Loader isLoading={isPreLoading} />
        </div>
        <div style={{ height: '1px' }} ref={preRef} />
        {preData}
        {nextData}
        <div className='listing-card col-12'>
          <Loader isLoading={!isDataFinished && isNextLoading} />
        </div>
        <div style={{ height: '1px' }} ref={nextRef} />
        {isDataFinished && !(isNextLoading || isPreLoading) && <NoMoreDivider countZero={!items.length} />}
      </div>)
}

export default SearchSaleEvent
