/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getDepartments = (baseUrl, data = {}) => {
  const path = `${baseUrl}/departments`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getDepartment = (baseUrl, departmentId, data = {}) => {
  const path = `${baseUrl}/departments/${departmentId}`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}