import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loader from '../../../../../components/Loader'
import { DealCard } from '../../../../../components/ElasticListingCards'

import { dealOptions } from './sortOptions'

import AdvertisementList from '../../AdvertisementList'
import {
  getBaseUrl,
  // getElasticBaseUrl,
  getDepartmentBySlug,
  getCategoryBySlug,
  getSubcategoryBySlug
} from '../../../reducers'
import {
  getTopElasticDeals,
  getStoreByIdentifier,
  getBrandByIdentifier,
  getAllElasticDeals
} from '../../../requesters'

import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnDOMTree from '../../BiInfiniteScroll/useElementOnDOMTree'

import { PER_PAGE_LIMIT } from '../../../../../../constant'
import { useMediaQuery } from 'react-responsive'

function ElasticList ({
  sortOptionValue,
  listType,
  handlePriceRangeChange,
  filterOptions: {
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    priceRange,
    discountRange,
    paymentMethods,
    lastPriceRange,
    attributeFilters
  },
  setDefaultDepartment,
  setDefaultCategory,
  setDefaultSubcategory,
  setDefaultStore,
  setDefaultBrand,
  makeReady,
  sortColumn,
  sortDirection,
  topPriceDropsFollowingPageFilter,
  handleTotalResultsCount,
  mbColumn
}) {
  const getCurrentPage = JSON.parse(window.localStorage.getItem('price_drop_page') || '{}')
  const [activeStore, setActiveStore] = useState(undefined)
  const [activeBrand, setActiveBrand] = useState(undefined)
  const [ready, setReady] = useState(false)
  const {
    department, category, subcategory, store, brand
  } = useParams()

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }
  const showMobileColumn = useMediaQuery({ query: '(max-width: 575px)' })

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  function listOrder () {
    if (listType === 'new-deals' || listType === 'new-price-drops') return 'last_price_date'
    if (departmentNames.length || categoryNames.length || subcategoryNames.length || department) return 'fixed_department_score'
    if (listType === 'all-products') return ['deal_views', 'last_price_date']
    return 'fixed_global_score'
  }
  const [column, setColumn] = useState(listOrder())
  const [direction, setDirection] = useState('desc')

  const baseUrl = useSelector(getBaseUrl)

  const activeDepartment = useSelector(state => getDepartmentBySlug(state, department))
  const activeCategory = useSelector(state => getCategoryBySlug(state, category))
  const activeSubcategory = useSelector(state => getSubcategoryBySlug(state, subcategory))

  const params = {
    is_top_deal: false,
    per_page: PER_PAGE_LIMIT,
    order: direction,
    value: column,
    values: Array.isArray(column) ? column : [column],
    store_names: activeStore ? [_.get(activeStore, 'attributes.name')] : storeNames.filter(store => store !== undefined),
    brand_names: activeBrand ? [_.get(activeBrand, 'attributes.name')] : brandNames.filter(brand => brand !== undefined),
    department_names: activeDepartment ? [_.get(activeDepartment, 'attributes.name')] : departmentNames,
    category_names: activeCategory ? [_.get(activeCategory, 'attributes.name')] : categoryNames,
    subcategory_names: activeSubcategory ? [_.get(activeSubcategory, 'attributes.name')] : subcategoryNames,
    by_payment_methods: paymentMethods,
    price: priceRange || [0, null],
    price_shift: discountRange.length ? discountRange.map(e => e * -1).reverse() : [-1, 0],
    last_price_date: lastPriceRange,
    applyFollowingPageFilter: topPriceDropsFollowingPageFilter,
    attribute_filters: attributeFilters
  }

  const [items, isNextLoading, isPreLoading, itemUsers, preItems, preItemUsers, pageNo, prePageNo, isDataFinished, initPage, loadNext, loadPre, loadNew] = useInfiniteScroll({
    baseUrl,
    method: listType.toLowerCase() === 'all-products' ? getAllElasticDeals : getTopElasticDeals,
    params,
    storedPage: getCurrentPage.page || 0
  })

  useElementOnDOMTree({ id: getCurrentPage?.id || '' })

  const hanldeStorePagination = (pos, id) => {
    const options = isFilterOptionAvailable()
    options.isAvailable && window.localStorage.setItem('price_drop_filter', JSON.stringify(options.options))
    window.localStorage.setItem('price_drop_page', JSON.stringify({ page: Math.floor(pos / PER_PAGE_LIMIT), id }))
  }

  useEffect(() => {
    // This is called when user click back from details page
    // Based on page number, user is scrolled to previous clicked product position
    async function fetchInitialPage () {
      makeReady()
      const response = await loadNext()
      typeof getCurrentPage.page === 'number' && getCurrentPage.page >= 0 && window.localStorage.setItem('price_drop_page', JSON.stringify({ ...getCurrentPage, check: true }))
      Object.keys(response?.counts || {})?.length && handleTotalResultsCount(response?.counts)
      Object.keys(response?.price_range || {})?.length && handlePriceRangeChange([response?.price_range?.min_price, response?.price_range?.max_price])
    }

    if (ready && !isNextLoading) fetchInitialPage()
  }, [ready])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) loadPre()
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    async function fetchStore () {
      const { response } = await getStoreByIdentifier(baseUrl, store)
      setActiveStore(response.store[_.first(Object.keys(response.store))])
    }
    async function fetchBrand () {
      const { response } = await getBrandByIdentifier(baseUrl, brand)
      setActiveBrand(response.brand[_.first(Object.keys(response.brand))])
    }

    if (store) fetchStore()
    if (brand) fetchBrand()
  }, [store, brand])

  useEffect(() => {
    if (department && !activeDepartment) return
    if (category && !activeCategory) return
    if (subcategory && !activeSubcategory) return
    if (store && !activeStore) return
    if (brand && !activeBrand) return
    if (!ready) {
      if (activeDepartment) setDefaultDepartment(_.get(activeDepartment, 'attributes.name'))
      if (activeCategory) setDefaultCategory(_.get(activeCategory, 'attributes.name'))
      if (activeSubcategory) setDefaultSubcategory(_.get(activeSubcategory, 'attributes.name'))
      if (activeStore) setDefaultStore(_.get(activeStore, 'attributes.name'))
      if (activeBrand) setDefaultBrand(_.get(activeBrand, 'attributes.name'))
      setReady(true)
    }
  }, [
    department, activeDepartment,
    category, activeCategory,
    subcategory, activeSubcategory,
    store, activeStore,
    brand, activeBrand
  ])

  useEffect(() => {
    if (pageNo > 0 && !isNextLoading && isNextVisible) loadNext()
  }, [isNextVisible, pageNo])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      removeStorePage()
    })
    return () => {
      window.removeEventListener('beforeunload', () => null)
      removeStorePage()
    }
  }, [])

  useEffect(() => {
    async function fetchInitialPage () {
      makeReady()
      const response = await loadNew()
      Object.keys(response?.counts || {})?.length && handleTotalResultsCount(response?.counts)
      Object.keys(response?.price_range || {})?.length && handlePriceRangeChange([response?.price_range?.min_price, response?.price_range?.max_price])
    }
    if (ready) fetchInitialPage()
  }, [
    column, direction,
    brandNames, storeNames,
    departmentNames, categoryNames,
    subcategoryNames, priceRange,
    discountRange, paymentMethods,
    lastPriceRange, ready,
    sortColumn, sortDirection, attributeFilters
  ])

  useEffect(() => {
    const option = dealOptions.values.find(opt => opt.key === parseInt(sortOptionValue, 10))
    if (option) {
      setColumn(option.value)
      setDirection(option.order)
    }
  }, [sortOptionValue])

  const removeStorePage = () => {
    const getPage = JSON.parse(window.localStorage.getItem('price_drop_page') || '{}')
    getPage.check && window.localStorage.removeItem('price_drop_page')
  }

  const isFilterOptionAvailable = () => {
    const availableFilter = { storeNames, brandNames, departmentNames, categoryNames, subcategoryNames, priceRange, discountRange, paymentMethods, lastPriceRange, attributeFilters }
    const options = {}
    Object.keys(availableFilter).forEach(li => {
      if (availableFilter[li].constructor === Array && availableFilter[li].length) {
        options[li] = availableFilter[li]
      } else if(typeof availableFilter[li] === 'object' && !Array.isArray(availableFilter[li])) {
        options[li] = availableFilter[li]
      }
    })
    return {
      options,
      isAvailable: Object.keys(options).length
    }
  }

  return (
    <>
      <br />
      {
        // Show when there is no data to see
        isDataFinished && items.length === 0
          ? <span>Oops! No results found. Try using different filters.</span>
          : (
            <>
              <AdvertisementList location={`ranking-${listType}-top`} position='middle' page='ranking' list={listType} />
              <div className={`row ${showMobileColumn && 'listing-grid mb-price-drops-2w'}`}>
                <div className='listing-card col-12'><Loader isLoading={isPreLoading} /></div>
                <div style={{ height: '1px' }} ref={preRef} />
                {preItems && preItems.map((deal, index) => (
                  <DealCard
                    deal={deal}
                    wrapperClass={`${showMobileColumn ? mbColumn === 'twoColumn' ? 'col-6 col-sm-6 col-lg-4' : 'col-sm-6 col-lg-4' : 'mx-3'}`}
                    position={column === 'fixed_global_score' ? index + 1 : 999}
                    currentSection='TrendingPriceDrops'
                    sectionPosition={1}
                    hanldeStorePagination={() => {
                      const dealPage = (initPage * PER_PAGE_LIMIT) - preItems.length + (index + 1)
                      return hanldeStorePagination(dealPage, deal.id)
                    }}
                    key={`deal-${deal.id}`}
                    mobileColumn={mbColumn === 'twoColumn' && showMobileColumn}
                    user={!(deal.user_name && deal.user_slug && deal.user_avatar_url) ? _.first(preItemUsers.filter(user => user.id === deal.user_id)) : null}
                  />
                ))}
                {items && items.map((deal, index) => (
                  <DealCard
                    deal={deal}
                    wrapperClass={`${showMobileColumn ? mbColumn === 'twoColumn' ? 'col-6 col-sm-6 col-lg-4' : 'col-sm-6 col-lg-4' : 'mx-3'}`}
                    position={column === 'fixed_global_score' ? index + 1 : 999}
                    currentSection='TrendingPriceDrops'
                    sectionPosition={1}
                    hanldeStorePagination={() => {
                      const dealPage = initPage * PER_PAGE_LIMIT + (index + 1)
                      return hanldeStorePagination(dealPage, deal.id)
                    }}
                    key={`deal-${deal.id}`}
                    mobileColumn={mbColumn === 'twoColumn' && showMobileColumn}
                    user={!(deal.user_name && deal.user_slug && deal.user_avatar_url) ? _.first(itemUsers.filter(user => user.id === deal.user_id)) : null}
                  />
                ))}
                <div className='listing-card col-12'><Loader isLoading={!isDataFinished && isNextLoading} /></div>
                <div style={{ height: '1px' }} ref={nextRef} />
                {isDataFinished && items.length && (
                  <p style={{ textAlign: 'center', width: '100%' }}>
                    <b>Yay! You have seen it all</b>
                  </p>)}
              </div>
            </>
            )
      }
    </>
  )
}

export default React.memo(ElasticList)
