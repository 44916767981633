import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { getBaseUrl } from '../../../reducers'
import { getTopStores } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

import { FILTER_MIN_LIMIT, FILTER_MAX_LIMIT } from '../../../../../../constant'

export default function StoreOptions ({ handleSelect, handleClearClick, selectedStores, handleFilterPill, sectionPosition }) {
  const [stores, setStores] = useState([])
  const baseUrl = useSelector(getBaseUrl)
  const [seeAll, setSeeAll] = useState(true)

  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Store')

  useEffect(() => {
    const params = {
      per_page: 50
    }

    async function fetchTopStores () {
      const { response } = await getTopStores(baseUrl, params)
      if (_.get(response, 'data.store')) {
        setStores(_.orderBy(response?.data?.store, [store => store.attributes?.name?.toLowerCase()], ['asc']))
      }
    }

    fetchTopStores()
  }, [seeAll])
  const handleSeeAllClick = () => {
    setSeeAll(!seeAll)
  }

  function getLabel (value) {
    return _.find(stores, (store) => store.id === value)?.attributes?.name
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingStore'>
        <div ref={accordionHeader} aria-controls='collapseStore' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseStore' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Store</div>
            {selectedStores?.length > 0 && <span className='selected-filter-preview'>{_.filter(stores, store => selectedStores.includes(store.id)).map(sStore => sStore.attributes.name).join(', ')}</span>}
            {
              selectedStores?.length > 0 &&
                <button
                  type='button'
                  name='storeIds'
                  data-input-name='storeNames'
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      <div aria-labelledby='headingStore' className='collapse' id='collapseStore'>
        {
          selectedStores?.length > 0 &&
            <div className='filter-pills-container'>
              {selectedStores.map((store, index) => (
                <div
                  key={index}
                  type='button'
                  onClick={() => handleFilterPill('storeIds', store)}
                  className='filter-pill ml-auto mr-2'
                >
                  {getLabel(store)}
                </div>
              ))}
            </div>
        }
        <div className='p-3'>
          <div className='checklist-group' style={{ maxHeight: '215px' }}>
            {(_.take(_.filter(stores, sStore => !selectedStores.includes(sStore.id)), (seeAll ? FILTER_MIN_LIMIT : FILTER_MAX_LIMIT))).map(store => (
              <div key={`store-${store.id}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`storeCustomCheck${store.id}`}
                  type='checkbox'
                  checked={selectedStores ? selectedStores.includes(store.id) : null}
                  name='storeIds'
                  value={store.id}
                  data-input-name='storeNames'
                  data-input-value={store?.attributes?.name}
                  onChange={e => handleSelect(e)}
                />
                <label className='custom-control-label' htmlFor={`storeCustomCheck${store.id}`}>
                  {store.attributes.name}
                  <span> </span>
                </label>
              </div>
            ))}
            {stores.length > FILTER_MIN_LIMIT
              ? (<span onClick={handleSeeAllClick} className='see-all-list'>{!seeAll ? 'See less' : 'See more'}</span>)
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
