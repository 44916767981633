import * as types from "./types"

export const loadCategories = keyword => ({
  type: types.CATEGORIES_FETCH,
  keyword
})

export const categoryRequestStart = data => ({
  type: types.CATEGORIES_REQUEST_START,
  data
})

export const categoryRequestSuccess = (data, order, departmentId) => ({
  type: types.CATEGORIES_REQUEST_SUCCESS,
  data,
  order,
  departmentId
})

export const categoryRequestFailure = error => ({
  type: types.CATEGORIES_REQUEST_FAILURE,
  error
})
