import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Loader from '../../../../../components/Loader'
import { SaleEventCard } from '../../../../../components/ListingCards'
import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { getBaseUrl } from '../../../reducers'
import { getTopSaleEvents } from '../../../requesters'
import { PER_PAGE_LIMIT } from '../../../../../../constant'
import NoResultDivider from '../../NoResultDivider'
import ErrorBoundries from '../../../../../components/ErrorBoundry'

function SaleEventsTab({ isFilter, value, order, filterOptions, handleStateUpdate, handleSearchFilterDataLayer }) {
  const baseUrl = useSelector(getBaseUrl)

  const {
    storeIds,
    brandIds,
    departmentIds,
    categoryIds,
    paymentMethods,
    startRange
  } = filterOptions

  const params = {
    is_top_deal: false,
    per_page: PER_PAGE_LIMIT,
    by_order: order && value ? {
      direction: order, column: value
    } : {},
    by_brands: brandIds,
    by_stores: storeIds,
    by_departments: departmentIds,
    by_categories: categoryIds,
    by_payment_methods: paymentMethods,
    by_date_range: startRange,
    doNormalize: false
  }

  const [
    items,
    isNextLoading,
    isPreLoading,
    itemUsers,
    preItems,
    preItemUsers,
    pageNo,
    prePageNo,
    isDataFinished,
    initPage,
    loadNext,
    loadPre,
    loadNew,
    resp,
    respHeader
  ] = useInfiniteScroll({
    baseUrl,
    method: getTopSaleEvents,
    params,
    storedPage: 1,
    pagePagination: true,
    initPageNo: 1
  })

  const formatSaleEvent = (saleEvent) => {
    return {
      ...saleEvent,
      type: 'saleEvent',
      attributes: {
        name: _.get(saleEvent, "attributes.name"),
        commentsCount: _.get(saleEvent, "attributes.comments_count"),
        impressionsCount: _.get(saleEvent, "attributes.impressions_count"),
        durationPeriod: _.get(saleEvent, "attributes.duration_period"),
        upvotesCount: _.get(saleEvent, "attributes.upvotes_count"),
        storeName: _.get(saleEvent, "attributes.store_name"),
        offerText: _.get(saleEvent, "attributes.offer_text"),
        endIn:_.get(saleEvent, "attributes.end_in"),
        expireAt:_.get(saleEvent, "attributes.expire_at")
      },
      links: {
        saleEventUrl: `/shop/sale-event/${_.get(saleEvent, "attributes.slug")}`,
        imageUrl: _.get(saleEvent, "links.image_url")
      }
    }
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  const nextData = _.map(items, (saleEvent, index) => {
    const formatedSaleEvent = formatSaleEvent(saleEvent)

    // We determine if we want to show the rank or not, by passing a number larger than 50, it will hide the rank for listing cards
    // We do this when we are filtering and the filter is not TOP
    const position = _.includes(
      ['rank', 'fixed_global_score'],
      _.get(order, 'column')
    )
      ? index + 1
      : 999
    return (
      <SaleEventCard
        item={formatedSaleEvent}
        type={formatedSaleEvent.type}
        position={position}
        id={saleEvent.id}
        wrapperClass='col-sm-6 col-lg-4'
        key={`${formatedSaleEvent.type}-${saleEvent.id}`}
        isRankingCard
        cardStyle='center'
        currentSection={'Trending' + _.upperFirst(formatedSaleEvent.type)}
        sectionPosition={1}
      />
    )
  })

  const preData = _.map(preItems, (saleEvent, index) => {
    const formatedSaleEvent = formatSaleEvent(saleEvent)

    // We determine if we want to show the rank or not, by passing a number larger than 50, it will hide the rank for listing cards
    // We do this when we are filtering and the filter is not TOP
    const position = _.includes(
      ['rank', 'fixed_global_score'],
      _.get(order, 'column')
    )
      ? index + 1
      : 999
    return (
      <SaleEventCard
        item={formatedSaleEvent}
        type={formatedSaleEvent.type}
        position={position}
        id={saleEvent.id}
        wrapperClass='col-sm-6 col-lg-4'
        key={`${formatedSaleEvent.type}-${saleEvent.id}`}
        isRankingCard
        cardStyle='center'
        currentSection={'Trending' + _.upperFirst(formatedSaleEvent.type)}
        sectionPosition={1}
      />
    )
  })

  useEffect(() => {
    if (!isDataFinished && pageNo > 0 && !isNextLoading && isNextVisible) loadNext()
  }, [isNextVisible, pageNo])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) loadPre()
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    if ((resp && resp.counts != null) || _.get(respHeader, 'x-total')) {
      const { counts } = resp

      const newState = {}
      if (!isFilter && !order && !value) {
        if (_.get(counts, 'sale_events') || _.get(respHeader, 'x-total')) {
          newState.isSaleEnabled = true
        } else {
          newState.isSaleEnabled = false
        }
      }

      newState.saleCount = _.get(counts, 'sale_events') || _.get(respHeader, 'x-total') || 0
      newState.isFilter = false
      newState.isSaleReady = true
      handleStateUpdate(newState)
    }
  }, [resp, respHeader])

  useEffect(() => {
    async function loadData() {
      const resp = await loadNew()
      handleSearchFilterDataLayer(_.get(resp.counts, 'sale_events') || _.get(respHeader, 'x-total') || 0, 'sale')
    }
    loadData()
  }, [
    storeIds,
    brandIds,
    departmentIds,
    categoryIds,
    paymentMethods,
    startRange,
    order,
    value
  ])

  return isDataFinished && !(isNextLoading || isPreLoading) && _.isEmpty(nextData)
    ? <span>Oops! No results found. Try using different filters.</span>
    : (
      <ErrorBoundries>
        <div className='row listing-grid'>
          <div className='listing-card col-12'>
            <Loader isLoading={isPreLoading} />
          </div>
          <div style={{ height: '1px' }} ref={preRef} />
          {preData}
          {nextData}
          <div className='listing-card col-12'>
            <Loader isLoading={!isDataFinished && isNextLoading} />
          </div>
          <div style={{ height: '1px' }} ref={nextRef} />
          {isDataFinished && !(isNextLoading || isPreLoading) && <NoResultDivider countZero={!items.length} />}
        </div>
      </ErrorBoundries>
    )
}

export default SaleEventsTab
