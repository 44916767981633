import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CouponCard } from '../../../../../components/ListingCards'
import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { getBaseUrl } from '../../../reducers'
import { getTopCoupons } from '../../../requesters'
import { PER_PAGE_LIMIT } from '../../../../../../constant'
import LoadMoreSpinner from '../../LoadMoreSpinner'
import NoResultDivider from '../../NoResultDivider'
import humanizeOffer from '../../../../../components/utils/humanizeOffer'
import ErrorBoundries from '../../../../../components/ErrorBoundry'

export default function VouchersTab({
  value,
  order,
  filterOptions,
  handleStateUpdate,
  isFilter,
  handleSearchFilterDataLayer
}) {
  const baseUrl = useSelector(getBaseUrl)

  const {
    storeIds,
    brandIds,
    departmentIds,
    categoryIds,
    paymentMethods,
    couponTypes
  } = filterOptions

  const params = {
    per_page: PER_PAGE_LIMIT,
    from: PER_PAGE_LIMIT * 0,
    by_order: order && value ? {
      direction: order, column: value
    } : {}, by_brands: brandIds,
    by_stores: storeIds,
    by_departments: departmentIds,
    by_categories: categoryIds,
    by_coupon_types: couponTypes,
    by_payment_methods: paymentMethods,
    doNormalize: false
  }

  const [
    items,
    isNextLoading,
    isPreLoading,
    itemUsers,
    preItems,
    preItemUsers,
    pageNo,
    prePageNo,
    isDataFinished,
    initPage,
    loadNext,
    loadPre,
    loadNew,
    resp,
    respHeader
  ] = useInfiniteScroll({
    baseUrl,
    method: getTopCoupons,
    params,
    storedPage: 0
  })

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  useEffect(() => {
    if (!isDataFinished && pageNo > 0 && !isNextLoading && isNextVisible) loadNext()
  }, [isNextVisible, pageNo])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) loadPre()
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    if ((resp && resp.counts != null) || _.get(respHeader, 'x-total')) {
      const { counts } = resp
      const newState = {}
      if (!isFilter && !order && !value) {
        if (_.get(counts, 'vouchers') || _.get(respHeader, 'x-total')) {
          newState.isVoucherEnabled = true
        } else {
          newState.isVoucherEnabled = false
        }
      }
      newState.voucherCount = _.get(counts, 'vouchers') || _.get(respHeader, 'x-total') || 0
      newState.isFilter = false
      newState.isVoucherReady = true
      handleStateUpdate(newState)
    }
  }, [resp, respHeader])

  useEffect(() => {
    async function loadData() {
      const resp = await loadNew()
      handleSearchFilterDataLayer(_.get(resp.counts, 'vouchers') || _.get(respHeader, 'x-total') || 0, 'voucher')
    }
    loadData()
  }, [
    storeIds,
    brandIds,
    departmentIds,
    categoryIds,
    paymentMethods,
    couponTypes,
    order,
    value
  ])

  const formatVoucher = (voucher) => {
    return {
      id: voucher.id,
      type: voucher.type,
      attributes: {
        ...voucher.attributes,
        couponType: _.get(voucher, "attributes.coupon_type"),
        commentsCount: _.get(voucher, "attributes.comments_count"),
        impressionsCount: _.get(voucher, "attributes.impressions_count"),
        offer: humanizeOffer(voucher),
        upvotesCount: _.get(voucher, "attributes.upvotes_count"),
        expireAt: _.get(voucher, "attributes.expire_at")
      },
      links: { couponUrl: `/shop/voucher/${_.get(voucher, "attributes.slug")}`, imageUrl: voucher.links.image_url, largeImage: voucher.links.large_image },
      relationships: { store: { attributes: { name: voucher?.attributes?.store_name }, links: { logoUrl: voucher.attributes?.store_logo } } }
    }
  }

  return (
    <>
      {isDataFinished && !(isNextLoading || isPreLoading) && items?.length === 0
        ? <span>Oops! No results found. Try using different filters.</span>
        : (
          <ErrorBoundries>
            <div className='row listing-grid'>
              <div className='listing-card col-12'>
                {isPreLoading && LoadMoreSpinner}
              </div>
              <div style={{ height: '1px' }} ref={preRef} />
              {preItems.map((voucher, index) => (
                <Fragment key={index}>
                  <CouponCard
                    item={formatVoucher(voucher)}
                    wrapperClass='col-sm-6 col-lg-4'
                    displayOffer={(voucher) => humanizeOffer(voucher)}
                  />
                </Fragment>
              ))}
              {items.map((voucher, index) => (
                <Fragment key={index}>
                  <CouponCard
                    item={formatVoucher(voucher)}
                    wrapperClass='col-sm-6 col-lg-4'
                    displayOffer={(voucher) => humanizeOffer(voucher)}
                  />
                </Fragment>
              ))}
              <div className='listing-card col-12'>
                {!isDataFinished && isNextLoading && LoadMoreSpinner}
              </div>
              <div style={{ height: '1px' }} ref={nextRef} />
              {isDataFinished && !(isNextLoading || isPreLoading) && <NoResultDivider countZero={!items.length} />}
            </div>
          </ErrorBoundries>
        )}
    </>
  )
}
