import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchDepartments() {
  yield put(actions.departmentRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getDepartments, baseUrl)
  yield (response) ? put(actions.departmentRequestSuccess(response)) : put(actions.departmentRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchDepartments, data)
  ])
}

function* watchDepartmentSaga() {
  yield takeLatest(actionTypes.DEPARTMENTS_FETCH, fetchData)
}

export {
  watchDepartmentSaga
}