import React, { Component } from 'react'

import { Link, NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import {
  navHeartIcon
} from '../../../../components/Icons'
import { gon } from '../../../../../constant'

import * as reducers from '../../reducers'
import { currentUserUpdate } from '../../actions/users'
import doLogout from '../../../../components/utils/DoLogout'

class SignedInList extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const {
      currentUser: {
        attributes,
        links
      },
      location: {
        pathname
      },
      newFollowing,
    } = this.props
    
    const role = gon.current_user.role

    const heartUrl = `/spotter/${attributes.slug}/following`
    const heart = pathname === heartUrl ? navHeartIcon : navHeartIcon
    const handleLogout = e => {
      e.preventDefault()
      doLogout()
    }
    return (
      <ul className='navbar-nav ml-auto'>
        <li className='nav-item d-flex'>
          <OverlayTrigger
            placement='bottom'
            show={newFollowing}
            overlay={(
              <Tooltip
                className='red-tooltip'
                placement='bottom-start'
                id='following-tooltip'
              >
                Followed
              </Tooltip>
            )}
          >
            <NavLink
              className='nav-link align-self-center'
              exact
              id='follow-tooltip'
              to={heartUrl}
            >
              {/* LINKS TO FOLLOWING PAGE */}
              <img className='nav-img header-img' src={heart} />
            </NavLink>
          </OverlayTrigger>
        </li>
        <li className='nav-item dropdown nav-profile d-none d-xl-block'>
          <a
            aria-expanded='false'
            aria-haspopup='true'
            className='nav-link dropdown-toggle'
            data-toggle='dropdown'
            href='/'
            id='navbarDropdown'
            role='button'
          >
            <LazyLoadImage
              effect='opacity'
              className='rounded-circle img-fluid profile-img-sm'
              alt={attributes.name}
              src={`${links.avatar.thumb.url}`}
            />
          </a>
          <div aria-labelledby='navbarDropdown' className='dropdown-menu dropdown-menu-profile'>
            <div className='text-center dropdown-header'>{attributes.username}</div>
            <div className='dropdown-divider' />
            <Link className='dropdown-item' to={`/spotter/${attributes.slug}/account`}>My Account Details</Link>
            <Link className='dropdown-item' to={`/spotter/${attributes.slug}/my-calendar`}>
              My Calendar
            </Link>
            <Link className='dropdown-item' to={`/spotter/${attributes.slug}/account/email`}>Email Notification</Link>
            <Link className='dropdown-item' to={`/spotter/${attributes.slug}`}>My Public Profile</Link>
            <Link className='dropdown-item' to='/submit-listing'>Post</Link>
            {['admin', 'data_entry'].includes(role) && <a className='dropdown-item' href={`${gon.base_url}/backend?auth_token=${gon.auth_token}`} >Backend</a>}
            <div className='dropdown-divider' />
            <a className='dropdown-item sign-out pointer' onClick={handleLogout}>
              Sign Out
            </a>
          </div>
        </li>
      </ul>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state),
  newFollowing: state.notifications.newFollowing,
  newCalendar: state.notifications.newCalendar
})

export default connect(mapStateToProps, { currentUserUpdate })(withRouter(SignedInList))
