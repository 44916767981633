import React, { Component } from "react"
import { connect } from "react-redux"

import ProfileHeader from "../../../../../components/UserProfileHeader"
import Breadcrumb from "../../../../../components/Breadcrumb"
import Meta from "../../../../../components/Meta"
import UserActivities from "../../../../../components/UserActivities"
import { loadPublicProfilePage } from "../../../actions"
import * as reducers from "../../../reducers"
import {
  // ElasticTopPriceDropsList,
  // TopCouponsList,
  // TopSalesEventsList,
  TopBrandsSidebarList,
  TopStoresSidebarList,
  // FollowedDealsList,
  FollowedCouponsList,
  // BestSellersList
} from "../../listings"
import Collections from "../../Collections"

class PublicProfilePage extends Component {
  componentDidMount() {
    this.initiatePageLoad()
  }

  componentDidUpdate(prevProps) {
    const { user: prev_user } = prevProps
    const { user } = this.props
    if (this.checkUserAndPrevUser(user, prev_user)) {
      this.initiatePageLoad()
    }
  }

  initiatePageLoad = () => {
    const {
      loadPublicProfilePage,
      match: { params: { slug } },
      user
    } = this.props

    // Initial fetch from page load
    loadPublicProfilePage({ user, slug })
  }

  recommendedPriceDropsUrl = () => {
    const { followedStoreNames, followedBrandNames } = this.props
    const storesString = followedStoreNames.map(store => `storeNames[]=${encodeURI(store)}`).join("&")
    const brandsString = followedBrandNames.map(brand => `brandNames[]=${encodeURI(brand)}`).join("&")

    if (!storesString && !brandsString) return "/top-price-drops"
    return `/top-price-drops?${storesString}${(storesString && brandsString) ? "&" : ""}${brandsString}`
  }

  recommendedListingsUrl = pathname => {
    const { followedStoreIds, followedBrandIds } = this.props
    const storesString = followedStoreIds.map(store => `storeIds[]=${encodeURI(store)}`).join("&")
    const brandsString = followedBrandIds.map(brand => `brandIds[]=${encodeURI(brand)}`).join("&")

    if (!storesString && !brandsString) return pathname
    return `${pathname}?${storesString}${(storesString && brandsString) ? "&" : ""}${brandsString}`
  }

  checkUserAndPrevUser(user, prev_user) {
    return prev_user !== null && prev_user.type !== user.type && prev_user.attributes.id !== user.attributes.id
  }

  render() {
    const {
      location,
      match,
      user,
      followedBrandNames,
      followedStoreNames,
      isFetchingTopBrands,
      isFetchingTopStores
    } = this.props
    return (
      <div className="">
        <div className="container">
          {user && (
            <Meta
              title={`${user.attributes.username || user.attributes.firstName}'s Profile Page | Little Birdie`}
              name="Following Page"
              description={`${user.attributes.username} is a spotter on Little Birdie. See all their deals, price drops, votes, vouchers, sale events and more!`}
              og_description={`See ${user.attributes.username} on Little Birdie. See all their price drops, price drops, votes, vouchers, sale events and more! `} />
          )}
          <Breadcrumb breadcrumbs={location} />
          {user && (
            <>
              <ProfileHeader user={user} />
            </>
          )}
        </div>
        <div className="">
          {/* ToDo: Remove Deal: Remove Price Drops form public profile page  */}
          {/* {
            user && user.attributes.publicProfileSettings.showFollowedProducts && (
              <FollowedDealsList
                user={user}
                title={`Price Drops ${user.attributes.username} is following`}
                description={`Here's the list of Price Drops ${user.attributes.username} has chosen to  follow`}
                currentSection={`Price Drops ${user.attributes.username} is following`}
                sectionPosition={1}
              />
            )
          } */}

          {
            user && user.attributes.publicProfileSettings.showFollowedCoupons && (
              <FollowedCouponsList
                user={user}
                title={`Promo Codes ${user.attributes.username} is following`}
                description={`Here's the list of great Promo Codes ${user.attributes.username} has chosen to follow`}
                currentSection={`Promo Codes ${user.attributes.username} is following`}
                sectionPosition={2}
              />
            )
          }

          {user && (<Collections
            placement="highlights"
            page="listing"
            currentSection={`Collections for ${user.attributes.username}!`}
            sectionPosition={5}
          />)
          }


          {
            user && (
              user.attributes.publicProfileSettings.showFollowedBrands
                ? <TopBrandsSidebarList
                    title={`Brands ${user.attributes.username} follows`}
                    showAll
                    currentSection={`Brands ${user.attributes.username} follows`}
                    sectionPosition={7}
                  />
                : <TopBrandsSidebarList
                    currentSection={`Brands ${user.attributes.username} follows`}
                    sectionPosition={7}
                  />
            )
          }
          {
            user && (
              user.attributes.publicProfileSettings.showFollowedStores
                ? <TopStoresSidebarList
                    title={`Stores ${user.attributes.username} follows`}
                    showAll
                    currentSection={`Stores ${user.attributes.username} follows`}
                    sectionPosition={8}
                  />
                : <TopStoresSidebarList
                    currentSection={`Stores ${user.attributes.username} follows`}
                    sectionPosition={8}
                  />
            )
          }

          {
            user && user.attributes.publicProfileSettings.showActivityFeed && (
              <UserActivities
                user={user}
                slug={match.params.slug}
                title={`${user.attributes.username} Activity`}
                numberOfActivities={5} />
            )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: reducers.getUserBySlug(state, props.match.params.slug),
  current_user: reducers.getCurrentUser(state),
  followedStoreIds: reducers.getTopStoreIds(state),
  followedBrandIds: reducers.getTopBrandIds(state),
  followedStoreNames: reducers.getTopStoreIds(state).map(storeId => state.data.stores.byId[storeId].attributes.name),
  isFetchingTopStores: reducers.isFetchingTopStores(state),
  followedBrandNames: reducers.getTopBrandIds(state).map(brandId => state.data.brands.byId[brandId].attributes.name),
  isFetchingTopBrands: reducers.isFetchingTopBrands(state)
})

export default connect(mapStateToProps, {
  loadPublicProfilePage
})(PublicProfilePage)
