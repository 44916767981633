export const AVAILABILITY_OPTIONS_LIST = [
  { value: "in_store", label: "In Store" },
  { value: "online", label: "Online" },
  { value: "australia", label: "Australia Wide" },
  { value: "nsw", label: "NSW" },
  { value: "vic", label: "VIC" },
  { value: "sa", label: "SA" },
  { value: "tas", label: "TAS" },
  { value: "qld", label: "QLD" },
  { value: "nt", label: "NT" },
  { value: "act", label: "ACT" },
  { value: "wa", label: "WA" }
]

export const LISTING_TYPE_OPTIONS = [
  { value: "deal", label: "Deal" },
  { value: "coupon", label: "Voucher" },
  { value: "sale_event", label: "Sale Event" }
]

export const COUPON_TYPE_OPTIONS = [
  { value: "percentage_discount", label: "% Discount" },
  { value: "dollar_discount", label: "$ Discount" },
  { value: "free_shipping", label: "Free Shipping" },
  { value: "bonus_item", label: "Bonus Item" },
  { value: "other", label: "Other" }
]