// // This is a version 2 of the ranking page where its used for custom lists
// // This component utilises react hooks`
// // We have since stopped using materialized views custom lists are now basically a ranking page with preselected filters

import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  getCustomListById
} from '../../../../../App/requesters'
import { getBaseUrl } from '../../../../../App/reducers'

import RedirectWithAlert from '../../../../../../components/utils/RedirectWithAlert'

import CustomElasticList from './CustomElasticList'
import CustomCouponList from './CustomCouponList'
import CustomSaleEventList from './CustomSaleEventList'

export default function CustomList ({
  sortOptionValue,
  getCollectionFilters,
  handlePriceRangeChange,
  filterOptions,
  setFilters,
  setCustomListFilters,
  isReady,
  sortColumn, sortDirection,
  setTitle,
  setSubTitle,
  handleTotalResultsCount
}) {
  const { listType } = useParams()
  const apiBaseUrl = useSelector(getBaseUrl)

  const [customList, setCustomList] = useState(null)
  const [listRequestStatus, setListRequestStatus] = useState(null)

  // Fetch the data for the custom list
  useEffect(() => {
    async function fetchData () {
      const { response, status } = await getCustomListById(apiBaseUrl, listType)
      getCollectionFilters(_.first(Object.values(response.customList)).attributes.resourceType)
      setListRequestStatus(status)
      setCustomList(_.first(Object.values(response.customList)))
    }
    fetchData()
  }, [listType])

  // Set filters in parent component
  useEffect(() => {
    const params = {
      storeIds: _.get(customList, 'attributes.rules.storeIds')?.map(id => id.toString()) || [],
      brandIds: _.get(customList, 'attributes.rules.brandIds')?.map(id => id.toString()) || [],
      departmentIds: _.get(customList, 'attributes.rules.departmentIds')?.map(id => id.toString()) || [],
      categoryIds: _.get(customList, 'attributes.rules.categoryIds')?.map(id => id.toString()) || [],
      priceRange: _.get(customList, 'attributes.rules.priceMin') || _.get(customList, 'attributes.rules.priceMax') ? [_.get(customList, 'attributes.rules.priceMin') || 0, _.get(customList, 'attributes.rules.priceMax') || 4000] : [],
      discountRange: _.get(customList, 'attributes.rules.discountMin') || _.get(customList, 'attributes.rules.discountMax') ? [_.get(customList, 'attributes.rules.discountMin') / 100 || 0, _.get(customList, 'attributes.rules.discountMax') / 100 || 1] : [],
      storeNames: _.get(customList, 'attributes.storeNames') || [],
      brandNames: _.get(customList, 'attributes.brandNames') || [],
      departmentNames: _.get(customList, 'attributes.departmentNames') || [],
      categoryNames: _.get(customList, 'attributes.categoryNames') || [],
      lastPriceRange: _.get(customList, 'attributes.priceFreshness') || []
    }

    if (customList) {
      setFilters(params)
      setCustomListFilters(params)
      setTitle(_.get(customList, 'attributes.title'))
      setSubTitle(_.get(customList, 'attributes.subtitle'))
    }
  }, [customList])

  // Redirect to home if this list does not exist
  if (listRequestStatus === 404) { return (<RedirectWithAlert />) }

  switch (_.get(customList, 'attributes.resourceType')) {
    case 'deal':
      return (
        <CustomElasticList
          sortOptionDealValue={sortOptionValue}
          customList={customList}
          handlePriceRangeChange={handlePriceRangeChange}
          filterOptions={filterOptions}
          listType={listType}
          isReady={isReady}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      )
    case 'coupon':
      return (
        <CustomCouponList
          sortOptionCouponValue={sortOptionValue}
          customList={customList}
          filterOptions={filterOptions}
          listType={listType}
          isReady={isReady}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      )
    case 'sale_event':
      return (
        <CustomSaleEventList
          sortOptionSaleValue={sortOptionValue}
          customList={customList}
          filterOptions={filterOptions}
          listType={listType}
          isReady={isReady}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
      )
    default: return null
  }
}
