import React, { Component } from "react"
import Select from "react-select"

import axios from "axios"
import normalize from "json-api-normalizer"
import { mapOrder } from "../../helpers/arrayHelpers"
import { gon } from "../../../constant"

class SelectBoxLookup extends Component {
  state = {
    keyword: "",
    options: []
  }

  componentDidUpdate(prevProps) {
    const { selectedCategory: prevCategory } = prevProps
    const { selectedCategory } = this.props

    if (!prevCategory && selectedCategory && selectedCategory !== "all") this.loadOptions()
  }

  handleInputChange = keyword => {
    this.setState({ keyword })
    this.loadOptions()
  }

  // eslint-disable-next-line consistent-return
  loadOptions = () => {
    const {
      keyword
    } = this.state
    const { selectedDepartment, selectedCategory } = this.props

    const name = "brand_id"
    const options = {
      params: {
        by_keyword: keyword,
        per_page: 10,
        by_department: selectedDepartment,
        by_category: selectedCategory
      }
    }

    const path = `${gon.urls.api}/subcategories`

    if (keyword.length > 2 || selectedCategory) {
      return axios.get(path, options)
        .then(({ data }) => {
          const subcategories = Object.values(normalize(data).subcategory)
          const orderedSubcategories = mapOrder(subcategories, data.meta.order, "id")

          this.setState({ options: _.map(orderedSubcategories, subcategory => ({ name, label: subcategory.attributes.name, value: subcategory.id })) })
        })
        .catch(() => [])
    }
  }

  render() {
    const {
      title,
      isMultiSelect,
      name,
      value,
      errorMessage,
      selectChange
    } = this.props
    const { options } = this.state

    return (
      <div className="form-group">
        <label>{title}</label>
        <Select
          name={name}
          className={'form-control form-control-select'}
          isClearable
          isSearchable
          isMulti={isMultiSelect}
          options={options}
          onChange={selectChange}
          onInputChange={this.handleInputChange}
          value={value} />
        {errorMessage}
      </div>
    )
  }
}

export default SelectBoxLookup
