import React from 'react'
import { littlebirdieLogo, cbaLogoProudPartner } from '../../../../../components/Icons'

function CbaCard ({ isLoginPage, message }) {
  return (
    <div className='cba-card shop-from-above'>
      <div>
        <div className='d-flex align-items-center' style={{ height: '3.438rem' }}>
          <img src={littlebirdieLogo} className='mb-2' style={{ width: '6rem' }} />
          <div className='vr' style={{ margin: '0 0.75rem', height: '1.5rem' }} />
          <img src={cbaLogoProudPartner} className='cba-logo' />
        </div>
        <div className='page-title-section'>
          <span className='page-title text-color-primary'>{isLoginPage ? 'Sign in' : 'Sign up'}</span>
          <span className='cba-offer-txt'>to unlock {`${message}`}</span>
        </div>
        <div className='cba-msg-text'>
          With Little Birdie, you can search &amp; compare online shopping and personalise your shopping feed
        </div>
      </div>
    </div>
  )
}

export default CbaCard
