import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { gon, REACT_APP_BE_CDN_ENDPOINT } from '../../../../constant'
import doLogout from '../../../components/utils/DoLogout'
import { setBeCdnLoaded } from '../actions/common'

export default function useCdnConnect () {
  const history = useHistory()
  const location = useLocation()
  const excludeCdnPaths = ['mobile/ext/']
  const [beScriptAdded, setBeScriptAdded] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    // bindCdn()
    return () => {
      beScriptAdded && window.removeEventListener('message', messageListener)
    }
  }, [])

  useEffect(() => {
    bindCdn()
  }, [location])

  const checkCanLoadCdn = () => {
    let canLoadCdn = true

    excludeCdnPaths.every((excludePath) => {
      if (location?.pathname?.indexOf(excludePath) > -1) {
        canLoadCdn = false
        return false
      }
      return true
    })

    return canLoadCdn
  }

  const messageListener = (event) => {
    if (event.data) {
      switch (event.data.type) {
        case 'lb-ext-sync-auth':
          dispatch(setBeCdnLoaded(true))
          window.postMessage({
            type: 'lb-cdn-auth-token-share',
            value: gon.auth_token || null
          })
          break
        case 'lb-ext-cdn-auth-req':
          if (event.data?.value == 'login' || event.data?.value == 'register') {
            history.push(`/${event.data?.value}`, { from: window.location.pathname })
          }
          break
        case 'lb-ext-cdn-do-logout':
          doLogout(window.location.href)
          break
      }
    }
  }

  const bindCdn = () => {
    const canLoadCdn = checkCanLoadCdn()
    if (!window.ReactNativeWebView && REACT_APP_BE_CDN_ENDPOINT && !beScriptAdded && canLoadCdn && typeof Cypress === 'undefined') {
      setBeScriptAdded(true)
      const cdnStyle = document.createElement('link')
      const cdnScript = document.createElement('script')

      cdnStyle.setAttribute('rel', 'stylesheet')
      cdnStyle.setAttribute('type', 'text/css')
      cdnStyle.setAttribute('media', 'all')

      cdnStyle.setAttribute('href', `${REACT_APP_BE_CDN_ENDPOINT}/static/css/main.css`)
      cdnScript.setAttribute('src', `${REACT_APP_BE_CDN_ENDPOINT}/static/js/content.js`)

      document.head.appendChild(cdnStyle)
      document.body.appendChild(cdnScript)

      window.addEventListener('message', messageListener)
    }

    if (!canLoadCdn && beScriptAdded) {
      window.location.reload()
    }
  }

  return {}
}
