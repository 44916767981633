// Props needs to contain a department object and match (from react router)
//
export const RankingPageLabel = ({ list, props }) => {
  const {
    match, department, category, subcategory, brand, store
  } = props

  const listLabel = {
    "top-features": "Features",
    "top-deals": "Deals",
    "top-price-drops": "Price Drops",
    "top-sales-events": "Sales Events",
    "new-sales-events": "Sales Events",
    "new-deals": "Deals",
    "trending-stores": "Trending Stores",
    "top-spotters": "Spotters",
    "trending-brands": "Trending Brands",
    "top-upcoming-sales": "Upcoming Sales",
    "top-current-sales": "Sales On Now",
    "top-promo-codes": "Promo Codes",
    "new-promo-codes": "Promo Codes",
    "best-sellers": "Best Sellers",
  }[list]

  const prefixLabel = {
    top: "Trending",
    new: "Newly Posted",
  }[list.split("-")[0]]

  // We want to ensure we wait for the department to be present if it exists in the params
  if (match.params.department != null && department === null) {
    return ""
  }
  if (match.params.category != null && category === null) {
    return ""
  }
  if (match.params.brand != null && brand === null) {
    return ""
  }
  if (match.params.shop != null && store === null) {
    return ""
  }

  if (subcategory) {
    return _.join(
      _.compact([
        prefixLabel,
        _.get(store, "attributes.name"),
        _.get(brand, "attributes.name"),
        _.get(subcategory, "attributes.name"),
        listLabel,
      ]),
      " "
    )
  } if (category) {
    return _.join(
      _.compact([
        prefixLabel,
        _.get(store, "attributes.name"),
        _.get(brand, "attributes.name"),
        _.get(category, "attributes.name"),
        listLabel,
      ]),
      " "
    )
  }
  return _.join(
    _.compact([
      prefixLabel,
      _.get(store, "attributes.name"),
      _.get(brand, "attributes.name"),
      _.get(department, "attributes.name"),
      listLabel,
    ]),
    " "
  )
}
