import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import Breadcrumb from "./../../../../components/Breadcrumb"
// import TrendingListings from "./../../../../components/TrendingListings"
import Meta from "./../../../../components/Meta"
// import PageTicker from "../PageTicker"
import popularAndActiveAndSortedList from "../../../../components/utils/popularAndActiveAndSortedList"
import AdvertisementList from "../AdvertisementList"
import Collections from "../Collections"
import { loadListingPage, loadDepartmentCategoryPage, activeTickersRequestStart, seoContentRequestStart, resetCarouselLoaded } from "../../actions"
import {isSafari, isIOS, isSafariDesktop, isPad} from '../../utilis/detectBrowser'
import * as reducers from "../../reducers"
import {
  // ElasticTopPriceDropsList,
  TopCouponsList,
  TopSalesEventsList,
  TopBrandsSidebarList,
  TopStoresSidebarList,
  SeoInfo
  // BestSellersList,
} from "../listings"

import HomepageSearch from '../HomepageSearch'
import HomepageSearchBlack from '../HomepageSearchBlack'
//import HomePageExtCrossSell from '../HomePageExtCrossSell'

import TodaysTopPicks from "../TodaysTopPicks"
import YourTopPicks from "../YourTopPicks"
import CbaExclusivePicks from "../CbaExclusivePicks"
import PersonalisationPrompt from "../PersonalisationPrompt"
import PersonalisationPromptBlack from "../PersonalisationPromptBlack"
// import WelcomeMessage from '../../../../components/WelcomeMessage'
import RankingPage from "./RankingPage"
import DepartmentHeader from "../../../../components/DepartmentHeader"
import DeparmentTopTen from "../DeparmentTopTen"
import Cookies from 'universal-cookie'
import { gon, SHOW_BLACK_FRIDAY } from '../../../../../constant'
import CustomWidget from '../customWidget'
import SearchOverlayModal from './SearchOverlayModal'
import SearchOverlayModalBlack from './SearchOverlayModalBlack'
import CategoryTopTen from "../CategoryTopTen"
import AppExperiment from '../MVT'
import ErrorBoundary from "../../utilis/ErrorBoundary"
import DepartmentViewAll from "../DepartmentViewAll"
import UpsellBanner from '../UpsellBanner'
import { upsellDeskBf } from '../../../../components/Icons'
import { isTablet } from '../../utilis/detectBrowser'


class ListingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deptCounts : {},
    }


    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }


  componentDidMount() {
    const { activeTickersRequestStart, seoContentRequestStart,deptCountsRequestStart , userLoading, isMobile} = this.props
    this.initiatePageLoad()
    activeTickersRequestStart()
    if(this.props.match?.params?.department || this.props.match?.params?.category){
      seoContentRequestStart({department: this.props.match?.params?.department, category: this.props.match?.params?.category})
    }
    this.getDeptCounts()
  }
  getDeptCounts = () => {
    axios.get(`${gon.urls.api}/stats/departments`)
      .then(response => {
        this.setState({ deptCounts: response?.data || {} })
      })
      .catch(err => {
        Sentry.captureException(err)
      })
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prev_params } } = prevProps
    const { match: { params } } = this.props
    // If either department/category or subcategory changes we go refresh the data
    if (!_.isEqual(params, prev_params)) {
      this.initiatePageLoad()
    }
  }

  initiatePageLoad = () => {
    const {
      loadListingPage,
      loadDepartmentCategoryPage,
      match: { params },
      location
    } = this.props
    // Initial fetch from page load
    const reqParams = this.generateRequestParams(params)
    loadListingPage(reqParams)
    if(location.pathname !== "/") {
      loadDepartmentCategoryPage(reqParams)
    }
  }

  generateRequestParams = params => {
    const { department, category, subcategory } = params
    if (department || category || subcategory) {
      return {
        department,
        category,
        subcategory,
        by_order: {
          column: "fixed_department_score",
          direction: "desc"
        }
      }
    }
    return null
  }

  getDepartment = index => {
    const { departments } = this.props
    const orderedActiveDepartmentsArr = popularAndActiveAndSortedList(Object.values(departments))
    return orderedActiveDepartmentsArr[index - 1]
  }

  topUpcomingSaleEventsList = () => {
  }

  // Generates the urls for the ranking pages including filtering
  //
  urlForRankingPage = rankingPage => {
    const {
      match: {
        params: {
          department,
          category
        }
      }
    } = this.props
    return `/${_.join(_.compact([rankingPage, department, category]), "/")}`
  }

  renderMeta() {
    const {
      match: {
        params: {
          department,
          category,
          subcategory
        }
      },
      seo
    } = this.props
    const titleDepartment  = _.startCase(department)
    const titleCategory    = _.startCase(category)
    const titleSubCategory = _.startCase(subcategory)

    // /shop/:department/:category/:subcategory
    if (subcategory) {
      return (
        <Meta
          name={`${titleSubCategory} Listing Page`}
          title={`${titleSubCategory} | Best Price Drops & Vouchers | Little Birdie`}
          description={`Find and save with the best ${titleSubCategory} prices, price drops, vouchers and sale events across millions of products from hundreds of stores in at Little Birdie.`} />
      )
    }

    // /shop/:department/:category
    if (category) {
      return (
        <Meta
          name={`${titleCategory} Listing Page`}
          title={seo?.data?.attributes?.page_title}
          description={seo?.data?.attributes?.meta_description} />
      )
    }
    // /shop/:department
    if (department) {
      return (
        <Meta
          name={`${titleDepartment} Listing Page`}
          title={seo?.data?.attributes?.page_title}
          description={seo?.data?.attributes?.meta_description} />
      )
    }
    // root path
    return (
      <Meta
        name="Homepage"
        title="Little Birdie | Get the best price"
        description="Little Birdie is a browser extension that checks the internet for the lowest price—and a shopping feed with top Sale Events and Voucher Codes." />
    )
  }
  isAffinityDataTrue = (affinity) => {
    let { brands, stores, departments, categories, subcategories } = affinity
    if(brands.length ||stores.length || departments.length || categories.length || subcategories.length )  return true
    else return false
  }

  componentWillUnmount() {
    this.props.resetCarouselLoaded()
  }

  render() {
    const {
      location,
      match,
      department,
      category,
      subcategory,
      departments,
      seo,
      isFetchingSeoContent,
      isUserloggedIn,
      user,
      isSearchActive
    } = this.props
    const { deptCounts } = this.state
    const homePage = (location.pathname === "/")
    const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
    const getTopCategory = (affinityData?.length && affinityData[0]?.affinity?.categories) || []
    const getDefinedDeptCategories = department?.relationships?.categories?.filter(cat => cat?.attributes?.carouselPosition !== null) || []
    const sortedDefinedDeptCategories = _.orderBy(getDefinedDeptCategories, 'attributes.carouselPosition', 'asc')
    const currentPageName = subcategory?.attributes?.name || category?.attributes?.name || department?.attributes?.name || null;
    /*const urlParams = new URLSearchParams(location.search)
    const lowerParams = new URLSearchParams()
    for (const [name, value] of urlParams) {
      lowerParams.append(name.toLowerCase(), value);
    }
    const wtidParam = lowerParams.get('wtid') || ''
    const wtidParamValid = wtidParam.length == 40
     || wtidParamValid*/

    const currentCookies = new Cookies()
    const isWtId = currentCookies.get('wtid') || gon?.current_user?.wtid

    if (match.params.subcategory) {
      match.params.listType = "top-price-drops"
      return <RankingPage match={match} location />
    }

    return (
      <Fragment>
        { this.renderMeta() }
        {!homePage && (
          <div className="container">
            <Breadcrumb breadcrumbs={location} />
          </div>
        )}
        {homePage && <AdvertisementList position="hero" page="listing" sectionId={'advertisementList'} /> }
        {
          (department || category) &&
          !subcategory &&
          <DepartmentHeader department={category ? category : department}  match={match}
            departmentNames={department}
            category={category}
            currentSection={`${!homePage ? currentPageName + ' TrendingListings' : 'TrendingListings'}`}
            sectionPosition={`${!homePage ? 1 : 3}`}
            deptCounts={deptCounts}
          />
        }

        {/* {homePage && <AppExperiment id="Homepage intro black friday" defaultVarinat="green homepage intro" components={{
          'green homepage intro': <HomepageSearchBlack isLoggedIn={this.props.user !== null} />,
          'black friday homepage intro': <HomepageSearchBlack isLoggedIn={this.props.user !== null} />
        }} />} */}
        {homePage ? 
        SHOW_BLACK_FRIDAY ?
           <HomepageSearchBlack isLoggedIn={this.props.user !== null} />
           :
           <HomepageSearch isLoggedIn={this.props.user !== null} />
           :
           null
        }
        {/* {!this.props.isMobile && homePage && !isTablet &&
          <div className='container upsell-banner'>
              <img onClick={() => window.open('https://chrome.google.com/webstore/detail/little-birdie-get-the-bes/eclmkceplokololbkleieojfomhcicdf')} src={upsellDeskBf} />
          </div>
        } */}
        
        {(this.props.isMobile || isTablet) &&
          <UpsellBanner />
        }
        {/* New header added in homepage to separate pages */}
        {/* {homePage && <HomePageExtCrossSell />} */}

        {/* {homePage && <div className='homepageheader'>
          Explore
          <p>Australia’s top Sale Events and Vouchers</p>
        </div>} */}
        {/* {homePage && <HomepageSearch isLoggedIn={this.props.user !== null} />} */}

        <div className={`homepage-listing ${homePage && !isTablet && 'min-margin'}`}>
          <div className={`${homePage && 'section-top'}`}>
        {homePage && <CustomWidget position='hero' sectionId='customWidgetHero' />}
        {homePage && (
          <>
          {
            isUserloggedIn && affinityData && affinityData[0]?.affinity
            ? isWtId
              ? <>
                 { this.isAffinityDataTrue(affinityData[0]?.affinity) &&  <YourTopPicks sectionId={'your-top-10'} /> }
                  <AdvertisementList position="top" page="listing" sectionId={'advertisementList'} />
                  <CustomWidget position='top' sectionId='customWidgetTop' />
                  <CbaExclusivePicks sectionId={'cbaPicks'} />
                  <TodaysTopPicks sectionId={'top-10-in-australia-today'} isYourTopPickShow = {affinityData && affinityData[0]?.affinity ? true : false} />
                </>
              : <>
                   { this.isAffinityDataTrue(affinityData[0]?.affinity) &&<YourTopPicks sectionId={'your-top-10'} /> }
                  <AdvertisementList position="top" page="listing" sectionId={'advertisementList'} />
                  <CustomWidget position='top' sectionId='customWidgetTop' />
                  <TodaysTopPicks sectionId={'top-10-in-australia-today'} isYourTopPickShow = {affinityData && affinityData[0]?.affinity ? true : false} />
                </>
            : isWtId
              ? <>
                   <TodaysTopPicks sectionId={'top-10-in-australia-today'} isYourTopPickShow = {affinityData && affinityData[0]?.affinity ? true : false} />
                   <AdvertisementList sectionId={'advertisementList'} position="top" page="listing" />
                   <CustomWidget position='top' sectionId='customWidgetTop' />
                   <CbaExclusivePicks sectionId={'cbaPicks'} />
                </>
              : <>
                    <TodaysTopPicks sectionId={'top-10-in-australia-today'} isYourTopPickShow = {affinityData && affinityData[0]?.affinity ? true : false} />
                    <AdvertisementList sectionId={'advertisementList'}  position="top" page="listing" />
                    <CustomWidget position='top' sectionId='customWidgetTop' />
                </>

          }
        </>
      )}

        {(department && !category) && <DepartmentViewAll department={department} />}
        {homePage && !isUserloggedIn && <AppExperiment id="Personalise Black Friday" defaultVarinat="Existing personalise feed" components={{
          'Existing personalise feed': <PersonalisationPrompt sectionId="PersonalisationPrompt" user={this.props.user} />,
          'Personalise for Black Friday version': <PersonalisationPromptBlack sectionId="PersonalisationPrompt" user={this.props.user} />
        }} />}
        {homePage && isUserloggedIn && <PersonalisationPrompt sectionId="PersonalisationPrompt" user={this.props.user} />}
        <Fragment>
          {/* Removed TrendingListing component here and added in DepartmentHeader component */}
          {
              !homePage && match.params.department !== undefined && (
                <>
                  <AdvertisementList position="top" page="listing" />
                </>
              )
          }
          {!homePage && (match.params.department !== undefined && match.params.category === undefined) && !isUserloggedIn && <AppExperiment id="Personalise Black Friday" defaultVarinat="Existing personalise feed" components={{
            'Existing personalise feed': <PersonalisationPrompt sectionId="PersonalisationPrompt" user={this.props.user} />,
            'Personalise for Black Friday version': <PersonalisationPromptBlack sectionId="PersonalisationPrompt" user={this.props.user} />
          }} />}
          {!homePage && (match.params.department !== undefined && match.params.category === undefined) && isUserloggedIn && <PersonalisationPrompt sectionId="PersonalisationPrompt" user={this.props.user} />}
          {
            (department && !category) && sortedDefinedDeptCategories?.[0] &&
              <CategoryTopTen
                key={`defined_cat_${sortedDefinedDeptCategories?.[0]?.id}`}
                sectionId='topCategoryPicks'
                category={sortedDefinedDeptCategories?.[0]}
                sectionPosition={2}
                isPersonalised={false}
              />
          }
          {
            homePage && !_.isEmpty(departments) &&
              <DeparmentTopTen
                deptCounts={deptCounts}
                sectionId='topDepartmentPick-1'
                department={this.getDepartment(1)}
                key={_.get(this.getDepartment(1), "id")}
                currentSection={_.get(this.getDepartment(1), "attributes.name")}
                sectionPosition={3}
                isPersonalised={false}
              />
          }

          <AdvertisementList sectionId={'advertisementList-1'} position="top-middle" page="listing" />

          {homePage && <CustomWidget position='top-middle' sectionId='customWidgetTopMiddle' />}
          { // only visible on category pages
            department && category && (
              <TopSalesEventsList
                viewAllUrl={this.urlForRankingPage("top-sales-events")}
                wrapperClass="listing-carousel"
                department={department}
                category={category}
                subcategory={subcategory}
                currentSection={`${!homePage ? currentPageName + ' SalesEvents' : 'TopSalesEvents'}`}
                sectionPosition={2}
              />
            )
          }
          {
            (department && !category) && sortedDefinedDeptCategories?.[1] &&
              <CategoryTopTen
                key={`defined_cat_${sortedDefinedDeptCategories?.[1]?.id}`}
                sectionId='topCategoryPicks'
                category={sortedDefinedDeptCategories?.[1]}
                sectionPosition={3}
                isPersonalised={false}
              />
          }
          {
            (department && !category) &&
              <TopStoresSidebarList
                department={department}
                category={category}
                subcategory={subcategory}
                viewAllUrl={this.urlForRankingPage("trending-stores")}
                currentSection={`${!homePage ? currentPageName + ' Stores' : 'TrendingStores'}`}
                sectionPosition={4}
                sectionId='topStorePick'
              />
          }
          {
            (department && !category) && sortedDefinedDeptCategories?.[2] &&
              <CategoryTopTen
                key={`defined_cat_${sortedDefinedDeptCategories?.[2]?.id}`}
                sectionId='topCategoryPicks'
                category={sortedDefinedDeptCategories?.[2]}
                sectionPosition={5}
                isPersonalised={false}
              />
          }
          {/* ToDo: Remove Deal: Remove Top Price Drops */}
          {/* {
            (department && category) &&
              <ElasticTopPriceDropsList
                viewAllUrl={this.urlForRankingPage("top-price-drops")}
                wrapperClass="listing-carousel"
                department={department}
                category={category}
                subcategory={subcategory}
                currentSection={`${!homePage ? currentPageName + ' PriceDropsList' : 'TopPriceDropsList'}`}
                sectionPosition={3}
              />
          } */}
          {(department && !category) && <AdvertisementList sectionId={'advertisementList-2'} position="middle" page="listing" />}
          {
            (department && !category) && sortedDefinedDeptCategories?.[3] &&
                <div style={{ marginTop: '40px' }} >
                <CategoryTopTen
                  key={`defined_cat_${sortedDefinedDeptCategories?.[3]?.id}`}
                  sectionId='topCategoryPicks'
                  category={sortedDefinedDeptCategories?.[3]}
                  sectionPosition={6}
                  isPersonalised={false}
                />
              </div>
          }
          {
            !homePage
              ? <Collections placement="highlights" page="listing" currentSection="Collections" sectionPosition={4} />
              : null
          }
          <ErrorBoundary >
            <TopBrandsSidebarList
              department={department}
              sectionId='topBrandPick'
              category={category}
              subcategory={subcategory}
              viewAllUrl={this.urlForRankingPage("trending-brands")}
              currentSection={`${!homePage ? currentPageName + ' Brands' : 'TrendingBrands'}`}
              sectionPosition={7}
            />
          </ErrorBoundary>
          {homePage && getTopCategory && getTopCategory.length  && <CategoryTopTen  sectionId='topCategoryPicks' category={getTopCategory[0]} sectionPosition={6} isPersonalised={true} /> || ''}
          {homePage && !_.isEmpty(departments) && <DeparmentTopTen deptCounts={deptCounts} sectionId='topDepartmentPick-2' department={this.getDepartment(2)} key={_.get(this.getDepartment(2), "id")} currentSection={_.get(this.getDepartment(2), "attributes.name")} sectionPosition={7} isPersonalised={false} />}
          {homePage && !_.isEmpty(departments) && <DeparmentTopTen deptCounts={deptCounts} sectionId='topDepartmentPick-3' department={this.getDepartment(3)} key={_.get(this.getDepartment(3), "id")} currentSection={_.get(this.getDepartment(3), "attributes.name")} sectionPosition={8} isPersonalised={false} />}

          {!department && <AdvertisementList sectionId={'advertisementList-2'} position="middle" page="listing" />}
          { /* customWidget position=middle is removed from this location as its not available in backend */ }
          { // visible on homepage and category page, hidden on department page
           (homePage || (department && category))
              ? <TopStoresSidebarList
                  department={department}
                  category={category}
                  subcategory={subcategory}
                  viewAllUrl={this.urlForRankingPage("trending-stores")}
                  currentSection={`${!homePage ? currentPageName + ' Stores' : 'TrendingStores'}`}
                  sectionPosition={9}
                  sectionId='topStorePick'
                />
              : null
          }
          { // only visible on category page
            department && category
              ? <TopCouponsList
                  viewAllUrl={this.urlForRankingPage("top-promo-codes")}
                  department={department}
                  category={category}
                  subcategory={subcategory}
                  currentSection={currentPageName + ' Vouchers'}
                  sectionPosition={10}
              />
              : null
          }
          {
            (department && !category) && (sortedDefinedDeptCategories?.length > 4)
              ? [...sortedDefinedDeptCategories]?.slice(4)?.map((cat, index) =>
                <CategoryTopTen
                  key={`defined_cat_${cat?.id}`}
                  sectionId='topCategoryPicks'
                  category={cat}
                  sectionPosition={index + 8}
                  isPersonalised={false}
                />
              )
              : null
          }
          {(this.props.match?.params?.department || this.props.match?.params?.category) && <SeoInfo seoData={seo.data} isLoading={isFetchingSeoContent} />}
          {homePage && !_.isEmpty(departments) && _.size(popularAndActiveAndSortedList(Object.values(departments))) >= 4 && _.range(4, _.size(popularAndActiveAndSortedList(Object.values(departments))) + 1).map(index => <DeparmentTopTen deptCounts={deptCounts} sectionId={`topDepartmentPick-${index}`} department={this.getDepartment(index)} key={_.get(this.getDepartment(index), "id")} currentSection={_.get(this.getDepartment(index), "attributes.name")} sectionPosition={ 10 + index} isPersonalised={false} />)}
          {homePage && <CustomWidget position='bottom' sectionId='customWidgetBottom' />}
        </Fragment>
        </div>
        </div>
        {/* {homePage && <AppExperiment id="Homepage intro black friday" defaultVarinat="green homepage intro" components={{
          'green homepage intro': <SearchOverlayModal isHomepageSearch />,
          'black friday homepage intro': <SearchOverlayModalBlack isHomepageSearch />
        }} />} */}
        {homePage ? 
        SHOW_BLACK_FRIDAY ?
           <SearchOverlayModalBlack isLoggedIn={this.props.user !== null} />
           :
           <SearchOverlayModal isLoggedIn={this.props.user !== null} />
           :
           null
        }

        {/* {homePage && <SearchOverlayModal isHomepageSearch />} */}
      </Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  activeTickerIds: reducers.getActiveTickerIds(state),
  departments: reducers.getAllDepartments(state),
  department: reducers.getDepartmentBySlug(state, ownProps.match.params.department),
  category: reducers.getCategoryBySlug(state, ownProps.match.params.category),
  subcategory: reducers.getSubcategoryBySlug(state, ownProps.match.params.subcategory),
  user: reducers.getCurrentUser(state),
  seo: reducers.getSeoContent(state),
  isFetchingSeoContent: reducers.isFetchingSeoContent(state),
  isUserloggedIn: reducers.isLoggedIn(state),
  userLoading: reducers.isUserLoading(state),
  isMobile: reducers.getIsMobile(state)
}}

export default connect(mapStateToProps, {
  loadListingPage,
  loadDepartmentCategoryPage,
  activeTickersRequestStart,
  seoContentRequestStart,
  resetCarouselLoaded,

})(ListingPage)
