const priceOptions = {
  values: [
    { key: 1, value: [0, 20], label: "Under $20" },
    { key: 2, value: [20, 40], label: "$20 to $40" },
    { key: 3, value: [40, 60], label: "$40 to $60" },
    { key: 4, value: [60, 90], label: "$60 to $90" },
    { key: 5, value: [90, 4000], label: "$90+" }
  ]
}

const discountOptions = {
  values: [
    { key: 1, value: [0, 0.1], label: "0% - 10%" },
    { key: 2, value: [0.1, 0.2], label: "10% - 20%" },
    { key: 3, value: [0.2, 0.3], label: "20% - 30%" },
    { key: 4, value: [0.3, 0.4], label: "30% - 40%" },
    { key: 5, value: [0.4, 0.5], label: "40% - 50%" },
    { key: 6, value: [0.5, 0.6], label: "50% - 60%" },
    { key: 7, value: [0.6, 0.7], label: "60% - 70%" },
    { key: 8, value: [0.7, 0.8], label: "70% - 80%" },
    { key: 9, value: [0.8, 0.9], label: "80% - 90%" },
    { key: 10, value: [0.9, 1], label: "90%+" }
  ]
}

const paymentOptions = {
  values: [
    { key: 1, value: "afterpay", label: "Afterpay" },
    { key: 2, value: "alipay", label: "Alipay" },
    { key: 3, value: "american_express", label: "American Express" },
    { key: 4, value: "apple_pay", label: "Apple Pay" },
    { key: 5, value: "google_pay", label: "Google Pay" },
    { key: 6, value: "humm", label: "Humm" },
    { key: 7, value: "klarna", label: "Klarna" },
    { key: 8, value: "latitude_pay", label: "Latitude Pay" },
    { key: 9, value: "master_card", label: "MasterCard" },
    { key: 10, value: "open_pay", label: "OpenPay" },
    { key: 11, value: "paypal", label: "Paypal" },
    { key: 12, value: "payright", label: "Payright" },
    { key: 13, value: "visa", label: "VISA" },
    { key: 14, value: "zip_pay", label: "Zip Pay" }
  ]
}

const freshnessOptions = {
  values: [
    { key: 1, value: [new Date(new Date().setDate((new Date()).getDate() - 2)), new Date()], label: "0 - 48hrs" },
    { key: 2, value: [new Date(new Date().setDate((new Date()).getDate() - 5)), new Date(new Date().setDate((new Date()).getDate() - 2))], label: "48hrs - 5 Days" },
    { key: 3, value: [new Date(null), new Date(new Date().setDate((new Date()).getDate() - 5))], label: "5 Days+" }
  ]
}

const startOptions = {
  values: [
    { key: 1, value: [new Date(null), new Date()], label: "On Now" },
    { key: 2, value: [new Date(new Date().setDate((new Date()).getDate() + 7)), new Date()], label: "This Week" },
    { key: 3, value: [new Date(new Date().setDate((new Date()).getDate() + 14)), new Date(new Date().setDate((new Date()).getDate() + 7))], label: "Next Week" },
    { key: 4, value: [new Date(new Date().setDate((new Date()).getDate() + 14)), new Date(new Date().setFullYear((new Date()).getFullYear() + 3))], label: "After Next Week" }
  ]
}

const couponTypeOptions = {
  values: [
    { key: 1, value: "dollar_discount", label: "$ Discount" },
    { key: 2, value: "percentage_discount", label: "% Discount" },
    { key: 3, value: "free_shipping", label: "Free Shipping" },
    { key: 4, value: "bonus_item", label: "Bonus Item" },
    { key: 5, value: "other", label: "Other" }
  ]
}

export {
  priceOptions,
  discountOptions,
  paymentOptions,
  freshnessOptions,
  startOptions,
  couponTypeOptions
}
