import React, { Component } from "react"

import SelectBox from "../../components/InputComponents/SelectBox"

import { COUPON_TYPE_OPTIONS } from "./formOptions"

class CouponFields extends Component {
  constructor(props) {
    super(props)

    this.state = {
      coupon_state: ""
    }
  }

  handleAttributeChange = object => {
    const {
      handleAttributeUpdate
    } = this.props

    handleAttributeUpdate(object)
  }

  handleCouponType = object => {
    const {
      handleAttributeUpdate
    } = this.props

    handleAttributeUpdate(object)

    this.setState({ coupon_state: object.value })
  }

  render() {
    const {
      discount_amount,
      errorMessage,
    } = this.props

    const {
      coupon_state
    } = this.state

    return (
      <div>
        <SelectBox
          title="Voucher Type"
          name="coupon_type"
          objects={COUPON_TYPE_OPTIONS}
          errorMessage={errorMessage}
          selectChange={e => { this.handleCouponType(e) }} />
        {errorMessage}

        { ["percentage_discount", "dollar_discount"].includes(coupon_state)
          && (
            <div className="form-group">
              Discount Value
              <input type="number" name="discount_amount" placeholder="We need a valid discount value" className="form-control" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={discount_amount} />
            </div>
          )
        }
      </div>
    )
  }
}

export default CouponFields