import * as types from "./types"

export const loadSubcategories = keyword => ({
  type: types.SUBCATEGORIES_FETCH,
  keyword
})

export const subcategoryRequestStart = data => ({
  type: types.SUBCATEGORIES_REQUEST_START,
  data
})

export const subcategoryRequestSuccess = data => ({
  type: types.SUBCATEGORIES_REQUEST_SUCCESS,
  data
})

export const subcategoryRequestFailure = error => ({
  type: types.SUBCATEGORIES_REQUEST_FAILURE,
  error
})