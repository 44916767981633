import React, { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

import { DealCard } from '../../../../../../components/ElasticListingCards'
import Loader from '../../../../../../components/Loader'

import { getTopElasticDeals } from '../../../../../App/requesters'
import { getBaseUrl } from '../../../../../App/reducers'

import AdvertisementList from '../../../AdvertisementList'
import { dealOptions } from '../sortOptions'

export default function CustomElasticList ({
  sortOptionDealValue,
  listType,
  isReady,
  handlePriceRangeChange,
  filterOptions: {
    storeNames,
    brandNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    paymentMethods,
    priceRange,
    discountRange,
    lastPriceRange
  },
  sortDirection, sortColumn
}) {
  const [page, setPage] = useState(1)
  const [deals, setDeals] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setIsLoading] = useState(true)
  const [column, setColumn] = useState('fixed_department_score')
  const [direction, setDirection] = useState('desc')

  const baseUrl = useSelector(getBaseUrl)

  const params = {
    id: 'deal_search',
    is_top_deal: true,
    per_page: 50,
    from: 50 * (page - 1),
    order: direction,
    value: column,
    store_names: storeNames,
    brand_names: brandNames,
    department_names: departmentNames,
    category_names: categoryNames,
    subcategory_names: subcategoryNames,
    by_payment_methods: paymentMethods,
    price: priceRange,
    price_shift: discountRange.map(e => e * -1).reverse(),
    last_price_date: lastPriceRange
  }

  useEffect(() => {
    async function fetchInitialPage () {
      setIsLoading(true)
      setDeals([])
      setPage(1)

      const { response } = await getTopElasticDeals(baseUrl, params)
      setDeals(response.data)
      setUsers(response.included.data)
      setIsLoading(false)
      Object.keys(response?.price_range)?.length && handlePriceRangeChange([response?.price_range?.min_price, response?.price_range?.max_price])
    }

    if (isReady) fetchInitialPage()
  }, [
    direction, column,
    sortDirection, sortColumn,
    storeNames, brandNames,
    departmentNames, categoryNames,
    subcategoryNames, paymentMethods,
    priceRange, discountRange,
    lastPriceRange
  ])

  useEffect(() => {
    async function fetchElasticData () {
      setIsLoading(true)

      const { response } = await getTopElasticDeals(baseUrl, params)
      setDeals(deals => [...deals, ...response.data])
      setUsers(users => [...users, ...response.included.data])
      setIsLoading(false)
    }

    if (page > 1) fetchElasticData()
  }, [page])

  useEffect(() => {
    const option = dealOptions.values.find(value => value.key === parseInt(sortOptionDealValue, 10))
    if (option) {
      setColumn(option.value)
      setDirection(option.order)
    }
  }, [sortOptionDealValue])

  return (
    <>
      <br />
      {/* <h3>{customList.attributes.title}</h3>
      <p>{customList.attributes.subtitle}</p> */}
      {
        // Show when there is no data to see
        !loading && deals.length === 0
          ? <span>Oops! No results found. Try using different filters.</span>
          : (
            <>
              <AdvertisementList location={`ranking-${listType}-top`} position='middle' page='ranking' list={listType} />
              <InfiniteScroll
                className='row listing-grid'
                dataLength={deals.length} // This is important field to render the next data
                next={() => setPage(page + 1)}
                hasMore
                loader={<div className='listing-card col-12'><Loader isLoading={loading} /></div>}
                endMessage={(
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                )}
              >
                {deals && deals.map((deal, index) => (
                  <DealCard
                    deal={deal}
                    wrapperClass='col-sm-6 col-lg-4'
                    position={index + 1}
                    currentSection='ElasticList'
                    sectionPosition={1}
                    key={`deal-${deal.id}`}
                    user={!(deal.user_name && deal.user_slug && deal.user_avatar_url) ? _.first(users.filter(user => user.id === deal.user_id)) : null}
                  />
                ))}
              </InfiniteScroll>
            </>
            )
      }
    </>
  )
}
