import {
  all, fork
} from "redux-saga/effects"

import { watchCategorySaga } from "./CategorySagas"
import { watchDepartmentSaga } from "./DepartmentSagas"
import { watchSubcategorySaga } from "./SubCategorySagas"

export default function* RootSaga() {
  yield all([
    fork(watchDepartmentSaga),
    fork(watchCategorySaga),
    fork(watchSubcategorySaga)
  ])
}