export const FIELD_VALIDATIONS = [
  { field: "name", message: "The first 20 characters in the title are critical for maximum exposure" },
  { field: "description", message: "A description is key so that the community can understand the ins and outs of the deal you're sharing" },
  { field: "url", message: "We need a valid URL to share with the community" },
  { field: "product_url", message: "We need a valid URL to share with the community" },
  { field: "listing_type", message: "Please select the deal you're sharing with the community " },
  { field: "start_at", message: "You must select a start date" },
  { field: "availability", message: "Please select one" },
  { field: "brand_id", message: "To give your deal the best exposure we need a brand" },
  { field: "price", message: "We need a valid deal price" },
  { field: "department_id", message: "Please select a department" },
  { field: "category_id", message: "Please select a category" },
  { field: "subcategory_id", message: "Please select a sub-category" },
  { field: "coupon_type", message: "Please select a coupon type" }
]