import React from 'react'
import Loader from './Loader'

import FollowButton from '../apps/App/components/buttons/FollowButton'
import InfoModalButton from './Buttons/InfoModalButton'
import { gon } from '../../constant'

export default function UserProfileHeader({ user }) {
  if (!user) {
    return (
      <div className='row'>
        <div className='col p-4'>
          <Loader />
        </div>
      </div>
    )
  }
  
  const {
    attributes: {
      username,
      upvotesCount, downvotesCount,
      followersCount,
      commentsCount,
      about
    },
    links: {
      avatar: {
        url: UserAvatarUrl
      }
    }
  } = user

  const getDesktopAboutContent = about => (
    about.length > 50 ? (
      <>
        <p className="store-description">{`${about}`}</p>
        <InfoModalButton text={about} wrapperClass="more pr-2" type="more..." />
      </>
    )
    : <p className="store-description">{`${about}`}</p>
  )

  const desktopAboutContent = about && getDesktopAboutContent(about)

  return (
    <section className="section-group">
      <div className="d-flex store-brand-summary profile-summary">
        <div className="listing-brand-store">
          <div className="wrapper">
            <img className="d-block profile-img profile-img-lg" src={UserAvatarUrl} />
            <FollowButton item={user} buttonClasses="btn btn-follow" />
          </div>
        </div>
        <div className="store-summary">
          <h1>{username}</h1>
          <div className="store-stats d-flex">
            <span>{`${followersCount} Followers`}</span>
            <span className="dash mx-2">-</span>
            <span>{`${upvotesCount + downvotesCount} Votes`}</span>
            <span className="dash mx-2">-</span>
            <span>{`${commentsCount} Comments`}</span>
          </div>
          { desktopAboutContent }
        </div>
      </div>
    </section>
  )
}
