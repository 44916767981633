import React, { Component } from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import { withCookies, Cookies } from 'react-cookie'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { connect } from 'react-redux'
import GoogleSignInCustom from './GoogleSignInCustom'

import { gon, GOOGLE_APP_ID, FACEBOOK_APP_ID, LB_APPLE_CLIENT_ID,LB_APPLE_REDIRECT_URI_ID } from '../../../../../../constant'

import {
  googleLogin,
  facebookLogin,
  reportIcon,
  LBLogo,
  aboutIcon,
  crossIcon,
  appleIcon
} from '../../../../../components/Icons'
import CbaCard from './CbaCard'
import { setUserEmailAddress } from '../../../actions/personalise'
import FlyingBirdLoop from '../../../../../../assets/images/interactions/flying-loop.gif'
import RegistrationForm from './RegistrationForm'
import onboardingFlowDataLayer from '../../../../../components/dataLayers/onboardingFlowDataLayer'
import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'
import AppleSignin from 'react-apple-signin-auth'

const emailValidation = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

class Register extends Component {
  constructor(props) {
    super(props)

    const { cookies } = props
    this.state = {
      wtidCookie: cookies.get('wtid') || '',
      wtidUtmCookies: {
        utm_source: cookies.get('utm_source') || '',
        utm_medium: cookies.get('utm_medium') || '',
        utm_campaign: cookies.get('utm_campaign') || '',
        utm_term: cookies.get('utm_term') || '',
        utm_content: cookies.get('utm_content') || ''
      },
      errors: [],
      formData: {
        email: this.props.userData.email || undefined
      },
      flyOff: '',
      isCbaRegisterInProgress: false
    }
  }

  componentDidMount () {
    trackForm()
    onboardingFlowDataLayer('onboarding-flow-view', 'start-personalising', 'submit-email', 1)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { formData } = this.state
    const {
      prevUrl: { isCba, prevUrl }
    } = this.props

    if (!formData.email) {
      this.setState({ errors: ['Email can’t be blank'] })
      return false
    }

    if (!emailValidation.test(formData.email)) {
      this.setState({ errors: ['Kindly provide valid email id'] })
      return false
    }

    onboardingFlowDataLayer('onboarding-flow-click', 'start-personalising', 'submit-email', 1)

    this.setState({ errors: [] })

    if (isCba) {
      this.setState({ isCbaRegisterInProgress: true })
    } else {
      const accountData = {
        wtidCookie: this.state.wtidCookie,
        wtidUtmCookies: this.state.wtidUtmCookies,
        ...formData,
        show: true
      }

      this.setState({ flyOff: 'animation-1' })

      setTimeout(() => {
        this.props.setUserEmailAddress(accountData)
        this.props.history.push('/', [{prevUrl}])
      }, 1500)
    }
  }

  onSuccessGoogle = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (googleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`
      const wtidUtmCookies = this.state.wtidCookie
        ? this.state.wtidUtmCookies
        : {}

      let queryData = {
        id_token: googleResponseData.tokenId,
        provider: 'google_oauth2',
        wtid: this.state.wtidCookie,
        ...wtidUtmCookies
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => this.setState({ errors: error?.data?.errors }))
    }
    fetchData(responseData)
  }

  handleGoogleSignInErrors = (responseData) => {
    let errorMessage =
      'There was an error authenticating with Google, please try again.'
    if (responseData) {
      errorMessage = responseData
    }  
    this.setState({ errors: [errorMessage] })
  }

  /***
   * Handle facebook success response
   */
  responseFacebook = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (facebookResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`
      const wtidUtmCookies = this.state.wtidCookie
        ? this.state.wtidUtmCookies
        : {}

      let queryData = {
        id_token: facebookResponseData.accessToken,
        provider: 'facebook',
        wtid: this.state.wtidCookie,
        ...wtidUtmCookies
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => {
          this.setState({
            error: [
              'There was an error authenticating with Facebook, please try again.'
            ]
          })
        })
    }
    fetchData(responseData)
  }

  /**
   * Handle Facebook failure response
   */
  onFacebookFailure = (failedResponseData) => {
    let errorMessage = [
      'There was an error authenticating with Facebook, please try again.'
    ]

    if (failedResponseData.error) {
      if (failedResponseData.error?.errors) {
        errorMessage = failedResponseData.error.errors
      } else
        errorMessage = [
          failedResponseData.error?.message || failedResponseData.error
        ]
    }
    this.setState({ errors: errorMessage })
  }

  handleAttributeChange = (event) => {
    const { name, value } = event
    const { formData } = this.state

    formData[name] = value

    this.setState({ formData })
  }
 /**
   * Handle Apple login success response
  */
 responseAppleLogin = (response) => {
  const { prevUrl } = this.props
  const fetchData = async (appleResponseData) => {
    const path = `${gon.urls.api}/omniauth/provider/apple_id`

    let queryData = {
      wtid: this.state.wtidCookie,
      id_token: appleResponseData?.authorization?.id_token,
      platform: 'web'
    }


    return axios
      .post(path, queryData)
      .then((response) => {
        if (response.status !== 200) {
          this.setState({ error: response?.data?.error })
          return null
        }
        window.localStorage.setItem(
          'auth_token',
          response.headers['x-auth-token']
        )
        sendAuthToExtension(response.headers['x-auth-token'])
        window.localStorage.setItem(
          'csrf_token',
          response.headers['x-csrf-token']
        )

        window.location.href =
          typeof prevUrl === 'object'
            ? prevUrl.prevUrl || '/'
            : prevUrl || '/'
        return null
      })
      .catch((error) => {
        this.setState({
          error: [
            'There was an error authenticating with Apple, please try again.'
          ]
        })
      })
  }
  fetchData(response)
}
/**
 * Handle Apple login failure response
*/
onAppleFailure = (failedResponseData) => {
  let errorMessage = ['There was an error authenticating with Apple, please try again.']

  this.setState({ errors: [errorMessage] })
}
  render() {
    const { errors } = this.state
    const {
      prevUrl: { message, prevUrl, backCount, isCba }
    } = this.props

    const renderTooltip = (props, content) => (
      <Tooltip id='button-tooltip' {...props}>
        {content}
      </Tooltip>
    )
    const prevPage = prevUrl?.includes('/shop')
    const fromExtension = sessionStorage.getItem('fromExtension')

    return (
      <>
        <div className='login-register signup-bg'>
          <div className='d-flex justify-content-center'>
            <div className='card-container'>
              <div className={`device-header m-40 ${isCba && 'is-cba'}`}>
                {
                  isCba
                    ? < CbaCard message={message} />
                    :
                    (prevPage || fromExtension ?
                      <>
                        <div className={`h2 title signup-background bird-fly-position d-flex flex-column up ${this.state.flyOff}`}>
                        <div >
                          <span className='text-color-primary'>Sign up </span>
                          to experience the best of Little Birdie
                        </div>
                      </div>
                      <p className='p-0 signup-para-text'>Stay up to date and personalise your shopping feed</p>
                      </>
                    :
                    <>
                        <div className={`h2 title signup-background d-flex flex-column up ${this.state.flyOff}`}>
                        <div >
                          <span className='text-color-primary spacing'>Personalise </span>
                          your shopping feed
                        </div>
                      </div>
                      <p>
                        Little Birdie hand picks offers from your favourite brands and
                        stores.
                      </p>
                      </>
                    )

                }
              </div>
              <div className='card device-form'>
                <div className={`card-body ${this.state?.isCbaRegisterInProgress && 'mb-4'}`}>
                  {Array.isArray(errors) && errors.length !== 0 ? (
                      <div className='alert alert-danger alert-block error-label'>
                        <button
                          className='close'
                          onClick={() => this.setState({ errors: [] })}
                          type='button'
                        >
                          <img src={crossIcon} />
                        </button>
                        <div className='mt-4'>
                          {errors?.map((error, index) => (
                            <p key={index} className='text-dark d-flex'>
                              <img src={aboutIcon} className='mr-3' /> {error}
                            </p>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ''
                  )}
                  {
                    this.state?.isCbaRegisterInProgress
                      ? (
                        <>
                          <RegistrationForm
                            handleBack={({errors}) => this.setState({ isCbaRegisterInProgress: false, errors: errors || []})}
                            userData={this.state.formData}
                            wtidCookie={this.state.wtidCookie}
                            wtidUtmCookies={this.state.wtidUtmCookies}
                            prevUrl={this.props?.prevUrl}
                          />
                        </>
                        )
                      : (
                        <form onSubmit={this.handleSubmit}>
                          <div className='form-group'>
                            <input
                              className='form-control'
                              type='email'
                              name='email'
                              id='user_email'
                              value={this.state.formData.email}
                              placeholder='Email'
                              onChange={(e) =>
                                this.handleAttributeChange({
                                  name: e.currentTarget.name,
                                  value: e.currentTarget?.value.toLowerCase()
                                })
                              }
                            />
                          </div>
                          <div className='text-center'>
                            <div className='form-group'>
                              <button
                                type='submit'
                                className='btn btn-main rounded-pill btn-block'>
                                {isCba || prevPage ? 'Sign up' : 'Start personalising'}
                              </button>
                            </div>
                            <div className='device-links d-flex justify-content-center align-items-center t-14'>
                              Have an account?
                              <Link
                                className='nav-link pl-1 text-underline text-budgee font-weight-bold'
                                to={{
                                  pathname: '/login',
                                  state: {
                                    from: {
                                      prevUrl,
                                      message,
                                      backCount: backCount - 1,
                                      isCba
                                    }
                                  }
                                }}>
                                Sign in
                              </Link>
                            </div>
                            <hr data-content='Or' className='divider-text' />
                          </div>
                          <div className='text-center mb-3'>
                            <div className='d-inline-flex flex-column justify-content-center'>
                              <div className='align-self-center border w-100 rounder-corners'>
                                <GoogleSignInCustom prevUrl={this.props.prevUrl} wtidCookie={this.state.wtidCookie} handleGoogleSignInErrors={(errors)=>this.handleGoogleSignInErrors(errors)} />
                              </div>
                              <div className='align-self-center border w-100 rounder-corners mt-4'>
                                <FacebookLogin
                                  appId={FACEBOOK_APP_ID}
                                  fields='name,email,picture'
                                  scope='public_profile,email'
                                  callback={this.responseFacebook}
                                  disableMobileRedirect={true}
                                  onFailure={this.onFacebookFailure}
                                  cssClass='my-facebook-button-class'
                                  render={(renderProps) => (
                                    <a
                                      className='btn btn-block animate shake pointer d-flex align-items-center'
                                      onClick={renderProps.onClick}>
                                      <div className='social-img mr-3'>
                                        <img
                                          style={{ width: '100%' }}
                                          src={facebookLogin}
                                        />
                                      </div>
                                      <span>Sign in with Facebook</span>
                                    </a>
                                  )}
                                  icon={facebookLogin}
                                />
                              </div>
                              <div className='align-self-center border w-100 rounder-corners mt-4'>
                                <AppleSignin
                                    /** Auth options passed to AppleID.auth.init() */
                                  authOptions={{
                                    /** Client ID - eg: 'com.example.com' */
                                    clientId: `${LB_APPLE_CLIENT_ID}`,
                                    /** Requested scopes, seperated by spaces - eg: 'email name' */
                                    scope: 'email name',
                                    /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                                    redirectURI: `${LB_APPLE_REDIRECT_URI_ID}`,
                                    usePopup: true
                                  }} 
                                  className="apple-auth-btn"
                                  onSuccess={(response) => this.responseAppleLogin(response)}
                                  onError={(error) => this.onAppleFailure(error)}
                                  buttonExtraChildren="Continue with Apple"
                                  render={(props) => (
                                    <a
                                      className='btn btn-block animate shake pointer d-flex align-items-center'
                                      {...props}>
                                      <div className='social-img-apple-id'>
                                        <img
                                          style={{ width: '100%' }}
                                          src={appleIcon}
                                        />
                                      </div>
                                      <span>Sign in with Apple ID</span>
                                    </a>
                                  )}
                                  icon={appleIcon}
                                />
                        </div>
                            </div>
                          </div>
                        </form>
                      )
                  }
                </div>
                { !this.state?.isCbaRegisterInProgress &&
                  <div className='device-links text-center px-3'>
                    By joining in you agree to our&nbsp;
                    <a href='/terms' target='_new'>
                      Terms and Conditions
                    </a>
                    ,&nbsp;
                    <a
                      href='https://help.littlebirdie.com.au/hc/en-us/articles/360057583591'
                      target='_new'>
                      Community Standards
                    </a>
                    {' and'}
                    &nbsp;
                    <a href='/privacy' target='_new'>
                      Privacy Policy
                    </a>
                    .
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    appStore: state,
    userData: state.personalise.Personalise
  }
}

const mapDispatchToEvents = (dispatch) => {
  return {
    setUserEmailAddress: (data) => dispatch(setUserEmailAddress(data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToEvents
)(withRouter(withCookies(Register)))
