import React, { Component } from "react"
import AsyncSelect from "react-select/async"

import axios from "axios"
import normalize from "json-api-normalizer"
import { mapOrder } from "../../helpers/arrayHelpers"

import { gon } from "../../../constant"

class SelectBoxLookup extends Component {
  state = { keyword: "" }

  handleInputChange = keyword => {
    this.setState({ keyword })
  }

  // eslint-disable-next-line consistent-return
  loadOptions = () => {
    const {
      keyword
    } = this.state

    const name = "brand_id"
    const options = { params: { keyword, per_page: 10 } }

    const path = `${gon.urls.api}/brands`

    if (keyword.length > 2) {
      return axios.get(path, options)
        .then(({ data }) => {
          const brands = Object.values(normalize(data).brand)
          const orderedBrands = mapOrder(brands, data.meta.order, "id")

          return _.map(orderedBrands, brand => ({ name, label: brand.attributes.name, value: brand.id }))
        })
        .catch(() => [])
    }
  }

  render() {
    const {
      title,
      isMultiSelect,
      name,
      value,
      errorMessage,
      selectChange
    } = this.props

    return (
      <div className="form-group">
        <label>{title}</label>
        <AsyncSelect
          name={name}
          isClearable
          isSearchable
          className={'form-control form-control-select'}
          isMulti={isMultiSelect}
          loadOptions={this.loadOptions}
          onChange={selectChange}
          onInputChange={this.handleInputChange}
          value={value} />
        {errorMessage}
      </div>
    )
  }
}

export default SelectBoxLookup
