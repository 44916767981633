import React from 'react'
import { useSelector } from 'react-redux'

import { getResource } from '../../../apps/App/reducers'
import Following from './Following'
import Voting from './Voting'
import Posting from './Posting'
import Commenting from './Commenting'

export default function ActivityRow ({ item }) {
  const {
    attributes: { key },
    relationships: {
      owner: { data: { id: ownerId, type: ownerType } },
      recipient: { data: { id: recipientId, type: recipientType } }
    }
  } = item

  const owner = useSelector(state => getResource(state, { type: ownerType, id: ownerId }))
  const recipient = useSelector(state => getResource(state, { type: recipientType, id: recipientId }))

  if (!item) return null

  if (['vote.upvote.remove', 'vote.downvote.remove', 'deal.create', 'coupon.create', 'coupon.archived', 'sale_event.create', 'sale_event.archived', 'sale_event.rejected'].includes(key)) {
    return null
  } if (['follow.follow', 'follow.unfollow'].includes(key)) {
    return <Following {...{ item, owner, recipient }} />
  } if (['vote.upvote.create', 'vote.downvote.create'].includes(key)) {
    return <Voting {...{ item, owner, recipient }} />
  } if (['deal.approved', 'coupon.approved', 'unique_coupon.create', 'sale_event.approved', 'unique_coupon.approved'].includes(key)) {
    return <Posting {...{ item, owner, recipient }} />
  } if (['comment.create'].includes(key)) {
    return <Commenting {...{ item, owner, recipient }} />
  }

  return (
    <div className='border-bottom activity-row p-4'>
      <div className='media'>
        {`${_.startCase(item.type)} for ${_.startCase(recipient.type)}`}
      </div>
    </div>
  )
}
