import * as types from "./types"

export const loadDepartments = keyword => ({
  type: types.DEPARTMENTS_FETCH,
  keyword
})

export const departmentRequestStart = data => ({
  type: types.DEPARTMENTS_REQUEST_START,
  data
})

export const departmentRequestSuccess = data => ({
  type: types.DEPARTMENTS_REQUEST_SUCCESS,
  data
})

export const departmentRequestFailure = error => ({
  type: types.DEPARTMENTS_REQUEST_FAILURE,
  error
})