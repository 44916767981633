import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Loader from '../../../components/Loader'
import { CBA_EXCLUSIVE_PICK_REQUEST_START, CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS } from '../actions/types'
import ListingCard from './ListingCard'
import ChevronRight from './ChevronRight'
import { cbaLogoProudPartner } from '../../../components/Icons'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'

export default function CbaExclusivePicks ({sectionId}) {
  const { department, category, subcategory } = useParams()
  const sectionRef = useRef(null)
  const { request, byIds } = useSelector(state => state.listings.cbaExclusivePicks)
  const dispatch = useDispatch()

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    if(!(department || category || subcategory)) {
      dispatch(setCarouselLoading({ carousel: sectionId }))
    }
  }, [])

  useEffect(()=> {
    if(_.size(byIds) == 0) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
    else if(sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [request, sectionRef?.current])

  if (department || category || subcategory) return null
  if (request === CBA_EXCLUSIVE_PICK_REQUEST_START) return <Loader isLoading />

  if ([CBA_EXCLUSIVE_PICK_REQUEST_START, CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS].includes(request)) {
    return (
      _.size(byIds) > 0 && (
        <div id={sectionId} className='lazyload-wrapper' ref={sectionRef}>
          <section className='section-group cba-exclusive'>
            <div className='container'>
              <section className='section-bg' />
            </div>
            <div className='section-group-body'>
              <div className='container'>
                <div className='listing-group-header d-flex flex-sm-nowrap flex-wrap justify-content-between'>
                  <div className='order-1 order-sm-0'>
                    <h2 className='cba-heading'>CommBank Exclusives</h2>
                    <div className='subtitle mb-2'>
                      CommBank has partnered with Little Birdie to bring you these exclusive offers
                    </div>
                  </div>
                  <div className='order-0 order-sm-1 '>
                    <img src={cbaLogoProudPartner} className='cba-logo mb-3 mt-n2' />
                  </div>
                </div>
              </div>
              <CbaExclusiveBody sectionId={sectionId} />
            </div>
          </section>
        </div>
      )
    )
  }
  return null
}

function CbaExclusiveBody ({sectionId}) {
  const myRef = useRef(null)
  const { byIds } = useSelector(state => state.listings.cbaExclusivePicks)

  const cbaExclusiveArr = _.orderBy(byIds, [obj => new Date(obj?.attributes?.createdAt)], ['desc'])

  return (
    <div className='container mt-3'>
      <div className='listing-carousel listing-group-header'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          <ChevronRight offset={300} ref={myRef} direction='left' />
          {cbaExclusiveArr?.map((item, index) => {
            const {
              relationships: { cbaExclusive: { data } }
            } = item
            if (data) {
              const { type, id } = data
              return <CbaExclusiveItem sectionId={sectionId} key={`${type}-${id}`} type={type} id={id} index={index} />
            }
            return null
          })}
          <ChevronRight offset={300} ref={myRef} direction='right' />
        </div>
      </div>
    </div>
  )
}

function CbaExclusiveItem ({ type, id, index, sectionId }) {
  const wrapperClass = {
    saleEvent: 'event',
    coupon: '',
    uniqueCoupon: ''
  }[type]

  return <ListingCard sectionId={sectionId} wrapperClass={wrapperClass} type={type} id={id} position={index + 1} currentSection='CbaExclusivePicks' sectionPosition={2} />
}
