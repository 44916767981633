import React from 'react'
import { iconCheckFilled } from '../../../../../components/Icons'

export default function ProgressBar ({ steps, currentStep = -1, className = '' }) {
  return (
    <ul className={`stepper-wizard-list px-0  ${className}`}>
      {steps.map((li, key) => {
        const isActive = key === (currentStep - 1) ? 'active' : ''
        const isCompleted = key < (currentStep - 1) ? 'completed' : ''
        return (
          <li key={key} className='step-wizard-item'>
            <div className={`progress-point ${isCompleted} ${isActive}`}>
              {isCompleted ? <img src={iconCheckFilled} /> : '?'}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
