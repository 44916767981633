import React, { Component } from "react"
import Select from "react-select"

class SelectBox extends Component {
  loadOptions = objects => {
    const {
      name,
      includeBlank
    } = this.props

    if ([undefined, null, []].includes(objects)) return [{ name, label: "All", value: "all" }]

    if (["availability", "coupon_type", "listing_type"].includes(name)) {
      return objects.map(object => ({ name, label: object.label, value: object.value }))
    }

    const mappedObjects = objects.filter(object => object.attributes.name !== null).map(object => ({ name, label: object.attributes.name, value: object.attributes.id }))

    if (includeBlank) { return _.uniq([{ name, label: "All", value: "all" }].concat(mappedObjects)) }

    return _.uniq(_.orderBy(mappedObjects, "label"))
  }

  handleSelectChange = object => {
    const {
      selectChange,
      name
    } = this.props

    if (_.isEmpty(object)) { return selectChange([{ name, value: undefined }]) }

    return selectChange(object)
  }

  render() {
    const {
      title,
      objects,
      isMultiSelect,
      name,
      value,
      errorMessage
    } = this.props

    return (
      <div className="form-group">
        <label>{title}</label>

        { name === "tag_ids"
        && (
          <i> (optional)</i>
        )
        }
        {":"}

        { ["availability", "coupon_type", "listing_type", "tag_ids"].includes(name)
          ? (
            <Select
              name={name}
              className={'form-control form-control-select'}
              isClearable
              isSearchable
              isMulti={isMultiSelect}
              options={this.loadOptions(objects)}
              onChange={this.handleSelectChange} />
          )
          : (
            <Select
              name={name}
              className={'form-control form-control-select'}
              isClearable
              isSearchable
              value={_.filter(this.loadOptions(objects), ["value", value])}
              isMulti={isMultiSelect}
              // selectedValue="all"
              options={this.loadOptions(objects)}
              onChange={this.handleSelectChange} />
          )
        }
        {errorMessage}
      </div>
    )
  }
}

export default SelectBox
