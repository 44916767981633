import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Loader from "../../../../components/Loader"
import { DealCard } from '../../../../components/ElasticListingCards'
import { getBaseUrl } from "../../reducers"
import { getDeals } from "../../requesters"
import ChevronRight from "../ChevronRight"

export default function FollowedDealsList({
  user, title, description
}) {
  const [followedDeals, setFollowedDeals] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const baseUrl = useSelector(getBaseUrl)
  const myRef = useRef(null)

  const {
    id,
  } = user

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
      followed_by: id,
      "by_order[column]": "last_price_date",
      "by_order[direction]": "desc"
    }

    async function fetchData() {
      const { response } = await getDeals(baseUrl, params)
      if (response?.length) {
        setFollowedDeals(response)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const followedDealsList = _.map(followedDeals, (deal, index) => (
    <DealCard 
      deal={deal}
      wrapperClass=''
      position={index + 1}
      currentSection='followedDealsList'
      sectionPosition={1}
      key={`deal-${deal.id}`}
    />
  ))

  return (
    <div className="section-group">
      <div className="container">
        <div className="listing-group-header">
          <h2>{title || `Price Drops ${user.attributes.username} following`}</h2>
          <p className="subtitle">{description || `Here's the list of Price Drops you've chosen to follow`}</p>
        </div>
      </div>
      <div className="listing-carousel">
        <div className=" d-flex listing-group-carousel" ref={myRef}>
          {
           isLoading
            ? <Loader isLoading={isLoading} />
            : (
              <>
                <ChevronRight offset={300} ref={myRef} direction="left" />
                {followedDealsList}
                <ChevronRight offset={300} ref={myRef} direction="right" />
                {
                  followedDealsList.length < 8 && (
                    <Link to="/" className="listing-card listing-find-more"> {/* top-price-drops */}
                      <div className="card-price-drop d-flex align-items-center justify-content-center">
                        <div className="rounded-pill btn btn-outline-dark">
                          Find more +
                        </div>
                      </div>
                    </Link>
                  )
                }
                {/* {followedDealsList.length >= 8 && (
                  <div className="listing-card">
                    <Link to={`/spotter/${user.attributes.slug}/following/deals`} className="listing-find-more">
                      <div className="rounded-pill mx-auto">
                        View all
                      </div>
                    </Link>
                  </div>
                )} */}
              </>
          )
        }
        </div>
      </div>
    </div>
  )
}
