import React from 'react'
import { Link } from 'react-router-dom'

import { departmentPath } from '../../../helpers/urlHelpers'

export default function RecipientLink ({ recipient }) {
  const { type } = recipient
  switch (type) {
    case 'user':
      return <UserRecipient recipient={recipient} />
    case 'deal':
      return <DealRecipient recipient={recipient} />
    case 'department':
      return <DepartmentRecipient recipient={recipient} />
    case 'store':
      return <StoreRecipient recipient={recipient} />
    case 'brand':
      return <BrandRecipient recipient={recipient} />
    case 'coupon':
      return <CouponRecipient recipient={recipient} />
    case 'saleEvent':
      return <SaleEventRecipient recipient={recipient} />
    default:
      return <div>{_.startCase(type) || ''}</div>
  }
}

export function UserRecipient ({ recipient }) {
  const {
    attributes: { username },
    links: {
      avatar: { thumb: { url } },
      userUrl
    }
  } = recipient
  return (
    <Link to={userUrl}>
      <img alt='' className='mr-2 rounded-circle img-30' src={url} />
      <strong>{`@${username}`}</strong>
    </Link>
  )
}

export function DealRecipient ({ recipient }) {
  const {
    attributes: { name },
    links: { dealUrl },
    relationships: {
      department
    }
  } = recipient

  return (
    <>
      {'a deal '}
      <Link to={dealUrl}>
        <strong>{name}</strong>
      </Link>
      {' in '}
      <Link to={departmentPath({ departmentSlug: _.get(department, 'attributes.slug') })}>
        <strong>{_.get(department, 'attributes.name')}</strong>
      </Link>
    </>
  )
}

export function DepartmentRecipient ({ recipient }) {
  const {
    attributes: { name, slug }
  } = recipient
  return (
    <>
      {'a category '}
      <Link to={departmentPath({ departmentSlug: slug })}>
        <strong>{name}</strong>
      </Link>
    </>
  )
}

export function StoreRecipient ({ recipient }) {
  const {
    attributes: { name },
    links: { storeUrl }
  } = recipient
  return (
    <>
      {'a store '}
      <Link to={storeUrl}>
        <strong>{name}</strong>
      </Link>
    </>
  )
}

export function BrandRecipient({ recipient }) {
  const {
    attributes: { name },
    links: { brandUrl }
  } = recipient
  return (
    <>
      {'a brand '}
      <Link to={brandUrl}>
        <strong>{name}</strong>
      </Link>
    </>
  )
}

export function CouponRecipient({ recipient }) {
  const {
    attributes: { name },
    links: { couponUrl }
  } = recipient
  return (
    <>
      {'a voucher '}
      <Link to={couponUrl}>
        <strong>{name}</strong>
      </Link>
    </>
  )
}

export function SaleEventRecipient({ recipient }) {
  const {
    attributes: { name },
    links: { saleEventUrl }
  } = recipient
  return (
    <>
      {'a sale event '}
      <Link to={saleEventUrl}>
        <strong>{name}</strong>
      </Link>
    </>
  )
}
