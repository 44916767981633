import React from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"

const toastId = "redirect-with-error"
const containerId = "main"
const toastOptions =  {
  toastId,
  containerId
}

const RedirectWithAlert = ({
  url = "/",
  message = "Sorry the page you requested does not exist"
}) => {
  toast.error(
    <div>
      <span><i className="fa fa-exclamation mr-2" /></span>
      { message }
    </div>,
    toastOptions
  )
  return (
    <Redirect to={{
      pathname: url,
    }} />
  )
}

export default RedirectWithAlert