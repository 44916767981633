import { useEffect } from 'react'
import filterDataLayer from '../../../../components/dataLayers/filterDataLayer'

function useAccordionOpenHeader (accordionHeader, position, currentSection) {
  useEffect(() => {
    accordionHeader?.current?.addEventListener('click', (e) => {
      if (accordionHeader?.current.ariaExpanded === 'false' || !accordionHeader?.current.ariaExpanded) {
        filterDataLayer(null, 'open-filter', position, currentSection, null)
      }
    })
    return () => {
      accordionHeader?.current?.removeEventListener()
    }
  }, [])
}
export default useAccordionOpenHeader