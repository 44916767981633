/* eslint-disable react/no-danger */
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { reportIcon } from "../../components/Icons"

export default function InfoModalButton({ text, wrapperClass, type }) {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {text ? (
        <div className={wrapperClass}>
          <div className="" onClick={handleShow}>
            { type || <img src={reportIcon} /> }
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Body><div dangerouslySetInnerHTML={{ __html: text }}></div></Modal.Body>
            </Modal.Header>
          </Modal>
        </div>
      ) : (
        <div className={wrapperClass} onClick={handleShow}>
          { type || <img src={reportIcon} /> }
        </div>
      )}
    </>
  )
}
