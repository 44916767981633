import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { YOUR_TOP_PICK_REQUEST_SUCCESS } from '../actions/types'
import ListingCard from './ListingCard'
import ChevronRight from './ChevronRight'
import { gon } from '../../../../constant'
import { yourTopPickOrderStore } from '../actions/your_top_picks'
import ForYou from '../../../components/Labels/ForYou'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { randomizeList, sortItems } from '../utilis/topPickCommonActions'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'
// import { cyberMondayBadge } from '../../../../javascript/components/Icons'

export default function YourTopPicks ({sectionId}) {
  const { department, category, subcategory } = useParams()
  const sectionRef = useRef(null)
  const dispatch = useDispatch()

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    if(!(department || category || subcategory)) {
      dispatch(setCarouselLoading({ carousel: sectionId }))
    }
  }, [])

  useEffect(()=> {
    if(sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [sectionRef?.current])

  const firstname = localStorage.getItem('firstname') || gon?.current_user?.firstname
  if (department || category || subcategory) return null
    return (
      <section id={sectionId} ref={sectionRef} className='section-group top-50'>
        <div className='container-fluid full-width-container'>
          <div className='section-bg your-top-section-bg your-top-10' />
        </div>
        <div className='section-group-body your-top-10-group-body'>
          <div className='container'>
          {/* <img className='bf-tag-small' src={cyberMondayBadge} /> */}
            <div className='listing-group-header'>
              <div className='d-flex mb-2'>
                <ForYou />
              </div>
              {firstname
                ?
                  <h2 className=''>{firstname}'s Top Offers</h2>
                :
                  <h2 className='carousel-title'>Your Top Offers</h2>}
              <div className='subtitle'>
                <span>Based on your following and recent activity</span>
              </div>
            </div>
          </div>
          <TopPicksBody sectionId={sectionId} />
        </div>
      </section>
    )
}

const TopPicksBody = ({sectionId}) => {
  const dispatch = useDispatch()
  const [yourRandomList, setYourList] = useState([])
  const { request, byIds, resultOrder } = useSelector(state => state.listings.yourTopPicks)
  const randomizeYourListNew = (lists) => {
    const finallist = randomizeList(lists)
    if (resultOrder.length > 0) {
      const randomFinalList = []
      resultOrder.forEach(id => {
        finallist.forEach(item => item.id === id && randomFinalList.push(item))
      })
      return randomFinalList
    } else {
      const randomFinalList = sortItems(finallist)
      dispatch(yourTopPickOrderStore(randomFinalList.map(item => item.id)))
      return randomFinalList
    }
  }

  const myRef = useRef(null)
  useEffect(() => {
    request === YOUR_TOP_PICK_REQUEST_SUCCESS && setYourList(randomizeYourListNew(byIds))
  }, [request])
  return (
    <div className='container'>
      <div className='listing-carousel listing-group-header'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          <ChevronRight offset={300} ref={myRef} direction='left' />
          {yourRandomList.map((item, index) => {
            return <TopPickItem sectionId={sectionId} key={`${item?.itemType}-${item?.id}`} type={item?.itemType} id={item?.id} index={index} listItem={item} />
          })}
          <ChevronRight offset={300} ref={myRef} direction='right' />
        </div>
      </div>
    </div>
  )
}

function TopPickItem ({ type, id, index, listItem, sectionId }) {
  const wrapperClass = {
    deal: 'listing-price-drop',
    saleEvent: 'event',
    coupon: 'coupon-large-image',
    uniqueCoupon: 'coupon-large-image',
    customList: ''
  }[type]

  return <ListingCard sectionId={sectionId} wrapperClass={wrapperClass} type={type} id={id} position={index + 1} showRank currentSection='your-top-10' sectionPosition={1} isYourTopList listItem={listItem} isPersonalised />
}
