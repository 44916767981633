import React, { Component } from "react"

import { lightHeartIcon, heartFillIcon } from "../../../../../components/Icons"

import FollowButton from "../../buttons/FollowButton"
import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'

class ItemIcon extends Component {
  constructor(props) {
    super(props)

    const { item } = this.props

    this.state = {
      followed: item.attributes.followed
    }
  }

  handleFollow = id => {
    const { followed } = this.state
    const { onClick } = this.props
    onClick(id)
    this.setState({ followed: !followed })
  }

  render() {
    const { item } = this.props
    const { followed } = this.state

    const icon = followed ? heartFillIcon : lightHeartIcon

    return (
      <div className="mb-4 store-item" key={`store-${item.id}`}>
        <div className="d-flex align-items-end justify-content-center br-50"  onClick={() => this.handleFollow(item.id)}>
          <div className="store-item-logo">
            <div className={`store-item-logo-inside ${followed ? "active" : ""}`}>
              <img className="img-fluid" src={item.links.logoUrl} />
            </div>
            <div className={`btn btn-follow ${followed ? "active" : ""} btn-content`} >
              <img src={icon} />
            </div>
          </div>
        </div>
        <div className="grid-item-label">{item.attributes.name}</div>
      </div>
    )
  }
}

export default ItemIcon
