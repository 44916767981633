import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import ListingCard from './ListingCard'
import ChevronRight from './ChevronRight'
import LazyLoad from 'react-lazyload'
import { affiTopDepRequestStart, merchTopDepRequestStart, topDepOrderStore } from '../actions'
import { AFFI_TOP_DEP_SUCCESS, MERCH_TOP_DEP_SUCCESS } from '../actions/types'
import ForYou from '../../../components/Labels/ForYou'
import { isLoggedIn } from '../reducers'
import CountUp from 'react-countup'
import Loader from '../../../components/Loader'
import { css } from '@emotion/core'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'
// import { cyberMondayBadge } from '../../../../javascript/components/Icons'

export default function DeparmentTopTen (props) {
  const { deptCatPage, isPersonalised, isPage } = props
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const color = props?.department?.attributes?.color
  const deptId = props?.department?.id
  const sectionRef = useRef(null)
  const sectionId = `department-${props?.department?.attributes?.slug}`
  const isUserLoggedIn = useSelector(isLoggedIn)

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })
  const score = props?.department?.score
  // const deptCount = props?.deptCounts?.[deptId]?.today_deal_count || 0
  // const displayDeptCounts = () => {
  //   const countWidth = deptCount?.toString().length * 8 || 1
  //   return (countWidth ? <span>From <span className='dept-counts' style={{ width: countWidth + 'px' }}><CountUp start={0} end={deptCount} duration={6} separator=',' /></span> offers in the past 24 hours</span> : <Loader className='mr-2' color='#000000' size={20} css={loaderCss} />)
  // }

  const loaderCss = css`
    display: block;
    margin: 0 auto;`

  const isLargeScreen = useMediaQuery({ query: '(min-width: 768px)' })
  const sectionHeight = isLargeScreen ? 520 : 564
  return (
    <div id={sectionId} ref={isPage ? null : sectionRef}>
    <LazyLoad height={sectionHeight} >
      <section className='section-group top-50'>
        <div className='container-fluid full-width-container'>
          <div className={`your-top-section-bg ${(score || isPersonalised) ? 'dept-top-10' : 'dept-top-10-for-you'}`} style={{ backgroundColor: color }} />
        </div>
        <div className={`section-group-body ${(score || isPersonalised) ? 'dept-group-body' : 'dept-group-body-for-you'}`}>
          <div className='container'>
            {/* <img className='bf-tag-small' src={cyberMondayBadge} /> */}
            <div className='listing-group-header'>
              <div className='d-flex mb-2'>
                {(score || isPersonalised) && <ForYou />}
              </div>
              <div className='d-flex justify-content-between'>
                <div>
                  <h2 className='carousel-title'>Top {_.get(props?.department, 'attributes.name')} Offers</h2>
                  {/* <div className='subtitle'>
                    {displayDeptCounts()}
                  </div> */}
                </div>
                <Link
                  to={deptCatPage ? `/shop/${props?.department?.attributes?.slug}/view-all` : `/shop/${props?.department?.attributes?.slug}`}
                  className='btn btn-outline-dark rounded-pill px-5 btn-view-all d-none d-md-block'
                  onClick={() => sectionId && localStorage.setItem('hsref', sectionId)}
                >
                  {deptCatPage ? 'View all' : 'View More'}
                </Link>
              </div>
            </div>

          </div>
          {affinityData && affinityData?.length > 0 && isUserLoggedIn
            ? <TopPicksAffinityBody sectionId={sectionId} department={props?.department} sectionPosition={props?.sectionPosition} isPersonalised={isPersonalised} isPage={isPage} />
            : <TopPicksBody sectionId={sectionId} department={props?.department} sectionPosition={props?.sectionPosition} />}
          <div className='d-xs-block d-md-none d-flex justify-content-center'>
            <Link
              to={deptCatPage ? `/shop/${props?.department?.attributes?.slug}/view-all` : `/shop/${props?.department?.attributes?.slug}`}
              className='btn btn-outline-dark rounded-pill px-5 btn-view-all btn-view-all-mobile'
              onClick={() => sectionId && localStorage.setItem('hsref', sectionId)}
            >
              {deptCatPage ? 'View all' : 'View More'}
            </Link>
          </div>
        </div>
      </section>
    </LazyLoad>
    </div>
  )
}

const TopPicksBody = ({ department, sectionPosition, sectionId }) => {
  const [finalList, setFinalList] = useState([])
  const shuffle = (array) => {
    let currentIndex = array.length
    let randomIndex
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }
    return array
  }
  const dispatch = useDispatch()
  const merchDepState = useSelector(state => state.merchTopDepartment)
  const affiDepState = useSelector(state => state.affiTopDepartment)
  useEffect(() => {
    merchDepState?.listByDep && !merchDepState?.listByDep.hasOwnProperty(_.get(department, 'attributes.slug')) && dispatch(merchTopDepRequestStart({ slug: _.get(department, 'attributes.slug'), name: _.get(department, 'attributes.name') }))
  }, [])
  const randomizeYourList = (listByDep) => {
    const typeSList = listByDep[_.get(department, 'attributes.slug')]?.saleResponse?.map((id, index) => ({ id: id, itemType: 'saleEvent', itemOrder: index + 1 })) || []
    const typeCList = listByDep[_.get(department, 'attributes.slug')]?.voucherResponse?.map((id, index) => ({ id: id, itemType: 'coupon', itemOrder: index + 1 })) || []
    const maxSList = _.take(typeSList, 7)
    const maxCList = _.take(typeCList, 3)
    const typeDList = maxSList.concat(maxCList).length >= 10 ? [] : listByDep[_.get(department, 'attributes.slug')]?.delResponse?.map((deal, index) => ({ ...deal, itemType: 'deal', itemOrder: index + 1, isElasticResult: true })) || []
    if (affiDepState.depOrder[_.get(department, 'attributes.slug')]?.length > 0) {
      const finalList = []
      affiDepState.depOrder[_.get(department, 'attributes.slug')].forEach(id => {
        [...maxSList, ...maxCList, ...typeDList].forEach(item => {
          item.id === id && finalList.push(item)
        })
      })
      setFinalList(finalList)
    } else {
      const finalList = _.take(_.orderBy(shuffle([...maxSList, ...maxCList, ...typeDList]), ['itemOrder'], ['asc']), 10)
      const orderList = finalList.map(item => item.id)
      dispatch(topDepOrderStore({ [_.get(department, 'attributes.slug')]: orderList }))
      setFinalList(finalList)
    }
  }

  const myRef = useRef(null)
  const { request, listByDep } = useSelector(state => state.merchTopDepartment)
  useEffect(() => {
    request[_.get(department, 'attributes.slug')] === MERCH_TOP_DEP_SUCCESS && randomizeYourList(listByDep)
  }, [request[_.get(department, 'attributes.slug')]])

  useEffect(()=> {
    dispatch(setCarouselLoading({ carousel: sectionId }))
  }, [])

  useEffect(()=> {
    if(myRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [myRef?.current])

  return (
    <div className='container'>
      <div className='listing-carousel listing-group-header'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='left' /> : ''}
          {finalList.map((item, index) => {
            return <TopPickItem sectionId={sectionId} key={`${item?.itemType}-${item?.id}`} type={item?.itemType} id={item?.id} index={index} listItem={item} isElasticResult={item?.isElasticResult} sectionPosition={sectionPosition} department={department} />
          })}
          {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='right' /> : ''}
        </div>
      </div>
    </div>
  )
}

const TopPicksAffinityBody = ({ department, sectionPosition, sectionId, isPersonalised, isPage }) => {
  const [finalList, setFinalList] = useState([])
  const dispatch = useDispatch()
  const affiDepState = useSelector(state => state.affiTopDepartment)
  useEffect(() => {
    if (isPage) {
      dispatch(affiTopDepRequestStart({ slug: _.get(department, 'attributes.slug'), name: _.get(department, 'attributes.name'), isPersonalised }))
    }
    else {
      affiDepState?.listByDep &&
      !affiDepState?.listByDep.hasOwnProperty(_.get(department, 'attributes.slug')) &&
      dispatch(affiTopDepRequestStart({ slug: _.get(department, 'attributes.slug'), name: _.get(department, 'attributes.name'), isPersonalised }))
    }
  }, [])
  const myRef = useRef(null)
  const shuffle = (array) => {
    let currentIndex = array.length
    let randomIndex
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]]
    }
    return array
  }
  const randomizeYourListNew = (lists) => {
    const typeDList = lists?.deals?.map((deal, index) => ({ ...deal, itemType: 'deal', itemOrder: index + 1, isElasticResult: true })) || []
    const typeSList = lists?.sale_events?.map((sale, index) => ({ ...sale, itemType: 'saleEvent', itemOrder: index + 1, isElasticResult: true })) || []
    const typeCList = lists?.vouchers?.map((coupon, index) => ({ ...coupon, itemType: 'coupon', itemOrder: index + 1, isElasticResult: true })) || []
    const maxSList = _.take(typeSList, 4)
    const maxCList = _.take(typeCList, 2)
    const finallist = [...typeDList, ...maxSList, ...maxCList]
    if (affiDepState.depOrder[_.get(department, 'attributes.slug')]?.length > 0) {
      const randFinalList = []
      affiDepState.depOrder[_.get(department, 'attributes.slug')].forEach(id => {
        [...typeDList, ...maxSList, ...maxCList].forEach(item => {
          item.id === id && randFinalList.push(item)
        })
      })
      return randFinalList
    } else {
      const randFinalList = _.take(_.orderBy(shuffle(finallist), ['itemOrder'], ['asc']), 10)
      const orderList = randFinalList.map(item => item.id)
      dispatch(topDepOrderStore({ [_.get(department, 'attributes.slug')]: orderList }))
      return randFinalList
    }
  }
  const { request, listByDep } = useSelector(state => state.affiTopDepartment)
  useEffect(() => {
    request[_.get(department, 'attributes.slug')] === AFFI_TOP_DEP_SUCCESS && setFinalList(randomizeYourListNew(_.get(listByDep, _.get(department, 'attributes.slug'))))
  }, [request[_.get(department, 'attributes.slug')]])

  useEffect(()=> {
    dispatch(setCarouselLoading({ carousel: sectionId }))
  }, [])

  useEffect(()=> {
    if(myRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [myRef?.current])

  return (
    <div className='container' id={sectionId}>
      <div className='listing-carousel'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='left' /> : null}
          {finalList.map((item, index) => {
            return <TopPickItem sectionId={sectionId} key={`${item?.itemType}-${item?.id}`} type={item?.itemType} id={item?.id} index={index} listItem={item} isElasticResult={item?.isElasticResult} sectionPosition={sectionPosition} department={department} />
          })}
          {finalList.length ? <ChevronRight offset={300} ref={myRef} direction='right' /> : ''}
        </div>
      </div>
    </div>
  )
}
function TopPickItem ({ type, id, index, listItem, isElasticResult, sectionPosition, department, sectionId }) {
  const { score } = department
  const wrapperClass = {
    deal: 'listing-price-drop',
    saleEvent: 'event',
    coupon: 'coupon-large-image',
    uniqueCoupon: 'coupon-large-image',
    customList: ''
  }[type]
  return <ListingCard sectionId={sectionId} wrapperClass={wrapperClass} type={type} id={id} position={index + 1} showRank currentSection={`${_.get(department, 'attributes.name')} Top 10`} sectionPosition={sectionPosition} isYourTopList={isElasticResult} listItem={listItem} isPersonalised={!!score} />
}
