import React from 'react'

const FallbackErrorComponent = ({ message, componentName, title, className }) => {
  return (
    <div className='container-fluid full-width-container section-group top-50'>
      <div className={`errorBg ${className}`}>
        <div className='errorMessage'>
          <h3>{title || 'Aaaah! something went wrong'}</h3>
          <p>{message}</p>
        </div>
      </div>
    </div>
  )
}

export default FallbackErrorComponent
