import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { withCookies, Cookies } from 'react-cookie'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { FACEBOOK_APP_ID } from '../../../../../../constant'

import {
  facebookLogin,
  impressionIcon,
  aboutIcon,
  crossIcon,
  appleIcon
} from '../../../../../components/Icons'
import { gon, LB_APPLE_CLIENT_ID, LB_APPLE_REDIRECT_URI_ID } from '../../../../../../constant'
import CbaCard from './CbaCard'
import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'
import AppleSignin from 'react-apple-signin-auth'
import GoogleSignInCustom from './GoogleSignInCustom'

class Login extends Component {
  constructor(props) {
    super(props)

    const { cookies } = props
    this.state = {
      wtidCookie: cookies.get('wtid') || '',
      errors: [],
      type: 'password',
      formData: {
        wtid: cookies.get('wtid') || '',
        login: undefined,
        password: undefined,
        remember_me: false
      }
    }
  }

  componentDidMount () {
    trackForm()
  }

  handleSubmit = (e) => {
    this.setState({ errors: [] })
    e.preventDefault()
    const { formData } = this.state
    const { prevUrl } = this.props

    const fetchData = async (data) => {
      const path = `${gon.urls.api}/auth/login`

      return axios
        .post(path, data)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response.data.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )
          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => this.setState({ errors: error.data.errors }))
    }
    if(formData.login && formData.password) {
      fetchData(formData)
    } else {
      this.setState({ errors: ['Username/email or password not valid']})
    }

  }

  onSuccessGoogle = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (googleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: googleResponseData.tokenId,
        provider: 'google_oauth2'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => this.setState({ errors: error?.data?.errors }))
    }
    fetchData(responseData)
  }

  /***
   * Handle Google failure response
   */
  handleGoogleSignInErrors = (responseData) => {
    let errorMessage =
      'There was an error authenticating with Google, please try again.'
    if (responseData) {
      errorMessage = responseData
    }  
    this.setState({ errors: [errorMessage] })
  }

  /***
   * Handle facebook success response
   */
  responseFacebook = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (facebookResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: facebookResponseData.accessToken,
        provider: 'facebook'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.error })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => {
          this.setState({
            errors: [
              'There was an error authenticating with Facebook, please try again.'
            ]
          })
        })
    }
    fetchData(responseData)
  }

  /**
   * Handle Facebook failure response
   */
  onFacebookFailure = (failedResponseData) => {
    let errorMessage = ['There was an error authenticating with Facebook, please try again.']
    this.setState({ errors: [errorMessage] })
  }

  handleAttributeChange = (event) => {
    const { formData } = this.state
    const { name, value } = event

    formData[name] = value

    this.setState({ formData })
  }
  
  /**
   * Handle Apple login success response
  */
  responseAppleLogin = (response) => {
    const { prevUrl } = this.props
    const fetchData = async (appleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/apple_id`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: appleResponseData?.authorization?.id_token,
        platform: 'web'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.error })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => {
          this.setState({
            errors: [
              'There was an error authenticating with Apple, please try again.'
            ]
          })
        })
    }
    fetchData(response)
  }
  /**
   * Handle Apple login failure response
  */
  onAppleFailure = (failedResponseData) => {
    let errorMessage = ['There was an error authenticating with Apple, please try again.']
    
    this.setState({ errors: [errorMessage] })
  }
  render() {
    const {
      errors,
      formData,
      formData: { remember_me }
    } = this.state
    const {
      prevUrl: { message, prevUrl, backCount, isCba }
    } = this.props
    const prevPage = prevUrl?.includes('/shop')
    return (
      <>
        <div className='login-register login-bg'>
          <div className='d-flex justify-content-center'>
             <div className='card-container'>
              <div className={`device-header m-80 ${isCba && 'is-cba'}`}>
                {
                  isCba
                    ? <CbaCard isLoginPage message={message} />
                    :
                    (prevPage ?
                      <>
                      <div className='h2 title login-background d-flex flex-column'>
                        <div>
                          <span className='text-color-primary'>Sign in</span> to experience the best of Little Birdie
                        </div>
                      </div>
                      <p className='p-0 para-margin'>
                      Stay up to date and personalise your shopping feed
                      </p>
                    </>
                    :
                    <div className='h2 title login-background d-flex flex-column'>
                    <div>
                      <span className='text-color-primary'>Sign in</span> to see
                      your
                    </div>
                    <div>personalised</div>
                    <div>shopping feed</div>
                  </div>
                    )
                }
              </div>
              <div className='card device-form'>
                <div className='card-body pb-0'>
                  <form onSubmit={this.handleSubmit}>
                    {Array.isArray(errors) && errors?.length !== 0 ? (
                      <div className='alert alert-danger alert-block errors-label'>
                        <button
                          onClick={() => this.setState({errors: []})}
                          className='close'
                          data-dismiss='alert'
                          type='button'>
                          <img src={crossIcon} />
                        </button>
                        <div className='mt-4'>
                          {errors?.map((message, key) => (
                            <p key={key} className='text-dark d-flex'>
                              <img src={aboutIcon} className='mr-3' />
                              {message}
                            </p>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className='form-group'>
                      <input
                        className='form-control'
                        placeholder='Email or Username'
                        type='text'
                        name='login'
                        id='user_login'
                        onChange={(e) =>
                          this.handleAttributeChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget?.value.toLowerCase()
                          })
                        }
                      />
                    </div>
                    <div className='form-group'>
                    <div className='form-field-password d-flex'>
                      <input
                        className='form-control '
                        type={this.state.type}
                        placeholder='Password'
                        name='password'
                        id='user_password'
                        onChange={(e) =>
                          this.handleAttributeChange({
                            name: e.currentTarget.name,
                            value: e.currentTarget.value
                          })
                        }
                      />
                      <img
                        onClick={() =>
                          this.setState({
                            type:
                              this.state.type === 'text' ? 'password' : 'text'
                          })
                        }
                        src={impressionIcon}
                      />
                    </div>
                    </div>
                    <div className='d-flex justify-content-between form-group form-check pl-0'>
                      <div className='custom-control custom-checkbox '>
                        <input
                          name='user[remember_me]'
                          type='hidden'
                          defaultValue={0}
                        />
                        <input
                          className='custom-control-input'
                          type='checkbox'
                          defaultValue={false}
                          name='remember_me'
                          id='user_remember_me'
                          onChange={() =>
                            this.setState({
                              formData: {
                                ...formData,
                                remember_me: !remember_me
                              }
                            })
                          }
                        />
                        <label
                          className='custom-control-label'
                          htmlFor='user_remember_me'>
                          Remember me
                        </label>
                      </div>
                      <div className='device-links align-self-end mb-0 t-14'>
                        <a href='/users/password/new' className='text-budgee font-weight-bold lh-20'>Forgot your password?</a>
                      </div>
                    </div>
                    <div className='text-center'>
                      <div className='form-group'>
                        <button
                          type='submit'
                          className='btn btn-main rounded-pill btn-block'>
                          Sign in
                        </button>
                      </div>
                      <div className='device-links d-flex justify-content-center align-items-center t-14'>
                        Don't have an account?
                        <Link
                          className='nav-link p-0 pl-1 text-underline text-budgee font-weight-bold'
                          to={{
                            pathname: '/register',
                            state: {
                              from: {
                                prevUrl,
                                message,
                                backCount: backCount - 1,
                                isCba
                              }
                            }
                          }}>
                          Sign up
                        </Link>
                      </div>
                    </div>
                    <hr data-content='Or' className='divider-text' />
                    <div className='text-center margin-bottom'>
                      <div className='d-inline-flex flex-column justify-content-center'>
                        <div className='form-group align-self-center border w-100 rounder-corners'>
                          <GoogleSignInCustom prevUrl={this.props.prevUrl} wtidCookie={this.state.wtidCookie} handleGoogleSignInErrors={(errors)=>this.handleGoogleSignInErrors(errors)} />                      
                       </div>
                        <div className='form-group align-self-center border w-100 rounder-corners'>
                          <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            fields='name,email,picture'
                            scope='public_profile,email'
                            callback={this.responseFacebook}
                            disableMobileRedirect={true}
                            onFailure={this.onFacebookFailure}
                            cssClass='my-facebook-button-class'
                            render={(renderProps) => (
                              <a
                                className='btn btn-block animate shake pointer d-flex align-items-center'
                                onClick={renderProps.onClick}>
                                <div className='social-img mr-3'>
                                  <img
                                    style={{ width: '100%' }}
                                    src={facebookLogin}
                                  />
                                </div>
                                <span>Sign in with Facebook</span>
                              </a>
                            )}
                            icon={facebookLogin}
                          />
                        </div>
                        <div className='form-group align-self-center border w-100 rounder-corners'>
                          <AppleSignin
                              /** Auth options passed to AppleID.auth.init() */
                            authOptions={{
                              /** Client ID - eg: 'com.example.com' */
                              clientId: `${LB_APPLE_CLIENT_ID}`,
                              /** Requested scopes, seperated by spaces - eg: 'email name' */
                              scope: 'email name',
                              /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                              redirectURI: `${LB_APPLE_REDIRECT_URI_ID}`,
                              usePopup: true
                            }} 
                            className="apple-auth-btn"
                            onSuccess={(response) => this.responseAppleLogin(response)}
                            onError={(error) => this.onAppleFailure(error)}
                            buttonExtraChildren="Continue with Apple"
                            render={(props) => (
                              <a
                                className='btn btn-block animate shake pointer d-flex align-items-center'
                                {...props}>
                                <div className='social-img-apple-id'>
                                  <img
                                    style={{ width: '100%' }}
                                    src={appleIcon}
                                  />
                                </div>
                                <span>Sign in with Apple ID</span>
                              </a>
                            )}
                            icon={appleIcon}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className='device-links text-center px-3'>
                  By joining in you agree to our&nbsp;
                  <a href='/terms' target='_new'>
                    Terms and Conditions
                  </a>
                  ,&nbsp;
                  <a
                    href='https://help.littlebirdie.com.au/hc/en-us/articles/360057583591'
                    target='_new'>
                    Community Standards
                  </a>
                  {' and'}
                  &nbsp;
                  <a href='/privacy' target='_new'>
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
             </div>
          </div>
        </div>
      </>
    )
  }
}

export default withCookies(Login)
