import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import activeAndSortedList from '../../../../../components/utils/activeAndSortedList'

import {
  getDepartments,
  getCategories,
  getBaseUrl
} from '../../../reducers'
import { getSubcategories } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function DepartmentOptions ({
  showCategory, showSubcategory, handleFilterPill,
  activeDepartments, activeCategories, activeSubcategories,
  handleSelect, handleClearClick, sectionPosition
}) {
  const departments = useSelector(getDepartments)
  const categories = useSelector(getCategories)
  const baseUrl = useSelector(getBaseUrl)

  const accordionDepartmentHeader = useRef(null)
  const accordionCategoryHeader = useRef(null)
  const accordionSubcategoryHeader = useRef(null)

  useAccordionOpenHeader(accordionDepartmentHeader, sectionPosition, 'Department')
  useAccordionOpenHeader(accordionCategoryHeader, sectionPosition + 1, 'Category')
  useAccordionOpenHeader(accordionSubcategoryHeader, sectionPosition + 2, 'Sub Category')

  const [selectedDepartments, setSelectedDepartments] = useState(activeDepartments)
  const [selectedCategories, setSelectedCategories] = useState(activeCategories)

  const orderedDepartments = _.take(activeAndSortedList(Object.values(departments)), 100)
  const [orderedCategories, setOrderedCategories] = useState(_.take(activeAndSortedList(Object.values(categories)), 100))
  const [orderedSubcategories, setOrderedSubcategories] = useState([])

  useEffect(() => {
    if (selectedDepartments.length === 0 && selectedCategories.length === 0) {
      setOrderedCategories(_.take(activeAndSortedList(Object.values(categories)), 100))
    }
  }, [categories])

  useEffect(() => {
    if (selectedDepartments.length > 0) {
      setOrderedCategories(_.take(activeAndSortedList(Object.values(categories.filter(cat => selectedDepartments.includes(_.get(cat, 'relationships.department.id'))))), 100))
    } else {
      setOrderedCategories(_.take(activeAndSortedList(Object.values(categories)), 100))
    }
  }, [selectedDepartments])

  useEffect(() => {
    const params = {
      by_departments: selectedDepartments,
      by_categories: selectedCategories,
      by_active: true,
      per_page: 50
    }
    async function fetchSubcategories () {
      const { response } = await getSubcategories(baseUrl, params)
      setOrderedSubcategories(response.subcategory)
    }
    fetchSubcategories()
  }, [selectedCategories, selectedDepartments])

  function handleOptionSelect (e) {
    if (e.currentTarget.name === 'departmentIds') {
      setSelectedDepartments(
        selectedDepartments.includes(e.currentTarget.value)
          ? selectedDepartments.filter(dep => dep !== e.currentTarget.value)
          : [...selectedDepartments, e.currentTarget.value]
      )
    } else if (e.currentTarget.name === 'categoryIds') {
      setSelectedCategories(
        selectedCategories.includes(e.currentTarget.value)
          ? selectedCategories.filter(cat => cat !== e.currentTarget.value)
          : [...selectedCategories, e.currentTarget.value]
      )
    }

    handleSelect(e)
  }

  function handleOptionClear (e) {
    if (e.currentTarget.name === 'categoryIds') {
      setSelectedCategories([])
    } else if (e.currentTarget.name === 'departmentIds') {
      setSelectedDepartments([])
    }

    handleClearClick(e)
  }
  function getLabel (type, value) {
    if (type === 'departmentIds') return _.find(orderedDepartments, (department) => department.id === value)?.attributes?.name
    if (type === 'categoryIds') return _.find(orderedCategories, (cat) => cat.id === value)?.attributes?.name
    if (type === 'departmentIds') return _.find(orderedSubcategories, (subCat) => subCat.id === value)?.attributes?.name
  }
  return (
    <>
      <div className='list-group-item p-0'>
        <div id='headingDepartment'>
          <div ref={accordionDepartmentHeader} aria-controls='collapseDepartment' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseDepartment' data-toggle='collapse'>
            <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
              <div className='header-label'>Department</div>
              {activeDepartments?.length > 0 && <span className='selected-filter-preview'>{_.filter(orderedDepartments, dep => activeDepartments.includes(dep.id)).map(sDep => sDep.attributes.name).join(', ')}</span>}
              {activeDepartments?.length > 0 &&
                <button
                  type='button'
                  name='departmentIds'
                  onClick={e => handleOptionClear(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>}
            </div>
          </div>
        </div>
        <div aria-labelledby='headingDepartment' className='collapse' id='collapseDepartment'>
          {activeDepartments?.length > 0 &&
            <div className='filter-pills-container'>
              {activeDepartments.map((department, index) => (
                <div
                  key={index}
                  type='button'
                  onClick={() => handleFilterPill('departmentIds', department)}
                  className='filter-pill ml-auto mr-2'
                >
                  {getLabel('departmentIds', department)}
                </div>
              ))}
            </div>}
          <div className='p-3'>
            <div className='checklist-group'>
              {_.filter(_.orderBy(orderedDepartments, [dep => dep.attributes.name.toLowerCase()], ['asc']), sDep => !activeDepartments.includes(sDep.id)).map(department => (
                <div key={`department-${department.id}`} className='custom-control custom-checkbox'>
                  <input
                    className='custom-control-input'
                    id={`departmentCustomCheck${department.id}`}
                    type='checkbox'
                    checked={activeDepartments.includes(department.id)}
                    name='departmentIds'
                    value={department.id}
                    data-input-name='departmentNames'
                    data-input-value={department?.attributes?.name}
                    onChange={e => handleOptionSelect(e)}
                  />
                  <label className='custom-control-label' htmlFor={`departmentCustomCheck${department.id}`}>
                    {department.attributes.name}
                    <span> </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showCategory && (
        <div className='list-group-item p-0'>
          <div id='headingCategory'>
            <div ref={accordionCategoryHeader} aria-controls='collapseCategory' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseCategory' data-toggle='collapse'>
              <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
                <div className='header-label'>Category</div>
                {activeCategories?.length > 0 && <span className='selected-filter-preview'>{_.filter(orderedCategories, cat => activeCategories.includes(cat.id)).map(sCat => sCat.attributes.name).join(', ')}</span>}
                {activeCategories?.length > 0 &&
                  <button
                    type='button'
                    name='categoryIds'
                    onClick={e => handleOptionClear(e)}
                    className='btn btn-clear ml-auto mr-2'
                  >
                    Clear selection
                  </button>}
              </div>
            </div>
          </div>
          <div aria-labelledby='headingCategory' className='collapse' id='collapseCategory'>
            {activeCategories?.length > 0 &&
              <div className='filter-pills-container'>
                {activeCategories.map((cat, index) => (
                  <div
                    key={index}
                    type='button'
                    onClick={() => handleFilterPill('categoryIds', cat)}
                    className='filter-pill ml-auto mr-2'
                  >
                    {getLabel('categoryIds', cat)}
                  </div>
                ))}
              </div>}
            <div className='p-3'>
              <div className='checklist-group'>
                {_.filter(_.orderBy(orderedCategories, [cat => cat.attributes.name.toLowerCase()], ['asc']), sCat => !activeCategories.includes(sCat.id)).map(category => (
                  <div key={`category-${category.id}`} className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id={`categoryCustomCheck${category.id}`}
                      type='checkbox'
                      checked={activeCategories.includes(category.id)}
                      name='categoryIds'
                      value={category.id}
                      data-input-name='categoryNames'
                      data-input-value={category?.attributes?.name}
                      onChange={e => handleOptionSelect(e)}
                    />
                    <label className='custom-control-label' htmlFor={`categoryCustomCheck${category.id}`}>
                      {category.attributes.name}
                      <span> </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {showSubcategory && (
        <div className='list-group-item p-0'>
          <div id='headingSubcategory'>
            <div ref={accordionSubcategoryHeader} aria-controls='collapseSubcategory' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseSubcategory' data-toggle='collapse'>
              <div className='header-label-wrapper py-1 d-flex justify-content-between align-items-center'>
                <div className='header-label'>Sub Category</div>
                {activeSubcategories?.length > 0 && <span className='selected-filter-preview'>{_.filter(orderedSubcategories, subCat => activeSubcategories.includes(subCat.id)).map(sSubCat => sSubCat.attributes.name).join(', ')}</span>}
                {activeSubcategories?.length > 0 &&
                  <button
                    type='button'
                    name='subcategoryIds'
                    onClick={e => handleOptionClear(e)}
                    className='btn btn-clear ml-auto mr-2'
                  >
                    Clear selection
                  </button>}
              </div>
            </div>
          </div>
          <div aria-labelledby='headingSubcategory' className='collapse' id='collapseSubcategory'>
            {activeSubcategories?.length > 0 &&
              <div className='filter-pills-container'>
                {activeSubcategories.map((subCat, index) => (
                  <div
                    key={index}
                    type='button'
                    onClick={() => handleFilterPill('subcategoryIds', subCat)}
                    className='filter-pill ml-auto mr-2'
                  >
                    {getLabel('subcategoryIds', subCat)}
                  </div>
                ))}
              </div>}
            <div className='p-3'>
              <div className='checklist-group'>
                {_.filter(_.orderBy(orderedSubcategories, [sub => sub.attributes.name.toLowerCase()], ['asc']), sCat => !activeCategories.includes(sCat.id)).map(subcategory => (
                  <div key={`subcategory-${subcategory.id}`} className='custom-control custom-checkbox'>
                    <input
                      className='custom-control-input'
                      id={`subcategoryCustomCheck${subcategory.id}`}
                      type='checkbox'
                      checked={activeSubcategories.includes(subcategory.id)}
                      name='subcategoryIds'
                      value={subcategory.id}
                      data-input-name='subcategoryNames'
                      data-input-value={subcategory?.attributes?.name}
                      onChange={e => handleOptionSelect(e)}
                    />
                    <label className='custom-control-label' htmlFor={`subcategoryCustomCheck${subcategory.id}`}>
                      {subcategory.attributes.name}
                      <span> </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
