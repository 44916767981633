import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import { loadDealPage, loadCouponPage, loadSaleEventPage } from "../../../apps/App/actions"

function CouponLink({ id }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadCouponPage({ identifier: id }))
  }, [dispatch, id])
  const item = useSelector(state => _.get(state, `data.coupons.byId.${id}`, null))
  if (!item) return <span>coupon ...</span>
  const {
    attributes: { name },
    links: { couponUrl }
  } = item
  return (
    <>
      {`coupon `}
      <Link to={couponUrl}>{name}</Link>
    </>
  )
}

function DealLink({ id }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadDealPage({ identifier: id }))
  }, [dispatch, id])
  const item = useSelector(state => _.get(state, `data.deals.byId.${id}`, null))
  if (!item) return <span>deal ...</span>
  const {
    attributes: { name },
    links: { dealUrl }
  } = item
  return (
    <>
      {`deal `}
      <Link to={dealUrl}>{name}</Link>
    </>
  )
}

function SaleEventLink({ id }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadSaleEventPage({ identifier: id }))
  }, [dispatch, id])
  const item = useSelector(state => _.get(state, `data.saleEvents.byId.${id}`, null))
  if (!item) return <span>sale event ...</span>
  const {
    attributes: { name },
    links: { saleEventUrl }
  } = item
  return (
    <>
      {`sale event `}
      <Link to={saleEventUrl}>{name}</Link>
    </>
  )
}

function CommentableItemLink({ item }) {
  const {
    attributes: {
      parameters: {
        commentableId,
        commentableType
      }
     }
  } = item

  if (commentableType === "Coupon") return <CouponLink id={commentableId} />
  if (commentableType === "Deal") return <DealLink id={commentableId} />
  if (commentableType === "SaleEvent") return <SaleEventLink id={commentableId} />
  return (
    <span>{`${commentableId}, ${commentableType}`}</span>
)
}

export default function Commenting({ item, owner, recipient }) {
  if (!(owner && recipient)) return null
  const { attributes: { createdAtLabel } } = item
  const {
    attributes: {
      username: ownerUsername,
    },
    links: {
      avatar: { thumb: { url: ownerAvatarUrl } },
      userUrl: ownerUserUrl
    }
  } = owner

  const {
    attributes: {
      message
    }
  } = recipient

  return (
    <div className="border-bottom activity-row p-4">
      <div className="media">
        <img alt="" className="mr-2 rounded-circle img-40" src={ownerAvatarUrl} />
        <div className="media-body">
          <Link to={ownerUserUrl}>{`@${ownerUsername}`}</Link>
          {` commented "${message}" on `}
          <CommentableItemLink item={item} />
          <div className="text-muted">{`${createdAtLabel.match(/just now/i) ? createdAtLabel : `${createdAtLabel} ago`}`}</div>
        </div>
      </div>
    </div>
  )
}
