import React, {useRef,useState,useEffect} from "react"
import {chevronDown,chevronUp} from '../../../../components/Icons';
import Loader from '../../../../components/Loader'


export default function SeoInfo(props){
  const [showMore, setShowMore] = useState(false)
  const clampDescription = useRef()
  const [showClamp, setClamp] = useState(false)    
  const {
      seoData,
      isLoading
    } = props
    useEffect(()=>{
      const renderShowMoreButton = clampDescription?.current?.clientHeight < clampDescription?.current?.scrollHeight
      setClamp(renderShowMoreButton)
    }, [seoData])

      return (
        <>
          {
            (isLoading)
            ? <Loader isLoading />
            :<div className='seo-content'>
              <div className='container group-section'>
                <h1>{ seoData?.attributes?.title || '' }</h1>
                <div className={showMore ? 'seo-text remove-clamp' : 'seo-text'} ref={clampDescription} dangerouslySetInnerHTML={{ __html: seoData?.attributes?.content || '' }}></div>            
                  {showClamp && (<span className='btn-outline-dark btn-show-more' onClick={() => setShowMore(!showMore)}>
                  {showMore ? 'Show less' : 'Show more'}
                  {showMore ? <img src={chevronUp} /> : <img src={chevronDown} />}
                </span>)}
              </div>
            </div>
          }
        </>
      )
}