import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { useCookies } from "react-cookie"
import {
  setIntialPersonalise
} from "../../actions"
import ProgressingBird from "../../../../../assets/images/interactions/progressBird.gif"

function PersonalisedPage({ isPersonalise, setIntialPersonalise, user }) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIntialPersonalise(false)
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className='bg-personalised-screen row'>
      <div className='d-flex flex-column align-items-center'>
        <div className="progress"></div>
        <div className='h1 title text-center'>
          <span className='text-color-primary'>Personalising</span> your shopping feed
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isPersonalised: state.personalise.Personalise.isPersonalise,
    user: state.currentUser.current_user
  }
}

const mapDispatchToEvents = (dispatch) => {
  return {
    setIntialPersonalise: data => dispatch(setIntialPersonalise(data))
  }
}

export default connect(mapStateToProps, mapDispatchToEvents)(PersonalisedPage)
