import React from 'react'
import { upsellAndroid, upsellIosApp, upsellIosPermission, upsellIosExt, upsellAndroidBf, 
  upsellIosAppBf, upsellIosExtBf, upsellIosAppIpad, upsellIosAppIpadBf, upsellIosExtIpadBf, 
  upsellIosExtIpad, upsellAndroidTab, upsellAndroidTabBf, upsellIosPermissionBf, upsellIosPermissionIpad, upsellIosPermissionIpadBf } from '../../../components/Icons'
import { useHistory } from 'react-router-dom'
import {SHOW_BLACK_FRIDAY} from '../../../../constant'
import { isPad, isTablet } from '../utilis/detectBrowser'
import { useMediaQuery } from 'react-responsive'
 
const UpsellBanner = () => {
  const history = useHistory()
  const isReactNativeWebView = !!window.ReactNativeWebView
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const addExtBanner = isPad() ? (SHOW_BLACK_FRIDAY ? upsellIosExtIpadBf : upsellIosExtIpad) : (SHOW_BLACK_FRIDAY ? upsellIosExtBf : upsellIosExt)
  const addAppBanner = isPad() ? (SHOW_BLACK_FRIDAY ? upsellIosAppIpadBf : upsellIosAppIpad) : (SHOW_BLACK_FRIDAY ? upsellIosAppBf : upsellIosApp)
  const addAndroidAppBanner = isTablet ? (SHOW_BLACK_FRIDAY ? upsellAndroidTabBf : upsellAndroidTab) : (SHOW_BLACK_FRIDAY ? upsellAndroidBf : upsellAndroid)
  const addPermissionBanner = isPad() ? (SHOW_BLACK_FRIDAY ? upsellIosPermissionIpadBf : upsellIosPermissionIpad) : (SHOW_BLACK_FRIDAY ? upsellIosPermissionBf : upsellIosPermission)
  const handlefullPermission = () => {
    window.ReactNativeWebView?.postMessage('{"type":"open-extension"}')
  }
  const handleEnable = () => {
    history.push('/mobile/ext/enable')
  }
  const handleActivate = () => {
    window.ReactNativeWebView?.postMessage('{"type":"open-extension"}')
  }
  const handleInstallApp = () => {
    const nid = localStorage.getItem('lbnid')
    const urlParams = new URLSearchParams(window.location.search)
    const paramObj = Object.fromEntries(urlParams.entries())
    let appsFlyerString = ''
    Object.keys(paramObj).map((key,index) => {
      appsFlyerString = appsFlyerString+`deep_link_sub${(index + 2)}=${key}||${paramObj[key]}&`
    })
    const Uri = `https://littlebirdie.onelink.me/tPpN?af_xp=custom&pid=deeplinking&deep_link_value=web&deep_link_sub1=userID||${nid}&${appsFlyerString}`
    window.open(Uri)
  }
  if (isReactNativeWebView && /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const extEnable = window.localStorage?.getItem('ext_enable') === 'true'
    const extPermission = window.localStorage?.getItem('ext_permission') === 'true'
    const extPermissionPartial = window.localStorage?.getItem('ext_permission_Partial') === 'true'
    const extNoPermission = window.localStorage?.getItem('ext_no_permission') === 'true'

    if (extEnable && extPermission) {
      //If extension and permission on
      return null
    }
    if (extEnable && !extPermissionPartial) {
      //Extension on but no any permission
      // Permission State Y, NULL
      return(
        <div className='upsell-banner'>
          <img onClick={handlefullPermission} src={addPermissionBanner} />
        </div>
      )
    } else if (extEnable && extPermissionPartial) {
      //If user only apply partal permission.
      // Permission State Y, P
      return null
    } else if (!extEnable && !extPermission && !extPermissionPartial && extNoPermission) {
      // Permission State N, NULL 
      return(
        <div className='upsell-banner'>
          <img onClick={handleEnable} src={addExtBanner} />
        </div>
      )
    } else if (!extEnable && !extPermissionPartial && extNoPermission) {
      //App => web redirection and no enable extension
      // Permission State N, NULL
      return(
        <div className='upsell-banner'>
          <img onClick={handleEnable} src={addExtBanner} />
        </div>
      )
    } else {
      // Permission State NULL, NULL
      return(
        <div className='upsell-banner'>
          <img onClick={handleActivate} src={addExtBanner} />
        </div>
      )
    }
  } else if (/android/i.test(userAgent) && !isReactNativeWebView) {
    return(
      <div className='upsell-banner'>
        <img onClick={handleInstallApp} src={addAndroidAppBanner} />
      </div>
    )
  }
  else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return(
      <div className='upsell-banner'>
        <img onClick={handleInstallApp} src={addAppBanner} />
      </div>
    )
  }
  return null
}

export default UpsellBanner