import React, { useRef } from 'react'

import { couponTypeOptions } from '../checkboxOptions'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function CouponTypeOptions ({ handleClearClick, handleSelect, selectedTypes, handleFilterPill, sectionPosition }) {
  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Coupon Type')

  function getSelectedCouponTypeLabel (selectedCouponType) {
    return _.find(couponTypeOptions?.values, (option) => option.value === selectedCouponType)?.label
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingCoupon'>
        <div ref={accordionHeader} aria-controls='collapseCoupon' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseCoupon' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Sub Category</div>
            {selectedTypes?.length > 0 && <span className='selected-filter-preview'>{_.filter(couponTypeOptions.values, couptype => selectedTypes.includes(couptype.value)).map(sSelectedTypes => sSelectedTypes.label).join(', ')}</span>}
            {
              selectedTypes?.length > 0 &&
                <button
                  type='button'
                  name='couponTypes'
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      <div aria-labelledby='headingCoupon' className='collapse' id='collapseCoupon'>
        {selectedTypes?.length > 0 &&
          <div className='filter-pills-container'>
            {selectedTypes.map((type, index) => (
              <div
                key={index}
                type='button'
                onClick={() => handleFilterPill('couponTypes', type)}
                className='filter-pill ml-auto mr-2'
              >
                {getSelectedCouponTypeLabel(type)}
              </div>
            ))}
          </div>}
        <div className='p-3'>
          <div className='checklist-group'>
            {_.filter(couponTypeOptions.values, sCoupOpt => !selectedTypes.includes(sCoupOpt.value)).map(type => (
              <div key={`type-${type.key}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`couponCheck${type.key}`}
                  type='checkbox'
                  checked={selectedTypes.includes(type.value)}
                  name='couponTypes'
                  value={type.value}
                  onChange={e => handleSelect(e)}
                />
                <label className='custom-control-label' htmlFor={`couponCheck${type.key}`}>
                  {type.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
