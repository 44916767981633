import React, { useEffect, useRef, useState } from 'react'
import FilterRangeSlider from '../../RangeSlider/FilterRangeSlider'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function DiscountOptions ({
  selectedDiscountRange, handleSelect, handleClearClick,
  selectedCustomDiscountRange, discountMin, discountMax,
  sectionPosition, keyword
}) {
  const [state, setState] = useState({ localDiscountMin: '0%', localDiscountMax: '100%' })
  const [isError, setIsError] = useState(false)
  const [allowSelection, setAllowSelection] = useState(true)
  const minRef = useRef(null)
  const maxRef = useRef(null)

  const accordionHeader = useRef(null)
  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Discount')

  useEffect(() => {
    discountMin !== '' && discountMax !== '' && setState({ ...state, localDiscountMax: discountMax, localDiscountMin: discountMin })
  }, [discountMax, discountMin])

  useEffect(() => {
    selectedDiscountRange?.length > 0
      ? setState({ ...state, localDiscountMax: getViewOnlyDiscount((Math.floor(selectedDiscountRange[1] * 100)).toString()), localDiscountMin: getViewOnlyDiscount((Math.floor(selectedDiscountRange[0] * 100)).toString()) })
      : setState({ ...state, localDiscountMin: '0%', localDiscountMax: '100%' })
  }, [selectedDiscountRange])

  useEffect(() => {
    setState({ ...state, localDiscountMin: '0%', localDiscountMax: '100%' })
  }, [keyword])

  useEffect(() => {
    if (!state.localDiscountMin.includes('%') && allowSelection) {
      minRef.current.select()
      setAllowSelection(false)
    }
    if (!state.localDiscountMax.includes('%') && allowSelection) {
      maxRef.current.select()
      setAllowSelection(false)
    }
  }, [state])

  function handleApply (e) {
    return handleSelect({ ...e, arrValue: [parseInt(state.localDiscountMin, 10) / 100, parseInt(state.localDiscountMax, 10) / 100] })
  }
  const onSliderChange = value => {
    setState({ ...state, localDiscountMin: getViewOnlyDiscount(value[0]), localDiscountMax: getViewOnlyDiscount(value[1]) })
    parseInt(getCleanDiscount(state.localDiscountMax)) < parseInt(getCleanDiscount(state.localDiscountMin)) ? setIsError(true) : setIsError(false)
  }
  const getCleanDiscount = discount => {
    return discount.replace(/%/g, '')
  }
  const getViewOnlyDiscount = discount => {
    return discount !== '' ? discount + '%' : discount
  }
  const handleDiscountInput = e => {
    const discountPattern = /^100(\.0{0,2})? *%?$|^\d{1,2}(\.\d{1,2})? *%?$|(^$)/
    discountPattern.test(e.currentTarget.value) && setState({ ...state, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleOnBlur = inputName => {
    setState({ ...state, [inputName]: getViewOnlyDiscount(state[inputName]) })
    state.localDiscountMin === '' && setState({ ...state, localDiscountMin: '0%' })
    state.localDiscountMax === '' && setState({ ...state, localDiscountMax: '100%' })
    parseInt(getCleanDiscount(state.localDiscountMax)) < parseInt(getCleanDiscount(state.localDiscountMin)) ? setIsError(true) : setIsError(false)
    setAllowSelection(true)
  }
  const handleOnFocus = inputName => {
    setState({ ...state, [inputName]: getCleanDiscount(state[inputName]) })
  }
  const handleClear = e => {
    setState({ ...state, localDiscountMin: '0%', localDiscountMax: '100%' })
    handleClearClick(e)
  }
  function handleDiscountRangeDisplay () {
    if (!_.isEmpty(selectedCustomDiscountRange)) {
      return (
        <div aria-labelledby='headingDiscount' className='collapse' id='collapseDiscount'>
          <div className='p-3'>
            <div className='checklist-group'>
              <div className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  type='checkbox'
                  defaultChecked
                  disabled
                  name='discountRange'
                />
                <label className='custom-control-label'>
                  {`${selectedCustomDiscountRange[0] * 100}% - ${selectedCustomDiscountRange[1] * 100}%`}
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div aria-labelledby='headingDiscount' className='collapse' id='collapseDiscount'>
        {
          selectedDiscountRange.length > 0 &&
            <div className='filter-pills-container'>
              <button
                type='button'
                name='discountRange'
                onClick={e => handleClear(e)}
                className='btn filter-pill ml-auto mr-2'
              >
                {`${state.localDiscountMin} - ${state.localDiscountMax}`}
              </button>
            </div>
        }
        <div className='p-3'>
          <FilterRangeSlider
            onSliderChange={onSliderChange}
            selectedMin={getCleanDiscount(state.localDiscountMin)}
            selectedMax={getCleanDiscount(state.localDiscountMax)}
            minLable='Min 0%'
            maxLable='Max 100%'
            minVal={0}
            maxVal={100}
            stepVal={5}
          />
          <div className='d-flex align-items-center mt-2 price-range'>
            <input
              ref={minRef}
              placeholder='Min'
              className='form-control form-control-sm mx-2 percentage'
              type='text'
              name='localDiscountMin'
              value={state.localDiscountMin}
              onChange={handleDiscountInput}
              onFocus={e => handleOnFocus('localDiscountMin')}
              onBlur={() => handleOnBlur('localDiscountMin')}
            />
            <span>to</span>
            <input
              ref={maxRef}
              placeholder='Max'
              className={`form-control form-control-sm mx-2 ${isError && 'error'}`}
              type='text'
              name='localDiscountMax'
              value={state.localDiscountMax}
              onChange={handleDiscountInput}
              onFocus={e => handleOnFocus('localDiscountMax')}
              onBlur={() => handleOnBlur('localDiscountMax')}
            />
            <button
              className='btn btn-link btn-sm'
              type='submit'
              name='discountRange'
              onClick={e => handleApply(e)}
              disabled={isError}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='list-group-item p-0'>
      <div id='headingDiscount'>
        <div ref={accordionHeader} aria-controls='collapseDiscount' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseDiscount' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Discount</div>
            {selectedDiscountRange?.length > 0 && <span className='selected-filter-preview'>{`${state.localDiscountMin} - ${state.localDiscountMax}`}</span>}
            {
              selectedDiscountRange.length > 0 &&
                <button
                  type='button'
                  name='discountRange'
                  onClick={e => handleClear(e)}
                  disabled={!_.isEmpty(selectedCustomDiscountRange)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      {handleDiscountRangeDisplay()}
    </div>
  )
}
