import React, { Component } from "react"
import { connect } from "react-redux"

import UserActivities from "../../../../../components/UserActivities"
import Breadcrumb from "../../../../../components/Breadcrumb"
import Meta from "../../../../../components/Meta"
import { loadProfileFollowingPage } from "../../../actions"
import * as reducers from "../../../reducers"
import Collections from "../../Collections"

import {
  // ElasticTopPriceDropsList,
  TopBrandsSidebarList,
  TopStoresSidebarList,
  FollowedDealsList,
  FollowedCouponsList,
  // TopSalesEventsList,
  // TopCouponsList
} from "../../listings"

class FollowingPage extends Component {
  componentDidMount() {
    this.initiatePageLoad()
  }

  componentDidUpdate(prevProps) {
    const { match: { params: prev_params } } = prevProps
    const { match: { params } } = this.props
    if (!_.isEqual(params, prev_params)) { this.initiatePageLoad() }
  }

  initiatePageLoad = () => {
    const {
      loadProfileFollowingPage,
      current_user,
    } = this.props

    // Initial fetch from page load
    loadProfileFollowingPage({ current_user })
  }

  recommendedPriceDropsUrl = () => {
    const { followedStoreNames, followedBrandNames } = this.props
    const storesString = followedStoreNames.map(store => `storeNames[]=${encodeURI(store)}`).join("&")
    const brandsString = followedBrandNames.map(brand => `brandNames[]=${encodeURI(brand)}`).join("&")

    if (!storesString && !brandsString) return "/top-price-drops"
    return `/top-price-drops?${storesString}${(storesString && brandsString) ? "&" : ""}${brandsString}`
  }

  recommendedListingsUrl = pathname => {
    const { followedStoreIds, followedBrandIds } = this.props
    const storesString = followedStoreIds.map(store => `storeIds[]=${encodeURI(store)}`).join("&")
    const brandsString = followedBrandIds.map(brand => `brandIds[]=${encodeURI(brand)}`).join("&")

    if (!storesString && !brandsString) return pathname
    return `${pathname}?${storesString}${(storesString && brandsString) ? "&" : ""}${brandsString}`
  }

  render() {
    const {
      location, match, user, followedBrandNames, followedStoreNames, isFetchingTopBrands, isFetchingTopStores
    } = this.props

    return (
      <>
        <div className="container">
          {user && (
            <Meta
              name="Following Page"
              title={`${user.attributes.username}'s Profile Page | Little Birdie`}
              description={`See ${user.attributes.username} on Little Birdie. See all their price drops, price drops, votes, vouchers, sale events and more!`} />
            )
          }
          <Breadcrumb breadcrumbs={location} positionWrappper="col-xl-12 ml-auto" />
        </div>


        <div className="">
          <div className="container ranking-page-header">
            <h1>Following</h1>
          </div>

          <div className="">

            {user && (
              <FollowedDealsList
                user={user}
                title="Deals you're following"
                description="Here's the list of Price Drops you've chosen to follow"
                currentSection="Price drops you're following"
                sectionPosition={4}
              />
            )}

            {user && (
              <FollowedCouponsList
                user={user}
                title="Vouchers you're following"
                description="Here's the list of great Promo Codes you've chosen to follow"
                currentSection="Vouchers you're following"
                sectionPosition={5}
              />
            )}


            <Collections
              match={match}
              hideViewAll
              placement="highlights"
              page="listing"
              currentSection="Collections"
              sectionPosition={6}
            />


            <TopBrandsSidebarList
              title="Brands you're following"
              showAll
              currentSection="Brands you're following"
              sectionPosition={7}
            />
            <TopStoresSidebarList
              title="Stores you're following"
              showAll
              currentSection="Stores you're following"
              sectionPosition={8}
            />

            <UserActivities
              user={user}
              slug={match.params.slug}
              title="My Activity"
              currentSection='My Activity'
              sectionPosition={9}
              numberOfActivities={5} />
          </div>
        </div>
      </>
      )
    }
}

const mapStateToProps = (state, props) => ({
  user: reducers.getUserBySlug(state, props.match.params.slug),
  current_user: reducers.getCurrentUser(state),
  followedStoreIds: reducers.getTopStoreIds(state),
  followedBrandIds: reducers.getTopBrandIds(state),
  followedStoreNames: reducers.getTopStoreIds(state).map(storeId => state.data.stores.byId[storeId].attributes.name),
  isFetchingTopStores: reducers.isFetchingTopStores(state),
  followedBrandNames: reducers.getTopBrandIds(state).map(brandId => state.data.brands.byId[brandId].attributes.name),
  isFetchingTopBrands: reducers.isFetchingTopBrands(state)
})

export default connect(mapStateToProps, {
  loadProfileFollowingPage
})(FollowingPage)
