import React, { useRef } from 'react'

import { startOptions } from '../checkboxOptions'
import useAccordionOpenHeader from '../useAccordionOpenHeader'

export default function StartDateOptions ({ handleSelect, handleClearClick, selectedDate, handleFilterPill, sectionPosition }) {
  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Start Date')

  function getSelectedLabel (selectedDate) {
    return _.find(startOptions?.values, (option) => option.value === selectedDate)?.label
  }
  return (
    <div className='list-group-item p-0'>
      <div id='headingStart'>
        <div ref={accordionHeader} aria-controls='collapseStart' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseStart' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Start Date</div>
            {selectedDate?.length > 0 && <span className='selected-filter-preview'>{getSelectedLabel(selectedDate)}</span>}
            {
              selectedDate?.length > 0 &&
                <button
                  type='button'
                  name='startRange'
                  onClick={e => handleClearClick(e)}
                  className='btn btn-clear ml-auto mr-2'
                >
                  Clear selection
                </button>
            }
          </div>
        </div>
      </div>
      <div aria-labelledby='headingStart' className='collapse' id='collapseStart'>
        {selectedDate?.length > 0 &&
          <div className='filter-pills-container'>
            <div
              type='button'
              onClick={() => handleFilterPill('startRange', selectedDate)}
              className='filter-pill ml-auto mr-2'
            >
              {getSelectedLabel(selectedDate)}
            </div>
          </div>}
        <div className='p-3'>
          <div className='checklist-group'>
            {_.filter(startOptions.values, sStartOption => selectedDate !== sStartOption.value)?.map(start => (
              <div key={`start-${start.key}`} className='custom-control custom-checkbox'>
                <input
                  className='custom-control-input'
                  id={`startCheck${start.key}`}
                  type='checkbox'
                  name='startRange'
                  checked={start.value === selectedDate}
                  value={start.value}
                  onChange={e => {
                    e.arrValue = start.value
                    handleSelect(e, start.value)
                  }}
                />
                <label className='custom-control-label' htmlFor={`startCheck${start.key}`}>
                  {start.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
