import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getBaseUrl, getBrandBySlug } from '../../../reducers'
import { getAssociationByKeyword, getTopListFilters, getAllDealFilters } from '../../../requesters'
import useAccordionOpenHeader from '../useAccordionOpenHeader'
import { FILTER_MIN_LIMIT, FILTER_MAX_LIMIT } from '../../../../../../constant'
import SearchFilter from '../SearchFilter'
import usePrevious from '../../../hooks/usePrevious'

export default function ElasticBrandOptions ({
  keyword, handleSelect, handleFilterPill,
  handleClearClick, selectedBrands, selectedCustomBrands,
  selectedStores, selectedDepartments,
  selectedCategories, selectedSubcategories, setBrand,
  selectedPriceRange, selectedDateRange, selectedDiscountRange,
  isReady, isSearchPage, topPriceDropsFollowingPageFilter, sectionPosition, isAllClear, voucherParam
}) {
  const [brands, setBrands] = useState([])
  const [searchedBrands, setSearchedBrands] = useState([])
  const [lockedBrands, setLockedBrands] = useState([])
  const [seeAll, setSeeAll] = useState(true)
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('')

  const baseUrl = useSelector(getBaseUrl)
  const location = useLocation()
  const viewAllDeals = location.pathname.includes('all-products')
  const activeBrand = useSelector(state => getBrandBySlug(state, setBrand))
  const accordionHeader = useRef(null)

  useAccordionOpenHeader(accordionHeader, sectionPosition, 'Brand')

  useEffect(() => {
    setSearchedBrands(getSearchedBrands)
  }, [filterSearchKeyword, brands])

  useEffect(() => {
    !isAllClear && setFilterSearchKeyword('')
  }, [isAllClear])

  const getSearchedBrands = () => brands?.filter(brand => _.startsWith(brand.key.toLowerCase(), filterSearchKeyword.toLowerCase())) || []

  const handleClear = e => {
    setFilterSearchKeyword('')
    handleClearClick(e)
  }

  const prevKeyword = usePrevious(keyword)

  useEffect(() => {
    function defaultDiscountRange () {
      return isSearchPage ? [] : [-1, 0]
    }

    function params (isDefaultFilters, viewAllDeals) {
      const param = {
        id: voucherParam || 'deal_search_v2',
        query: keyword,
        field: 'brand_name',
        per_page: 50,
        is_top_deal: keyword ? null : viewAllDeals ? false : true,
        store_name: selectedStores,
        brand_name: [],
        department_name: selectedDepartments,
        category_name: selectedCategories,
        subcategory_name: selectedSubcategories,
        price: selectedPriceRange,
        last_price_date: selectedDateRange,
        price_shift: selectedDiscountRange.length ? selectedDiscountRange.map(e => e * -1).reverse() : defaultDiscountRange(),
        applyFollowingPageFilter: topPriceDropsFollowingPageFilter
      }
      if (isDefaultFilters) {
        return { ...param, store_name: [], brand_name: [], department_name: [], category_name: [], subcategory_name: [], price: [], last_price_date: [] }
      } else {
        return param
      }
    }

    async function getElasticBrands (isDefaultFilters) {
      if (viewAllDeals) {
        const { response } = await getAllDealFilters(baseUrl, params(isDefaultFilters, viewAllDeals))
        response?.options?.length ? setBrands(_.concat(response?.options[0] || {}, _.orderBy(_.take(_.drop(response?.options, 1), FILTER_MAX_LIMIT - 1), [brand => brand?.key.toLowerCase()], ['asc']), _.takeRight(response?.options, (response?.options?.length > FILTER_MAX_LIMIT ? response?.options?.length - FILTER_MAX_LIMIT : 0)) || [])) : setBrands([])
      } else {
        const { response } = params(isDefaultFilters).is_top_deal ? await getTopListFilters(baseUrl, params(isDefaultFilters)) : await getAssociationByKeyword(baseUrl, params(isDefaultFilters))
        response?.options?.length ? setBrands(_.concat(response?.options[0] || {}, _.orderBy(_.take(_.drop(response?.options, 1), FILTER_MAX_LIMIT - 1), [brand => brand?.key.toLowerCase()], ['asc']), _.takeRight(response?.options, (response?.options?.length > FILTER_MAX_LIMIT ? response?.options?.length - FILTER_MAX_LIMIT : 0)) || [])) : setBrands([])
      }
    }

    if (isSearchPage && !keyword) return
    isReady && !activeBrand && _.isEmpty(lockedBrands) && !topPriceDropsFollowingPageFilter && prevKeyword === keyword ? getElasticBrands(false) : getElasticBrands(true)
    if (!_.isEmpty(selectedCustomBrands)) setLockedBrands(selectedCustomBrands)
    if (activeBrand) setLockedBrands([_.get(activeBrand, 'attributes.name')])
    if (topPriceDropsFollowingPageFilter) setLockedBrands(selectedBrands)
  }, [
    keyword, selectedStores,
    selectedDepartments, selectedCategories, selectedSubcategories,
    selectedPriceRange, selectedDateRange, selectedDiscountRange,
    isReady, voucherParam
  ])
  const handleSeeAllClick = () => {
    setSeeAll(!seeAll)
  }
  return (
    <div className='list-group-item p-0'>
      <div id='headingBrand'>
        <div ref={accordionHeader} aria-controls='collapseBrand' aria-expanded='false' className='header px-3 py-2 collapsed pointer' data-target='#collapseBrand' data-toggle='collapse'>
          <div className='header-label-wrapper py-1 d-flex justify-content-start align-items-center'>
            <div className='header-label'>Brand</div>
            {selectedBrands?.length > 0 && <span className='selected-filter-preview'>{selectedBrands.join(', ')}</span>}
            {selectedBrands?.length > 0 &&
              <button
                type='button'
                disabled={!_.isEmpty(lockedBrands)}
                name='brandNames'
                onClick={handleClear}
                className='btn btn-clear ml-auto mr-2'
              >
                Clear selection
              </button>
            }
          </div>
        </div>

      </div>
      <div aria-labelledby='headingBrand' className='collapse' id='collapseBrand' style={{}}>
        {
          selectedBrands?.length > 0 &&
            <div className='filter-pills-container'>
              {selectedBrands.map((brand, index) => (
                <div
                  key={index}
                  type='button'
                  onClick={() => _.isEmpty(lockedBrands) && handleFilterPill('brandNames', brand)}
                  className='filter-pill ml-auto mr-2'
                >
                  {brand === '' ? 'Other' : brand}
                </div>
              ))}
            </div>
        }
        <div className='p-3'>
          {
            _.isEmpty(lockedBrands) &&
              <SearchFilter
                filterSection='Brand'
                filterSearchKeyword={filterSearchKeyword}
                handleSearchFilter={keyword => setFilterSearchKeyword(keyword)}
              />
          }
          <div className='checklist-group'>
            {_.isEmpty(lockedBrands) &&
              (_.take(_.filter(searchedBrands, sBrand => !selectedBrands.includes(sBrand.key)), (seeAll ? FILTER_MIN_LIMIT : FILTER_MAX_LIMIT))).map(brand => (
                <div key={`brand-${brand.key}`} className='custom-control custom-checkbox'>
                  <input
                    className='custom-control-input'
                    id={`brandCustomCheck${brand.key}`}
                    type='checkbox'
                    checked={selectedBrands ? selectedBrands.includes(brand.key) : null}
                    name='brandNames'
                    value={brand.key}
                    onChange={e => handleSelect(e)}
                  />
                  <label className='custom-control-label' htmlFor={`brandCustomCheck${brand.key}`}>
                    {brand.key}
                    <span>
                      &nbsp;(
                      {brand.doc_count}
                      )
                    </span>
                  </label>
                </div>
              ))}
            {searchedBrands.length > FILTER_MIN_LIMIT
              ? (<span onClick={handleSeeAllClick} className='see-all-list'>{!seeAll ? 'See less' : 'See more'}</span>)
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
