import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../../../../components/Loader'
import {
  VoucherCard
} from '../../../../../components/ElasticListingCards'

import { useInfiniteScroll } from '../../BiInfiniteScroll/useInfiniteScroll'
import useElementOnScreen from '../../BiInfiniteScroll/useElementOnScreen'
import { getBaseUrl } from '../../../reducers'
import { getElasticVouchersResults } from '../../../requesters/'
import { PER_PAGE_LIMIT } from '../../../../../../constant'

const LoadMoreSpinner = (
  <div className='col-12 m-3 d-flex justify-content-center'>
    <Loader isLoading />
  </div>
)

function NoMoreDivider ({ countZero }) {
  return (
    <div className='col-12 text-center'>
      {countZero ? <div>No matches found</div> : <div>No More Results</div>}
      <hr className='mt-0' />
    </div>
  )
}

export default function VouchersResults ({
  value,
  order,
  searchMeta,
  keyword,
  filterOptions,
  handleStateUpdate,
  isFilter,
  handleSearchFilterDataLayer
}) {
  const baseUrl = useSelector(getBaseUrl)

  const {
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames
  } = filterOptions

  const params = {
    query: keyword,
    per_page: PER_PAGE_LIMIT,
    from: PER_PAGE_LIMIT * 0,
    order,
    value,
    store_names: storeNames,
    brand_names: brandNames,
    department_names: departmentNames,
    category_names: categoryNames,
    subcategory_names: subcategoryNames
  }

  const [
    items,
    isNextLoading,
    isPreLoading,
    itemUsers,
    preItems,
    preItemUsers,
    pageNo,
    prePageNo,
    isDataFinished,
    initPage,
    loadNext,
    loadPre,
    loadNew,
    resp
  ] = useInfiniteScroll({
    baseUrl,
    method: getElasticVouchersResults,
    params,
    storedPage: 0
  })

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  }

  const [nextRef, isNextVisible] = useElementOnScreen(options)
  const [preRef, isPreVisible] = useElementOnScreen(options)

  useEffect(() => {
    if (pageNo > 0 && !isNextLoading && isNextVisible) loadNext()
  }, [isNextVisible, pageNo])

  useEffect(() => {
    if (prePageNo >= 0 && !isPreLoading && isPreVisible) loadPre()
  }, [isPreVisible, prePageNo])

  useEffect(() => {
    if (resp?.counts) {
      const { counts } = resp
      const newState = {}
      if (!isFilter && !order && !value) {
        if (counts.vouchers) {
          newState.isVoucherEnabled = true
        } else {
          newState.isVoucherEnabled = false
        }
      }
      newState.voucherCount = counts.vouchers || 0
      newState.isFilter = false
      newState.isVoucherReady = true
      handleStateUpdate(newState)
    }
  }, [resp])

  useEffect(() => {
    async function loadData () {
      if (keyword) {
        const resp = await loadNew()
        handleSearchFilterDataLayer(resp, 'voucher')
      }
    }
    loadData()
  }, [
    brandNames,
    storeNames,
    departmentNames,
    categoryNames,
    subcategoryNames,
    order,
    value,
    searchMeta,
    keyword
  ])

  const displayOffer = (voucher) => {
    switch (voucher.coupon_type) {
      case 'percentage_discount':
        return `${parseInt(voucher.discount_amount)}% OFF`
      case 'dollar_discount':
        return `$${voucher.discount_amount % 1 === 0 ? Number(voucher.discount_amount).toFixed(0) : Number(voucher.discount_amount).toFixed(2)} OFF`
      case 'other':
        return 'Special offer'
      case 'bonus_item':
        return 'Free Gift'
      case 'free_shipping':
        return 'Free Shipping'
      default:
        return null
    }
  }

  return (
    <>
      {isDataFinished && !(isNextLoading || isPreLoading) && items?.length === 0
        ? <span>Oops! No results found. Try using different filters.</span>
        : (
          <div className='row listing-grid'>
            <div className='listing-card col-12'>
              {isPreLoading && LoadMoreSpinner}
            </div>
            <div style={{ height: '1px' }} ref={preRef} />
            {preItems.map((voucher, index) => (
              <Fragment key={index}>
                <VoucherCard
                  voucher={voucher}
                  wrapperClass='col-sm-6 col-lg-4'
                  displayOffer={(voucher) => displayOffer(voucher)}
                />
              </Fragment>
            ))}
            {items.map((voucher, index) => (
              <Fragment key={index}>
                <VoucherCard
                  voucher={voucher}
                  wrapperClass='col-sm-6 col-lg-4'
                  displayOffer={(voucher) => displayOffer(voucher)}
                />
              </Fragment>
            ))}
            <div className='listing-card col-12'>
              {!isDataFinished && isNextLoading && LoadMoreSpinner}
            </div>
            <div style={{ height: '1px' }} ref={nextRef} />
            {isDataFinished && !(isNextLoading || isPreLoading) && <NoMoreDivider countZero={!items.length} />}
          </div>
          )}
    </>
  )
}
