import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import Modal from "react-bootstrap/Modal"
import swal from "sweetalert"

import {
  crossIcon, heartFillIcon, heartIcon
} from "../../../../../components/Icons"

import Loader from "../../../../../components/Loader"
import activeAndSortedList from "../../../../../components/utils/activeAndSortedList"

import { getAllDepartments } from "../../../reducers"
import FollowButton from "../../buttons/FollowButton"
import onboardingFlowDataLayer from "../../../../../components/dataLayers/onboardingFlowDataLayer"

class DepartmentSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      nagCount: 0
    }
  }

  componentDidMount() {
    onboardingFlowDataLayer('onboarding-flow-view', null, 'departments', 3)
  }

  isActive = id => {
    const {
      activeDepartments
    } = this.props

    if (activeDepartments.includes(id)) {
      return "active"
    }
    return null
  }

  handleNext = () => {
    const { nagCount } = this.state
    const {
      handleNext,
      activeDepartments
    } = this.props

    if (nagCount < 1 && activeDepartments.length === 0) {
      swal({
        title: "Selecting at least one allows us to bring you more of what you love",
        button: "OK"
      })
      this.setState({ nagCount: nagCount + 1 })
    } else {
      handleNext()
    }
  }

  render() {
    const {
      hideModal,
      departments,
      selectDepartment,
      progressBar
    } = this.props

    const orderedActiveDepartmentsArr = activeAndSortedList(Object.values(departments))

    return (
      <Fragment>
        <Modal.Header className='d-flex flex-column border-0 pb-0'>
          <div className="align-self-end">
            <button type="button" className="close" onClick={hideModal}>
              <img src={crossIcon} />
            </button>
          </div>
          <div className='header d-flex justify-content-between w-100'>
              <h3>Departments</h3>
              <div className='label-skip' >
                <span onClick={this.props.handleSkip} >Skip</span>
              </div>
          </div>
          <div className="subtitle">Start personalising by picking as many as you'd like.</div>
          <div className='w-100'>
            {progressBar}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="favourites-category">
            <div className="row">
              {departments?.length === 0
                ? <Loader isLoading />
                : orderedActiveDepartmentsArr.map(department => {
                  let icon = this.isActive(department?.id) ? heartFillIcon : heartIcon
                  return (
                    <div
                      className="col-4 col-lg-2 mb-4"
                      key={`department-${department?.id}`}
                    >
                      <button
                        type="button"
                        onClick={() => selectDepartment(department?.id)}
                        className={`btn btn-wrapper rounded-pill d-flex border align-items-center justify-content-center favourites-category-item mx-auto ${this.isActive(department?.id)}`}>
                        <img width="60px" src={department?.links?.icon?.medium?.url}
                      />
                        <div className={`btn btn-follow ${this.isActive(department?.id)} btn-content`} >
                          <img src={icon} />
                        </div>
                      </button>
                      <div className="grid-item-label">{department?.attributes?.name}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto">
            <button
              type="button"
              className="btn btn-main rounded-pill mx-2"
              onClick={() => this.handleNext()}>
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Fragment>
    )
  }
}


const mapStateToProps = state => ({
  departments: getAllDepartments(state)
})

export default connect(mapStateToProps, {})(DepartmentSelect)
